import { styled } from "@mui/material/styles";
import variableStyles from "../../theme/variable-styles";

export const PotentialKeywordWrapper = styled("div")(() => ({
  padding: "24px",
  width: "818px",
  margin: "0 auto",
  "& .potential-form-wrapper": {
    backgroundColor: variableStyles.NaturalColor0,
    border: `1px solid ${variableStyles.NaturalColor300}`,
    boxShadow:
      "0px 39px 67.8px 0px #1956580A, 0px 21px 37px -15px #1956580F, 0px 1px 1px 0px #00000014",
    padding: "12px",
    borderRadius: "12px",
    "& .potential-form": {
      "& .inputs-wrapper": {
        gap: "12px",
        marginBottom: "12px",
        display: "flex",
        "& .url-form": {
          flex: "1 1 auto"
        },
        "& .keyword-form": {
          width: "210px",
          flex: "0 0 auto"
        }
      },
      "& .actions-wrapper": {
        gap: "8px",
        "& button": {
          "&.submit-button": { flex: "1 1 auto" },
          "&.reset-button": {
            backgroundColor: variableStyles.NaturalColor200,
            color: variableStyles.NaturalColor950,
            width: "83px",
            flex: "0 0 auto",
            boxShadow: "none"
          }
        }
      }
    },
    "& .potential-results": {
      paddingTop: "20px",
      marginTop: "20px",
      borderTop: `1px solid ${variableStyles.NaturalColor300}`,
      "& .results-header": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "8px",
        "& .header-text": {
          color: variableStyles.NaturalColor950,
          fontWeight: variableStyles.fwMedium,
          fontSize: "14px",
          lineHeight: "19.6px",
          "& span": {
            color: variableStyles.GreenPrimaryColor600,
            fontWeight: variableStyles.fwBold
          }
        },
        "& .download-button": {
          border: `1px solid ${variableStyles.NaturalColor400}`,
          color: variableStyles.NaturalColor950,
          gap: "6px"
        }
      }
    }
  }
}));
