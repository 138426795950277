import { IBaseResponseModel } from "../../models/common/models.type";
import { ILoginResponse, ILoginRequest } from "../../models/auth";
import api from "../remote/baseApi";
import ApiEndpoints from "../remote/apiEndpoints";
import { envConfig } from "../../config/env.config";

const API_AUTH = ApiEndpoints.AUTH;
const baseURL = envConfig.API_KEY_AUTH_ENDPOINT;

const loginWithPassword = (
  login: ILoginRequest
): Promise<IBaseResponseModel<ILoginResponse>> => {
  return api.post(baseURL, API_AUTH.LOGIN_WITH_PASSWORD.url, login);
};

const adminLoginWithPassword = (
  login: ILoginRequest
): Promise<IBaseResponseModel<ILoginResponse>> => {
  return api.post(baseURL, API_AUTH.ADMIN_LOGIN_WITH_PASSWORD.url, login);
};

const AuthService = {
  loginWithPassword,
  adminLoginWithPassword
};

export default AuthService;
