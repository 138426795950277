export interface IReduxSignalRState {
  idReport: string | null;
}

export enum ESignalRType {
  SET_SIGNALR_STATE = "SET_SIGNALR_STATE",
  CLEAR_SIGNALR = "CLEAR_SIGNALR",
}
export interface IActionSetSignalRState {
  type: ESignalRType.SET_SIGNALR_STATE;
  payload: { idReport: string };
}

export interface IActionClearSignalR {
  type: ESignalRType.CLEAR_SIGNALR;
  payload: {};
}

export type IActionSignalR = IActionSetSignalRState | IActionClearSignalR;
