import { Popover } from "@mui/material";
import { styled } from "@mui/material/styles";
import variableStyles from "../../../../theme/variable-styles";

export const CheckToolTitleWrapper = styled("div")(({ theme }) => ({
  padding: "24px",
  gap: "12px",
  "& .title-wrapper": {
    gap: "8px",
    flexWrap: "wrap",
    "& .title": {
      color: variableStyles.NaturalColor1000,
      fontWeight: variableStyles.fwSemiBold,
      fontSize: "24px",
      lineHeight: "29.05px",
    },
    "& .domain": {
      color: variableStyles.blueSecondary700,
      fontWeight: variableStyles.fwMedium,
      fontSize: "12px",
      lineHeight: "18px",
      padding: "2px 8px",
      borderRadius: "100px",
      backgroundColor: variableStyles.blueSecondary100,
      maxWidth: "234px",
    },
  },
  "& .action-wrapper": {
    gap: "4px",
    flex: "0 0 auto",
    "& .action-input-target": {
      width: "220px",
      flex: "0 0 auto",
    },
    "& .action-select-type": {
      width: "126px",
    },
    "& .search-button": {
      width: "102px",
      flex: "0 0 auto",
    },
  },
}));

export const CheckToolTitlePopoverWrapper = styled(Popover)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: "12px",
    border: `1px solid ${variableStyles.NaturalColor100}`,
    boxShadow: "0px 5px 24px 0px #0000003D",
    padding: "12px",
    width: "300px",
    "& .popover-title": {
      color: variableStyles.NaturalColor950,
      fontWeight: variableStyles.fwMedium,
      fontSize: "14px",
      lineHeight: "19.6px",
      marginBottom: "16px",
    },
    "& .popover-input-wrapper": {
      position: "relative",
      marginBottom: "12px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      gap: "12px",
      paddingBottom: "12px",
      borderBottom: `1px solid ${variableStyles.NaturalColor300}`,
    },
    "& .submit-button": {
      width: "100%",
    },
  },
}));
