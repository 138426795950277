import { styled } from "@mui/material/styles";
import variableStyles from "../../theme/variable-styles";

export const TabsCustomWrapper = styled("ul")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: "0 24px 0",
  gap: "24px",
  position: "relative",
  "&::after": {
    content: '""',
    width: "100%",
    height: "1px",
    backgroundColor: variableStyles.NaturalColor300,
    position: "absolute",
    bottom: "0",
    left: "0",
  },
  "& li": {
    position: "relative",
    overflow: "hidden",
    "& button": {
      color: variableStyles.NaturalColor500,
      fontWeight: variableStyles.fwSemiBold,
      fontSize: "14px",
      lineHeight: "20px",
      backgroundColor: "transparent",
      paddingBottom: "9px",
      transition: "all .2s ease-out",
    },
    "&.active, &:hover": {
      "& button": {
        color: variableStyles.GreenPrimaryColor500,
      },
      "&::after": { left: "0" },
    },
    "&::after": {
      content: '""',
      width: "100%",
      height: "2px",
      backgroundColor: variableStyles.GreenPrimaryColor500,
      position: "absolute",
      bottom: "0",
      zIndex: "1",
      left: "-100%",
      transition: "all .2s ease-out",
    },
  },
}));
