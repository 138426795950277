import api from "../remote/baseApi";
import ApiEndpoints from "../remote/apiEndpoints";
import { envConfig } from "../../config/env.config";
import { IBaseResponseModel } from "../../models/common/models.type";
import {
  ICrawlPageRes,
  IGetAuditOverviewErrorRes,
  IGetBackLinkAnchorReq,
  IGetBackLinkAnchorRes,
  IGetBackLinkAuthorizeScoreProfileReq,
  IGetBackLinkAuthorizeScoreProfileRes,
  IGetBackLinkOverviewReq,
  IGetBackLinkOverviewRes,
  IGetBackLinkOverviewTableReq,
  IGetBackLinkOverviewTableRes,
  IGetDomainAuditResourceReq,
  IGetDomainOrganicAccessReq,
  IGetDomainOrganicAccessRes,
  IGetDomainOrganicKeywordReq,
  IGetDomainOrganicKeywordRes,
  IGetDomainOrganicSearchReq,
  IGetDomainOrganicSearchRes,
  IGetDomainOverviewReq,
  IGetDomainOverviewRes,
  IGetErrorsFromLinksReq,
  IGetErrorsFromPagesReq,
  IGetHeadingErrorRes,
  IGetInformationAbilityRes,
  IGetRefferingDomainsReq,
  IGetRefferingDomainsRes,
  IGetRefferingIPsReq,
  IGetRefferingIPsRes,
  IGetSectionAuditReq,
} from "../../models/audit-report";
import { ISEOPagesRes } from "../../models/seo-page";
import { ISEOLinksRes } from "../../models/seo-link";

const API_REPORT_AUDIT = ApiEndpoints.REPORT_AUDIT;
const baseURL = envConfig.API_KEY_PROJECT_ENDPOINT;
const baseURLCrawlData = envConfig.API_KEY_CRAWL_DATA_ENDPOINT;

const getDomainOverviewPerform = (
  data: IGetDomainOverviewReq
): Promise<IBaseResponseModel<IGetDomainOverviewRes>> => {
  return api.post(
    baseURL,
    API_REPORT_AUDIT.GET_DOMAIN_AUDIT_OVERVIEW.url,
    data
  );
};

const getDomainOrganicKeyword = (
  data: IGetDomainOrganicKeywordReq
): Promise<IBaseResponseModel<IGetDomainOrganicKeywordRes[]>> => {
  return api.post(
    baseURL,
    API_REPORT_AUDIT.GET_DOMAIN_ORGANIC_KEYWORD.url,
    data
  );
};

const getDomainOrganicAccess = (
  data: IGetDomainOrganicAccessReq
): Promise<IBaseResponseModel<IGetDomainOrganicAccessRes[]>> => {
  return api.post(
    baseURL,
    API_REPORT_AUDIT.GET_DOMAIN_ORGANIC_ACCESS.url,
    data
  );
};

const getBackLinkOverview = (
  data: IGetBackLinkOverviewReq
): Promise<IBaseResponseModel<IGetBackLinkOverviewRes>> => {
  return api.post(
    baseURL,
    API_REPORT_AUDIT.GET_DOMAIN_BACKLINK_OVERVIEW.url,
    data
  );
};

const getBackLinkOverviewTable = (
  data: IGetBackLinkOverviewTableReq
): Promise<IBaseResponseModel<IGetBackLinkOverviewTableRes[]>> => {
  return api.post(
    baseURL,
    API_REPORT_AUDIT.GET_DOMAIN_BACKLINK_OVERVIEW_TABLE.url,
    data
  );
};

const getBackLinkAnchor = (
  data: IGetBackLinkAnchorReq
): Promise<IBaseResponseModel<IGetBackLinkAnchorRes[]>> => {
  return api.post(
    baseURL,
    API_REPORT_AUDIT.GET_DOMAIN_BACKLINK_ANCHOR.url,
    data
  );
};

const getBackLinkAuthorizeScoreProfile = (
  data: IGetBackLinkAuthorizeScoreProfileReq
): Promise<IBaseResponseModel<IGetBackLinkAuthorizeScoreProfileRes[]>> => {
  return api.post(
    baseURL,
    API_REPORT_AUDIT.GET_DOMAIN_BACKLINKAUTHORIZE_SCORE_PROFILE.url,
    data
  );
};

const getDomainOrganicSearch = (
  data: IGetDomainOrganicSearchReq
): Promise<IBaseResponseModel<IGetDomainOrganicSearchRes[]>> => {
  return api.post(
    baseURL,
    API_REPORT_AUDIT.GET_DOMAIN_ORGANIC_SEARCH.url,
    data
  );
};

const getSEOErrorSummary = (id: string): Promise<IBaseResponseModel<any>> => {
  return api.get(
    baseURL,
    `${API_REPORT_AUDIT.GET_SEO_ERROR_SUMMARY.url}/${id}`
  );
};

const getErrorsFromPages = (
  data: IGetErrorsFromPagesReq
): Promise<IBaseResponseModel<ISEOPagesRes>> => {
  return api.post(baseURL, API_REPORT_AUDIT.GET_ERROR_FROM_PAGES.url, data);
};

const getDomainAuditResources = (
  data: IGetDomainAuditResourceReq
): Promise<IBaseResponseModel<any>> => {
  return api.post(baseURL, API_REPORT_AUDIT.GET_RESOURCES.url, data);
};

const getErrorsFromLink = (
  data: IGetErrorsFromLinksReq
): Promise<IBaseResponseModel<ISEOLinksRes>> => {
  return api.post(baseURL, API_REPORT_AUDIT.GET_ERROR_FROM_LINKS.url, data);
};

const getRefferringDomains = (
  data: IGetRefferingDomainsReq
): Promise<IBaseResponseModel<IGetRefferingDomainsRes[]>> => {
  return api.post(baseURL, API_REPORT_AUDIT.GET_REFFERING_DOMAINS.url, data);
};

const getRefferringIPs = (
  data: IGetRefferingIPsReq
): Promise<IBaseResponseModel<IGetRefferingIPsRes[]>> => {
  return api.post(baseURL, API_REPORT_AUDIT.GET_REFFERING_IPS.url, data);
};

const getInformationAbility = (
  id: string
): Promise<IBaseResponseModel<IGetInformationAbilityRes>> => {
  return api.get(baseURL, API_REPORT_AUDIT.GET_INFORMATION_ABILITY.url, {
    params: { id },
  });
};

const getAuditReportSection = (
  params: IGetSectionAuditReq
): Promise<IBaseResponseModel<any>> => {
  return api.post(
    baseURL,
    API_REPORT_AUDIT.GET_AUDIT_REPORT_SECTION.url,
    params
  );
};

const getAuditReportHeadingError = (
  id: string
): Promise<IBaseResponseModel<IGetHeadingErrorRes>> => {
  return api.get(baseURL, API_REPORT_AUDIT.GET_HEADING_ERROR.url, {
    params: { id },
  });
};

const getAuditReportOverviewError = (
  id: string
): Promise<IBaseResponseModel<IGetAuditOverviewErrorRes>> => {
  return api.get(baseURL, API_REPORT_AUDIT.GET_OVERVIEW_ERRORS.url, {
    params: { id },
  });
};
const getHttpPagesInSitemapError = (
  id: string
): Promise<IBaseResponseModel<any>> => {
  return api.get(baseURL, API_REPORT_AUDIT.GET_HTTP_PAGE_IN_SITEMAP.url, {
    params: { id },
  });
};
const getPagesNotfoundInSitemapError = (
  id: string
): Promise<IBaseResponseModel<any>> => {
  return api.get(baseURL, API_REPORT_AUDIT.GET_PAGE_NOT_FOUND_IN_SITEMAP.url, {
    params: { id },
  });
};
const getNoIndexPagesInSitemapError = (
  id: string
): Promise<IBaseResponseModel<any>> => {
  return api.get(baseURL, API_REPORT_AUDIT.GET_NOINDEX_IN_SITEMAP.url, {
    params: { id },
  });
};
const getIncorrectCanonicalPagesInSitemapError = (
  id: string
): Promise<IBaseResponseModel<any>> => {
  return api.get(
    baseURL,
    API_REPORT_AUDIT.GET_INCORRECT_CANONICAL_IN_SITEMAP.url,
    {
      params: { id },
    }
  );
};

const getUIUXCrawlData = (
  auditId: string
): Promise<IBaseResponseModel<ICrawlPageRes>> => {
  return api.get(baseURLCrawlData, API_REPORT_AUDIT.GET_UIUX_CRAWL_DATA.url, {
    params: { auditId },
  });
};

const getPageNoIndex = (params: {
  id: string;
  pageSize: number;
}): Promise<IBaseResponseModel<any>> => {
  return api.post(baseURL, API_REPORT_AUDIT.GET_PAGE_NO_INDEX.url, params);
};

const AuditReportService = {
  getDomainOverviewPerform,
  getDomainOrganicKeyword,
  getDomainOrganicSearch,
  getDomainOrganicAccess,
  getBackLinkOverviewTable,
  getBackLinkAnchor,
  getBackLinkAuthorizeScoreProfile,
  getBackLinkOverview,
  getSEOErrorSummary,
  getErrorsFromPages,
  getErrorsFromLink,
  getDomainAuditResources,
  getRefferringDomains,
  getRefferringIPs,
  getInformationAbility,
  getAuditReportSection,
  getAuditReportHeadingError,
  getAuditReportOverviewError,
  getUIUXCrawlData,
  getHttpPagesInSitemapError,
  getPagesNotfoundInSitemapError,
  getNoIndexPagesInSitemapError,
  getIncorrectCanonicalPagesInSitemapError,
  getPageNoIndex,
};

export default AuditReportService;
