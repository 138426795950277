import { FormControl, InputLabel, SxProps, Theme } from "@mui/material";
import { IValue } from "../../models/common/models.type";
import { ImageCustom } from "..";
import { MenuItemWrapper, SelectCustomWrapper } from "./styles";
import IconArrow from "../../assets/images/common/icon_arrow_down_line_black.svg";
import { useState } from "react";
import variableStyles from "../../theme/variable-styles";

interface SelectCustomProps {
  value?: string | number;
  onChange: (value: any) => void;
  options: Array<IValue>;
  label?: string;
  className?: string;
  sx?: SxProps<Theme>;
  size?: "medium" | "small" | "large";
  variant?: "filled" | "outlined" | "standard";
  placeholder?: string;
  isSelectedIcon?: boolean;
  fullWidth?: boolean;
  isHasClear?: boolean;
  disabled?: boolean;
}

export default function SelectCustom(props: SelectCustomProps) {
  const {
    value,
    onChange,
    options,
    label,
    className = "",
    sx,
    size = "medium",
    variant = "outlined",
    placeholder,
    isSelectedIcon = false,
    fullWidth = true,
    isHasClear,
    disabled = false,
  } = props;

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const renderValue = (value: any) => {
    const result = options.find(o => o.value === value);
    if (!!result) {
      return result;
    }
    return undefined;
  };

  return (
    <FormControl fullWidth={fullWidth} disabled={disabled}>
      <InputLabel id="simple-select-label">{label}</InputLabel>
      <SelectCustomWrapper
        sx={sx}
        size={size}
        variant={variant}
        labelId="simple-select-label"
        id="simple-select"
        value={value}
        label={label}
        onChange={onChange}
        className={`${className} ${size}`}
        IconComponent={() => (
          <figure
            className="select-icon"
            aria-expanded={isOpen ? "true" : "false"}
          >
            <ImageCustom
              aria-expanded="true"
              src={IconArrow}
              alt="Select-Icon"
              width={"18px"}
              height={"18px"}
            />
          </figure>
        )}
        displayEmpty
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        renderValue={(selected: any) => {
          const result = renderValue(selected);
          if (!!result && !!placeholder) {
            return (
              <p className="selected-box">
                {!!isSelectedIcon && !!result.icon && (
                  <ImageCustom
                    src={result.icon}
                    alt="Select-Icon"
                    width={"18px"}
                    height={"18px"}
                  />
                )}
                {result?.title}
              </p>
            );
          } else return <p>{placeholder}</p>;
        }}
        MenuProps={{
          sx: {
            "& .MuiPaper-root": {
              backgroundColor: "transparent",
              paddingTop: "0",
              maxHeight: "500px",
              borderRadius: "8px",
              "&::-webkit-scrollbar": {
                width: "3px",
                height: "3px",
              },
              "&::-webkit-scrollbar-thumb": {
                borderRadius: "7px",
                backgroundColor: variableStyles.NaturalColor400,
              },
            },
            "& .MuiList-root": {
              padding: isHasClear ? "48px 0 0px" : "4px 0 0",
              borderRadius: "8px",
              backgroundColor: variableStyles.colorWhite,
              overflow: "auto",
            },
          },
        }}
      >
        {!!isHasClear && (
          <MenuItemWrapper key="clear" value="All" className="clear-menuitem">
            Clear filter
          </MenuItemWrapper>
        )}
        {options.map((value, index) => (
          <MenuItemWrapper value={value.value} key={index}>
            {!!value.icon && (
              <ImageCustom
                src={value.icon}
                alt="Select-Icon"
                width={"18px"}
                height={"18px"}
                className="select-icon"
              />
            )}
            {value.title}
          </MenuItemWrapper>
        ))}
      </SelectCustomWrapper>
    </FormControl>
  );
}
