import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@mui/material";
import { ImageCustom } from "../../../../../../../../../../components";
import moment from "moment";
import { DATETIME_FORMAT } from "../../../../../../../../../../config/constants";

interface IProps {
  data: string;
  timing: number;
  timestamp: number;
}

export default function Screenshot({ data, timing, timestamp }: IProps) {
  return (
    <Box>
      <Table size="small" aria-label="purchases">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              <ImageCustom src={data} width={100} height={100} />
            </TableCell>
            <TableCell>{timing}</TableCell>
            <TableCell align="right">
              {moment(timestamp).format(DATETIME_FORMAT)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
}
