import React from "react";
import { Helmet } from "react-helmet";
import { FormCheckLink } from "../../../../components";
import { CommonLayoutWrapper } from "./styles";

interface CommonLayoutProps {
  title: string;
  description: string;
  formTitle: string;
  formDescription: string;
  link: string;
}

export default function CommonLayout(props: CommonLayoutProps) {
  const { title, description, formTitle, formDescription, link } = props;

  return (
    <CommonLayoutWrapper>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="layout-header">
        <h1 className="layout-title">{title}</h1>
        <FormCheckLink
          title={formTitle}
          description={formDescription}
          link={link}
        />
      </div>
    </CommonLayoutWrapper>
  );
}
