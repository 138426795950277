import ImageCustom from "../Image";
import { ReportHeaderWrapper } from "./styles";
import IconWeb from "../../assets/images/common/icon_earth_line_grey.svg";
import IconCompany from "../../assets/images/common/icon_company_line_grey.svg";

interface ReportHeaderHeaderProps {
  className?: string;
  title: React.ReactNode;
  description: string;
  brandName: string;
  domain: string;
  height?: string;
}

export default function ReportHeader(props: ReportHeaderHeaderProps) {
  const {
    className = "",
    title,
    description,
    brandName,
    domain,
    height,
  } = props;

  return (
    <ReportHeaderWrapper className={`${className}`} height={height}>
      <div className="polygon-overlay"></div>
      <p className="madeby-text">
        Made by
        <br />
        WEBAUDIT
      </p>
      <div className="header-infor">
        {!!brandName && (
          <div className="infor-wrapper flex-start-align-center">
            <ImageCustom
              src={IconCompany}
              alt="Icon-Company"
              width={"16px"}
              height={"16px"}
            />
            <p className="infor-brandname">{brandName}</p>
          </div>
        )}
        <div className="infor-wrapper flex-start-align-center">
          <ImageCustom
            src={IconWeb}
            alt="Icon-Web"
            width={"16px"}
            height={"16px"}
          />
          <p className="infor-domain">Website: {domain}</p>
        </div>
      </div>
      <div className="header-intro">
        <p className="intro-title">{title}</p>
        <p className="intro-description">{description}</p>
      </div>
    </ReportHeaderWrapper>
  );
}
