import { styled } from "@mui/material/styles";
import variableStyles from "../../../../../../../theme/variable-styles";

export const ThematicReportWrapper = styled("div")(({}) => ({
  backgroundColor: variableStyles.NaturalColor100,
  border: `1px solid ${variableStyles.NaturalColor200}`,
  borderRadius: "12px",
  padding: "12px",
  "& .thematic-title": {
    color: variableStyles.NaturalColor900,
    fontWeight: variableStyles.fwMedium,
    fontSize: "12px",
    lineHeight: "18px",
    backgroundColor: variableStyles.NaturalColor50,
    border: `1px solid ${variableStyles.NaturalColor300}`,
    borderRadius: "6px",
    padding: "2px 8px",
    marginBottom: "12px",
    width: "min-content",
  },
  "& .thematic-index": {
    gap: "8px",
    marginBottom: "12px",
    marginLeft: "-4px",
    "& p": {
      color: variableStyles.NaturalColor800,
      fontWeight: variableStyles.fwMedium,
      fontSize: "28px",
      lineHeight: "33.89px",
    },
  },
  "& .thematic-button": {
    color: variableStyles.GreenPrimaryColor500,
    fontWeight: variableStyles.fwMedium,
    fontSize: "12px",
    lineHeight: "14.52px",
  },
}));
