import { styled } from "@mui/material/styles";
import variableStyles from "../../../theme/variable-styles";

export const ReportsWrapper = styled("div")(() => ({
  "& .my-report-header": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "4px 24px 16px",
    gap: "24px",
    borderBottom: `1px solid ${variableStyles.NaturalColor200}`,
    "& .my-report-title-wrapper": {
      maxWidth: "600px",
      "& .my-report-title": {
        display: "flex",
        gap: "8px",
        alignItems: "center",
        "& .header-name": {
          color: variableStyles.NaturalColor950,
          fontWeight: variableStyles.fwSemiBold,
          fontSize: "24px",
          lineHeight: "29.05px"
        },
        "& .header-count": {
          color: variableStyles.blueSecondary700,
          fontWeight: variableStyles.fwMedium,
          fontSize: "12px",
          lineHeight: "18px",
          padding: "2px 8px",
          backgroundColor: variableStyles.blueSecondary100,
          borderRadius: "16px",
          flex: "0 0 auto"
        }
      },
      "& .my-report-description": {
        color: variableStyles.NaturalColor900,
        fontWeight: variableStyles.fwRegular,
        fontSize: "14px",
        lineHeight: "22.4px"
      }
    },
    "& .header-action": { display: "flex", gap: "12px", flex: "0 0 auto" }
  },
  "& .my-report-wrapper": {
    padding: "24px",
    "& .report-filter-box": { marginBottom: "16px" }
  },
  "& .overflow-wrapper.scrollbar-small": {
    height: "calc(100vh - 286px)"
  },
  "& .report-filter-box": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .filter-box-select": {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      gap: "12px"
    }
  },
  "& .tablet-filter-box": {
    borderRadius: "12px",
    border: `1px solid ${variableStyles.NaturalColor300}`,
    padding: "9px 12px",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    boxShadow: "0px 0px 2px 0px #00000033",
    cursor: "pointer",
    "& .tablet-filter-text": {
      color: variableStyles.NaturalColor700,
      fontWeight: variableStyles.fwMedium,
      fontSize: "14px",
      lineHeight: "19.6px"
    }
  }
}));
