import { Collapse, Popover, Table } from "@mui/material";
import { styled } from "@mui/material/styles";
import variableStyles from "../../../theme/variable-styles";

export const TableBoxWrapper = styled("div")(() => ({
  "& .table-wrapper": {
    border: `1px solid ${variableStyles.NaturalColor200}`,
    borderRadius: "12px",
    boxShadow: "1px 2px 24px 0px #0000000F",
  },
}));

export const TableDataWrapper = styled(Table)(() => ({
  minWidth: "max-content",
  borderRadius: "12px",
  backgroundColor: variableStyles.NaturalColor0,
  tableLayout: "fixed",
  "& .MuiTableHead-root": {
    "& .MuiTableCell-root": {
      color: variableStyles.NaturalColor950,
      fontWeight: variableStyles.fwMedium,
      fontSize: "14px",
      lineHeight: "14px",
      padding: "17px 16px 16px",
      borderBottom: `1px solid ${variableStyles.NaturalColor200}`,
      backgroundColor: variableStyles.NaturalColor100,
    },
  },
  "& .MuiTableBody-root": {
    "& .MuiTableRow-root": {
      "&:hover": {
        backgroundColor: variableStyles.NaturalColor100,
        "& .sticky-column": {
          backgroundColor: `${variableStyles.NaturalColor100} !important`,
        },
      },
      "&.not-collapse": {
        "& .MuiTableCell-root": {
          borderBottom: `1px solid ${variableStyles.NaturalColor200}`,
          "&.action-list-cell-wrapper": {
            padding: "8px 12px 7px 0 !important",
          },
        },
      },
      "&:last-of-type": {
        "& .MuiTableCell-root": {
          borderBottom: `none`,
        },
      },
    },
    "& .MuiTableCell-root": {
      color: variableStyles.NaturalColor800,
      fontWeight: variableStyles.fwRegular,
      fontSize: "14px",
      lineHeight: "14px",
      borderBottom: `none`,
      padding: "12px 16px",
    },
  },
  "& .MuiTableBody-root .MuiTableRow-root:hover .action-cell, & .MuiTableBody-root .MuiTableRow-root:hover button, & .MuiTableBody-root .MuiTableRow-root.hover-active .action-cell,& .MuiTableBody-root .MuiTableRow-root.hover-active button":
    {
      visibility: "visible",
      opacity: 1,
      pointerEvents: "auto",
    },
  "& .action-list-cell-wrapper": {
    padding: "8px 12px 8px 0 !important",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    "& .hover-button": {
      borderRadius: "10px",
      backgroundColor: variableStyles.NaturalColor0,
      borderColor: variableStyles.NaturalColor400,
      color: variableStyles.NaturalColor950,
      opacity: "0",
      pointerEvents: "none",
      transition: "background-color .2s linear",
      "&:hover": {
        backgroundColor: variableStyles.NaturalColor50,
      },
    },
    "& .open-list-button": {
      opacity: "0",
      pointerEvents: "none",
      width: "40px",
      height: "40px",
    },
    "& .open-collapse-button": {
      opacity: "0",
      pointerEvents: "none",
      width: "40px",
      height: "40px",
      transition: "all .2s linear",
      "&.active": {
        transform: "rotate(-180deg)",
      },
    },
    "&.sticky-column": {
      right: "0",
      left: "auto",
      "& .open-list-button": {
        opacity: "1 !important",
        pointerEvents: "auto !important",
      },
      "& .open-collapse-button": {
        opacity: "1 !important",
        pointerEvents: "auto !important",
      },
    },
  },
  "& .action-cell-wrapper": {
    padding: "7px 12px 6px 0 !important",
    maxWidth: "180px",
    width: "180px",
    "&.sticky-column": {
      width: "92px !important",
      maxWidth: "92px !important",
      right: 0,
      left: "auto",
    },
    "&.notEdit": { maxWidth: "120px", width: "120px" },
    "& .action-cell": {
      display: "flex",
      gap: "16px",
      alignItems: "center",
      justifyContent: "space-between",
      opacity: "0",
      pointerEvents: "none",
      "& .hover-button": {
        borderRadius: "10px",
        backgroundColor: variableStyles.NaturalColor0,
        borderColor: variableStyles.NaturalColor400,
        color: variableStyles.NaturalColor950,
        transition: "all .25s linear",
        "&:hover": {
          backgroundColor: variableStyles.NaturalColor50,
        },
      },
      "& .button-wrapper": {
        display: "flex",
        gap: "12px",
        "& button": {
          width: "40px",
          height: "40px",
          opacity: ".6",
          transition: "all .25s linear",
          "&:hover": {
            opacity: "1",
          },
        },
      },
    },
  },
  "& .table-name-project": {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    color: variableStyles.NaturalColor950,
    fontWeight: variableStyles.fwMedium,
    fontSize: "14px",
    lineHeight: "19.6px",
    textAlign: "left",
    "& img": {
      flex: "0 0 auto",
    },
  },
  "& .MuiTableCell-root.table-cell-link": {
    "& a": {
      color: variableStyles.blueSecondary700,
      fontWeight: variableStyles.fwMedium,
      fontSize: "14px",
      lineHeight: "16px",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    "& button": {
      color: variableStyles.blueSecondary700,
      fontWeight: variableStyles.fwMedium,
      fontSize: "14px",
      lineHeight: "16px",
      width: "100%",
      textAlign: "left",
      display: "inline-block !important",
      backgroundColor: "transparent",
      "&.disabled": {
        pointerEvents: "none",
      },
      "&:hover": {
        textDecoration: "underline",
      },
    },
  },
  "& .ps__rail-x": {
    height: "7px",
  },
  "& .MuiTableCell-stickyHeader.sticky-column": { zIndex: "999" },
  "& .sticky-column": {
    position: "sticky",
    left: 0,
    background: variableStyles.NaturalColor0,
    zIndex: "99",
  },
  "& .collapse-temp": {
    width: "100%",
    borderBottom: `1px solid ${variableStyles.NaturalColor200}`,
  },
}));

export const TableActionListWrapper = styled(Popover)(() => ({
  "& .MuiPaper-root": {
    borderRadius: "12px",
    boxShadow:
      "0px 39px 67.8px 0px #1956580A, 0px 21px 37px -15px #1956580F, 0px 1px 1px 0px #00000014",
    padding: "12px 8px",
    width: "183px",
    "& .action-list": {
      "& button": {
        backgroundColor: "transparent",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: "8px",
        padding: "10px 6px",
        borderRadius: "6px",
        transition: "all .25s linear",
        width: "100%",
        "&:hover": {
          backgroundColor: variableStyles.NaturalColor100,
        },
        "&.delete-action": {
          "&:hover": {
            backgroundColor: variableStyles.Error100,
          },
        },
        "& p": {
          color: variableStyles.NaturalColor900,
          fontWeight: variableStyles.fwRegular,
          fontSize: "14px",
          lineHeight: "14px",
          textAlign: "left",
        },
      },
    },
  },
}));

export const TableCollapseWrapper = styled(Collapse)<{ width: number }>(
  ({ width }) => ({
    width: width - 8,
    borderBottom: `1px solid ${variableStyles.NaturalColor200}`,
    position: "sticky",
    left: "0",
    "&.MuiCollapse-hidden": {
      visibility: "visible",
    },
    "& .collapse-detail-infor": {
      display: "flex",
      padding: "12px",
      overflow: "hidden",
      width: "calc(100% - 19px)",
      alignItems: "center",
      justifyContent: "space-between",
      backgroundColor: variableStyles.NaturalColor100,
      margin: "12px 7px 12px 12px",
      borderRadius: "4px",
      "&.page": {
        "& .keyword-wrapper": {
          "& .keyword-title": {
            color: variableStyles.NaturalColor900,
            fontWeight: variableStyles.fwMedium,
            fontSize: "10px",
            lineHeight: "15px",
          },
          "& .keyword-content": {
            color: variableStyles.NaturalColor1000,
            fontWeight: variableStyles.fwMedium,
            fontSize: "12px",
            lineHeight: "18px",
          },
        },
        "& .url-list": {
          display: "flex",
          alignItems: "center",
          gap: "16px",
          "& .detail-order": {
            color: variableStyles.NaturalColor1000,
            fontWeight: variableStyles.fwMedium,
            fontSize: "12px",
            lineHeight: "18px",
          },
          "& .content-url": {
            color: variableStyles.blueSecondary800,
            fontWeight: variableStyles.fwRegular,
            fontSize: "12px",
            lineHeight: "18px",
          },
        },
      },
      "&.domain": {
        "& .list-domain": {
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "24px",
          "& .domain-detail": {
            display: "flex",
            alignItems: "center",
            gap: "12px",
            "& .detail-order": {
              color: variableStyles.NaturalColor0,
              fontWeight: variableStyles.fwSemiBold,
              fontSize: "14px",
              lineHeight: "21px",
              borderRadius: "50%",
              width: "24px",
              height: "24px",
              textAlign: "center",
              backgroundColor: variableStyles.GreenPrimaryColor400,
            },
            "& .content-name": {
              color: variableStyles.NaturalColor1000,
              fontWeight: variableStyles.fwMedium,
              fontSize: "12px",
              lineHeight: "18px",
            },
            "& .content-url": {
              color: variableStyles.blueSecondary800,
              fontWeight: variableStyles.fwRegular,
              fontSize: "12px",
              lineHeight: "18px",
            },
          },
        },
      },
    },
  })
);
