import { NavigateFunction } from "react-router-dom";
import { EPlans, Roles } from "../../models/common/models.enum";
import {
  EUserType,
  IActionClearUser,
  IActionGetUser,
  IActionSetUserState,
  IActionUser,
  IReduxUserState,
} from "../../models/redux/user";
import { IUser } from "../../models/user";

export const setUserState = (data: IUser): IActionSetUserState => ({
  type: EUserType.SET_USER_STATE,
  payload: { data },
});

export const getUser = (
  email: string,
  navigate: NavigateFunction
): IActionGetUser => ({
  type: EUserType.GET_USER,
  payload: { email, navigate },
});

export const clearUser = (): IActionClearUser => ({
  type: EUserType.CLEAR_USER,
  payload: {},
});

const defaultState: IReduxUserState = {
  data: {
    UserId: "",
    FullName: "",
    PhoneNumbers: "",
    Email: "",
    CompanyName: "",
    IsVerify: false,
    Status: false,
    Role: Roles.Customer,
    Avatar: null,
    PlanPackage: EPlans.NONE,
  },
};

export default function userReducer(
  state: IReduxUserState = defaultState,
  action: IActionUser
): IReduxUserState {
  switch (action.type) {
    case EUserType.SET_USER_STATE:
      return {
        data: action.payload.data,
      };
    case EUserType.CLEAR_USER:
      return {
        data: defaultState.data,
      };
    default:
      return state;
  }
}
