import { styled } from "@mui/material/styles";
import variableStyles from "../../../../theme/variable-styles";

export const PhotoBoxWrapper = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "10px 12px",
  borderRadius: "12px",
  border: `1px solid ${variableStyles.NaturalColor200}`,
  backgroundColor: variableStyles.NaturalColor100,
  "& button.MuiButtonBase-root": {
    minWidth: "0",
    width: "40px",
    height: "40px",
    borderRadius: "10px",
    flex: "0 0 auto"
  },
  "& button.MuiButtonBase-root.preview-button": {
    width: "42px",
    height: "42px",
    borderRadius: "3px",
    overflow: "hidden"
  },
  "&.error": {
    border: `1px solid ${variableStyles.Error400}`,
    backgroundColor: variableStyles.Error50
  },
  "& .infor-detail": {
    display: "flex",
    alignItems: "center",
    gap: "12px",
    "& .detail-image": {
      borderRadius: "3px"
    },
    "& .detail-text": {
      "& .text-name": {
        color: variableStyles.NaturalColor950,
        fontWeight: variableStyles.fwMedium,
        fontSize: "13px",
        lineHeight: "20px",
        marginBottom: "1px"
      },
      "& .text-size": {
        color: variableStyles.NaturalColor800,
        fontWeight: variableStyles.fwRegular,
        fontSize: "13px",
        lineHeight: "21px"
      },
      "& .text-error": {
        color: variableStyles.Error500,
        fontWeight: variableStyles.fwRegular,
        fontSize: "12px",
        lineHeight: "18px"
      }
    }
  },
  "& .status-action-box": {
    display: "flex",
    alignItems: "center",
    gap: "21px",
    "& .status-wrapper": {
      "& .status-scale": {
        color: variableStyles.NaturalColor950,
        fontWeight: variableStyles.fwSemiBold,
        fontSize: "13px",
        lineHeight: "21px"
      },
      "& .status-scale-size": {
        color: variableStyles.NaturalColor800,
        fontWeight: variableStyles.fwRegular,
        fontSize: "13px",
        lineHeight: "21px"
      }
    },
    "& .action-wrapper": { display: "flex", alignItems: "center", gap: "4px" }
  },
  "& .loading-box": {
    width: "40px",
    height: "40px",
    position: "relative",
    "& .lds-roller": {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%) scale(0.4)"
    }
  },
  "& .button-overlay": {
    position: "absolute",
    top: "0",
    left: "0",
    backgroundColor: "#00000066",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
}));
