import { styled } from "@mui/material/styles";
import variableStyles from "../../../../theme/variable-styles";

export const OverviewCommonTitleWrapper = styled("div")(() => ({
  "& .title-description": {
    backgroundColor: variableStyles.GreenPrimaryColor50,
    borderLeft: `4px solid ${variableStyles.GreenPrimaryColor400}`,
    padding: "8px 8px 8px 12px",
    display: "inline-block",
  },
  "& .title-wrapper": {
    gap: "12px",
    marginBottom: "12px",
  },
  "& .scores-wrapper": {
    gap: "6px",
    backgroundColor: variableStyles.NaturalColor100,
    border: `1px solid ${variableStyles.NaturalColor200}`,
    padding: "4px 4px 4px 12px",
    borderRadius: "100px",
    flex: "0 0 auto",
    "& .scores-text": {
      color: variableStyles.NaturalColor1000,
      fontWeight: variableStyles.fwMedium,
      fontSize: "12px",
      lineHeight: "12px",
    },
    "& .scores-number": {
      color: variableStyles.NaturalColor0,
      fontWeight: variableStyles.fwSemiBold,
      fontSize: "14px",
      lineHeight: "16.94px",
      padding: "4px 8px",
      backgroundColor: variableStyles.Success500,
      borderRadius: "100px",
    },
  },
}));
