import React, { useEffect, useState } from "react";
import { ImageCustom } from "..";
import { DragAndDropFileWrapper } from "./styles";
import IconUpload from "../../assets/images/common/icon_upload_fill_green.svg";
import IconExcel from "../../assets/images/common/icon_excel.svg";
import IconDelete from "../../assets/images/common/icon_delete_line_grey.svg";
import { Button } from "@mui/material";
import IconError from "../../assets/images/common/icon_warning_fill_red.png";

export interface DragAndDropFileProps {
  disabled?: boolean;
  helpertext?: string;
  placeholder?: string;
  classname?: string;
  handleUploadError?: (value: boolean) => void;
  onUploaded: (data: File, name: string) => void;
  name: string;
  handleRemoveFile: () => void;
}

export default function DragAndDropFile(props: DragAndDropFileProps) {
  const {
    disabled,
    helpertext = "",
    placeholder,
    classname = "",
    handleUploadError,
    name,
    onUploaded,
    handleRemoveFile
  } = props;

  const inputRef = React.useRef<any>(null);

  const [files, setFiles] = useState<{ name: string; size: string } | null>(
    null
  );
  const [validateFiles, setValidateFiles] = useState<File | null>();
  const [dragActive, setDragActive] = useState(false);
  const [error, setError] = useState("");
  const [fileUploaded, setFileUploaded] = useState<File | undefined>();
  const [checkSize, setCheckSize] = useState(false);
  const [checkType, setCheckType] = useState(false);
  const [fileProgress, setFileProgress] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    handleUploadError && handleUploadError(!!error && error.length > 0);
  }, [error]);

  useEffect(() => {
    if (fileUploaded) {
      onUploaded(fileUploaded, name);
    }
  }, [fileUploaded]);

  useEffect(() => {
    if (checkType && checkSize && !disabled) {
      let value = validateFiles;
      if (value) {
        setFileUploaded(value);
      }
      setCheckSize(false);
      setCheckType(false);
      setError("");

      inputRef.current.value = null;
    }
  }, [checkSize, checkType]);

  const handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setError("");
    if (!disabled) {
      if (e.type === "dragenter" || e.type === "dragover") {
        setDragActive(true);
      } else if (e.type === "dragleave") {
        setDragActive(false);
      }
    }
  };

  const checkValidate = (e: any, typeAction: "drop" | "click") => {
    e.preventDefault();
    e.stopPropagation();
    if (!disabled) {
      setError("");
      let dataClick: any;
      if (typeAction === "click") {
        dataClick = e.target.files[0];
      } else {
        dataClick = e.dataTransfer.files[0];
      }
      setFiles({
        name: dataClick.name,
        size: `${(dataClick.size / 1024 / 1024).toFixed(2)} MB`
      });

      const reader = new FileReader();

      reader.onprogress = event => {
        if (event.lengthComputable) {
          const progress = Math.round((event.loaded * 100) / event.total);
          setFileProgress(progress);
        }
      };

      reader.onloadend = () => {
        setFileProgress(100);
        setLoading(false);
      };
      if (typeAction === "click") {
        reader.readAsDataURL(e.target.files[0]);
      } else {
        reader.readAsDataURL(e.dataTransfer.files[0]);
      }

      setDragActive(false);
      setValidateFiles(dataClick);

      if (dataClick.size / 1024 / 1024 > 15) {
        setError(`Bạn đã upload file vượt quá dung lượng`);
        setCheckSize(false);
        inputRef.current.dataClick = null;
      } else {
        setCheckSize(true);
        setError("");
      }
      if (
        ![
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ].includes(dataClick.type)
      ) {
        setError(`Bạn đã upload sai định dạng`);
        setCheckType(false);
        inputRef.current.dataClick = null;
      } else {
        setCheckType(true);
        setError("");
      }
    }
  };

  const onButtonClick = () => {
    if (!disabled) {
      inputRef.current.click();
    }
  };

  const onDelete = () => {
    setFiles(null);
    setError("");
    setCheckSize(false);
    setCheckType(false);
    setFileProgress(null);
    handleRemoveFile();
  };

  return (
    <DragAndDropFileWrapper
      id="form-file-upload"
      className={classname}
      error={error.length > 0 || helpertext.length > 0}
      disabled={disabled}
      progresspercent={fileProgress}
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
      onDragOver={handleDrag}
    >
      <div className="drag-drop-box">
        <>
          <input
            ref={inputRef}
            type="file"
            id="input-file-upload"
            multiple={false}
            onChange={e => checkValidate(e, "click")}
            className="input-file-upload"
            accept="application/*"
          />
          <div
            id="label-file-upload"
            className={`file-upload-box flex-column-center ${
              dragActive ? "drag-active" : ""
            }`}
          >
            <p className="upload-text">
              {placeholder ? (
                placeholder
              ) : (
                <>
                  <ImageCustom
                    src={IconUpload}
                    alt="Icon-Upload"
                    width={"40px"}
                    height={"40px"}
                    className="upload-icon"
                  />
                  <span className="upload-title">Kéo tệp vào đây</span>
                  <span className="upload-subtitle">
                    Hoặc{" "}
                    <Button
                      size="large"
                      variant="text"
                      className="choose-file-button"
                      onClick={onButtonClick}
                    >
                      Chọn File
                    </Button>{" "}
                    từ máy tính của bạn
                  </span>
                </>
              )}
            </p>
          </div>
          {dragActive && !disabled && (
            <div
              className="drag-file-box"
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={e => checkValidate(e, "drop")}
            ></div>
          )}
        </>
      </div>
      {files !== null && (
        <div className={`file-infor ${error.length > 0 ? "error" : ""}`}>
          <div className="infor-detail">
            <ImageCustom
              src={IconExcel}
              alt="Icon-Excel"
              width={"36px"}
              height={"36px"}
              className="detail-icon"
            />
            <div className="detail-text">
              <p className="text-name">{files.name}</p>
              {error.length > 0 ? (
                <p className="upload-error error-text">{error}</p>
              ) : (
                <p className="text-size">{files.size}</p>
              )}
            </div>
          </div>
          {fileProgress !== null && fileProgress < 100 ? (
            <div className="infor-progress-bar">
              <div className="progress-bar-child"></div>
            </div>
          ) : (
            <>
              {error.length > 0 ? (
                <ImageCustom
                  src={IconError}
                  alt="Icon-Error"
                  width={"24px"}
                  height={"24px"}
                />
              ) : (
                <Button
                  size="large"
                  variant="text"
                  className="delete-file-button"
                  onClick={onDelete}
                >
                  <ImageCustom
                    src={IconDelete}
                    alt="Icon-Delete"
                    width={"18px"}
                    height={"18px"}
                  />
                </Button>
              )}
            </>
          )}
        </div>
      )}
    </DragAndDropFileWrapper>
  );
}
