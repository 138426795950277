import React from "react";
import { DEFAULT_BASE_PAGING_RES } from "../../../../../../../common/constants/value.constant";
import { ProgressBar } from "../../../../../../../components";
import {
  ITableNavDetailNoPaginationHeader,
  TableNavDetailNoPagination,
} from "../../../../../../../components/Table/TableNavDetailNoPagination";

export default function OrganicCompetitorsTab() {
  const headersOrganicTable: ITableNavDetailNoPaginationHeader<any>[] = [
    {
      field: "url",
      title: `Website`,
      className: "table-cell-link",
      width: "180px",
      handleItem: (item: any) => {
        return (
          <a className="fit-one-line" target="_blank" rel="noopener noreferrer">
            {item.url}
          </a>
        );
      },
    },
    {
      field: "competitorRelevance",
      title: `Competitor Relevence`,
      width: "140px",
      className: "table-cell-progress",
      handleItem: (item: any) => {
        return (
          <div>
            <p className="progress-percent">{item.competitorRelevance || 0}%</p>
            <ProgressBar
              percent={item.competitorRelevance || 0}
              className="progress-bar"
              type={"normal"}
              height={"4px"}
            />
          </div>
        );
      },
    },
    {
      field: "organicKeywords",
      title: `Common keywords`,
      width: "120px",
    },
    {
      field: "commonKeywords",
      title: `Organic keyword`,
      width: "140px",
    },
    {
      field: "commonKeywords",
      title: `Organic traffic`,
      width: "140px",
    },
    {
      field: "commonKeywords",
      title: `Adword keyword`,
      width: "140px",
    },
  ];
  return (
    <div className="organic-tab-wrapper tab-common-wrapper">
      <div className="overview-table">
        <TableNavDetailNoPagination
          headersTable={headersOrganicTable}
          data={{
            ...DEFAULT_BASE_PAGING_RES,
          }}
        />
      </div>
    </div>
  );
}
