import React, { useCallback, useState } from "react";
import { useMediaQuery } from "@mui/material";
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Sector,
} from "recharts";
import { ContentType } from "recharts/types/component/DefaultLegendContent";
import { PieChartColors } from "../../../common/constants/value.constant";
import CommonHandle from "../../../common/handles/common.handles";
import { IPieChartValue } from "../../../models/common/models.type";
import variableStyles from "../../../theme/variable-styles";
import { PieChartDonutShapeWrapper } from "./styles";
import { idID } from "@mui/material/locale";

interface PieChartProps {
  data: IPieChartValue[];
  isLegendBottom?: boolean;
  className?: string;
  isSmall?: boolean;
  onClickNavigate?: (value: string) => void;
}

export default function PieChartDonutShape(props: PieChartProps) {
  const {
    data,
    isLegendBottom = false,
    className = "",
    isSmall = false,
    onClickNavigate,
  } = props;

  const [activeIndex, setActiveIndex] = useState(0);

  const matches1180 = useMediaQuery("(max-width:1180px)");

  const onPieEnter = useCallback(
    (_: any, index: any) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  const renderActiveShape = (props: any) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";
    const sum = data.reduce((acc, o) => acc + o.value, 0);

    return (
      <g>
        <text
          x={cx}
          y={cy}
          dy={8}
          textAnchor="middle"
          fontSize={matches1180 || isSmall ? "11px" : "14px"}
          fontWeight={variableStyles.fwMedium}
          fill={variableStyles.NaturalColor1000}
        >
          Total: {CommonHandle.nFormatter(sum, 1)}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />

        <path d={`M${sx},${sy}L${mx},${my}`} stroke={fill} fill="none" />
        <circle cx={mx} cy={my} r={2} fill={fill} stroke="none" />
        <text
          x={mx + (cos >= 0 ? 5 : -5)}
          y={my - 2}
          textAnchor={textAnchor}
          fill={variableStyles.NaturalColor1000}
          fontSize={matches1180 || isSmall ? "12px" : "14px"}
        >{`${matches1180 || isSmall ? `${CommonHandle.formatNumber(value, "vn")}` : `${payload.name}: ${CommonHandle.formatNumber(value, "vn")}`}`}</text>
        <text
          x={mx + (cos >= 0 ? 5 : -5)}
          y={my - 6}
          dy={18}
          textAnchor={textAnchor}
          fontSize={matches1180 || isSmall ? "10px" : "12px"}
          fill={variableStyles.NaturalColor700}
        >
          {matches1180 || isSmall
            ? `${(percent * 100).toFixed(2)}%`
            : `(Rate ${(percent * 100).toFixed(2)}%)`}
        </text>
      </g>
    );
  };

  const renderLegend: ContentType = () => {
    return (
      <ul className="legend-list">
        {data?.map((entry: IPieChartValue, index: any) => (
          <li
            key={`item-${index}`}
            onMouseEnter={() => setActiveIndex(index)}
            onClick={() => {
              if (!!onClickNavigate) {
                onClickNavigate(entry.name);
              }
            }}
            className={`${!!onClickNavigate ? "pointer" : ""}`}
          >
            <div
              className={`legend-color`}
              style={{ backgroundColor: `${PieChartColors[index]}` }}
            ></div>
            <p className="legend-text">{entry.name}</p>
          </li>
        ))}
      </ul>
    );
  };

  const RADIAN = Math.PI / 180;

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
    value,
  }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill={variableStyles.NaturalColor0}
        fontSize={"12px"}
        textAnchor="middle"
        dominantBaseline="central"
      >
        {`${value}: ${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };
  return (
    <PieChartDonutShapeWrapper
      className={`${isLegendBottom ? "isLegendBottom" : ""} ${className}`}
    >
      <ResponsiveContainer>
        <PieChart
          style={{
            position: "relative",
            zIndex: "1",
          }}
        >
          <Pie
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            labelLine={false}
            data={data}
            innerRadius={matches1180 || isSmall ? "30%" : "40%"}
            outerRadius={"50%"}
            dataKey="value"
            onMouseEnter={onPieEnter}
          >
            {data.map((_, index) => (
              <Cell
                key={`cell-${index}`}
                fill={PieChartColors[index % PieChartColors.length]}
              />
            ))}
          </Pie>
          <Legend
            layout="vertical"
            align={isLegendBottom ? "center" : "right"}
            verticalAlign={isLegendBottom ? "bottom" : "middle"}
            content={renderLegend}
          />
        </PieChart>
      </ResponsiveContainer>
    </PieChartDonutShapeWrapper>
  );
}
