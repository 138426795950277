import { styled } from "@mui/material/styles";
import variableStyles from "../../../../theme/variable-styles";

export const CheckToolServiceIntroWrapper = styled("div")(() => ({
  "& .service-intro": {
    margin: "40px 0",
    textAlign: "center",
    "& .service-title": {
      color: variableStyles.NaturalColor1000,
      fontWeight: variableStyles.fwBold,
      fontSize: "32px",
      lineHeight: "48px",
      marginBottom: "2px",
    },
    "& .service-description": {
      color: variableStyles.NaturalColor950,
      fontWeight: variableStyles.fwRegular,
      fontSize: "14px",
      lineHeight: "24px",
    },
  },
  "& .card-list": {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gap: "24px",
    margin: "0 auto",
    "@media (min-width:1140px)": {
      width: "873px",
      gridTemplateColumns: "1fr 1fr 1fr",
    },
    "@media (max-width:1139.98px)": {
      width: "100%",
      padding: "0 20px",
      gridTemplateColumns: "1fr 1fr",
    },
  },
}));
