import { Button } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { ImageCustom } from "..";
import { DragAndDropImageWrapper } from "./styles";
import IconUpload from "../../assets/images/common/icon_upload_fill_green.svg";

export interface DragAndDropFileProps {
  disabled?: boolean;
  placeholder?: string;
  title: string;
  classname?: string;
  onUploaded: (value: { data: Blob; error: string | null }[]) => void;
}

export default function DragAndDropImage(props: DragAndDropFileProps) {
  const { disabled, placeholder, title, classname = "", onUploaded } = props;

  const inputRef = useRef<any>(null);

  const [dragActive, setDragActive] = useState(false);
  const [filesUploaded, setFilesUploaded] = useState<
    { data: Blob; error: string | null }[]
  >([]);

  useEffect(() => {
    if (filesUploaded) {
      onUploaded(filesUploaded);
    }
  }, [filesUploaded]);

  const handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (!disabled) {
      if (e.type === "dragenter" || e.type === "dragover") {
        setDragActive(true);
      } else if (e.type === "dragleave") {
        setDragActive(false);
      }
    }
  };

  const checkValidate = (e: any, typeAction: "drop" | "click") => {
    e.preventDefault();
    e.stopPropagation();
    if (!disabled) {
      setDragActive(false);
      let dataClick: any;
      if (typeAction === "click") {
        dataClick = e.target.files;
      } else {
        dataClick = e.dataTransfer.files;
      }

      const dataUpload: {
        data: Blob;
        error: string | null;
      }[] = [];

      for (const key in dataClick) {
        if (key !== "length" && key !== "item") {
          let value = dataClick[key];

          let dataImage: {
            data: Blob;
            error: string | null;
          } = { data: value, error: null };

          if (
            ![
              "image/jpg",
              "image/png",
              "image/jpeg",
              "image/gif",
              "image/svg+xml"
            ].includes(value.type)
          ) {
            dataImage.error = `Bạn đã upload sai định dạng`;
          } else {
            if (value.size / 1024 / 1024 > 5) {
              dataImage.error = `Bạn đã upload file vượt quá dung lượng (< 5MB)`;
            }
          }

          dataUpload.push(dataImage);
        }
      }
      setFilesUploaded(dataUpload);
      inputRef.current.value = null;
    }
  };

  const onButtonClick = () => {
    if (!disabled) {
      inputRef.current.click();
    }
  };

  return (
    <DragAndDropImageWrapper
      className={`${classname}`}
      disabled={disabled ? 1 : 0}
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
      onDragOver={handleDrag}
    >
      <div className="drag-drop-box">
        <>
          <input
            ref={inputRef}
            type="file"
            id="input-file-upload"
            multiple={true}
            onChange={e => checkValidate(e, "click")}
            className="input-file-upload"
            accept="image/*"
          />
          <div
            id="label-file-upload"
            className={`file-upload-box flex-column-center ${
              dragActive ? "drag-active" : ""
            }`}
          >
            <p className="upload-text">
              {placeholder ? (
                placeholder
              ) : (
                <>
                  <ImageCustom
                    src={IconUpload}
                    alt="Icon-Upload"
                    width={"40px"}
                    height={"40px"}
                    className="upload-icon"
                  />
                  <span className="upload-title">{title}</span>
                  <span className="upload-subtitle">
                    Hoặc{" "}
                    <Button
                      size="large"
                      variant="text"
                      className="choose-file-button"
                      onClick={onButtonClick}
                    >
                      Chọn File
                    </Button>{" "}
                    từ máy tính của bạn
                  </span>
                </>
              )}
            </p>
          </div>
          {dragActive && !disabled && (
            <div
              className="drag-file-box"
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={e => checkValidate(e, "drop")}
            ></div>
          )}
        </>
      </div>
    </DragAndDropImageWrapper>
  );
}
