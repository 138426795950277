import api from "../remote/baseApi";
import ApiEndpoints from "../remote/apiEndpoints";
import { envConfig } from "../../config/env.config";
import {
  IBasePagingReq,
  IBasePagingRes,
  IBaseResponseModel
} from "../../models/common/models.type";
import {
  IGetProjectReq,
  IProject,
  IProjectUpdateCreateReq
} from "../../models/project";

const API_PROJECT = ApiEndpoints.PROJECT;
const baseURL = envConfig.API_KEY_PROJECT_ENDPOINT;

const createProject = (
  data: IProjectUpdateCreateReq
): Promise<IBaseResponseModel<IProject>> => {
  return api.post(baseURL, API_PROJECT.CREATE_PROJECT.url, data);
};

const updateProject = (
  data: IProjectUpdateCreateReq
): Promise<IBaseResponseModel<IProject>> => {
  return api.put(baseURL, API_PROJECT.UPDATE_PROJECT.url, data);
};

const getProjectList = (
  params: IGetProjectReq
): Promise<IBaseResponseModel<IBasePagingRes<IProject>>> => {
  return api.get(baseURL, API_PROJECT.GET_PROJECT_LIST.url, {
    params: params
  });
};

const getProjectDetail = (
  id: string
): Promise<IBaseResponseModel<IProject>> => {
  return api.get(baseURL, `${API_PROJECT.GET_PROJECT_DETAIL.url}/${id}`);
};

const deleteProject = (params: {
  id: string;
}): Promise<IBaseResponseModel<boolean>> => {
  return api.delete(baseURL, `${API_PROJECT.DELETE_PROJECT.url}`, {
    params: params
  });
};

const ProjectService = {
  createProject,
  updateProject,
  getProjectList,
  getProjectDetail,
  deleteProject
};

export default ProjectService;
