import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Highlighter from "react-highlight-words";

interface HighlightedContentProps {
  content: string;
  keywords: string[];
  keywordColors: { [keyword: string]: string };
  className?: string;
}

function HighlightedContent(props: HighlightedContentProps) {
  const { content, keywords, keywordColors, className = "" } = props;

  const [highlightedText, setHighlightedText] = useState<string>("");

  useEffect(() => {
    setHighlightedText(content);
  }, [content]);

  const getRandomColor = () => {
    return "#" + Math.floor(Math.random() * 16777215).toString(16);
  };

  const getHighlightStyle = (keyword: string) => ({
    color: keywordColors[keyword] || getRandomColor(),
    fontWeight: "bold" as const,
    backgroundColor: "transparent"
  });

  const findChunks = ({
    searchWords,
    textToHighlight
  }: {
    searchWords: Array<string | RegExp>;
    textToHighlight: string;
  }) => {
    const chunks: { start: number; end: number; keyword: string }[] = [];
    const text = textToHighlight;

    searchWords.forEach(keyword => {
      if (typeof keyword === "string") {
        const escapedKeyword = keyword.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
        const regex = new RegExp(
          `(?<=^|[^a-zA-Z\u00C0-\u1EF9])${escapedKeyword}(?=[^a-zA-Z\u00C0-\u1EF9]|$)`,
          "gi"
        );

        let match;
        while ((match = regex.exec(text)) !== null) {
          chunks.push({
            start: match.index,
            end: match.index + match[0].length,
            keyword: keyword.toLowerCase() // Lưu từ khóa gốc ở dạng chữ thường
          });
        }
      }
    });

    return chunks;
  };

  return (
    <Box
      className={className}
      sx={{
        width: "100%",
        overflowWrap: "break-word"
      }}
    >
      <Highlighter
        searchWords={keywords}
        textToHighlight={highlightedText}
        highlightTag={({ children, chunk }) => {
          const keyword = chunk
            ? chunk.keyword
            : children.toString().toLowerCase();
          return <span style={getHighlightStyle(keyword)}>{children}</span>;
        }}
        findChunks={findChunks}
      />
    </Box>
  );
}

export default React.memo(HighlightedContent);
