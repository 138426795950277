import "react-perfect-scrollbar/dist/css/styles.css";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import reduxStore from "../src/redux/store";
import "./App.css";
import Main from "./pages/Main";
import "moment/locale/vi";
import Toast from "./components/Toast/Toast";
import ConfirmModal from "./components/ConfirmModal/ConfirmModal";
import { useEffect, useState } from "react";
import { CountryLanguage, changeLanguage } from "./i18n/i18n";
import {
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from "@microsoft/signalr";

const App = () => {
  const [connection, setConnection] = useState<HubConnection>();
  const [idReport, setIdReport] = useState<string>();

  const joinReportStatus = async () => {
    try {
      const connection = new HubConnectionBuilder()
        .withUrl("http://112.213.88.229:9100/miko-notify")
        .withAutomaticReconnect()
        .build();
      await connection.start();
      connection.on("UpdateAudit", (message: any) => {
        setIdReport(message);
      });
      setConnection(connection);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    const locale = localStorage.getItem("language");

    if (!locale) {
      localStorage.setItem("language", CountryLanguage.EN);
      changeLanguage(CountryLanguage.EN);
    } else {
      changeLanguage(locale as CountryLanguage);
    }
    joinReportStatus();
  }, []);

  useEffect(() => {
    return () => {
      if (!!connection) {
        connection.stop();
      }
    };
  }, []);

  return (
    <Provider store={reduxStore.store}>
      <PersistGate loading={null} persistor={reduxStore.persistor}>
        <Toast />
        <Main idReport={idReport} />
        <ConfirmModal />
      </PersistGate>
    </Provider>
  );
};

export default App;
