import React from "react";
import { EReportType } from "../../models/common/models.enum";
import { ReportTagsWrapper } from "./styles";

interface ReportTagsProps {
  type: EReportType;
}

export default function ReportTypeTags(props: ReportTagsProps) {
  const { type } = props;

  const renderText = () => {
    switch (type) {
      case EReportType.DomainAudit:
        return "Domain Audit";
      case EReportType.DomainAuditSEO:
        return "Domain Audit SEO";
      case EReportType.DomainAuditUX:
        return "Domain Audit UX";
      case EReportType.DomainCompetitive:
        return "Domain Competitive";
      case EReportType.PageAudit:
        return "Page Audit";
      default:
        return "Page Competitive";
    }
  };
  const renderClassname = () => {
    switch (type) {
      case EReportType.DomainAudit:
        return "blue";
      case EReportType.DomainAuditSEO:
        return "brown";
      case EReportType.DomainAuditUX:
        return "purple";
      case EReportType.DomainCompetitive:
        return "green";
      case EReportType.PageAudit:
        return "grey";
      default:
        return "yellow";
    }
  };
  return (
    <ReportTagsWrapper className={renderClassname()}>
      {renderText()}
    </ReportTagsWrapper>
  );
}
