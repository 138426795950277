import { styled } from "@mui/material/styles";
import variableStyles from "../../theme/variable-styles";

export const ReportStatusTagsWrapper = styled("div")(() => ({
  fontWeight: variableStyles.fwMedium,
  fontSize: "14px",
  lineHeight: "20px",
  position: "relative",
  paddingLeft: "14px",
  display: "flex",
  gap: "10px",
  alignItems: "center",
  justifyContent: "flex-start",
  "&::before": {
    content: '""',
    width: "6px",
    height: "6px",
    borderRadius: "50%",
    position: "absolute",
    top: "50%",
    left: "0",
    transform: "translateY(-50%)",
  },
  "&.in-proccess": {
    paddingLeft: "30px",
    "&::before": {
      display: "none",
    },
    "& .proccess-loading": {
      position: "absolute",
      left: "0",
      top: "50%",
      transform: "translateY(-50%)",
    },
  },
  "&.blue": {
    color: variableStyles.blueSecondary700,
    "&::before": {
      backgroundColor: variableStyles.blueSecondary700,
    },
  },
  "&.orange": {
    color: variableStyles.Warning700,
    "&::before": {
      backgroundColor: variableStyles.Warning700,
    },
  },
  "&.green": {
    color: variableStyles.Success600,
    "&::before": {
      backgroundColor: variableStyles.Success600,
    },
  },
  "&.grey": {
    color: variableStyles.NaturalColor500,
    "&::before": {
      backgroundColor: variableStyles.NaturalColor500,
    },
  },
  "& .proccess-loading": {
    width: "20px",
    height: "20px",
    "@keyframes rotate": {
      "0%": { transform: "rotate(0deg)" },
      "100%": { transform: "rotate(360deg)" },
    },
    "@keyframes prixClipFix": {
      "0%": { clipPath: "polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)" },
      "50%": {
        clipPath: "polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)",
      },
      "75%, 100%": {
        clipPath: "polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)",
      },
    },
    "& .loader": {
      width: "20px",
      height: "20px",
      borderRadius: "50%",
      position: "absolute",
      animation: "rotate .8s linear infinite",
    },
    "& .loader::before , & .loader::after": {
      content: '""',
      boxSizing: "border-box",
      position: "absolute",
      inset: "0px",
      borderRadius: "50%",
      border: `2px solid ${variableStyles.blueSecondary500}`,
      animation: " prixClipFix 2s linear forwards",
    },
    "& .loader::after": {
      transform: "rotate3d(90, 90, 0, 180deg )",
      borderColor: variableStyles.NaturalColor0,
      opacity: ".5",
    },
  },
}));
