import { NavigateFunction } from "react-router-dom";
import { ILoginRequest } from "../../auth";

export enum EAuthType {
  LOGIN_WITH_PASSWORD = "LOGIN_WITH_PASSWORD",
  ADMIN_LOGIN_WITH_PASSWORD = "ADMIN_LOGIN_WITH_PASSWORD",
  SET_USER = "SET_USER",
  CLEAR_USER = "CLEAR_USER",
  LOGOUT = "LOGOUT"
}
export interface IActionLoginWithPassword {
  type: EAuthType.LOGIN_WITH_PASSWORD;
  payload: { loginRequest: ILoginRequest; navigate: NavigateFunction };
}

export interface IActionAdminLoginWithPassword {
  type: EAuthType.ADMIN_LOGIN_WITH_PASSWORD;
  payload: { loginRequest: ILoginRequest; navigate: NavigateFunction };
}

export interface IActionLogout {
  type: EAuthType.LOGOUT;
  payload: { navigate?: NavigateFunction };
}

export type IActionAuth =
  | IActionLoginWithPassword
  | IActionLogout
  | IActionAdminLoginWithPassword;
