import { styled } from "@mui/material/styles";
import variableStyles from "../../../../theme/variable-styles";

export const CommonLayoutWrapper = styled("div")(() => ({
  "& .layout-header": {
    padding: "12px 24px",
    borderBottom: `1px solid ${variableStyles.NaturalColor200}`,
    "& .layout-title": {
      color: variableStyles.NaturalColor1000,
      fontWeight: variableStyles.fwSemiBold,
      fontSize: "24px",
      lineHeight: "29.05px",
      marginBottom: "12px",
    },
    "& .layout-description": {
      color: variableStyles.NaturalColor900,
      fontWeight: variableStyles.fwRegular,
      fontSize: "14px",
      lineHeight: "22.4px",
      width: "388px",
    },
  },
}));
