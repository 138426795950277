import { Button } from "@mui/material";
import { ImageCustom } from "..";
import IconPlan from "../../assets/images/common/icon_plan_fill_blue.svg";
import { PlanBoxWrapper } from "./styles";

interface PlanBoxProps {
  className?: string;
}

export default function PlanBox(props: PlanBoxProps) {
  const { className = "" } = props;
  return (
    <PlanBoxWrapper className={`${className}`}>
      <p className="plan-title">Nâng cấp Pro Plan</p>
      <p className="plan-description">Lorem ipsum</p>
      <Button size="xsmall" variant="contained" color="secondary">
        <ImageCustom
          src={IconPlan}
          alt="Plan-Icon"
          width={"18px"}
          height={"18px"}
        />
        <p>Update the plan</p>
      </Button>
    </PlanBoxWrapper>
  );
}
