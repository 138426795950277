import { styled } from "@mui/material/styles";
import variableStyles from "../../../../../../../theme/variable-styles";

export const BackLinkContentsWrapper = styled("div")(() => ({
  "& .backlink-overview-wrapper": {
    padding: "40px",
    "& .backlink-description": {
      color: variableStyles.NaturalColor1000,
      fontWeight: variableStyles.fwRegular,
      fontSize: "12px",
      lineHeight: "18px",
      marginBottom: "20px",
      padding: "8px 12px",
      backgroundColor: variableStyles.Info50,
      borderRadius: "8px",
    },
  },
  "& .overview-list": {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "16px",
  },
  "& .overview-chart-list": {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    padding: "40px",
    backgroundColor: variableStyles.NaturalColor50,
  },
  "& .backlink-tables-wrapper": {
    padding: "40px",
    "& .backlink-table-tabs": {
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr",
      padding: "4px",
      borderRadius: "12px",
      backgroundColor: variableStyles.NaturalColor100,
      border: `1px solid ${variableStyles.NaturalColor200}`,
      boxShadow: "0px 0px 2.6px 0px #0000001F inset",
      marginBottom: "20px",
      "& li": {
        flex: "1 1 auto",
        "& button": {
          color: variableStyles.NaturalColor700,
          fontWeight: variableStyles.fwMedium,
          fontSize: "14px",
          lineHeight: "16px",
          borderRadius: "8px",
          padding: "14px",
          backgroundColor: "transparent",
          width: "100%",
          border: `1px solid transparent`,
          transition: "all .2s linear",
          "&:hover": {
            borderColor: `${variableStyles.NaturalColor200}`,
            backgroundColor: variableStyles.NaturalColor0,
          },
        },
        "&.active": {
          "& button": {
            borderColor: `${variableStyles.NaturalColor200}`,
            backgroundColor: variableStyles.NaturalColor0,
            color: variableStyles.NaturalColor1000,
            fontWeight: variableStyles.fwSemiBold,
          },
        },
      },
    },
  },
}));


