import { ImageCustom } from "../..";
import { ModalPhotoWrapper } from "./styles";
import IconClose from "../../../assets/images/common/icon_close_line_black_background.svg";
import { Button } from "@mui/material";

interface ModalPhotoProps {
  open?: boolean;
  src: string;
  width?: number;
  height?: number;
  onClose: () => void;
}

export default function ModalPreviewPhoto(props: ModalPhotoProps) {
  const { open = false, width = 598, height = 598, src, onClose } = props;
  return (
    <ModalPhotoWrapper open={open} onClose={onClose}>
      <Button
        className={"button-close"}
        size="large"
        variant="text"
        onClick={onClose}
      >
        <ImageCustom
          src={IconClose}
          alt="Icon-Close"
          width={"40px"}
          height={"40px"}
        />
      </Button>
      <ImageCustom
        src={src}
        alt="photo-preview"
        width={width}
        height={height}
        className="detail-image"
      />
    </ModalPhotoWrapper>
  );
}
