// ----------------------------------------------------------------------

import variableStyles from "../variable-styles";

export default function Button(theme: any) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
            backgroundColor: variableStyles.GreenPrimaryColor500
          },
          "@keyframes rotate": {
            "0%": { transform: "rotate(0deg)" },
            "100%": { transform: "rotate(360deg)" }
          },
          "@keyframes prixClipFix": {
            "0%": { clipPath: "polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)" },
            "50%": {
              clipPath: "polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)"
            },
            "75%, 100%": {
              clipPath:
                "polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)"
            }
          },
          "& .loader": {
            width: "20px",
            height: "20px",
            borderRadius: "50%",
            position: "absolute",
            animation: "rotate .8s linear infinite"
          },
          "& .loader::before , & .loader::after": {
            content: '""',
            boxSizing: "border-box",
            position: "absolute",
            inset: "0px",
            borderRadius: "50%",
            border: "2px solid #FFF",
            animation: " prixClipFix 2s linear forwards"
          },
          "& .loader::after": {
            transform: "rotate3d(90, 90, 0, 180deg )",
            borderColor: variableStyles.NaturalColor0,
            opacity: ".5"
          },

          "&.MuiButton-text": {
            padding: "0",
            width: "fit-content",
            height: "unset",
            minWidth: "0",
            "&:hover": {
              boxShadow: "none",
              color: `${variableStyles.GreenPrimaryColor500} !important`,
              backgroundColor: "transparent"
            }
          },
          "&.MuiButton-outlined": {
            color: variableStyles.NaturalColor950,
            border: `1px solid ${variableStyles.NaturalColor400}`,
            "&:hover": {
              boxShadow: "none",
              backgroundColor: variableStyles.NaturalColor50
            },
            "&.Mui-disabled": {
              backgroundColor: variableStyles.NaturalColor0,
              color: `${variableStyles.GreenPrimaryColor400} !important`
            }
          },
          "&.MuiButton-contained": {
            "&.Mui-disabled": {
              backgroundColor: variableStyles.NaturalColor200,
              color: `${variableStyles.NaturalColor400} !important`
            }
          },
          "&.MuiButton-containedSecondary": {
            color: variableStyles.blueSecondary700,
            boxShadow: "none",
            "&:hover": {
              backgroundColor: variableStyles.blueSecondary100
            }
          }
        },
        sizeLarge: {
          height: 46,
          borderRadius: 10,
          fontSize: "14px",
          lineHeight: "14px",
          fontWeight: variableStyles.fwSemiBold,
          padding: "9px"
        },
        sizeSmall: {
          height: 40,
          borderRadius: 10,
          fontSize: "14px",
          lineHeight: "14px",
          fontWeight: variableStyles.fwSemiBold
        },
        sizeXsmall: {
          height: 36,
          borderRadius: 10,
          lineHeight: "14px",
          fontWeight: variableStyles.fwSemiBold,
          padding: "9px"
        },
        sizeXxsmall: {
          height: 32,
          borderRadius: 10,
          lineHeight: "13px",
          fontSize: "13px",
          fontWeight: variableStyles.fwSemiBold,
          padding: "9px"
        },
        sizeMedium: {
          height: 44,
          borderRadius: "10px",
          boxShadow: "none"
        },
        containedInherit: {
          color: theme.palette.grey[800],
          boxShadow: theme.customShadows.z8,
          "&:hover": {
            backgroundColor: variableStyles.GreenPrimaryColor500
          }
        },
        containedPrimary: {
          boxShadow: theme.customShadows.primary
        },
        containedSecondary: {
          boxShadow: theme.customShadows.blueSecondary
        },
        outlinedInherit: {
          border: `1px solid ${theme.palette.grey[500]}`,
          "&:hover": {
            backgroundColor: variableStyles.GreenPrimaryColor500
          }
        },
        textInherit: {
          "&:hover": {
            color: variableStyles.GreenPrimaryColor500
          }
        }
      }
    }
  };
}
