import { Grow, InputAdornment, Paper } from "@mui/material";
import Autocomplete, {
  AutocompleteInputChangeReason,
} from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { forwardRef, UIEventHandler } from "react";
import { FieldError } from "react-hook-form";
import { Wrapper } from "./styles";
import { IValue } from "../../models/common/models.type";
import variableStyles from "../../theme/variable-styles";
import IconCheck from "../../assets/images/common/icon_check_line_green.svg";
import { ImageCustom } from "..";
import IconArrow from "../../assets/images/common/icon_arrow_down_line_black.svg";
import IconError from "../../assets/images/common/icon_error_fill_orange.svg";

interface IProps {
  options: IValue[];
  placeholder?: string;
  onChange: (value: any) => void;
  value?: string | number;
  id?: string;
  onInputChange?: (text: string) => void;
  errors?: FieldError;
  onScroll?: UIEventHandler<HTMLUListElement>;
  maxHeight?: string | number;
  label?: React.ReactNode;
  disabled?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  isFreeSolo?: boolean;
  startIcon?: string;
  endIcon?: string;
}

function PaperComponent(paperProps: any, ref: any) {
  return (
    <Grow in>
      <Paper {...paperProps} ref={ref} />
    </Grow>
  );
}

export default function InputAutocomplete(props: IProps) {
  const {
    options,
    placeholder,
    value,
    onChange,
    id = "multiple-limit-tags",
    onInputChange,
    errors,
    onScroll,
    maxHeight,
    label,
    disabled,
    onFocus,
    onBlur,
    isFreeSolo,
    startIcon,
    endIcon = IconArrow,
  } = props;

  const onChangeValue = (v: any, freeValue: boolean) => {
    onChange(v);
  };

  const handleInputChange = async (
    e: React.SyntheticEvent<Element, Event>,
    text: string,
    reson: AutocompleteInputChangeReason
  ) => {
    if (reson === "input") {
      onInputChange && onInputChange(text);
      onChangeValue(text, true);
    }
  };

  const selectedValue = options.find(f => {
    if (typeof value === "string") {
      return value === f.value?.toString();
    } else if (typeof value === "number") {
      return value === Number(f.value);
    } else {
      return value === f.value;
    }
  });

  const PaperComponentForward = forwardRef(PaperComponent);

  return (
    <Wrapper
      sx={{ width: "100%" }}
      error={!!errors?.message}
      onFocus={onFocus}
      onBlur={onBlur}
    >
      <Autocomplete
        freeSolo={isFreeSolo}
        id={`${id}`}
        options={options}
        getOptionLabel={option =>
          typeof option === "string" ? option : option.title
        }
        value={
          selectedValue
            ? selectedValue
            : {
                value: value,
                title: value?.toString() ?? "",
              }
        }
        onChange={(e, v) => {
          onChangeValue(
            typeof v === "string" ? v : v?.value,
            typeof v === "string" ? true : false
          );
        }}
        PaperComponent={PaperComponentForward}
        onInputChange={handleInputChange}
        renderInput={params => (
          <TextField
            {...params}
            label={label}
            placeholder={placeholder}
            error={!!errors?.message}
            InputProps={{
              ...params.InputProps,
              startAdornment: !!startIcon ? (
                <InputAdornment position="start">
                  <ImageCustom
                    isAvatar
                    src={startIcon}
                    alt={"start-icon"}
                    width={"18px"}
                    height={"18px"}
                  />
                </InputAdornment>
              ) : (
                <></>
              ),
              endAdornment: !!endIcon ? (
                <InputAdornment position="end">
                  <ImageCustom
                    isAvatar
                    src={endIcon}
                    alt={"end-icon"}
                    width={"20px"}
                    height={"20px"}
                  />
                </InputAdornment>
              ) : (
                <></>
              ),
            }}
          />
        )}
        sx={{ width: "100%" }}
        ListboxProps={{
          onScroll: onScroll,
          style: { maxHeight: maxHeight },
        }}
        disabled={disabled}
        slotProps={{
          popper: {
            sx: {
              "& .MuiAutocomplete-paper": {
                borderRadius: "12px",
              },
              "& .MuiAutocomplete-listbox": {
                maxHeight: "360px",
                padding: "0",
                "&::-webkit-scrollbar": {
                  width: "3px",
                },
                "&::-webkit-scrollbar-thumb": {
                  borderRadius: "7px",
                  backgroundColor: variableStyles.GreenPrimaryColor400,
                },
                "& li": {
                  padding: "10px 14px",
                  fontWeight: variableStyles.fwMedium,
                  color: variableStyles.NaturalColor950,
                  "&:hover": {
                    backgroundColor: variableStyles.NaturalColor50,
                  },
                  "&.Mui-focused": {
                    backgroundColor: `${variableStyles.NaturalColor50} !important`,
                  },

                  "&[aria-selected=true]": {
                    position: "relative",
                    padding: "10px 39px 10px 14px",
                    "&::after": {
                      content: '""',
                      background: `url(${IconCheck}) center/contain no-repeat`,
                      width: "20px",
                      height: "20px",
                      position: "absolute",
                      right: "14px",
                      top: "calc(50% - 10px)",
                      zIndex: "9999",
                    },
                  },
                  "&[aria-selected=true], &.Mui-focused": {
                    backgroundColor: `${variableStyles.NaturalColor50} !important`,
                  },
                },
              },
            },
          },
        }}
      />
      {errors?.message && (
        <div className="helper-text-error">
          <ImageCustom
            src={IconError}
            alt="Icon-Error"
            width={"18px"}
            height={"18px"}
          />
          <p className="input-search-error">{errors?.message}</p>
        </div>
      )}
    </Wrapper>
  );
}
