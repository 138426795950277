import { MenuItem, Select } from "@mui/material";
import { styled } from "@mui/material/styles";
import variableStyles from "../../../theme/variable-styles";

export const SelectProjectWrapper = styled(Select)(() => ({
  width: "100%",
  backgroundColor: variableStyles.NaturalColor0,
  borderRadius: "12px",
  padding: "8px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: "16px",
  "& .MuiInputBase-root": {
    padding: "0",
  },
  "& fieldset": {
    border: "none",
  },
  "& .MuiSelect-select": {
    display: "flex",
    gap: "10px",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "0",
    flex: "1 1 auto",
    "& .project-avatar": {
      padding: "5px",
      borderRadius: "50%",
      overflow: "hidden",
      border: `1px solid ${variableStyles.NaturalColor400}`,
      backgroundColor: variableStyles.NaturalColor50,
      pointerEvents: "none",
      flex: "0 0 auto",
    },
    "& .project-name": {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "space-between",
      gap: "4px",
      flex: "1 1 auto",
      textAlign: "left",
      "& .name-project": {
        color: variableStyles.NaturalColor950,
        fontWeight: variableStyles.fwSemiBold,
        fontSize: "14px",
        lineHeight: "14px",
      },
      "& .name-domain": {
        color: variableStyles.NaturalColor900,
        fontWeight: variableStyles.fwRegular,
        fontSize: "12px",
        lineHeight: "12px",
      },
    },
  },
}));
export const MenuItemWrapper = styled(MenuItem)(() => ({
  padding: "0",
  backgroundColor: "unset !important",
  "&.Mui-selected": {
    "& .infor-button": {
      backgroundColor: variableStyles.NaturalColor100,
      "& .icon-check": {
        visibility: "visible",
      },
    },
  },
  "& .infor-button": {
    display: "flex",
    gap: "12px",
    alignItems: "center",
    flex: "1 1 auto",
    padding: "6px !important",
    borderRadius: "12px",
    "&.MuiButton-text:hover": {
      backgroundColor: variableStyles.NaturalColor100,
    },
    "& .icon-check": {
      visibility: "hidden",
    },
    "& .project-avatar": {
      padding: "5px",
      borderRadius: "50%",
      overflow: "hidden",
      border: `1px solid ${variableStyles.NaturalColor400}`,
      backgroundColor: variableStyles.NaturalColor50,
      pointerEvents: "none",
      flex: "0 0 auto",
    },
    "& .project-name": {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "space-between",
      gap: "4px",
      flex: "1 1 auto",
      "& .name-project": {
        color: variableStyles.NaturalColor950,
        fontWeight: variableStyles.fwSemiBold,
        fontSize: "14px",
        lineHeight: "14px",
      },
      "& .name-domain": {
        color: variableStyles.NaturalColor900,
        fontWeight: variableStyles.fwRegular,
        fontSize: "12px",
        lineHeight: "12px",
      },
    },
  },
}));
