import React, { useRef, useMemo, useState, RefObject } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CustomImage from "./CustomImage";
import Quill from "quill";
import { Modal, Box, TextField, Button, Typography } from "@mui/material";
import { TextEditorQuillWrapper } from "./styles";
import { CSSObject } from "@mui/system";

Quill.register(CustomImage);

interface TextEditorProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  toolbar?: boolean;
  readOnly?: boolean;
  quillRef?: RefObject<ReactQuill>;
  toolbarOptions?: any[];
  className?: string;
}

const TextEditorQuill: React.FC<TextEditorProps> = ({
  value,
  onChange,
  placeholder,
  toolbar = false,
  readOnly = false,
  quillRef,
  toolbarOptions = [],
  className = ""
}) => {
  const localQuillRef = useRef<ReactQuill>(null);
  const quillInstanceRef = quillRef || localQuillRef;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [image, setImage] = useState({
    url: "",
    alt: "",
    width: "",
    height: ""
  });

  const modules = useMemo(
    () => ({
      toolbar: toolbar
        ? {
            container:
              toolbarOptions.length > 0 ? toolbarOptions : toolbarOptions,
            handlers: {
              image: () => setIsModalOpen(true)
            }
          }
        : false
    }),
    []
  );

  const fontSizeArr = [
    "9px",
    "10px",
    "11px",
    "12px",
    "14px",
    "16px",
    "18px",
    "20px",
    "22px",
    "24px",
    "26px",
    "28px"
  ];

  const Size = Quill.import("attributors/style/size");

  Size.whitelist = fontSizeArr;
  Quill.register(Size, true);

  const handleInsertImage = () => {
    if (quillInstanceRef.current) {
      let editor = quillInstanceRef.current.getEditor();
      editor.focus();
      let range = editor.getSelection();
      if (range) {
        editor.insertEmbed(range.index, "customImage", {
          url: image.url,
          alt: image.alt,
          width: image.width,
          height: image.height
        });
        editor.setSelection({ index: range.index + 1, length: 0 });
      }
    }
    setIsModalOpen(false);
    setImage({ url: "", alt: "", width: "", height: "" });
  };

  return (
    <TextEditorQuillWrapper className={`${className}`}>
      <ReactQuill
        theme="snow"
        value={value}
        onChange={onChange}
        modules={modules}
        placeholder={placeholder}
        readOnly={readOnly}
        ref={quillInstanceRef}
        className="quill-custom"
      />
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            p: 4
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h3"
            component="h2"
            sx={{ textAlign: "center" }}
          >
            Thêm hình ảnh
          </Typography>
          <TextField
            id="standard-basic"
            variant="standard"
            margin="dense"
            label="Url"
            fullWidth
            onChange={e => setImage({ ...image, url: e.target.value })}
          />
          <TextField
            id="standard-basic"
            variant="standard"
            margin="dense"
            label="Alt"
            fullWidth
            onChange={e => setImage({ ...image, alt: e.target.value })}
          />
          <TextField
            id="standard-basic"
            variant="standard"
            margin="dense"
            label="Chiều ngang"
            fullWidth
            onChange={e => setImage({ ...image, width: e.target.value })}
          />
          <TextField
            id="standard-basic"
            variant="standard"
            margin="dense"
            label="Chiều dọc"
            fullWidth
            onChange={e => setImage({ ...image, height: e.target.value })}
          />
          <Box
            mt={2}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleInsertImage}
              sx={{ marginRight: "10px", height: "40px" }}
            >
              Thêm
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setIsModalOpen(false)}
              sx={{ height: "40px" }}
            >
              Đóng
            </Button>
          </Box>
        </Box>
      </Modal>
    </TextEditorQuillWrapper>
  );
};

export default TextEditorQuill;
