import React from "react";
import { useNavigate } from "react-router-dom";
import { ImageCustom } from "../../../../components";
import { CheckToolNavCardWrapper } from "./styles";

interface CheckToolNavCardProps {
  data: {
    title: string;
    description: string;
    background: string;
    icon: string;
    path: string;
  };
}

export default function CheckToolNavCard(props: CheckToolNavCardProps) {
  const { data } = props;
  const navigate = useNavigate();
  return (
    <CheckToolNavCardWrapper
      style={{ backgroundColor: data.background }}
      className={``}
      onClick={() => navigate(data.path)}
    >
      <div className="card-icon-wrapper">
        <figure className="card-icon">
          <ImageCustom
            src={data.icon}
            alt="card-icon"
            width={"46px"}
            height={"46px"}
          />
        </figure>
      </div>
      <div className="card-infor">
        <p className="card-title">{data.title}</p>
        <p className="card-description">{data.description}</p>
      </div>
    </CheckToolNavCardWrapper>
  );
}
