import React from "react";
import StatusFullCircle from "../StatusFullCircle";
import { ThematicReportWrapper } from "./styles";

interface ThematicReportBoxProps {
  data: {
    title: string;
    index: number;
  };
  onClick: () => void;
}

export default function ThematicReportBox(props: ThematicReportBoxProps) {
  const { data, onClick } = props;
  return (
    <ThematicReportWrapper>
      <p className="thematic-title">{data.title}</p>
      <div className="thematic-index flex-start-align-center">
        <StatusFullCircle
          percent={data.index}
          className="thematic-circle-progress"
          size="small"
          type="normal"
        />
        <p className="index-text">{data.index}%</p>
      </div>
      <button className="thematic-button" onClick={onClick}>
        Chi tiết
      </button>
    </ThematicReportWrapper>
  );
}
