import { styled } from "@mui/material/styles";
import variableStyles from "../../theme/variable-styles";

export const NoDataBoxWrapper = styled("div")(() => ({
  margin: "74px auto 0",
  width: "352px",
  textAlign: "center",
  "& .nodata-image": {
    margin: "0 auto 10px",
    transform: "scale(0.8)"
  },
  "& .nodata-title": {
    color: variableStyles.NaturalColor950,
    fontWeight: variableStyles.fwMedium,
    fontSize: "20px",
    lineHeight: "24px",
    marginBottom: "12px"
  },
  "& .nodata-description": {
    color: variableStyles.NaturalColor800,
    fontWeight: variableStyles.fwRegular,
    fontSize: "14px",
    lineHeight: "22.4px",
    marginBottom: "38px"
  },
  "& .add-create": {
    width: "125px"
  }
}));
