import { Button } from "@mui/material";
import React from "react";
import ImageCustom from "../Image";
import IconDownload from "../../assets/images/common/icon_download_line_white.svg";
import { ReportPageHeaderWrapper } from "./styles";

interface ReportPageHeaderProps {
  className?: string;
  title: string;
}

export default function ReportPageHeader(props: ReportPageHeaderProps) {
  const { className, title } = props;
  const downloadPdf = () => {};
  return (
    <ReportPageHeaderWrapper className={className}>
      <p className="header-name fit-one-line">{title}</p>
      <div className="header-action">
        <Button
          color="primary"
          size="small"
          variant="contained"
          onClick={downloadPdf}
          className="download-button"
        >
          <ImageCustom
            src={IconDownload}
            alt="Icon-Download"
            width={"18px"}
            height={"18px"}
          />
          <p>Tải báo cáo</p>
        </Button>
        <Button
          color="primary"
          size="small"
          variant="outlined"
          onClick={_ => {}}
          className="share-button"
        >
          Chia sẻ
        </Button>
      </div>
    </ReportPageHeaderWrapper>
  );
}
