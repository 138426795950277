import { styled } from "@mui/material/styles";
import variableStyles from "../../../../../theme/variable-styles";
import ImageTitleUnderline from "../../../../../assets/images/domai-audit/overview-section/image_underline_title_green.png";

export const PerformSectionWrapper = styled("div")(() => ({
  "& .perform-title": {
    padding: "60px 40px 24px",
  },
  "& .organic-traffic-wrapper": {
    padding: "20px 40px 40px",
    backgroundColor: variableStyles.NaturalColor50,
    borderTop: `1px solid ${variableStyles.NaturalColor200}`,
    borderBottom: `1px solid ${variableStyles.NaturalColor200}`,
  },
  "& .organic-traffic-index-wrapper": {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    "& .organic-traffic-index-box": {
      backgroundColor: variableStyles.NaturalColor0,
      border: `1px solid ${variableStyles.NaturalColor200}`,
      padding: "24px 0 0",
      borderRadius: "6px",
      "& .index-box-caption": { padding: "0 24px  24px" },
      "& .MuiDivider-root": {
        borderColor: variableStyles.NaturalColor200,
      },
      "& .line-chart-wrapper": {
        padding: "32px 0 54px 24px",
      },
      "& .bar-chart-wrapper": {
        padding: "32px 24px 32px 0",
      },
    },
  },
  "& .keyword-ranking-wrapper": {
    padding: "40px 40px 24px",
    "& .keyword-chart-box": {
      borderRadius: "6px",
      border: `1px solid ${variableStyles.NaturalColor200}`,
      "& .box-header": {
        padding: "24px",
        borderBottom: `1px solid ${variableStyles.NaturalColor200}`,
        marginBottom: "24px",
        backgroundColor: variableStyles.NaturalColor50,
      },
      "& .box-chart": {
        padding: "0 0 60px 20px",
      },
    },
  },
  "& .keyword-data-table-wrapper": {
    padding: "0 40px 40px",
    display: "flex",
    flexDirection: "column",
    gap: "24px",
  },
}));
