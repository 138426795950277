import React from "react";
import { ROUTE_PATH } from "../../../common/constants/app.constant";
import { CommonReportListPage } from "../../../components";
import { EReportType } from "../../../models/common/models.enum";
import { DomainAuditUXPageWrapper } from "./styles";

export default function DomainAuditUXPage() {
  return (
    <DomainAuditUXPageWrapper>
      <CommonReportListPage
        title="Domain Audit UX"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
        eiusmod tempor incididunt eiusmo"
        type={EReportType.DomainAuditUX}
        pathProps={ROUTE_PATH.DOMAIN_AUDIT_UX}
        pathDetail={ROUTE_PATH.DOMAIN_AUDIT_UX_DETAIL}
        disabledSelectType
      />
    </DomainAuditUXPageWrapper>
  );
}
