import { styled } from "@mui/material/styles";
import variableStyles from "../../theme/variable-styles";

interface DragAndDropFileWrapperProps {
  disabled?: number;
}

export const DragAndDropImageWrapper = styled(
  "div"
)<DragAndDropFileWrapperProps>(({ disabled }) => ({
  padding: "30px 12px",
  backgroundColor: variableStyles.NaturalColor0,
  borderRadius: "16px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  gap: "33px",
  pointerEvents: !!disabled ? "none" : "auto",
  border: `1px dashed ${variableStyles.NaturalColor500}`,

  "& .input-file-upload": {
    display: "none"
  },
  "& .file-upload-box": {
    "& .upload-text": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
      "& .upload-icon": {
        marginBottom: "12px"
      },
      "& .upload-title": {
        marginBottom: "4px",
        color: variableStyles.NaturalColor950,
        fontWeight: variableStyles.fwSemiBold,
        fontSize: "16px",
        lineHeight: "24px"
      },
      "& .upload-subtitle": {
        color: variableStyles.NaturalColor800,
        fontWeight: variableStyles.fwRegular,
        fontSize: "12px",
        lineHeight: "18px",
        "& button.MuiButtonBase-root": {
          color: variableStyles.blueSecondary700,
          fontWeight: variableStyles.fwRegular,
          fontSize: "12px",
          lineHeight: "18px",
          paddingBottom: "2px",
          "&:hover": {
            textDecoration: "underline",
            color: `${variableStyles.blueSecondary700} !important`
          }
        }
      }
    }
  },
  "& .drag-file-box": {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: "0px",
    left: "0px"
  },
  "& .upload-error": {
    color: variableStyles.Error500,
    fontWeight: variableStyles.fwRegular,
    fontSize: "12px",
    lineHeight: "18px"
  }
}));
