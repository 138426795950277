import React, { useEffect } from "react";
import { Control, FieldErrors, UseFormReset } from "react-hook-form";
import { ControlForm } from "../../../../..";
import InputForm from "../../../../../controls/InputForm";
import { ICreateReportCommonReq } from "../../../../../../models/report";

interface DomainAuditReportProps {
  control: Control<ICreateReportCommonReq, any>;
  errors: FieldErrors<ICreateReportCommonReq>;
  reset: UseFormReset<ICreateReportCommonReq>;
}

export default function DomainAuditReport(props: DomainAuditReportProps) {
  const { control, errors, reset } = props;

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  return (
    <form>
      <ControlForm
        title="Tên thương hiệu"
        infor="Làm nổi bật sản phẩm/dịch vụ bằng tiêu đề thu hút người dùng."
        classname="brand-form"
      >
        <InputForm
          placeholder="Tên thương hiệu"
          required
          name="brandName"
          errors={errors}
          control={control}
          size="small"
        />
      </ControlForm>
    </form>
  );
}
