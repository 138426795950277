// ----------------------------------------------------------------------

export default function Tooltip(theme: any) {
  return {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: theme.palette.grey[800],
          borderRadius: "8px",
          fontSize: "12px"
        },
        arrow: {
          color: theme.palette.grey[800]
        }
      }
    }
  };
}
