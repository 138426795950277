import { ILang } from "../i18n";

const EN: ILang = {
  FilterDate: {
    Default: "Default",
    Daily: "Daily",
    Weekly: "Weekly",
    Monthly: "Monthly",
    ViewBy: "View by",
    ErrorSelectDateMessage:
      "Please choose a period of at least 3 days and maximum 15 days!"
  },
  Lang: {
    Code: "EN",
    TextEN: "English",
    TextVN: "Vietnamese"
  },
  Login: {
    ErrorEmail: "Please enter email",
    LoginDescription: "Enter your email and password to login",
    ButtonText: "Login"
  },
  Sidebar: {
    Overview: "Overview",
    Manage: "Manage",
    Account: "Account",
    Dashboard: "Dashboard",
    ManageUser: "Manage User",
    ManageCashflow: "Manage Cashflow",
    RecordingBalance: "Recording balance",
    AccountName: "Account Name",
    BankAccount: "Bank Account",
    ManageExchangeRate: "Manage Exchange Rate",
    Transaction: "Transaction",
    Setting: "Setting",
    Notification: "Notification",
    FAQ: "FAQ",
    Cashflow: "Cashflow",
    ManageAdminUser: "Manage Admin User",
    ManagePartnerUser: "Manage Partner User"
  },
  Modal: {
    CancelText: "Cancel",
    SubmitText: "Submit"
  },
  Setting: {
    AccountInformation: "Account Information",
    TimeRegister: "Account registered on",
    ModalTitle: "Change Password",
    ModalOldPassword: "Old password",
    ModalPassword: "New password",
    ModalOldPasswordPlaceholder: "Enter old password",
    ModalPasswordPlaceholder: "Enter new password",
    ModalConfirmPassword: "Confirm new password",
    UpdateSuccess: "Update Password successfully",
    UpdateFail: "Update Password failed!"
  },
  Role: {
    Owner: "Owner",
    Admin: "Admin",
    ViewReport: "ViewReport"
  },
  Common: {
    InsertNew: "Add new",
    Edit: "Edit",
    Delete: "Delete",
    Status: "Status",
    Update: "Update",
    DateCreated: "Date Created",
    RowPerPage: "Row per page",
    Of: "of",
    More: "more",
    Code: "Code",
    Name: "Name",
    NameValidate: "Please enter name",
    Description: "Description",
    Title: "Title",
    Content: "Content",
    DiscountPrice: "Discount price",
    Price: "Price",
    Type: "Type",
    Prominent: "Prominent",
    StartDate: "Start date",
    EndDate: "End date",
    Quantity: "Quantity",
    Product: "Product",
    Customer: "Customer",
    Detail: "View Detail",
    Cancel: "Cancel",
    Confirm: "Confirm",
    Inflow: "Inflow",
    Outflow: "Outflow",
    Active: "Active",
    Deactive: "Deactive",
    BankNumber: "Bank Number",
    Currency: "Currency",
    ApiError: "Error! Please try again later.",
    Amount: "Amount",
    AmountPlaceholder: "Enter amount",
    Search: "Search",
    Role: "Role",
    Date: "Date",
    LastName: "Lastname",
    FirstName: "Firstname",
    Password: "Password",
    Logout: "Logout account",
    PasswordValidate: "Please enter password",
    PasswordOldValidate: "Please enter your current password!",
    PasswordConfirmValidate: "Please enter correct password!",
    ResetPassword: "Reset password",
    UpdateCashflow: "Update Cashflow",
    AddMore: "Add More",
    TimeDefault: "Time default",
    AllAccountBank: "All account bank",
    CompanyName: "Company name",
    PhoneNumber: "Phone number",
    QuantityMember: "Quantity member",
    Limit: "Limit",
    PercentComplete: "Percent complete",
    Close: "close"
  }
};

export default EN;
