import { Button, Collapse } from "@mui/material";
import { KeyWordGroupDataGridWrapper } from "./styles";
import IconArrow from "../../../../assets/images/common/icon_arrow_up_line_black.svg";
import { ImageCustom } from "../../../../components";
import { useState } from "react";
import { IGroupKeywordList } from "../../../../models/keyword-group";

interface KeywordDataGridProps {
  data: IGroupKeywordList[];
  order: number;
}

export default function KeywordDataGrid(props: KeywordDataGridProps) {
  const { data, order } = props;
  const [isCollapse, setIsCollapse] = useState<boolean>(true);
  return (
    <KeyWordGroupDataGridWrapper>
      <div className="datagrid-header">
        <p className="header-text header-name">Nhóm {order}</p>
        <p className="header-text header-keyword">Từ khoá</p>
        <p className="header-text header-volume">Volume</p>
        <Button
          size="large"
          variant="text"
          onClick={() => setIsCollapse(!isCollapse)}
          className={`button-expanded ${isCollapse ? "is-collapse" : ""}`}
        >
          <ImageCustom
            src={IconArrow}
            alt="Icon-Arrow"
            width={"18px"}
            height={"18px"}
          />
        </Button>
      </div>
      <Collapse in={isCollapse}>
        <ul className="datagrid-list">
          {data.map((item, idx) => (
            <li className="list-item" key={item.text}>
              <p className="item-text item-name">{idx + 1}</p>
              <p className="item-text item-keyword">{item.text}</p>
              <p className="item-text item-volume">{item.volume}</p>
            </li>
          ))}
        </ul>
      </Collapse>
    </KeyWordGroupDataGridWrapper>
  );
}
