import React from "react";
import {
  getColorByStatus,
  getStatusByPoint,
} from "../../../../../../../../../common/handles/common.handles";
import { ProgressHalfCircleWrapper } from "./styles";

type IProps = {
  value: number; // Giá trị từ 0 đến 100
  lable: string;
  size?: "small" | "large";
};

const ProgressHalfCircle: React.FC<IProps> = ({
  value,
  lable,
  size = "small",
}) => {
  const point = value * 100;

  const color = getColorByStatus(getStatusByPoint(value));
  return (
    <ProgressHalfCircleWrapper
      value={point}
      progressfill={color.secondary}
      valuefill={color.primary}
      className={`${size === "large" ? "large" : "small"}`}
    >
      <div className="circle-wrapper">
        <span className="graph"></span>
        <p className="progress-value">{point.toFixed()}</p>
      </div>
      <p className="lable">{lable}</p>
    </ProgressHalfCircleWrapper>
  );
};

export default ProgressHalfCircle;
