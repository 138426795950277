import * as React from "react";
import { useTheme } from "@mui/material/styles";
import { FullWidthTabsTimeWrapper } from "./styles";
import { ImageCustom } from "..";

interface ITab<T> {
  lable: { icon?: string; iconActive?: string; text: string };
  value: T;
  content: React.ReactNode;
}

interface IProps<T> {
  value: T;
  tabs: ITab<T>[];
  onChange: (value: T) => void;
  className?: string;
  time: string;
}

function a11yProps<T>(tabValue: T) {
  return {
    id: `full-width-tab-${tabValue}`,
    "aria-controls": `full-width-tabpanel-${tabValue}`,
  };
}

export default function FullWidthTabsTime<T>({
  tabs,
  value,
  onChange,
  className,
  time,
}: IProps<T>) {
  const theme = useTheme();

  const handleChange = (_: React.SyntheticEvent, newValue: T) => {
    onChange(newValue);
  };

  return (
    <FullWidthTabsTimeWrapper className={className}>
      <div className="technical-detail-header flex-between-align-center">
        <ul className="header-tabs flex-start-align-center">
          {tabs.map(tab => (
            <li
              key={tab.lable.text}
              className={`${value === tab.value ? "active" : ""}`}
            >
              <button onClick={e => handleChange(e, tab.value)}>
                {!!tab.lable.icon && !!tab.lable.iconActive && (
                  <div className="icon-wrapper">
                    <ImageCustom
                      src={tab.lable.icon}
                      alt="Icon-Tab"
                      width={"24px"}
                      height={"24px"}
                      className="icon-deactive"
                    />
                    <ImageCustom
                      src={tab.lable.iconActive}
                      alt="Icon-Tab-Active"
                      width={"24px"}
                      height={"24px"}
                      className="icon-active"
                    />
                  </div>
                )}
                <p className="fit-one-line">{tab.lable.text}</p>
              </button>
            </li>
          ))}
        </ul>
        <div className="header-time">
          <p>Thời gian: {time}</p>
        </div>
      </div>
      <div className="tab-content-wrapper">
        {tabs.map((tab, idx) => (
          <div
            className={`${value !== tab.value ? "hidden-box" : "visible-box"} tab-content`}
            {...a11yProps}
            key={idx}
          >
            {tab.content}
          </div>
        ))}
      </div>
    </FullWidthTabsTimeWrapper>
  );
}
