import { getAuditStatus } from "../../../../../../../../../common/handles/common.handles";
import { ImageCustom } from "../../../../../../../../../components";
import { EAuditErrorStatus } from "../../../../../../../../../models/common/models.enum";
import { ContentfulPaint } from "../../../../../../../../../models/google/google.page.speed";
import IconError from "../../../../../../../../../assets/images/common/icon_error_fill_red_border.svg";
import IconWarning from "../../../../../../../../../assets/images/common/icon_warning_fill_yellow_border.svg";
import IconInforBorder from "../../../../../../../../../assets/images/common/icon_infor_fill_blue_border.svg";
import IconSuccess from "../../../../../../../../../assets/images/common/icon_success_fill_green_border.svg";

export default function IconByScore({
  data,
  weight,
}: {
  data: ContentfulPaint;
  weight: number;
}) {
  const status = getAuditStatus(data, weight);

  return (
    <ImageCustom
      src={
        status === EAuditErrorStatus.Error
          ? IconError
          : status === EAuditErrorStatus.Warning
            ? IconWarning
            : status === EAuditErrorStatus.Success
              ? IconSuccess
              : IconInforBorder
      }
      alt="Icon-Issue"
      width={"22px"}
      height={"22px"}
    />
  );
}
