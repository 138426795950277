import React from "react";
import { useSelector } from "react-redux";
import {
  ReportFooter,
  ReportHeader,
  ReportPageHeader,
} from "../../../../components";
import { IStateApp } from "../../../../models/redux";
import { IGetReportsRes } from "../../../../models/report";
import UXSection from "../../components/OverviewSection/UXSection";
import { DomainAuditUXDetailWrapper } from "./styles";

export default function DomainAuditUXPage() {
  const dataReport = useSelector<IStateApp, IGetReportsRes>(
    appState => appState.project?.dataReport
  );
  return (
    <DomainAuditUXDetailWrapper>
      <ReportPageHeader className="report-header" title="Domain Audit UX" />
      <div className="report-wrapper scrollbar">
        <ReportHeader
          title={
            <>
              Báo Cáo Hiệu Suất &<br />
              Giao Diện
            </>
          }
          description="Hiệu suất và giao diện website là yếu tố then chốt quyết định mức độ hài lòng khách hàng và khả năng chuyển đổi.Nhận diện và triển khai ngay các cải tiến giúp nâng cao hiệu suất và tối ưu trải nghiệm người dùng trên website."
          brandName={dataReport.projectName}
          domain={dataReport.target}
          height={"calc(100vh - 168px)"}
        />
        <UXSection />
        <ReportFooter
          conclusion={
            <ul
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <li>
                Cải thiện hiệu suất và giao diện website không chỉ giúp tăng
                tính thẩm mỹ mà còn tối ưu trải nghiệm người dùng, tạo nền tảng
                cho website phát triển bền vững. Hành động để biến website của
                bạn thành công cụ mạnh mẽ trong việc thu hút và giữ chân khách
                hàng ngay hôm nay!
              </li>
              <li>
                Nếu bạn cần tư vấn thiết kế, tối ưu website, đừng ngần ngại liên
                hệ với chúng tôi. Webaudit luôn sẵn sàng đồng hành để mang đến
                giải pháp hoàn hảo, phù hợp nhất cho doanh nghiệp của bạn!
              </li>
            </ul>
          }
          height={"calc(100vh - 168px)"}
          title="Sẵn sàng nâng tầm giao diện!"
        />
      </div>
    </DomainAuditUXDetailWrapper>
  );
}
