import { Fragment, useState } from "react";
import { Helmet } from "react-helmet";
import {
  Box,
  Grid,
  Typography,
  Button,
  Paper,
  Tabs,
  Tab,
  TextareaAutosize,
} from "@mui/material";
import { toggleMessage } from "../../components/Toast/Toast";
import variableStyles from "../../theme/variable-styles";
import Title from "../../components/Title";
import { TableData } from "../../components/Table/TableData/TableData";
import { KeywordDensityWrapper } from "./styles";
import HighlightedContent from "../../components/HighlightedContent";

interface WordCount {
  index: number;
  name: string;
  wordCount: number;
  usedCount: number;
  density: number;
}

interface KeywordColors {
  [keyword: string]: string;
}

const KeywordDensity = () => {
  const [content, setContent] = useState("");
  const [keyword, setKeyword] = useState("");
  const [wordCounts, setWordCounts] = useState<WordCount[]>([]);
  const [isHighlighted, setIsHighlighted] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [keywordColors, setKeywordColors] = useState<KeywordColors>({});
  const [confirmedContent, setConfirmedContent] = useState("");
  const [confirmedKeywords, setConfirmedKeywords] = useState<string[]>([]);

  const cleanText = (text: string): string => {
    return text.replace(/<\/?[^>]+(>|$)/g, "").trim();
  };

  const shouldShowAsterisk = (text: string) => {
    const cleanedContent = cleanText(text);
    return cleanedContent === "";
  };

  const validateInputs = (): boolean => {
    if (content.trim() === "") {
      toggleMessage({ message: "Vui lòng nhập nội dung", type: "info" });
      return false;
    } else if (keyword.trim() === "") {
      toggleMessage({ message: "Vui lòng nhập từ khoá", type: "info" });
      return false;
    }
    return true;
  };

  const extractKeywords = (text: string) => {
    return text
      .split("\n")
      .map(line => line.trim())
      .filter(line => line !== "");
  };

  const assignColorsToKeywords = (keywords: string[]) => {
    const newKeywordColors: KeywordColors = {};
    keywords.forEach(keyword => {
      newKeywordColors[keyword] = getRandomColor();
    });
    setKeywordColors(newKeywordColors);
  };

  const handleHighlight = () => {
    if (!validateInputs()) {
      setIsHighlighted(false);
      return;
    }

    const keywordArray = extractKeywords(keyword);
    setConfirmedContent(content);
    setConfirmedKeywords(keywordArray);
    assignColorsToKeywords(keywordArray);
    handleCountDensity();
    setIsHighlighted(true);
  };

  const getRandomColor = () => {
    return "#" + Math.floor(Math.random() * 16777215).toString(16);
  };

  const handleCountDensity = () => {
    const words = content.match(/\S+/g) || [];
    const totalWords = words.length;

    const keywords = keyword
      .split("\n")
      .map(line => line.trim())
      .filter(line => line !== "");

    const wordCountResults: WordCount[] = [];

    keywords.forEach((word, index) => {
      const keywordParts = word.toLowerCase().split(/\s+/);
      let count = 0;

      for (let i = 0; i <= words.length - keywordParts.length; i++) {
        if (
          keywordParts.every(
            (part, j) =>
              words[i + j].toLowerCase().replace(/[.,!?;:()[\]{}'"]/g, "") ===
              part.replace(/[.,!?;:()[\]{}'"]/g, "")
          )
        ) {
          count++;
        }
      }

      const keywordCount = count * keywordParts.length;
      const density = (keywordCount / totalWords) * 100;

      wordCountResults.push({
        index: index + 1,
        name: word,
        wordCount: keywordParts.length,
        usedCount: count,
        density: density,
      });
    });

    setWordCounts(wordCountResults);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const filterWordCounts = (counts: WordCount[], times: number) => {
    if (times === 4) {
      return counts.filter(wc => wc.wordCount >= 4 && wc.usedCount > 0);
    }
    return counts.filter(wc => wc.wordCount === times && wc.usedCount > 0);
  };

  const renderTableData = () => {
    let filteredWordCounts: WordCount[] = [];

    switch (selectedTab) {
      case 0:
        filteredWordCounts = filterWordCounts(wordCounts, 1);
        break;
      case 1:
        filteredWordCounts = filterWordCounts(wordCounts, 2);
        break;
      case 2:
        filteredWordCounts = filterWordCounts(wordCounts, 3);
        break;
      case 3:
        filteredWordCounts = filterWordCounts(wordCounts, 4);
        break;
      default:
        filteredWordCounts = wordCounts;
    }

    filteredWordCounts = filteredWordCounts.map((wc, index) => ({
      ...wc,
      index: index + 1,
    }));

    return (
      <TableData
        data={{
          items: filteredWordCounts.map(wc => ({
            ...wc,
            density: `${wc.density.toFixed(0)}%`,
          })),
          total: filteredWordCounts.length,
          page: 1,
          pageSize: filteredWordCounts.length,
          isFull: false,
        }}
        single={true}
        footer={false}
        minWidth={"100%"}
        headersTable={[
          {
            title: "STT",
            width: "10%",
            field: "index",
          },
          {
            title: "Từ khóa",
            field: "name",
            width: "30%",
          },
          {
            title: "Density (%)",
            field: "density",
            width: "25%",
            handleItem: item => (
              <Box sx={{ color: variableStyles.blueSecondary700 }}>
                {item.density}
              </Box>
            ),
          },
          {
            title: "Used",
            width: "25%",
            field: "usedCount",
          },
        ]}
        keyField="name"
      />
    );
  };

  return (
    <Fragment>
      <Helmet>
        <title>Keyword Density</title>
      </Helmet>
      <KeywordDensityWrapper>
        <Grid container spacing={3}>
          {/* Input */}
          <Grid item sm={12} lg={4}>
            <Title
              highlightTitle="Keyword"
              normalTitle="Density"
              children={
                <p>
                  Lorem ipsum dolor sit amet,consectetur adipiscing elit, sed do
                  eiusmod tem.
                </p>
              }
              marginBottom={15}
            />

            <Paper
              elevation={3}
              sx={{
                padding: "0 16px 16px 16px",
                marginTop: "10px",
                borderRadius: "8px",
                boxShadow: "1px 2px 24px 0px #0000000F",
              }}
            >
              <Box>
                <Typography variant="body2" p={1}>
                  Nội dung{" "}
                  {shouldShowAsterisk(content) ? (
                    <span style={{ color: "red" }}>*</span>
                  ) : null}
                </Typography>
                <TextareaAutosize
                  value={content}
                  onChange={e => setContent(e.target.value)}
                  placeholder="Nhập văn bản tại đây..."
                  style={{
                    width: "100%",
                    borderRadius: "10px",
                    padding: "8px",
                    height: "280px",
                    border: "1px solid #ddd",
                    fontSize: "14px",
                    fontFamily: "Inter",
                    lineHeight: "21px",
                    overflowY: "auto",
                    resize: "none",
                    outline: "none",
                  }}
                />
              </Box>
              <Box mb={2}>
                <Typography variant="body2" p={1}>
                  Bộ từ khoá{" "}
                  {shouldShowAsterisk(keyword) ? (
                    <span style={{ color: "red" }}>*</span>
                  ) : null}
                </Typography>
                <TextareaAutosize
                  value={keyword}
                  onChange={e => setKeyword(e.target.value)}
                  placeholder="Từ khoá SEO..."
                  minRows={4}
                  maxRows={8}
                  style={{
                    width: "100%",
                    borderRadius: "10px",
                    padding: "8px",
                    border: "1px solid #ddd",
                    fontSize: "14px",
                    fontFamily: "Inter",
                    lineHeight: "21px",
                    overflowY: "auto",
                    resize: "none",
                    outline: "none",
                  }}
                />
              </Box>
              <Button
                color="primary"
                fullWidth
                size="medium"
                variant="contained"
                onClick={handleHighlight}
              >
                Bắt đầu
              </Button>
            </Paper>
          </Grid>

          {/* Output Section */}
          {isHighlighted ? (
            <Grid item sm={12} lg={8}>
              <Box>
                <Typography variant="subtitle1">Kết quả kiểm tra</Typography>
                <Paper
                  elevation={3}
                  sx={{
                    padding: 2,
                    backgroundColor: variableStyles.GreenPrimaryColor50,
                    borderRadius: "10px",
                    margin: "10px 0px 20px 0px",
                  }}
                >
                  <HighlightedContent
                    content={confirmedContent}
                    keywords={confirmedKeywords}
                    keywordColors={keywordColors}
                  />
                </Paper>
                <Tabs
                  value={selectedTab}
                  onChange={handleTabChange}
                  sx={{
                    marginBottom: "20px",
                    borderBottom: "0.5px solid #D3DCDF",
                  }}
                >
                  <Tab label="1 Words Density" sx={{ width: "25%" }} />
                  <Tab label="2 Words Density" sx={{ width: "25%" }} />
                  <Tab label="3 Words Density" sx={{ width: "25%" }} />
                  <Tab label="4+ Words Density" sx={{ width: "25%" }} />
                </Tabs>
                {renderTableData()}
              </Box>
            </Grid>
          ) : null}
        </Grid>
      </KeywordDensityWrapper>
    </Fragment>
  );
};

export default KeywordDensity;
