import { Popover } from "@mui/material";
import { styled } from "@mui/material/styles";
import variableStyles from "../../theme/variable-styles";

export const FastFilterListWrapper = styled("ul")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  backgroundColor: variableStyles.NaturalColor100,
  width: "max-content",
  padding: "1px",
  borderRadius: "8px",
  marginBottom: "16px",
  "&.disabled": {
    pointerEvents: "none",
  },
  "& li": {
    "& button": {
      color: variableStyles.NaturalColor700,
      fontWeight: variableStyles.fwMedium,
      fontSize: "13px",
      lineHeight: "18.2px",
      borderRadius: "6px",
      padding: "5px 11px",
      backgroundColor: "transparent",
      border: `1px solid ${variableStyles.NaturalColor100}`,
      transition: "all .1s ease-out",
      justifyContent: "flex-start",
      gap: "8px",
    },
    "&.active, &:hover": {
      "& button": {
        backgroundColor: variableStyles.NaturalColor0,
        border: `1px solid ${variableStyles.NaturalColor200}`,
        boxShadow:
          "0px 39px 67.8px 0px #1956580A,0px 21px 37px -15px #1956580F,0px 1px 1px 0px #00000014",
      },
    },
    "&.active": {
      "& button": {
        color: variableStyles.NaturalColor1000,
      },
    },
  },
}));

export const FastFilterListPopoverWrapper = styled(Popover)(() => ({
  "& .MuiPaper-root": {
    borderRadius: "8px",
    padding: "2px 0",
  },
  "& .popover-list": {
    display: "flex",
    flexDirection: "column",
    "& li": {
      padding: "2px 4px",
      width: "100%",
      "& button": {
        color: variableStyles.NaturalColor700,
        fontWeight: variableStyles.fwMedium,
        fontSize: "13px",
        lineHeight: "18.2px",
        backgroundColor: "transparent",
        width: "100%",
        justifyContent: "flex-start",
        padding: "8px 10px",
        borderRadius: "6px",
        "&:hover": {
          backgroundColor: variableStyles.NaturalColor100,
        },
      },
      "&.active": {
        "& button": {
          backgroundColor: variableStyles.NaturalColor100,
        },
      },
    },
  },
}));
