import { Typography } from "@mui/material";
import { OverviewCommonSubTitleWrapper } from "./styles";

interface OverviewCommonSubTitleProps {
  title: string;
  className?: string;
  description?: string;
}

export default function OverviewCommonSubTitle(
  props: OverviewCommonSubTitleProps
) {
  const { title, className = "", description } = props;
  return (
    <OverviewCommonSubTitleWrapper
      className={`${className}`}
    >
      <Typography variant="h3" className="fit-one-line">
        {title}
      </Typography>
      {!!description && <p className="description">{description}</p>}
    </OverviewCommonSubTitleWrapper>
  );
}
