import variableStyles from "../../theme/variable-styles";
import ImageCustom from "../ImageCustom";
import { AvatarWrapper } from "./styles";

export interface AvatarProps {
  src?: string | null;
  alt?: string;
  width?: number;
  height?: number;
  className?: string;
  borderColor?: string;
  borderWidth?: number;
  isOnline?: boolean;
}

export default function Avatar(props: AvatarProps) {
  const {
    src,
    alt,
    width = 36,
    height = 36,
    className,
    borderColor = variableStyles.NaturalColor400,
    borderWidth = 1,
    isOnline,
  } = props;
  return (
    <AvatarWrapper
      borderWidth={borderWidth}
      borderColor={borderColor}
      className={className}
      width={width}
      height={height}
    >
      <div className="image-wrapper">
        <ImageCustom
          isAvatar
          src={src}
          alt={alt}
          width={width - 2}
          height={height - 2}
        />
      </div>
      {isOnline && <div className="online-circle"></div>}
    </AvatarWrapper>
  );
}
