import { styled } from "@mui/material/styles";
import variableStyles from "../../theme/variable-styles";

export const OverviewIndexBoxWrapper = styled("div")<{ maxwidth: string }>(
  ({ maxwidth }) => ({
    backgroundColor: variableStyles.NaturalColor0,
    border: `1px solid ${variableStyles.NaturalColor100}`,
    borderRadius: "12px",
    boxShadow:
      "0px 39px 67.8px 0px #1956580A, 0px 21px 37px -15px #1956580F,0px 1px 1px 0px #00000014",
    padding: "10px 16px 16px",
    width: "100%",
    maxWidth: maxwidth,
    "&.normal": {
      "& .analysis-wrapper": {
        "& .analysis-text": {
          "& span": {
            color: variableStyles.Success500,
          },
        },
      },
    },
    "&.warning": {
      "& .analysis-wrapper": {
        "& .analysis-text": {
          "& span": {
            color: variableStyles.Warning600,
          },
        },
      },
    },
    "&.danger": {
      "& .analysis-wrapper": {
        "& .analysis-text": {
          "& span": {
            color: variableStyles.Error500,
          },
        },
      },
    },
    "& .box-header": {
      display: "flex",
      gap: "14px",
      alignItems: "flex-start",
      "& .header-title": {
        display: "flex",
        alignItems: "center",
        flex: "1 1 auto",
        gap: "4px",
        borderBottom: `1px solid ${variableStyles.NaturalColor300}`,
        padding: "4px 0 6px",
        "& .title-text": {
          color: variableStyles.NaturalColor900,
          fontWeight: variableStyles.fwSemiBold,
          fontSize: "14px",
          lineHeight: "16.94px",
        },
      },
      "& figure": {
        width: "40px",
        height: "40px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
    "& .box-index": {
      color: variableStyles.NaturalColor950,
      fontWeight: variableStyles.fwMedium,
      fontSize: "30px",
      lineHeight: "36.31px",
      marginBottom: "11px",
    },
    "& .analysis-wrapper": {
      display: "flex",
      alignItems: "flex-start",
      gap: "4px",
      marginTop: "24px",
      "& .analysis-text": {
        color: variableStyles.NaturalColor950,
        fontWeight: variableStyles.fwMedium,
        fontSize: "12px",
        lineHeight: "14.52px",
        "& span": {
          color: variableStyles.Warning600,
          fontWeight: variableStyles.fwBold,
        },
      },
    },
  })
);
