import React from "react";
import OverviewIndexBox from "../../../../../components/OverviewIndexBox";
import { TechnicalViewDomainAuditWrapper } from "./styles";
import IconSEO from "../../../../../assets/images/domai-audit/overview-section/icon_overview_SEO.svg";
import IconUX from "../../../../../assets/images/domai-audit/overview-section/icon_overview_UX.svg";
import LogoTemp from "../../../../../assets/images/temp/temp_avatar.png";
import TechnicalTitle from "./components/TechnicalTitle";
import StatusFullCircle from "./components/StatusFullCircle";
import ThematicReportBox from "./components/ThematicReportBox";
import IssueChart from "./components/IssueChart";
import { IChartValue } from "../../../../../models/common/models.type";

export default function TechnicalViewTab() {
  const indexData: {
    type: "normal" | "warning" | "danger";
    percent: number;
    title: string;
    icon?: string;
    logo?: string;
    percentHistory?: number;
    infor: string;
  }[] = [
    {
      type: "normal",
      percent: 20,
      title: "Trải nghiệm người dùng",
      icon: IconSEO,
      percentHistory: 15,
      infor: "",
    },
    {
      type: "warning",
      percent: 10,
      title: "SEO Website",
      icon: IconUX,
      percentHistory: 15,
      infor: "",
    },
    {
      type: "normal",
      percent: 20,
      title: "Tối ưu chuyển đổi",
      icon: IconSEO,
      percentHistory: 15,
      infor: "",
    },
    {
      type: "normal",
      percent: 20,
      title: "Hồ sơ doanh nghiệp",
      logo: LogoTemp,
      percentHistory: 15,
      infor: "",
    },
  ];

  const dataIssueBox: {
    title: string;
    index: number;
    diffrent: number;
    chartData: IChartValue[];
    status: "notes" | "warning" | "error";
  }[] = [
    {
      title: "Nghiêm trọng",
      index: 12,
      diffrent: 2,
      chartData: [
        { test: 10, date: "22/10/2023" },
        { test: 8, date: "22/11/2023" },
        { test: 4, date: "22/12/2023" },
        { test: 1, date: "22/01/2024" },
        { test: 4, date: "22/02/2024" },
        { test: 5, date: "22/03/2024" },
        { test: 2, date: "22/04/2024" },
        { test: 9, date: "22/05/2024" },
      ].reverse(),
      status: "error",
    },
    {
      title: "Cảnh báo",
      index: 12,
      diffrent: 2,
      chartData: [
        { test: 10, date: "22/10/2023" },
        { test: 8, date: "22/11/2023" },
        { test: 4, date: "22/12/2023" },
        { test: 1, date: "22/01/2024" },
        { test: 4, date: "22/02/2024" },
        { test: 5, date: "22/03/2024" },
        { test: 2, date: "22/04/2024" },
        { test: 9, date: "22/05/2024" },
      ].reverse(),
      status: "warning",
    },
    {
      title: "Chú ý",
      index: 12,
      diffrent: 2,
      chartData: [
        { test: 10, date: "22/10/2023" },
        { test: 8, date: "22/11/2023" },
        { test: 4, date: "22/12/2023" },
        { test: 1, date: "22/01/2024" },
        { test: 4, date: "22/02/2024" },
        { test: 5, date: "22/03/2024" },
        { test: 2, date: "22/04/2024" },
        { test: 9, date: "22/05/2024" },
      ].reverse(),
      status: "notes",
    },
  ];

  const thematicData = [
    { title: "Crawlability", index: 0 },
    { title: "HTTPS", index: 10 },
    { title: "Crawlability", index: 30 },
    { title: "Crawlability", index: 40 },
    { title: "Crawlability", index: 50 },
    { title: "Crawlability", index: 60 },
    { title: "Crawlability", index: 90 },
  ];

  return (
    <TechnicalViewDomainAuditWrapper className="scrollbar">
      <ul className="index-list">
        {indexData.map(data => (
          <li key={data.title}>
            <OverviewIndexBox
              type={data.type}
              percent={data.percent}
              title={data.title}
              icon={data.icon}
              logo={data.logo}
              percentHistory={data.percentHistory}
              maxWidth="100%"
              infor={data.infor}
            />
          </li>
        ))}
      </ul>
      <div className="technical-detail-wrapper">
        <div className="detail-wrapper-left">
          <div className="left-site-health common-box-layout">
            <TechnicalTitle
              title="Site Health"
              infor="Site Health"
              className="site-health-title"
            />
            <p className="site-health-status">
              Your Site Heath: <span>Good</span>
            </p>
            <StatusFullCircle
              percent={60}
              className="status-circle-progress"
              type="good"
            />
            <button>Lorem ipsum dolor sit amet</button>
          </div>
          <div className="left-crawled-pages common-box-layout">
            <TechnicalTitle
              title="Crawled Pages"
              infor="Crawled Pages"
              className="crawled-pages-title"
            />
          </div>
        </div>
        <div className="detail-wrapper-right">
          <div className="right-issue common-box-layout">
            <TechnicalTitle
              title="Issue"
              infor="Issue"
              className="issue-title"
            />
            <ul className="issue-list">
              {dataIssueBox.map(issue => (
                <li key={issue.title}>
                  <IssueChart data={issue} />
                </li>
              ))}
            </ul>
          </div>
          <div className="right-thematic common-box-layout">
            <TechnicalTitle
              title="Thematic Report"
              infor="Thematic Report"
              className="thematic-title"
            />
            <ul className="thematic-list">
              {thematicData.map((thematic, idx) => (
                <li key={idx}>
                  <ThematicReportBox onClick={() => {}} data={thematic} />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </TechnicalViewDomainAuditWrapper>
  );
}
