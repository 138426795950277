import { Button, PopoverOrigin } from "@mui/material";
import React from "react";
import { IValue } from "../../models/common/models.type";
import { TabletFilterModalWrapper } from "./styles";

interface TabletFilterModalProps {
  children: React.ReactNode;
  onSubmit: () => void;
  open: boolean;
  anchorEl: any;
  onClose: () => void;
  onReset: () => void;
  anchorOrigin?: PopoverOrigin;
}

export default function TabletFilterModal(props: TabletFilterModalProps) {
  const { children, onSubmit, onClose, anchorEl, open, onReset, anchorOrigin } =
    props;

  return (
    <TabletFilterModalWrapper
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={
        anchorOrigin
          ? anchorOrigin
          : {
              vertical: "bottom",
              horizontal: "left"
            }
      }
    >
      <p className="filter-modal-text">Hiển thị theo</p>
      <div className="filter-checkboxs">{children}</div>
      <div className="filter-action">
        <button onClick={onReset} className="clear-date-button">
          Xoá bộ lọc
        </button>
        <Button
          size="xsmall"
          variant="contained"
          className="button-submit"
          onClick={onSubmit}
        >
          Áp dụng
        </Button>
      </div>
    </TabletFilterModalWrapper>
  );
}
