import { styled } from "@mui/material/styles";
import variableStyles from "../../../../../../../../theme/variable-styles";

export const TechnicalOverviewTabWrapper = styled("div")(() => ({
  "& .technical-overview-title": {
    fontWeight: variableStyles.fwSemiBold,
    fontSize: "18px",
    lineHeight: "22.05px",
    color: variableStyles.blueSecondary800,
    marginBottom: "16px",
  },
  "& .chart-list": {
    gap: "16px",
    display: "flex",
    flexDirection: "column",
    marginBottom: "16px",
    "& li": {
      flex: "1 1 auto",
    },
  },
  "& .overview-list": {
    "& .overview-nodata": {
      backgroundColor: variableStyles.NaturalColor100,
      borderRadius: "6px",
      border: `1px solid ${variableStyles.NaturalColor200}`,
    },
  },
  "& .issue-list-wrapper": {
    marginBottom: "40px",
    "&.list-have-title": {
      marginBottom: "16px",
    },
  },
  "& .issue-list": {
    display: "flex",
    flexDirection: "column",
    padding: "16px",
    backgroundColor: variableStyles.NaturalColor100,
    borderRadius: "6px",
    border: `1px solid ${variableStyles.NaturalColor200}`,
    "& li": {
      padding: "15px 16px",
      backgroundColor: variableStyles.NaturalColor0,
      borderBottom: `1px solid ${variableStyles.NaturalColor200}`,
      borderLeft: `1px solid ${variableStyles.NaturalColor200}`,
      borderRight: `1px solid ${variableStyles.NaturalColor200}`,
      gap: "12px",
      "&:first-of-type": {
        borderTop: `1px solid ${variableStyles.NaturalColor200}`,
        borderRadius: "6px 6px 0 0",
      },
      "&:last-of-type": { borderRadius: "0 0 6px 6px" },
      "& .issue-content": {
        flex: "1 1 auto",
        "& .content-type-icon": {
          marginRight: "8px",
        },
        "& .issue-subtitle": {
          fontWeight: variableStyles.fwMedium,
          fontSize: "12px",
          lineHeight: "18px",
          color: variableStyles.NaturalColor1000,
          marginRight: "4px",
        },
      },
      "& .issue-action": {
        gap: "24px",
        flex: "0 0 auto",
        "&.no-key": {
          "& button": { pointerEvents: "none" },
        },
        "& button": {
          backgroundColor: variableStyles.NaturalColor0,
          fontWeight: variableStyles.fwSemiBold,
          fontSize: "10px",
          lineHeight: "15px",

          padding: "2px 8px",

          borderRadius: "100px",
          "&.error": {
            border: `1px solid ${variableStyles.Error500}`,
            color: variableStyles.Error700,
          },
          "&.warning": {
            color: variableStyles.Warning600,
            border: `1px solid ${variableStyles.Warning600}`,
          },
          "&.infor": {
            color: variableStyles.Info400,
            border: `1px solid ${variableStyles.Info500}`,
          },
        },
        "& .action-recommend": {
          fontWeight: variableStyles.fwRegular,
          fontSize: "10px",
          lineHeight: "20px",
          color: variableStyles.blueSecondary800,
        },
      },
    },
  },
  "& .issue-child-list": {
    "&:not(:last-of-type)": {
      marginBottom: "16px",
    },
  },
  "& .issue-title": {
    fontWeight: variableStyles.fwSemiBold,
    fontSize: "18px",
    lineHeight: "22.05px",
    color: variableStyles.blueSecondary800,
    marginBottom: "16px",
  },
  "& .issue-detail-title": {
    fontWeight: variableStyles.fwSemiBold,
    fontSize: "14px",
    lineHeight: "24px",
    color: variableStyles.NaturalColor1000,
    marginBottom: "14px",
  },
}));

export const TechnicalOverviewChartWrapper = styled("div")(() => ({
  border: `1px solid ${variableStyles.NaturalColor300}`,
  borderRadius: "6px",
  backgroundColor: variableStyles.NaturalColor0,
  boxShadow: "0px 2px 3.5px 0px #0000000A",
  "& .overview-chart-caption": {
    padding: "24px",
  },
  "& .chart-wrapper": {
    borderTop: `1px solid ${variableStyles.NaturalColor200}`,
  },
}));
