import { styled } from "@mui/material/styles";

export const ContentContentsWrapper = styled("div")(() => ({
  padding: "40px",
  "& .content-list": {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    marginBottom: "32px",
  },
  "& .issue-table-wrapper": {
    display: "flex",
    flexDirection: "column",
    gap: "32px",
  },
}));
