import { styled } from "@mui/material/styles";
import variableStyles from "../../../theme/variable-styles";

export const BarChartCustomWrapper = styled("div")<{ height?: string }>(
  ({ theme, height }) => ({
    width: "100%",
    height: !!height ? height : "195px",
    marginTop: "34px",
    "& .custom-tooltip": {
      backgroundColor: variableStyles.NaturalColor0,
      border: `1px solid ${variableStyles.NaturalColor200}`,
      borderRadius: "5px",
      padding: "6px",
      textAlign: "center",
      width: "128px",
      boxShadow: "0px 4px 4.9px 0px #00000012",
      "& .YAxis-text": {
        color: variableStyles.NaturalColor950,
        fontWeight: variableStyles.fwSemiBold,
        fontSize: "12px",
        lineHeight: "20px",
      },
      "& .XAxis-text": {
        color: variableStyles.NaturalColor800,
        fontWeight: variableStyles.fwRegular,
        fontSize: "12px",
        lineHeight: "15px",
      },
    },
    "& .legend-list": {
      display: "flex",
      alignItems: "center",
      gap: "24px",
      flexWrap: "wrap",
      "& li": {
        display: "flex",
        alignItems: "center",
        gap: "8px",
        backgroundColor: variableStyles.NaturalColor100,
        padding: "6px 8px",
        borderRadius: "8px",
        "& div": {
          width: "14px",
          height: "14px",
          borderRadius: "4px",
        },
        "& p": {
          color: variableStyles.NaturalColor1000,
          fontWeight: variableStyles.fwRegular,
          fontSize: "12px",
          lineHeight: "18px",
        },
      },
    },
  })
);
