import { Box, Paper } from "@mui/material";
import { styled } from "@mui/styles";
import variableStyles from "../../../../../../../../../theme/variable-styles";

export const LoadingExperienceWrapper = styled(Paper)(() => ({
  marginTop: "12px",
  padding: "12px",
  boxShadow: "0px 1px 3px 1px #3C404326",
  borderRadius: "8px",
  "& .domain-infor": {
    marginBottom: "20px",
  },
  "& .overview-wrapper": {
    display: "flex",
    gap: "8px ",
    "@media (min-width:1200px)": {
      "& .overview-infor-wrapper": {
        flex: "0 0 auto",
        width: "228px",
      },
    },
    "@media (max-width:1199.98px)": {
      flexDirection: "column",
      alignItems: "center",
      "& .overview-infor-wrapper": {
        flex: "1 1 auto",
        width: "100%",
      },
      "& .overview-index-wrapper": {
        width: "100%",
      },
    },
    "& .overview-infor-wrapper": {
      backgroundColor: variableStyles.NaturalColor100,
      borderRadius: "8px",
      display: "flex",
      flexDirection: "column",
      gap: "12px",
      padding: "16px",
      "& li": {
        gap: "8px ",
        "& .icon-overview-audit": {
          flex: " 0 0 auto",
        },
        "& p": {
          color: variableStyles.NaturalColor1000,
          fontWeight: variableStyles.fwRegular,
          fontSize: "10px",
          lineHeight: "15px",
        },
      },
    },
    "& .overview-index-wrapper": {
      display: "grid",
      gridTemplateColumns: "repeat(auto-fill, minmax(178px, 1fr))",
      width: "100%",
      gap: "8px",
      "& li": {
        minWidth: "178px",
      },
    },
  },
}));
