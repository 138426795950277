import { Dialog } from "@mui/material";
import { styled } from "@mui/material/styles";
import variableStyles from "../../../../theme/variable-styles";

export const ErrorTableModalWrapper = styled(Dialog)(() => ({
  "& .MuiDialog-container": { justifyContent: "flex-end" },
  "& .MuiPaper-root": {
    borderRadius: "12px 0 0 12px",
    minWidth: "744px",
    width: "744px",
    height: "100vh",
    maxHeight: "100vh",
    maxWidth: "unset",
    padding: "20px 12px 16px",
    backgroundColor: variableStyles.NaturalColor0,
    margin: "0",
  },
}));
