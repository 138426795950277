import React, { useEffect, useState } from "react";
import { Divider } from "@mui/material";
import { DEFAULT_BASE_PAGING_RES } from "../../../../common/constants/value.constant";
import CommonHandle from "../../../../common/handles/common.handles";
import {
  BackLinkTag,
  BarChartCustom,
  BubbleChartCustom,
  BubbleNoAxisChart,
  FastFilterList,
  ImageCustom,
  IndexNoPercentBox,
  PieChartDonutShape,
  ProgressBar,
} from "../../../../components";
import {
  ITableNavDetailNoPaginationHeader,
  TableNavDetailNoPagination,
} from "../../../../components/Table/TableNavDetailNoPagination";
import {
  IGetBackLinkAnchorRes,
  IGetBackLinkOverviewRes,
  IGetBackLinkOverviewTableRes,
  IGetDomainOrganicAccessRes,
  IGetDomainOrganicKeywordRes,
  IGetDomainOverviewRes,
  IGetRefferingDomainsRes,
} from "../../../../models/audit-report";
import {
  EBackLinkTag,
  EIntentsPositions,
  ETypeLinkCheck,
} from "../../../../models/common/models.enum";

import variableStyles from "../../../../theme/variable-styles";
import OverviewCommonCaption from "../../../DomainAudit/components/OverviewCommonCaption";
import IconChart from "../../../../assets/images/common/icon_chart_line_green.svg";
import { DomainOverviewCheckWrapper } from "./styles";
import CheckToolTitle from "../../components/CheckToolTitle";
import AuditReportService from "../../../../services/api/audit.report.service";
import ImageNoData from "../../../../assets/images/common/img_project_empty.png";
import { DATE_FORMAT } from "../../../../config/constants";
import moment from "moment";
import { generatePath, useNavigate, useSearchParams } from "react-router-dom";
import queryString from "query-string";
import { ROUTE_PATH } from "../../../../common/constants/app.constant";
import RegionService from "../../../../services/api/region.service";
import { IRegionRes } from "../../../../models/region";
import { IValue } from "../../../../models/common/models.type";

export interface IDataFilterOverviewType {
  target: string | null;
  type: string | null;
  region: string | null;
}

export default function DomainOverviewCheck() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const dataFilter: IDataFilterOverviewType = {
    target: searchParams.get("target"),
    type: searchParams.get("type"),
    region: searchParams.get("region") || "all",
  };

  const [dataRes, setDataRes] = useState<IGetDomainOverviewRes | null>();
  const [dataResKeyword, setDataResKeyword] =
    useState<IGetDomainOrganicKeywordRes[]>();
  const [dataResSearch, setDataResSearch] = useState<any[]>();
  const [dataResBacklinkOverview, setDataResBacklinkOverview] =
    useState<IGetBackLinkOverviewRes>();
  const [
    dataResBackLinkAuthorizeScoreProfile,
    setDataResBackLinkAuthorizeScoreProfile,
  ] = useState<any[]>();
  const [dataResRefferingDomainsTable, setDataResRefferingDomainsTable] =
    useState<IGetRefferingDomainsRes[]>();
  const [dataResBackLinkOverviewTable, setDataResBackLinkOverviewTable] =
    useState<IGetBackLinkOverviewTableRes[]>();
  const [dataResBackLinkAnchorTable, setDataResBackLinkAnchorTable] =
    useState<IGetBackLinkAnchorRes[]>();
  const [dataResCountries, setDataResCountries] = useState<IRegionRes[]>();
  const [resDataOrganicAccess, setResDataOrganicAccess] =
    useState<IGetDomainOrganicAccessRes[]>();

  useEffect(() => {
    getDataofPage();
  }, []);

  const getDataofPage = async () => {
    await Promise.all([
      await AuditReportService.getDomainOverviewPerform({
        type: "domain_rank_history",
        domain: "string",
        displayDaily: 1,
      }),
      await AuditReportService.getDomainOrganicKeyword({
        type: "",
        domain: "",
        isOrderByAsc: true,
        pageSize: 30,
      }),
      await AuditReportService.getDomainOrganicSearch({
        type: "",
        domain: "",
        isOrderByAsc: true,
        pageSize: 30,
      }),
      await AuditReportService.getBackLinkOverview({
        type: "",
        domain: "",
      }),
      await AuditReportService.getBackLinkAuthorizeScoreProfile({
        type: "",
        domain: "",
      }),
      await AuditReportService.getRefferringDomains({
        type: "",
        domain: "",
        pageSize: 30,
      }),
      await AuditReportService.getBackLinkOverviewTable({
        type: "",
        domain: "",
        pageSize: 30,
      }),
      await AuditReportService.getBackLinkAnchor({
        type: "",
        domain: "",
        pageSize: 5,
      }),
      await RegionService.getAllCountries(),
      await AuditReportService.getDomainOrganicAccess({
        type: "",
        domain: "",
        pageSize: 5,
        isOrderByAsc: false,
      }),
    ]).then(
      ([
        resOverview,
        resKeyword,
        resSearch,
        resBacklinkOverview,
        resBackLinkAuthorizeScoreProfile,
        resRefferringDomains,
        resBackLinkOverviewTable,
        resBackLinkAnchor,
        resCountries,
        resDomainOrganicAccess,
      ]) => {
        if (!resOverview.isError) {
          setDataRes(resOverview.data);
        }
        if (!resKeyword.isError) {
          setDataResKeyword(resKeyword.data.slice(0, 5));
        }
        if (!resSearch.isError) {
          setDataResSearch(resSearch.data.slice(0, 5));
        }
        if (!resBacklinkOverview.isError) {
          setDataResBacklinkOverview(resBacklinkOverview.data);
        }
        if (!resBackLinkAuthorizeScoreProfile.isError) {
          setDataResBackLinkAuthorizeScoreProfile(
            resBackLinkAuthorizeScoreProfile.data
          );
        }
        if (!resRefferringDomains.isError) {
          setDataResRefferingDomainsTable(
            resRefferringDomains.data.slice(0, 5)
          );
        }
        if (
          !resBackLinkOverviewTable.isError &&
          !!resBackLinkOverviewTable.data
        ) {
          setDataResBackLinkOverviewTable(
            resBackLinkOverviewTable.data.slice(0, 10)
          );
        }
        if (!resBackLinkAnchor.isError && !!resBackLinkAnchor.data) {
          setDataResBackLinkAnchorTable(resBackLinkAnchor.data.slice(0, 5));
        }
        if (!resCountries.isError && !!resCountries.data) {
          setDataResCountries(resCountries.data.items);
        }
        if (!resDomainOrganicAccess.isError && !!resDomainOrganicAccess.data) {
          setResDataOrganicAccess(resDomainOrganicAccess.data);
        }
      }
    );
  };

  const renderIntents = (value: string) => {
    switch (value) {
      case EIntentsPositions.Commercial:
        return "C";
      case EIntentsPositions.Informational:
        return "I";
      case EIntentsPositions.Navigational:
        return "N";
      default:
        return "T";
    }
  };

  const headersTable: ITableNavDetailNoPaginationHeader<any>[] = [
    {
      field: "keyword",
      title: `Từ khoá`,
      width: "120px",
      isSticky: true,
    },
    {
      field: "intents",
      title: `Intent`,
      width: "80px",
      className: "table-cell-intents-tag",
      handleItem: (item: IGetDomainOrganicKeywordRes) => {
        const arrayIntents = item.intents?.split(",");
        return (
          <div className="flex-start-align-center intents-tags">
            {arrayIntents.map(array => {
              const character = renderIntents(array);
              return (
                <p className={`${character} intents-tag`} key={array}>
                  {character}
                </p>
              );
            })}
          </div>
        );
      },
    },
    {
      field: "position",
      title: `Vị trí`,
      width: "70px",
    },
    {
      field: "searchVolume",
      title: `Volume`,
      width: "80px",
    },
    {
      field: "keywordDifficulty",
      title: `KD`,
      width: "50px",
    },
    {
      field: "cpc",
      title: `CPC`,
      width: "50px",
    },
    {
      field: "traffic",
      title: `Traffic`,
      width: "80px",
    },
    {
      field: "url",
      title: `URL`,
      width: "180px",
      className: "table-cell-link",
      isLink: true,
    },
  ];

  const headersOrganicTable: ITableNavDetailNoPaginationHeader<any>[] = [
    {
      field: "domain",
      title: `Domain`,
      width: "110px",
      isSticky: true,
      className: "table-cell-link",
      isLink: true,
    },
    {
      field: "competitorRelevance",
      title: `Competitor Relevence`,
      width: "200px",
      className: "table-cell-progress",
      handleItem: (item: any) => {
        return (
          <div>
            <p className="progress-percent">{item.competitorRelevance || 0}%</p>
            <ProgressBar
              percent={item.competitorRelevance || 0}
              className="progress-bar"
              type={"normal"}
              height={"4px"}
            />
          </div>
        );
      },
    },
    {
      field: "organicTraffic",
      title: `Organic Traffic`,
      width: "120px",
    },
    {
      field: "organicKeywords",
      title: `Organic keywords`,
      width: "140px",
    },
    {
      field: "commonKeywords",
      title: `Common keywords`,
      width: "140px",
    },
  ];

  const headersTopPageTable: ITableNavDetailNoPaginationHeader<any>[] = [
    {
      field: "url",
      title: `Website`,
      className: "table-cell-link",
      handleItem: (item: any) => {
        return (
          <a className="fit-one-line" target="_blank" rel="noopener noreferrer">
            {item.url}
          </a>
        );
      },
    },
    {
      field: "numberOfKeywords",
      title: `Number keywords`,
      width: "140px",
    },
    {
      field: "traffic",
      title: `Traffic`,
      width: "120px",
    },
    {
      field: "commonKeywords",
      title: `%Traffic`,
      width: "140px",
      handleItem: item => {
        return <p>{Number(item.trafficPercent).toFixed(2)}%</p>;
      },
    },
    // {
    //   field: "commonKeywords",
    //   title: `Top key`,
    //   width: "140px",
    // },
    // {
    //   field: "commonKeywords",
    //   title: `Volume`,
    //   width: "140px",
    // },
    // {
    //   field: "commonKeywords",
    //   title: `Position`,
    //   width: "140px",
    // },
  ];

  const headersBacklinkTable: ITableNavDetailNoPaginationHeader<any>[] = [
    {
      field: "sourceTitle",
      title: `Tiêu đề/ đường dẫn trang giới thiệu`,
      width: "280px",
      className: "table-cell-link",
      isSticky: true,
      sx: { verticalAlign: "top", padding: "14px 6px !important" },
      handleItem: (item: any) => {
        return (
          <div className="title-with-link">
            <p className="fit-one-line">{item.sourceTitle}</p>
            <a
              className="fit-one-line"
              href={item.sourceUrl}
              target="_blank"
              rel="noreferrer"
            >
              {item.sourceUrl}
            </a>
          </div>
        );
      },
    },
    {
      field: "pageAScore",
      title: `Điểm uy tín`,
      width: "100px",
      sx: { verticalAlign: "top", padding: "14px 6px !important" },
      handleItem: item => {
        return <p>{CommonHandle.formatCurrency(item.pageAScore)}</p>;
      },
    },
    {
      field: "anchor",
      title: `Anchor text/ URL`,
      width: "200px",
      sx: { verticalAlign: "top", padding: "14px 6px !important" },
      handleItem: (item: any) => {
        const firstTag = [
          { name: EBackLinkTag.Text, value: item.text },
          { name: EBackLinkTag.Form, value: item.form },
          { name: EBackLinkTag.Frame, value: item.frame },
          { name: EBackLinkTag.Image, value: item.image },
        ];

        const secondTag = [
          { name: EBackLinkTag.Newlink, value: item.newLink },
          { name: EBackLinkTag.Lostlink, value: item.lostLink },
        ];

        const renderFirstTag = firstTag.find(tag => !!tag.value);
        const renderSecondTag = secondTag.find(tag => !!tag.value);
        return (
          <div className="title-with-link">
            <p className="fit-one-line">{item.anchor}</p>
            <a
              className="fit-one-line"
              href={item.targetUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.targetUrl}
            </a>
            <ul
              className="anchor-tag-list flex-start-align-center"
              style={{ gap: "4px" }}
            >
              {!!renderFirstTag && <BackLinkTag tag={renderFirstTag.name} />}
              {!!renderSecondTag && <BackLinkTag tag={renderSecondTag.name} />}
              <BackLinkTag
                tag={
                  item.noFollow ? EBackLinkTag.NoFollow : EBackLinkTag.Follow
                }
              />
            </ul>
          </div>
        );
      },
    },
    {
      field: "firstSeen",
      title: `First seen`,
      width: "100px",
      sx: { verticalAlign: "top", padding: "14px 6px !important" },
      handleItem: item => {
        return moment(item.firstSeen).format(DATE_FORMAT);
      },
    },
    {
      field: "lastSeen",
      title: `Last seen`,
      width: "100px",
      sx: { verticalAlign: "top", padding: "14px 6px !important" },
      handleItem: item => {
        return moment(item.lastSeen).format(DATE_FORMAT);
      },
    },
  ];

  const headersReferingDomainTable: ITableNavDetailNoPaginationHeader<any>[] = [
    {
      field: "domain",
      title: `Tên miền`,
      width: "210px",
      className: "table-cell-link",
      isSticky: true,
      sx: { padding: "14px 6px !important" },
      handleItem: (item: any) => {
        return (
          <a
            className="fit-one-line"
            href={item.domain}
            target="_blank"
            rel="noopener noreferrer"
          >
            {item.domain}
          </a>
        );
      },
    },
    {
      field: "domainAScore",
      title: `Điểm uy tín`,
      width: "150px",
      sx: { padding: "14px 6px !important" },
      handleItem: (item: any) => {
        return <p className="fit-one-line">{item.domainAScore}</p>;
      },
    },
    {
      field: "backLinksNum",
      title: `Số liên kết ngược`,
      width: "150px",
      sx: { padding: "14px 6px !important" },
      handleItem: (item: any) => {
        return <p className="fit-one-line">{item.backLinksNum}</p>;
      },
    },
    {
      field: "ip",
      title: `Quốc gia/IP`,
      width: "200px",
      sx: { padding: "14px 6px !important" },
      handleItem: (item: any) => {
        return (
          <div className="table-cell-IP flex-start-align-center">
            {/* <ImageCustom
              src={IconTemp}
              alt={"country-icon"}
              width={"16px"}
              height={"12px"}
            /> */}
            <p className="fit-one-line">{item.ip}</p>
          </div>
        );
      },
    },
  ];

  const headersAnchorTextTable: ITableNavDetailNoPaginationHeader<any>[] = [
    {
      field: "anchor",
      title: `Anchor text`,
      width: "140px",
      sx: { verticalAlign: "top", padding: "14px 6px !important" },
      handleItem: item => {
        return <p>{item.anchor}</p>;
      },
    },
    {
      field: "backLinksNum",
      title: `Backlink`,
      width: "100px",
      sx: { verticalAlign: "top", padding: "14px 6px !important" },
      handleItem: item => {
        return <p>{item.backLinksNum}</p>;
      },
    },
    {
      field: "domainsNum",
      title: `Referring domain`,
      width: "140px",
      sx: { verticalAlign: "top", padding: "14px 6px !important" },
      handleItem: item => {
        return <p>{item.domainsNum}</p>;
      },
    },
    {
      field: "firstSeen",
      title: `First seen`,
      width: "100px",
      sx: { verticalAlign: "top", padding: "14px 6px !important" },
      handleItem: item => {
        return moment(item.firstSeen).format(DATE_FORMAT);
      },
    },
    {
      field: "lastSeen",
      title: `Last seen`,
      width: "100px",
      sx: { verticalAlign: "top", padding: "14px 6px !important" },
      handleItem: item => {
        return moment(item.lastSeen).format(DATE_FORMAT);
      },
    },
  ];

  const backlinkTypesData: {
    title: string;
    percent: string;
  }[] = [
    {
      title: "Text",
      percent: (
        ((dataResBacklinkOverview?.textsNum || 0) /
          (dataResBacklinkOverview?.total || 0)) *
        100
      ).toFixed(2),
    },
    {
      title: "Image",
      percent: (
        ((dataResBacklinkOverview?.imagesNum || 0) /
          (dataResBacklinkOverview?.total || 0)) *
        100
      ).toFixed(2),
    },
    {
      title: "Form",
      percent: (
        ((dataResBacklinkOverview?.formsNum || 0) /
          (dataResBacklinkOverview?.total || 0)) *
        100
      ).toFixed(2),
    },
    {
      title: "Frame",
      percent: (
        ((dataResBacklinkOverview?.framesNum || 0) /
          (dataResBacklinkOverview?.total || 0)) *
        100
      ).toFixed(2),
    },
  ];

  const dataChart: any = [];
  for (let t in dataRes?.dateItems[0]) {
    if (t.startsWith("x")) {
      switch (t) {
        case "x0":
          dataChart.push({ name: "Top 3", volume: dataRes?.dateItems[0][t] });
          break;
        case "x1":
          dataChart.push({
            name: "Top 4-10",
            volume: dataRes?.dateItems[0][t],
          });
          break;
        case "x2":
          dataChart.push({
            name: "Top 11-20",
            volume: dataRes?.dateItems[0][t],
          });
          break;
        case "x3":
          dataChart.push({
            name: "Top 21-50",
            volume: dataRes?.dateItems[0][t],
          });
          break;
        default:
          dataChart.push({
            name: "Top 51-100",
            volume: dataRes?.dateItems[0][t],
          });
          break;
      }
    }
  }

  const indexNoPercentData: any[] = [
    {
      title: "Điểm uy tín",
      value: CommonHandle.nFormatter(dataResBacklinkOverview?.aScore || 0),
      infor: "Điểm uy tín",
    },
    {
      title: "Organic traffic",
      value: CommonHandle.nFormatter(
        Number(dataRes?.dateItems[0].organicTraffic),
        1
      ),
      infor: "Organic traffic",
      navigatePath: generatePath(ROUTE_PATH.ORGANIC_KEYWORD_CHECK),
      navigateFilter: queryString.stringify({
        target: dataFilter.target,
        type: dataFilter.type,
        tab: "toppage",
      }),
    },
    {
      title: "Organic keyword",
      value: CommonHandle.nFormatter(
        dataChart.reduce((acc: any, o: any) => acc + parseInt(o.volume), 0)
      ),
      infor: "Organic keyword",
      navigatePath: generatePath(ROUTE_PATH.ORGANIC_KEYWORD_CHECK),
      navigateFilter: queryString.stringify({
        target: dataFilter.target,
        type: dataFilter.type,
        tab: "organickeywords",
      }),
    },
    {
      title: "Backlink",
      value: CommonHandle.nFormatter(dataResBacklinkOverview?.total || 0),
      infor: "Backlink",
      navigatePath: generatePath(ROUTE_PATH.BACKLINK_CHECK),
      navigateFilter: queryString.stringify({
        target: dataFilter.target,
        type: dataFilter.type,
        tab: "overview",
      }),
    },
    {
      title: "Reffering domain",
      value: CommonHandle.nFormatter(dataResBacklinkOverview?.domainsNum || 0),
      infor: "Reffering domain",
      navigatePath: generatePath(ROUTE_PATH.BACKLINK_CHECK),
      navigateFilter: queryString.stringify({
        target: dataFilter.target,
        type: dataFilter.type,
        tab: "referringdomain",
      }),
    },
  ];

  const handleChangeParamUrl = (
    key: keyof IDataFilterOverviewType,
    value: any
  ) => {
    const queryParams: any = {
      ...dataFilter,
      [key]: value,
    };
    if (!value || value.length <= 0) {
      delete queryParams[key];
    }
    const removeEmptyQuery = Object.fromEntries(
      Object.entries(queryParams).filter(([_, v]) => v != null)
    );
    navigateParams(removeEmptyQuery);
  };

  const navigateParams = (value: { [k: string]: unknown }) => {
    const newParams = queryString.stringify(value);

    const path = generatePath(ROUTE_PATH.DOMAIN_OVERVIEW_CHECK);
    navigate({
      pathname: `${path}`,
      search: `${newParams}`,
    });
  };

  const dataBubbleNoAxisChart: IValue[] =
    dataResBackLinkAnchorTable?.map(dataBubble => ({
      value: dataBubble.backLinksNum,
      title: dataBubble.anchor,
    })) || [];

  return (
    <DomainOverviewCheckWrapper>
      <CheckToolTitle
        title="Domain overview"
        domain={dataFilter.target || ""}
        onSubmit={value => {
          const path = generatePath(ROUTE_PATH.DOMAIN_OVERVIEW_CHECK);
          const newParams = queryString.stringify({
            ...value,
            region: dataFilter.region,
          });
          navigate({
            pathname: `${path}`,
            search: `${newParams}`,
          });
        }}
        value={{
          target: dataFilter.target,
          type: dataFilter.type as ETypeLinkCheck,
        }}
        className="domain-overview-header"
      />
      <div
        className="domain-overview-content scrollbar"
        id="domain-overview-content"
      >
        {!!dataResCountries && (
          <FastFilterList
            selectedValue={dataFilter.region || "all"}
            onChange={(v: string | null) => {
              if (!!v) {
                handleChangeParamUrl("region", v);
              } else {
                handleChangeParamUrl("region", "all");
              }
            }}
            data={[
              { value: "all", title: "Trên toàn thế giới" },
              ...dataResCountries.map(region => ({
                value: region.code,
                title: region.displayName,
              })),
            ]}
            hasShowMore
            numberOfItemShowed={4}
            className="content-location-filter"
          />
        )}
        <ul className="indexNoPercent-list">
          {indexNoPercentData.map(data => (
            <li key={data.title}>
              <IndexNoPercentBox
                data={data}
                onClick={() => {
                  if (!!data.navigatePath) {
                    const newWindow = window.open(
                      `${data.navigatePath}?${data.navigateFilter?.toLocaleLowerCase()}`,
                      "_blank",
                      "noopener,noreferrer"
                    );
                    if (newWindow) newWindow.opener = null;
                  }
                }}
                isNavidate={!!data.navigatePath}
              />
            </li>
          ))}
        </ul>
        <div className="content-divide-2">
          <div className="divide-left">
            <OverviewCommonCaption
              className="divide-caption"
              width="420px"
              caption="Top Organic keyword"
              tooltip="Top Organic keyword"
              icon={IconChart}
            />
            <Divider />
            <div className="left-content">
              {!!dataRes ? (
                <BarChartCustom
                  data={dataChart}
                  listKeyField={["volume"]}
                  fieldTextList={[
                    {
                      value: "Number of keyword",
                      color: variableStyles.blueSecondary200,
                    },
                  ]}
                  XAxisKey="name"
                  YAxisType="number"
                  height="200px"
                />
              ) : (
                <div className={"box-empty flex-column-center"}>
                  <ImageCustom
                    src={ImageNoData}
                    alt="Icon-NoData"
                    width={"120px"}
                    height={"120px"}
                  />
                  <p className={"text-empty"}>No data</p>
                </div>
              )}

              <TableNavDetailNoPagination
                headersTable={headersTable}
                data={{
                  ...DEFAULT_BASE_PAGING_RES,
                  items: dataResKeyword || [],
                }}
                handleViewMore={() => {
                  const path = generatePath(ROUTE_PATH.ORGANIC_KEYWORD_CHECK);
                  const newParams = queryString.stringify({
                    target: dataFilter.target,
                    type: dataFilter.type,
                    tab: "organickeywords",
                  });
                  const newWindow = window.open(
                    `${path}?${newParams?.toLocaleLowerCase()}`,
                    "_blank",
                    "noopener,noreferrer"
                  );
                  if (newWindow) newWindow.opener = null;
                }}
                maxHeight={"calc(100vh - 280px)"}
              />
            </div>
          </div>
          <div className="divide-right">
            <OverviewCommonCaption
              className="divide-caption"
              width="420px"
              caption="Top Organic competitive"
              tooltip="Top Organic competitive"
              icon={IconChart}
            />

            <Divider />
            <div className="right-content">
              {!!dataResSearch ? (
                <BubbleChartCustom
                  data={dataResSearch}
                  XAxisKey="organicKeywords"
                  YAxisKey="organicTraffic"
                  ZAxisKey="commonKeywords"
                  fieldTextList={[
                    { value: dataResSearch[0].domain, color: "" },
                    { value: dataResSearch[1].domain, color: "" },
                    { value: dataResSearch[2].domain, color: "" },
                    { value: dataResSearch[3].domain, color: "" },
                    { value: dataResSearch[4].domain, color: "" },
                  ]}
                  listKeyField={[
                    {
                      title: "Organic Keywords",
                      value: "organicKeywords",
                    },
                    {
                      title: "Organic Traffic",
                      value: "organicTraffic",
                    },
                    {
                      title: "Common Keywords",
                      value: "commonKeywords",
                    },
                  ]}
                  height="214px"
                />
              ) : (
                <div
                  className={"box-empty flex-column-center"}
                  style={{ marginTop: "44px" }}
                >
                  <ImageCustom
                    src={ImageNoData}
                    alt="Icon-NoData"
                    width={"120px"}
                    height={"120px"}
                  />
                  <p className={"text-empty"}>No data</p>
                </div>
              )}

              <TableNavDetailNoPagination
                headersTable={headersOrganicTable}
                data={{
                  ...DEFAULT_BASE_PAGING_RES,
                  items: dataResSearch || [],
                }}
                handleViewMore={() => {
                  const path = generatePath(ROUTE_PATH.ORGANIC_KEYWORD_CHECK);
                  const newParams = queryString.stringify({
                    target: dataFilter.target,
                    type: dataFilter.type,
                    tab: "organiccompetitors",
                  });
                  const newWindow = window.open(
                    `${path}?${newParams?.toLocaleLowerCase()}`,
                    "_blank",
                    "noopener,noreferrer"
                  );
                  if (newWindow) newWindow.opener = null;
                }}
              />
            </div>
          </div>
        </div>
        <h2 className="h2-title">Top page</h2>
        <div className="top-page-table">
          <TableNavDetailNoPagination
            headersTable={headersTopPageTable}
            data={{
              ...DEFAULT_BASE_PAGING_RES,
              items: resDataOrganicAccess || [],
            }}
            handleViewMore={() => {
              const path = generatePath(ROUTE_PATH.ORGANIC_KEYWORD_CHECK);
              const newParams = queryString.stringify({
                target: dataFilter.target,
                type: dataFilter.type,
                tab: "toppage",
              });
              const newWindow = window.open(
                `${path}?${newParams?.toLocaleLowerCase()}`,
                "_blank",
                "noopener,noreferrer"
              );
              if (newWindow) newWindow.opener = null;
            }}
          />
        </div>
        <h2 className="h2-title">Backlink</h2>
        <div className="backlink-wrapper">
          <div className="backlink-left">
            <p className="left-title">Follow and no follow</p>
            {!!dataResBacklinkOverview ? (
              <PieChartDonutShape
                data={[
                  { name: "Follow", value: dataResBacklinkOverview.followsNum },
                  {
                    name: "No Follow",
                    value: dataResBacklinkOverview.noFollowsNum,
                  },
                ]}
                isLegendBottom
                className="left-chart"
                isSmall
              />
            ) : (
              <div className={"box-empty flex-column-center"}>
                <ImageCustom
                  src={ImageNoData}
                  alt="Icon-NoData"
                  width={"120px"}
                  height={"120px"}
                />
                <p className={"text-empty"}>No data</p>
              </div>
            )}
            <p className="left-title">Backlink types</p>
            <ul className="left-proccess-list">
              {backlinkTypesData.map(backlink => (
                <li key={backlink.title}>
                  <div className="proccess-text flex-between-align-bottom">
                    <p>{backlink.title}</p>
                    <p>{backlink.percent}%</p>
                  </div>
                  <ProgressBar percent={Number(backlink.percent)} />
                </li>
              ))}
            </ul>
          </div>
          <div className="backlink-right">
            {!!dataResBackLinkOverviewTable ? (
              <TableNavDetailNoPagination
                headersTable={headersBacklinkTable}
                data={{
                  ...DEFAULT_BASE_PAGING_RES,
                  items: dataResBackLinkOverviewTable,
                }}
                maxHeight="614px"
                handleViewMore={() => {
                  const path = generatePath(ROUTE_PATH.BACKLINK_CHECK);
                  const newParams = queryString.stringify({
                    target: dataFilter.target,
                    type: dataFilter.type,
                    tab: "overview",
                  });
                  const newWindow = window.open(
                    `${path}?${newParams?.toLocaleLowerCase()}`,
                    "_blank",
                    "noopener,noreferrer"
                  );
                  if (newWindow) newWindow.opener = null;
                }}
              />
            ) : (
              <div className={"box-empty flex-column-center"}>
                <ImageCustom
                  src={ImageNoData}
                  alt="Icon-NoData"
                  width={"120px"}
                  height={"120px"}
                />
                <p className={"text-empty"}>No data</p>
              </div>
            )}
          </div>
        </div>
        <div className="content-divide-2">
          <div className="divide-left">
            <OverviewCommonCaption
              className="divide-caption"
              width="100%"
              caption="Referring domain"
              tooltip="Referring domain"
              icon={IconChart}
              description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua"
            />
            <Divider />
            <div className="left-content">
              <div className="left-chart-wrapper">
                {!!dataResBackLinkAuthorizeScoreProfile ? (
                  <BarChartCustom
                    data={dataResBackLinkAuthorizeScoreProfile}
                    listKeyField={["totalDomains"]}
                    YAxisKey={"range"}
                    XAxisType="number"
                    layout="vertical"
                    reversedYAxis
                    colorTextList={[variableStyles.NaturalColor0]}
                    isHasLabelList={true}
                    colorFillList={[variableStyles.Info600]}
                    height="260px"
                    fieldTextList={[
                      {
                        value: "Số lượng tên miền",
                        color: variableStyles.Info600,
                      },
                    ]}
                  />
                ) : (
                  <div className={"box-empty flex-column-center"}>
                    <ImageCustom
                      src={ImageNoData}
                      alt="Icon-NoData"
                      width={"120px"}
                      height={"120px"}
                    />
                    <p className={"text-empty"}>No data</p>
                  </div>
                )}
              </div>
              <TableNavDetailNoPagination
                headersTable={headersReferingDomainTable}
                data={{
                  ...DEFAULT_BASE_PAGING_RES,
                  items: dataResRefferingDomainsTable || [],
                }}
                handleViewMore={() => {
                  const path = generatePath(ROUTE_PATH.BACKLINK_CHECK);
                  const newParams = queryString.stringify({
                    target: dataFilter.target,
                    type: dataFilter.type,
                    tab: "referringdomain",
                  });
                  const newWindow = window.open(
                    `${path}?${newParams?.toLocaleLowerCase()}`,
                    "_blank",
                    "noopener,noreferrer"
                  );
                  if (newWindow) newWindow.opener = null;
                }}
                handlePageChange={page => {}}
              />
            </div>
          </div>
          <div className="divide-right">
            <OverviewCommonCaption
              className="divide-caption"
              width="100%"
              caption="Top anchor text"
              tooltip="Top anchor text"
              icon={IconChart}
              description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua"
            />
            <Divider />
            <div className="right-content">
              <BubbleNoAxisChart
                data={dataBubbleNoAxisChart.slice(0, 5)}
                tooltipText={"Backlink"}
                defaultWidth={100}
              />
              <TableNavDetailNoPagination
                headersTable={headersAnchorTextTable}
                data={{
                  ...DEFAULT_BASE_PAGING_RES,
                  items: dataResBackLinkAnchorTable || [],
                }}
                handleViewMore={() => {
                  const path = generatePath(ROUTE_PATH.BACKLINK_CHECK);
                  const newParams = queryString.stringify({
                    target: dataFilter.target,
                    type: dataFilter.type,
                    tab: "anchortext",
                  });
                  const newWindow = window.open(
                    `${path}?${newParams?.toLocaleLowerCase()}`,
                    "_blank",
                    "noopener,noreferrer"
                  );
                  if (newWindow) newWindow.opener = null;
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </DomainOverviewCheckWrapper>
  );
}
