import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@mui/material";
import { ContentfulPaintDetails } from "../../../../../../../../../../models/google/google.page.speed";
import { ImageCustom } from "../../../../../../../../../../components";
import moment from "moment";
import { DATETIME_FORMAT } from "../../../../../../../../../../config/constants";

interface IProps {
  items: ContentfulPaintDetails[];
}

export default function Filmstrip({ items }: IProps) {
  return (
    <Box>
      <Table size="small" aria-label="purchases">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item, key) => (
            <TableRow key={key}>
              <TableCell component="th" scope="row">
                <ImageCustom src={item.data} width={100} height={100} />
              </TableCell>
              <TableCell>{item.timing}</TableCell>
              <TableCell align="right">
                {moment(item.timestamp).format(DATETIME_FORMAT)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
}
