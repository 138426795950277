import { styled } from "@mui/material/styles";
import variableStyles from "../../theme/variable-styles";

export const PlanBoxWrapper = styled("div")(() => ({
  borderRadius: "12px",
  backgroundColor: variableStyles.NaturalColor0,
  padding: "12px",
  border: `1px solid ${variableStyles.NaturalColor200}`,
  "& .plan-title": {
    color: variableStyles.NaturalColor950,
    fontWeight: variableStyles.fwSemiBold,
    fontSize: "14px",
    lineHeight: "14px",
    marginBottom: "8px"
  },
  "& .plan-description": {
    color: variableStyles.NaturalColor600,
    fontWeight: variableStyles.fwMedium,
    fontSize: "12px",
    lineHeight: "12px",
    marginBottom: "16px"
  },
  "& button": {
    width: "100%",
    gap: "8px"
  }
}));
