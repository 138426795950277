import { NavigateFunction } from "react-router-dom";
import { IUser } from "../../user";

export interface IReduxUserState {
  data: IUser;
}

export enum EUserType {
  SET_USER_STATE = "SET_USER_STATE",
  GET_USER = "GET_USER",
  CLEAR_USER = "CLEAR_USER",
}
export interface IActionSetUserState {
  type: EUserType.SET_USER_STATE;
  payload: { data: IUser };
}

export interface IActionGetUser {
  type: EUserType.GET_USER;
  payload: { email: string; navigate: NavigateFunction };
}
export interface IActionClearUser {
  type: EUserType.CLEAR_USER;
  payload: {};
}

export type IActionUser =
  | IActionSetUserState
  | IActionGetUser
  | IActionClearUser;
