import React, { useEffect, useState } from "react";
import { Button, TextField } from "@mui/material";
import { ImageCustom } from "..";
import {
  NumberRangePickerPopoverWrapper,
  NumberRangePickerWrapper,
} from "./styles";
import IconArrow from "../../assets/images/common/icon_arrow_down_line_black.svg";
import { NumericFormat as NumericFormatCustom } from "react-number-format";

interface NumberRangePickerProps {
  isFormatNumber?: boolean;
  placeholder: string;
  modalTitle: string;
  onSubmit: (value: { from: number | null; to: number | null }) => void;
  onClear: () => void;
  value: {
    from: number | null;
    to: number | null;
  };
  size?: "large" | "medium" | "small";
}

export default function NumberRangePicker(props: NumberRangePickerProps) {
  const {
    isFormatNumber = false,
    onSubmit,
    onClear,
    placeholder,
    modalTitle,
    value,
    size,
  } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [valueState, setValueState] = useState<{
    from: number | null;
    to: number | null;
  }>({
    from: null,
    to: null,
  });

  useEffect(() => {
    setValueState(value);
  }, [value]);

  return (
    <NumberRangePickerWrapper
      className={`${!!value.from && !!value.to ? "has-value" : ""}`}
    >
      <Button
        aria-describedby={"number-range-picker"}
        variant="outlined"
        onClick={event => {
          setAnchorEl(event.currentTarget);
        }}
        size={size === "small" ? "xxsmall" : "small"}
        className="picker-button"
      >
        <p className="picker-placeholder">{placeholder}</p>
        <ImageCustom
          src={IconArrow}
          alt="Icon-Arrow"
          width={"22px"}
          height={"22px"}
        />
      </Button>
      <NumberRangePickerPopoverWrapper
        id={"number-range-popover-popover"}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
          setValueState(value);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <p className="popover-title">{modalTitle}</p>
        <div className="popover-input-wrapper flex-between-align-center">
          <NumericFormatCustom
            onValueChange={values => {
              setValueState(prev => ({
                from: Number(values.value),
                to: valueState.to,
              }));
            }}
            decimalSeparator={isFormatNumber ? "," : undefined}
            thousandSeparator={isFormatNumber ? "." : undefined}
            decimalScale={3}
            customInput={TextField}
            size="small"
            id="number-from"
            variant="outlined"
            fullWidth
            value={valueState.from}
          />
          <NumericFormatCustom
            onValueChange={values => {
              setValueState(prev => ({
                from: prev.from,
                to: Number(values.value),
              }));
            }}
            decimalSeparator={isFormatNumber ? "," : undefined}
            thousandSeparator={isFormatNumber ? "." : undefined}
            decimalScale={3}
            customInput={TextField}
            size="small"
            id="number-to"
            variant="outlined"
            fullWidth
            value={valueState.to}
          />
        </div>
        <div className="popover-action flex-between-align-center">
          <button
            aria-describedby={"number-range-popover-action-clear"}
            onClick={_ => {
              onClear();
              setAnchorEl(null);
            }}
            className="action-clear"
          >
            Xoá bộ lọc
          </button>
          <Button
            aria-describedby={"number-range-popover-action-submit"}
            variant="contained"
            onClick={_ => {
              onSubmit(valueState);
              setAnchorEl(null);
            }}
            size="small"
            disabled={!valueState.from || !valueState.to}
          >
            Áp dụng
          </Button>
        </div>
      </NumberRangePickerPopoverWrapper>
    </NumberRangePickerWrapper>
  );
}
