import { useTranslation } from "react-i18next";
import ModalCustom from "../../../components/Modal";
import { useForm } from "react-hook-form";
import InputForm from "../../../components/controls/InputForm";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { isValidDomain } from "../../../common/handles/common.handles";
import AuditService from "../../../services/api/audit.service";
import { toggleMessage } from "../../../components/Toast/Toast";

interface IProps {
  open?: boolean;
  onClose: () => void;
  submit: (success: boolean) => void;
}

export default function StartAuditModal(props: IProps) {
  const { open: openModal, onClose, submit } = props;
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    domain: Yup.string()
      .required(`Vui lòng nhập domain!`)
      .test("Is Valid Domain?", `Vui lòng nhập đúng doamin!`, value => {
        return isValidDomain(value);
      })
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<{ domain: string }>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      domain: ""
    }
  });

  const onSubmit = async (data: { domain: string }): Promise<void> => {
    const res = await AuditService.postAuditDomain(data.domain);

    if (res.success) {
      submit(true);
      reset();
    } else {
      toggleMessage({
        type: "error",
        message: res.message ?? "",
        title: "Error Occurred"
      });
    }
  };

  return (
    // <ModalCustom
    //   onSubmit={handleSubmit(onSubmit)}
    //   onClose={onClose}
    //   title={"Audit domain"}
    //   open={openModal}
    //   textSubmit={"Modal.SubmitText"}
    // >
    //   <InputForm
    //     type="text"
    //     name="domain"
    //     errors={errors}
    //     control={control}
    //     label="Nhập vào domain của bạn"
    //     size="medium"
    //   />
    // </ModalCustom>
    <></>
  );
}
