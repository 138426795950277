import queryString from "query-string";
import React, { useRef, useState } from "react";
import { IDataFilterBacklinkType } from "../../..";
import { DEFAULT_BASE_PAGING_RES } from "../../../../../../../common/constants/value.constant";
import { TableNavDetailNoPagination } from "../../../../../../../components";
import InputSearch from "../../../../../../../components/controls/InputSearch";
import { ITableNavDetailNoPaginationHeader } from "../../../../../../../components/Table/TableNavDetailNoPagination";

interface BacklinkIndexPageTabProps {
  onChangeParamsURL: (value: string) => void;
  dataFilter: IDataFilterBacklinkType;
}

export default function BacklinkIndexPageTab(props: BacklinkIndexPageTabProps) {
  const { onChangeParamsURL, dataFilter } = props;

  const timerOut = useRef<number>();
  const [keySearchState, setKeySearchState] = useState<string | null>(null);

  const onChangeSearch = (e: any) => {
    const value = e?.target?.value;
    if (timerOut.current) {
      clearTimeout(timerOut.current);
    }
    timerOut.current = window.setTimeout(() => {
      const newFilter = {
        anchorText: value,
        tab: dataFilter.tab,
        target: dataFilter.target,
        type: dataFilter.type,
      };
      if (!value || value.length <= 0) {
        delete newFilter.anchorText;
      }
      const newParams = queryString.stringify(newFilter);
      onChangeParamsURL(newParams.toLocaleLowerCase());
    }, 700);
  };

  const headersOrganicTable: ITableNavDetailNoPaginationHeader<any>[] = [
    {
      field: "organicTraffic",
      title: `URL`,
      width: "260px",
    },
    {
      field: "organicTraffic",
      title: `Sô tên miền`,
      width: "100px",
    },
    {
      field: "organicKeywords",
      title: `Số liên kết ngược`,
      width: "120px",
    },
    {
      field: "organicKeywords",
      title: `External link`,
      width: "120px",
    },
    {
      field: "commonKeywords",
      title: `First seen`,
      width: "140px",
    },
    {
      field: "commonKeywords",
      title: `Last seen`,
      width: "140px",
    },
  ];

  return (
    <div className="tab-common-wrapper">
      <div className="tab-header-filter flex-between-align-center">
        <div className="filter-left flex-start-align-center">
          <InputSearch
            placeholder={"Tìm kiếm"}
            onChange={(e: any) => {
              setKeySearchState(e.target.value);
              onChangeSearch(e);
            }}
            value={keySearchState}
            size={"medium"}
            width={"340px"}
            typeStyle="outlined"
          />
        </div>
      </div>
      <div className="overview-table">
        <TableNavDetailNoPagination
          headersTable={headersOrganicTable}
          data={{
            ...DEFAULT_BASE_PAGING_RES,
          }}
        />
      </div>
    </div>
  );
}
