import { Button, Collapse } from "@mui/material";
import { ImageCustom } from "../../../../components";
import { SuggestBoxWrapper } from "./styles";
import IconAdd from "../../../../assets/images/common/icon_plus_line_white.svg";
import IconAddBlack from "../../../../assets/images/common/icon_plus_line_black.svg";

import { Link } from "react-router-dom";
import { useState } from "react";
import IconArrow from "../../../../assets/images/common/icon_arrow_left_line_black.svg";

export interface SuggestDetails {
  tag: string;
  content: string;
  html: string;
}

export interface Suggest {
  title: string;
  url: string;
  values: SuggestDetails[];
}

interface SuggestBoxProps {
  data: Suggest;
  onAddAction: (value: string) => void;
  onAddAllAction: (value: string[]) => void;
}

export default function SuggestBox(props: SuggestBoxProps) {
  const { data, onAddAction, onAddAllAction } = props;

  const [openCollapse, setOpenCollapse] = useState<boolean>(false);

  return (
    <SuggestBoxWrapper>
      <div className="box-header">
        <p className="header-title fit-four-line">{data.title}</p>
        <Button
          color="primary"
          size="xxsmall"
          variant="contained"
          onClick={_ => {
            const dataHtml = data.values.map(value => value.html);
            onAddAllAction(dataHtml);
          }}
          className="add-all-button"
        >
          <ImageCustom
            src={IconAdd}
            alt="Icon-Add"
            width={"18px"}
            height={"18px"}
          />
          <p>Add all</p>
        </Button>
      </div>
      <Link
        to={data.url}
        target="_blank"
        rel="noopener noreferrer"
        className="box-link fit-one-line"
      >
        {data.url}
      </Link>
      <Button
        color="primary"
        size="xxsmall"
        variant="contained"
        onClick={() => setOpenCollapse(!openCollapse)}
        className={`collapse-button ${openCollapse ? "active" : ""}`}
      >
        <ImageCustom
          src={IconArrow}
          alt="Icon-Arrow"
          width={"18px"}
          height={"18px"}
        />
        Xem tất cả ({data.values.length})
      </Button>
      <Collapse in={openCollapse}>
        <ul className="box-list">
          {data.values.map((detail, idx) => (
            <li key={idx}>
              <div className="content-box">
                <p className={`item-tag-name ${detail.tag}`}>{detail.tag}</p>
                <p className="item-title fit-two-line">{detail.content}</p>
              </div>
              <Button
                color="primary"
                size="small"
                variant="contained"
                onClick={_ => {
                  onAddAction(detail.html);
                }}
                className="add-button"
              >
                <ImageCustom
                  src={IconAddBlack}
                  alt="Icon-Add"
                  width={"18px"}
                  height={"18px"}
                />
              </Button>
            </li>
          ))}
        </ul>
      </Collapse>
    </SuggestBoxWrapper>
  );
}
