import {
  IOriginLoadingExperience,
  LoadingExperienceMetrics,
  ShiftScore,
} from "../../../../../../../../../models/google/google.page.speed";
import {
  ImageCustom,
  ProjectInforBox,
} from "../../../../../../../../../components";
import BrowerIcon from "../../../../../../../../../assets/images/domai-audit/security-section/svg/brower.svg";
import CalenderIcon from "../../../../../../../../../assets/images/domai-audit/security-section/svg/calender.svg";
import CircleThreeDotIcon from "../../../../../../../../../assets/images/domai-audit/security-section/svg/circle-three-dot.svg";
import ClockIcon from "../../../../../../../../../assets/images/domai-audit/security-section/svg/clock.svg";
import DevicesIcon from "../../../../../../../../../assets/images/domai-audit/security-section/svg/devices.svg";
import WifiIcon from "../../../../../../../../../assets/images/domai-audit/security-section/svg/wifi.svg";
import { formatTime } from "../../../../../../../../../common/handles/common.handles";
import { LoadingExperienceWrapper } from "./styles";
import CoreWebVitalsItem from "../CoreWebVitalsItem";
import { EAuditErrorStatus } from "../../../../../../../../../models/common/models.enum";

interface IProps {
  loadingExperience: IOriginLoadingExperience;
  companyName: string;
}

const getMetricData = (
  { distributions, category, percentile }: ShiftScore,
  key: keyof LoadingExperienceMetrics
) => {
  const minSlowValue = distributions[distributions.length - 1].min;

  const totalProportion = distributions.reduce(
    (sum, dist) => sum + dist.proportion,
    0
  );

  const stages = distributions.map(item => {
    const percentage = item.proportion * 100;

    return {
      percentage: percentage,
    };
  });

  // Tính vị trí của con trỏ dựa trên percentile
  let accumulatedPercent = 0;
  let pointerPosition = 0;

  for (let i = 0; i < distributions.length; i++) {
    const dist = distributions[i];
    const rangePercent = (dist.proportion * 100) / totalProportion;

    if (
      percentile >= dist.min &&
      (dist.max === undefined || percentile < dist.max)
    ) {
      // Nếu percentile nằm trong khoảng min - max của phần này
      pointerPosition =
        accumulatedPercent +
        ((percentile - dist.min) / ((dist.max || percentile) - dist.min)) *
          rangePercent;
      break;
    }

    // Cộng dồn phần đã đi qua
    accumulatedPercent += rangePercent;
  }

  const label =
    key === "CUMULATIVE_LAYOUT_SHIFT_SCORE"
      ? (percentile / 100).toFixed(2)
      : formatTime(percentile);

  const status: EAuditErrorStatus =
    category === "FAST"
      ? EAuditErrorStatus.Success
      : category === "AVERAGE"
        ? EAuditErrorStatus.Warning
        : EAuditErrorStatus.Error;

  let title = "";
  let infor = "";

  switch (key) {
    case "CUMULATIVE_LAYOUT_SHIFT_SCORE": {
      title = "Tổng độ dịch chuyển bố cục (CLS)";
      infor =
        "Tổng độ dịch chuyển bố cục cho biết mức độ ổn định của các phần tử trên trang (như hình ảnh, văn bản, nút bấm), chỉ  số này giúp xác định tần suất người dùng gặp phải những thay đổi về bố cục ngoài dự kiến.";
      break;
    }
    case "EXPERIMENTAL_TIME_TO_FIRST_BYTE": {
      title = "Thời gian đến Byte đầu tiên (TTFB)";
      infor =
        "Thời gian đến Byte đầu tiên cho biết thời gian từ khi người dùng gửi yêu cầu truy cập trang web cho đến khi trình duyệt nhận được byte dữ liệu đầu tiên từ máy chủ.";
      break;
    }
    case "FIRST_CONTENTFUL_PAINT_MS": {
      title = "Thời gian hiển thị nội dung đầu tiên (FCP)";
      infor =
        "Thời gian hiển thị nội dung đầu tiên cho biết thời gian cần thiết để trình duyệt web hiển thị nội dung đầu tiên trên một trang web như văn bản, hình ảnh hoặc nền màu,...";
      break;
    }
    case "FIRST_INPUT_DELAY_MS": {
      title = "";
      break;
    }
    case "INTERACTION_TO_NEXT_PAINT": {
      title = "Thời gian phản hồi với tương tác (INP)";
      infor =
        "Thời gian phản hồi tương tác là thời gian từ lúc người dùng thực hiện hành động (nhấp chuột, cuộn trang) cho đến khi trang web phản hồi và hiển thị sự thay đổi hoặc cập nhật giao diện.";
      break;
    }
    case "LARGEST_CONTENTFUL_PAINT_MS": {
      title = "Thời gian hiển thị nội dung lớn nhất (LCP)";
      infor =
        "Thời gian hiển thị nội dung lớn nhất cho biết thời gian để phần nội dung lớn nhất trên trang được tải xong và hiển thị đầy đủ cho người dùng.";
      break;
    }
  }

  return {
    stages: stages,
    value: pointerPosition,
    label: label,
    status: status,
    title: title,
    infor: infor,
    maxValue: minSlowValue,
  };
};

export default function LoadingExperience({
  loadingExperience,
  companyName,
}: IProps) {
  const leftContent = [
    {
      icon: CalenderIcon,
      label: "Khoảng thời gian thu thập 28 ngày",
    },
    {
      icon: DevicesIcon,
      label: "Nhiều thiết bị máy tính và di động",
    },
    {
      icon: CircleThreeDotIcon,
      label: "Nhiều mẫu (Chrome UX report)",
    },
    {
      icon: ClockIcon,
      label: "Toàn bộ thời lượng truy cập",
    },
    {
      icon: WifiIcon,
      label: "Nhiều kết nối mạng",
    },
    {
      icon: BrowerIcon,
      label: "Tất cả phiên bản Chrome",
    },
  ];

  const datas = Object.keys(loadingExperience.metrics).map(item => {
    const key = item as keyof LoadingExperienceMetrics;
    return getMetricData(loadingExperience.metrics[key], key);
  });

  return (
    <LoadingExperienceWrapper>
      <ProjectInforBox
        data={{
          name: companyName,
          domain: loadingExperience.initial_url,
          href: loadingExperience.initial_url,
        }}
        isActive={false}
        className="domain-infor"
        disabled
      />
      <div className="overview-wrapper">
        <ul className="overview-infor-wrapper">
          {leftContent.map((item, key) => {
            return (
              <li key={key} className="flex-start-align-center">
                <ImageCustom
                  src={item.icon}
                  alt="Icon"
                  width={"14px"}
                  height={"14px"}
                  className="icon-overview-audit"
                  objectFit={"contain"}
                />
                <p>{item.label}</p>
              </li>
            );
          })}
        </ul>
        <ul className="overview-index-wrapper">
          {datas.map((item, key) => {
            return (
              <li key={key}>
                <CoreWebVitalsItem
                  value={item.value}
                  stages={item.stages}
                  title={item.title}
                  infor={item.infor}
                  label={item.label}
                  status={item.status}
                />
              </li>
            );
          })}
        </ul>
      </div>
    </LoadingExperienceWrapper>
  );
}
