import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../redux/auth/auth.duck";
import { ActionPopoverWrapper, NavbarWrapper } from "./styles";
import { Avatar, ImageCustom } from "..";
import { Button, Divider } from "@mui/material";
import LogoImage from "../../assets/images/header/header_logo.png";
import NotificationIcon from "../../assets/images/common/icon_notification_active_grey.svg";
import ProfileIcon from "../../assets/images/common/icon_user_line_grey.svg";
import SettingIcon from "../../assets/images/common/icon_setting_line_grey.svg";
import SupportIcon from "../../assets/images/common/icon_question_line_grey.svg";
import LogoutIcon from "../../assets/images/common/icon_logout_line_red.svg";
import { useState } from "react";
import { menuData } from "./dashboardData";
import { toggleConfirmModal } from "../ConfirmModal/ConfirmModal";
import { IStateApp } from "../../models/redux";
import { IUser } from "../../models/user";
import { REPORT_ID_KEY } from "../../services/local/local-storage";

const DashboardNavbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentUser = useSelector<IStateApp, IUser>(
    appState => appState.user.data
  );

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const onLogout = (event: any) => {
    event.preventDefault();
    const asyncFuncLogout: () => Promise<void> = async () => {
      await Promise.resolve(dispatch(logoutUser(navigate)));
    };

    toggleConfirmModal({
      open: true,
      title: `Bạn có muốn đăng xuất`,
      content: `Bạn có chắc chắc muốn đăng xuất khỏi tài khoản này?`,
      onSubmit: () => asyncFuncLogout(),
      submitText: "Đăng xuất",
      type: "warning-red",
    });
  };

  const menuDataProfile: { icon: string; text: string; link: string }[] = [
    { icon: ProfileIcon, text: "Profile", link: "/" },
    { icon: SettingIcon, text: "Setting", link: "/" },
    { icon: SupportIcon, text: "Support", link: "/" },
  ];

  return (
    <>
      <ActionPopoverWrapper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className="profile-infor">
          <Avatar
            src={currentUser.Avatar}
            alt="Avatar-Image"
            width={32}
            height={32}
            isOnline
          />
          <div className="infor-detail">
            <p className="detail-name">{currentUser.CompanyName}</p>
            <p className="detail-email">{currentUser.Email}</p>
          </div>
        </div>
        <Divider />
        <ul className="profile-actions">
          {menuDataProfile.map(action => (
            <li key={action.text}>
              <Link to={action.link}>
                <ImageCustom
                  src={action.icon}
                  alt="HEADER_ACTION"
                  width={"18px"}
                  height={"18px"}
                />
                <p>{action.text}</p>
              </Link>
            </li>
          ))}
        </ul>
        <Divider />
        <div className="profile-plan">
          <div className="plan-infor">
            <p className="infor-title">Pro Plan</p>
            <p className="infor-description">Lorem ipsum</p>
          </div>
          <Button size="xsmall" variant="contained" className="action-button">
            Update
          </Button>
        </div>
        <Divider />
        <Button
          size="large"
          variant="text"
          className="profile-logout"
          onClick={onLogout}
        >
          <ImageCustom
            src={LogoutIcon}
            alt="HEADER_LOGOUT"
            width={"20px"}
            height={"20px"}
          />
          <p>Log out</p>
        </Button>
      </ActionPopoverWrapper>
      <NavbarWrapper>
        <div className="header-navigate">
          <Link to="/">
            <ImageCustom
              src={LogoImage}
              alt="HEADER_LOGO"
              width={"98px"}
              height={"30px"}
            />
          </Link>
          <ul className="header-menu">
            {menuData.map((menu, idx) => (
              <li className="menu-item" key={idx}>
                <Link to={menu.link}>{menu.text}</Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="header-action">
          <div className="action-group">
            <Button size="large" variant="text" className="action-button">
              <ImageCustom
                src={NotificationIcon}
                alt="Notification-Icon"
                width={"24px"}
                height={"24px"}
              />
              <div className="have-noti-circle"></div>
            </Button>
          </div>
          <Button
            size="large"
            variant="text"
            className="action-profile"
            onClick={event => setAnchorEl(event.currentTarget)}
          >
            <Avatar
              src={currentUser.Avatar}
              alt="Avatar-Image"
              width={32}
              height={32}
            />
          </Button>
        </div>
      </NavbarWrapper>
    </>
  );
};

DashboardNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func,
};

export default DashboardNavbar;
