import { Popover } from "@mui/material";
import { styled } from "@mui/material/styles";
import variableStyles from "../../../../../theme/variable-styles";

export const CompanyProfileSectionWrapper = styled("div")(() => ({
  "& .company-profile-overview": {
    padding: "20px 40px 40px",
    "& .company-profile-title": {
      marginBottom: "40px",
    },
    "& .overview-wrapper": {
      display: "flex",
      flexDirection: "column",
      gap: "24px",
      "& .overview-infor": {
        "& .infor-name": {
          width: "297px",
          "& .name-text": {
            color: variableStyles.blueSecondary950,
            fontWeight: variableStyles.fwSemiBold,
            fontSize: "18px",
            lineHeight: "27px",
            marginBottom: "16px",
          },
          "& .name-subtext": {
            color: variableStyles.NaturalColor950,
            fontWeight: variableStyles.fwMedium,
            fontSize: "12px",
            lineHeight: "20px",
            backgroundColor: variableStyles.Info50,
            borderLeft: `2px solid ${variableStyles.Info400}`,
            padding: "6px 12px",
          },
        },
        "& .infor-logo": {
          position: "relative",
          "& .logo": {
            borderRadius: "50%",
            overflow: "hidden",
            border: `1px solid ${variableStyles.NaturalColor300}`,
          },
          "& .logo-overlay": {
            position: "absolute",
            bottom: "-10px",
            left: "50%",
            transform: "translateX(-50%)",
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            backgroundColor: variableStyles.NaturalColor0,
            boxShadow:
              "0px 39px 67.8px 0px #1956580A,0px 21px 37px -15px #1956580F,0px 1px 1px 0px #00000014",
            borderRadius: "4px",
            border: `1px solid ${variableStyles.Warning100}`,
            "& figure": {
              width: "24px",
              backgroundColor: variableStyles.Warning100,
              padding: "3px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            },
            "& p": {
              color: variableStyles.NaturalColor950,
              fontWeight: variableStyles.fwMedium,
              fontSize: "12px",
              lineHeight: "18px",
              width: "22px",
            },
          },
        },
      },
      "& .overview-infor-detail": {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: "0 40px",
        "& .detail-group": {
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        },
        "& li": {
          display: "flex",
          alignItems: "center",
          gap: "8px",
          "& p": {
            color: variableStyles.NaturalColor1000,
            fontWeight: variableStyles.fwRegular,
            fontSize: "12px",
            lineHeight: "14.06px",
          },
          "& a": {
            color: variableStyles.NaturalColor1000,
            fontWeight: variableStyles.fwRegular,
            fontSize: "12px",
            lineHeight: "14.06px",
            "&:hover": {
              textDecoration: "underline",
            },
          },
          "&.time-work-wrapper": {
            "& button": {
              backgroundColor: "transparent",
              display: "flex",
              alignItems: "center",
              gap: "8px",
              "& .time-work": {
                display: "flex",
                alignItems: "center",
                "& .icon-arrow": {
                  transition: "all .2s linear",
                },
                "&.active": {
                  "& .icon-arrow": {
                    transform: "rotate(-180deg)",
                  },
                },
              },
            },
          },
        },
      },
      "& .overview-analysis": {
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        verticalAlign: "center",
        backgroundColor: variableStyles.NaturalColor50,
        borderRadius: "8px",
        "& li": {
          textAlign: "center",
          padding: "18px",
          position: "relative",
          "&:not(:last-of-type)::after": {
            content: '""',
            width: "1px",
            height: "43px",
            backgroundColor: variableStyles.NaturalColor300,
            position: "absolute",
            top: "50%",
            right: "0",
            transform: "translateY(-50%)",
          },
          "& .analysis-name": {
            color: variableStyles.NaturalColor950,
            fontWeight: variableStyles.fwRegular,
            fontSize: "12px",
            lineHeight: "14px",
            marginBottom: "12px",
          },
          "& .analysis-count": {
            color: variableStyles.Info900,
            fontWeight: variableStyles.fwSemiBold,
            fontSize: "14px",
            lineHeight: "14px",
          },
        },
      },
      "& .overview-maps": {
        display: "grid",
        gap: "12px",
        "@media (min-width:1250px)": {
          gridTemplateColumns: "1fr 1fr",
        },
        "@media (max-width:1249.98px)": {
          gridTemplateColumns: "1fr",
        },
        "& img, & iframe": {
          borderRadius: "4px",
        },
      },
      "& .overview-preview": {
        "& .preview-header": {
          gap: "24px",
          marginBottom: "16px",
          "& .header-title": {
            gap: "12px",
            "& p": {
              color: variableStyles.blueSecondary950,
              fontWeight: variableStyles.fwSemiBold,
              fontSize: "14px",
              lineHeight: "21px",
            },
          },
          "& button": {
            color: variableStyles.GreenPrimaryColor500,
            fontWeight: variableStyles.fwMedium,
            fontSize: "12px",
            lineHeight: "14.52px",
            backgroundColor: "transparent",
            flex: "0 0 auto",
          },
        },
      },
    },
  },
}));

export const PopoverTimeWorkWrapper = styled(Popover)(() => ({
  "& .MuiPaper-root": {
    padding: "10px 12px",
    borderRadius: "12px",
    boxShadow:
      "0px 39px 67.8px 0px #1956580A,0px 21px 37px -15px #1956580F,0px 1px 1px 0px #00000014",
    border: `1px solid ${variableStyles.NaturalColor200}`,
    marginTop: "6px",
    "& ul": {
      display: "flex",
      flexDirection: "column",
      gap: "4px",
      "& li": {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        "& p": {
          color: variableStyles.NaturalColor1000,
          fontSize: "12px",
          lineHeight: "14.06px",
          display: "flex",
          gap: "8px",
          alignItems: "center",
          "&.date-text": {
            fontWeight: variableStyles.fwMedium,
            textTransform: "capitalize",
          },
          "&.time-text": { fontWeight: variableStyles.fwRegular },
        },
        "&.active": {
          "& p": {
            fontWeight: variableStyles.fwBold,
          },
        },
      },
    },
  },
}));

export const ReviewListWrapper = styled("ul")(() => ({
  display: "grid",
  gap: "12px",
  "&.preview-list-modal": {
    overflow: "auto",
    maxHeight: "calc(100vh - 200px)",
  },
  "&.review-list": {
    "@media (min-width: 1260px)": {
      gridTemplateColumns:
        "minmax(140px, 232px) repeat(2, minmax(140px, 232px))",
    },
    "@media (max-width: 1259.98px) and (min-width: 1044px)": {
      gridTemplateColumns: "minmax(140px, 100%) repeat(1, minmax(140px, 100%))",
    },
    "@media (max-width: 1043.98px)": {
      gridTemplateColumns: "minmax(140px, 100%)",
    },
  },
  "& li": {
    backgroundColor: variableStyles.NaturalColor100,
    border: `1px solid ${variableStyles.NaturalColor200}`,
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    "& .list-header": {
      display: "flex",
      alignItems: "center",
      gap: "12px",
      padding: "14px 12px",
      justifyContent: "space-between",
      "& .header-infor-wrapper": {
        display: "flex",
        alignItems: "center",
        gap: "4px",
        "& .header-avatar": {
          marginRight: "4px",
        },
        "& .header-infor": {
          "& .infor-name": {
            color: variableStyles.NaturalColor950,
            fontWeight: variableStyles.fwSemiBold,
            fontSize: "10px",
            lineHeight: "12px",
          },
          "& .infor-count": {
            color: variableStyles.NaturalColor900,
            fontWeight: variableStyles.fwRegular,
            fontSize: "10px",
            lineHeight: "12px",
          },
        },
      },
    },
    "& .list-content": {
      borderRadius: "12px 12px 8px 8px",
      boxShadow: "0px 0px 5px 0px #0000000A",
      backgroundColor: variableStyles.NaturalColor0,
      padding: "12px 10px",
      flex: "1 1 auto",
      "& .content-text": {
        color: variableStyles.NaturalColor1000,
        fontWeight: variableStyles.fwRegular,
        fontSize: "10px",
        lineHeight: "15px",
        marginBottom: "10px",
        minHeight: "45px",
      },
      "& .content-footer": {
        gap: "8px",
        "& .footer-time": {
          color: variableStyles.NaturalColor700,
          fontWeight: variableStyles.fwSemiBold,
          fontSize: "8px",
          lineHeight: "12px",
        },
      },
    },
  },
}));

export const PostListWrapper = styled("ul")(() => ({
  display: "grid",
  gap: "12px",
  "&.post-list-modal": {
    overflow: "auto",
    maxHeight: "calc(100vh - 200px)",
  },
  "&.post-list": {
    "@media (min-width: 1260px)": {
      gridTemplateColumns:
        "minmax(140px, 232px) repeat(2, minmax(140px, 232px))",
    },
    "@media (max-width: 1259.98px) and (min-width: 1044px)": {
      gridTemplateColumns: "minmax(140px, 100%) repeat(1, minmax(140px, 100%))",
    },
    "@media (max-width: 1043.98px)": {
      gridTemplateColumns: "minmax(140px, 100%)",
    },
  },
  "& li": {
    padding: "14px",
    backgroundColor: variableStyles.NaturalColor50,
    border: `1px solid ${variableStyles.NaturalColor200}`,
    borderRadius: "12px",
    "& .post-text": {
      color: variableStyles.Info900,
      fontWeight: variableStyles.fwSemiBold,
      fontSize: "12px",
      lineHeight: "18px",
      marginBottom: "35px",
      height: "36px",
    },
    "& .post-footer": {
      "& a": {
        color: variableStyles.NaturalColor800,
        fontWeight: variableStyles.fwMedium,
        fontSize: "12px",
        lineHeight: "14.52px",
        "&:hover": {
          textDecoration: "underline",
        },
      },
    },
  },
}));
