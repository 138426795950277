import { styled } from "@mui/material/styles";
import variableStyles from "../../../../theme/variable-styles";

export const PostsWrapper = styled("div")(() => ({
  padding: "24px",
  height: "100%",
  position: "relative",
  "& .outline-header": {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "24px",
    gap: "12px",
    "& .outline-post-title": {
      display: "flex",
      gap: "8px",
      alignItems: "center",
      "& .header-name": {
        color: variableStyles.NaturalColor950,
        fontWeight: variableStyles.fwSemiBold,
        fontSize: "24px",
        lineHeight: "29.05px"
      },
      "& .header-count": {
        color: variableStyles.blueSecondary700,
        fontWeight: variableStyles.fwMedium,
        fontSize: "12px",
        lineHeight: "18px",
        padding: "2px 8px",
        backgroundColor: variableStyles.blueSecondary100,
        borderRadius: "16px",
        flex: "0 0 auto"
      }
    },
    "& .header-action": { display: "flex", gap: "12px" }
  },
  "& .loading-indicator": {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)"
  }
}));
