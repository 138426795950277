import { styled } from "@mui/material/styles";
import variableStyles from "../../theme/variable-styles";

export const KeyWordGroupWrapper = styled("div")(() => ({
  display: "flex",
  height: "100%",
  "& .group-left-box": {
    width: "438px",
    flex: "0 0 auto",
    padding: "24px",
    "& .upload-file": {
      marginBottom: "8px"
    },
    "& .submit-button": {
      width: "100%"
    },

    "& .right-box-form": {
      backgroundColor: variableStyles.NaturalColor0,
      padding: "12px",
      borderRadius: "12px",
      border: `1px solid ${variableStyles.NaturalColor300}`,
      boxShadow:
        "0px 39px 67.8px 0px #1956580A, 0px 21px 37px -15px #1956580F,0px 1px 1px 0px #00000014"
    },
    "& .text-area-box": {
      marginBottom: "8px"
    }
  },
  "& .group-right-box": {
    flex: "1 1 auto",
    backgroundColor: variableStyles.NaturalColor0,
    padding: "28px 17px 28px 24px",
    borderLeft: `1px solid ${variableStyles.NaturalColor300}`,
    display: "flex",
    flexDirection: "column",

    "& .right-header": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: "16px",
      flex: "0 0 auto",
      "& .header-text": {
        color: variableStyles.NaturalColor950,
        fontWeight: variableStyles.fwSemiBold,
        fontSize: "16px",
        lineHeight: "16px"
      }
    },
    "& .right-content": {
      flex: "1 1 auto",
      position: "relative",
      scrollbarGutter: "stable",
      overflow: "auto",
      "& .content-loading": {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)"
      },
      "& .content-list": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        gap: "16px",
        "& .list-item": {
          width: "100%"
        }
      }
    },
    "& .download-button": {
      gap: "6px"
    }
  },
  "@media (max-width:1299.98px)": {
    flexDirection: "column",
    alignItems: "center",
    "& .group-right-box": {
      border: "none",
      width: "100%"
    }
  }
}));
