import React from "react";
import { CrawlDataRenderBoxWrapper } from "./styles";

interface CrawlDataRenderBoxProps {
  commentText?: string;
  children?: React.ReactNode;
  className?: string;
}

export default function CrawlDataRenderBox(props: CrawlDataRenderBoxProps) {
  const { commentText, children, className = "" } = props;
  return (
    <CrawlDataRenderBoxWrapper className={className}>
      {!!commentText && <p className="common-text">{commentText}</p>}
      {!!children && <div className="children-wrapper"> {children}</div>}
    </CrawlDataRenderBoxWrapper>
  );
}
