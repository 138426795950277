import { Button, useMediaQuery } from "@mui/material";
import { ImageCustom, LoadingIndicator } from "../../../../components";
import IconDelete from "../../../../assets/images/common/icon_delete_line_grey.svg";
import IconDownload from "../../../../assets/images/common/icon_download_line_white.svg";
import { PhotoBoxWrapper } from "./styles";
import CommonHandle, {
  truncateText
} from "../../../../common/handles/common.handles";
import { IPhotoShow } from "../..";
import IconCheck from "../../../../assets/images/common/icon_check_line_white.svg";

interface PhotoBoxProps {
  data: IPhotoShow;
  onDelete: (value: string) => void;
  isLoading: boolean;
  handlePreview?: (value: string) => void;
  onDownloadSuccess: (value: string) => void;
  dataDownloaded?: string[];
}

export default function PhotoBox(props: PhotoBoxProps) {
  const {
    data,
    onDelete,
    isLoading,
    handlePreview,
    onDownloadSuccess,
    dataDownloaded
  } = props;

  const matches830 = useMediaQuery("(max-width:830px)");

  const onDownloadFile = () => {
    CommonHandle.startDownloadBlob(data.data, data.name);
    onDownloadSuccess(data.name);
  };

  return (
    <PhotoBoxWrapper
      className={`${!!data.error && data.error.length > 0 ? "error" : ""}`}
    >
      <div className="infor-detail">
        <Button
          className="preview-button"
          onClick={() => handlePreview && handlePreview(data.src)}
        >
          <ImageCustom
            src={data.src}
            alt="photo-compress"
            width={"42px"}
            height={"42px"}
            className="detail-image"
          />
          {dataDownloaded?.find(item => item === data.name) && (
            <div className="button-overlay">
              <ImageCustom
                src={IconCheck}
                alt="photo-overlay"
                width={"18px"}
                height={"18px"}
                className="overlay-image"
              />
            </div>
          )}
        </Button>
        <div className="detail-text">
          <div className="">
            <p className="text-name">
              {truncateText(
                data.name,
                matches830 ? 20 : !!data.newSize ? 30 : 40
              )}
            </p>
          </div>
          {!!data.error ? (
            <p className="text-error">{data.error}</p>
          ) : (
            <p className="text-size">
              {data.oldSize} {data.data.size < 100000 ? "KB" : "MB"}
            </p>
          )}
        </div>
      </div>
      {!!data.error ? (
        <Button
          size="large"
          variant="text"
          className="delete-file-button"
          onClick={_ => {
            onDelete(data.name);
          }}
        >
          <ImageCustom
            src={IconDelete}
            alt="Icon-Delete"
            width={"18px"}
            height={"18px"}
          />
        </Button>
      ) : (
        <div className="status-action-box">
          {isLoading ? (
            <LoadingIndicator className="loading-box" />
          ) : (
            <>
              {!!data.newSize && !!data.percent && (
                <div className="status-wrapper">
                  <p className="status-scale">-{data.percent || 0}%</p>
                  <p className="status-scale-size">
                    {data.newSize} {data.data.size < 100000 ? "KB" : "MB"}
                  </p>
                </div>
              )}
              <div className="action-wrapper">
                {data.newSize !== null && !!data.percent && (
                  <Button
                    size="small"
                    variant="contained"
                    className="down-file-button"
                    onClick={onDownloadFile}
                  >
                    <ImageCustom
                      src={IconDownload}
                      alt="Icon-Delete"
                      width={"18px"}
                      height={"18px"}
                    />
                  </Button>
                )}
                <Button
                  size="large"
                  variant="text"
                  className="delete-file-button"
                  onClick={_ => {
                    onDelete(data.name);
                  }}
                >
                  <ImageCustom
                    src={IconDelete}
                    alt="Icon-Delete"
                    width={"18px"}
                    height={"18px"}
                  />
                </Button>
              </div>
            </>
          )}
        </div>
      )}
    </PhotoBoxWrapper>
  );
}
