import React from "react";
import { DEFAULT_BASE_PAGING_RES } from "../../../../../../../common/constants/value.constant";
import { TableNavDetailNoPagination } from "../../../../../../../components";
import { ITableNavDetailNoPaginationHeader } from "../../../../../../../components/Table/TableNavDetailNoPagination";

export default function BacklinkReferringIPSTab() {
  const headersOrganicTable: ITableNavDetailNoPaginationHeader<any>[] = [
    {
      field: "organicTraffic",
      title: `Quốc gia IP`,
      width: "260px",
    },
    {
      field: "organicTraffic",
      title: `Sô tên miền`,
      width: "100px",
    },
    {
      field: "organicKeywords",
      title: `Số liên kết ngược`,
      width: "120px",
    },
    {
      field: "commonKeywords",
      title: `First seen`,
      width: "140px",
    },
    {
      field: "commonKeywords",
      title: `Last seen`,
      width: "140px",
    },
  ];
  return (
    <div className="tab-common-wrapper">
      <div className="overview-table">
        <TableNavDetailNoPagination
          headersTable={headersOrganicTable}
          data={{
            ...DEFAULT_BASE_PAGING_RES,
          }}
        />
      </div>
    </div>
  );
}
