import React from "react";
import variableStyles from "../../../../theme/variable-styles";
import IconWebAudit from "../../../../assets/images/common/icon_domainaudit_fill_blue.svg";
import IconWebCompetitive from "../../../../assets/images/common/icon_domaincompetitive_fill_pink.svg";
import IconPageAudit from "../../../../assets/images/common/icon_pageaudit_fill_blue.svg";
import IconPageCompetitive from "../../../../assets/images/common/icon_pagecompetitive_fill_green.svg";
import IconSEO from "../../../../assets/images/common/icon_seo_fill_yellow.svg";
import IconUX from "../../../../assets/images/common/icon_ux_fill_purple.svg";
import CheckToolNavCard from "../CheckToolNavCard";
import { CheckToolServiceIntroWrapper } from "./styles";
import { ROUTE_PATH } from "../../../../common/constants/app.constant";

export default function CheckToolServiceIntro() {
  const tempData = [
    {
      title: "Web audit",
      description: "Lorem ipsum dolor sit amet",
      background: variableStyles.blueSecondary200,
      icon: IconWebAudit,
      path: ROUTE_PATH.DOMAIN_AUDIT,
    },
    {
      title: "SEO",
      description: "Lorem ipsum dolor sit amet",
      background: "#F4F0E4",
      icon: IconSEO,
      path: ROUTE_PATH.DOMAIN_AUDIT_SEO,
    },
    {
      title: "User Experience",
      description: "Lorem ipsum dolor sit amet",
      background: "#D8DFFF",
      icon: IconUX,
      path: ROUTE_PATH.DOMAIN_AUDIT_UX,
    },
    {
      title: "Web competitive",
      description: "Lorem ipsum dolor sit amet",
      background: "#FFE2ED",
      icon: IconWebCompetitive,
      path: ROUTE_PATH.DOMAIN_COMPETITIVE,
    },
    {
      title: "Page Audit",
      description: "Lorem ipsum dolor sit amet",
      background: variableStyles.Info100,
      icon: IconPageAudit,
      path: ROUTE_PATH.PAGE_AUDIT,
    },
    {
      title: "Page Competive",
      description: "Lorem ipsum dolor sit amet",
      background: "#AFF5DB",
      icon: IconPageCompetitive,
      path: ROUTE_PATH.PAGE_COMPETITIVE,
    },
  ];
  return (
    <CheckToolServiceIntroWrapper>
      <div className="service-intro">
        <p className="service-title">Our outstanding service</p>
        <p className="service-description">
          Use our professionally written and designed audit reports to help your
          agency close more deals.
        </p>
      </div>
      <ul className="card-list">
        {tempData.map(card => (
          <li key={card.title}>
            <CheckToolNavCard data={card} />
          </li>
        ))}
      </ul>
    </CheckToolServiceIntroWrapper>
  );
}
