import { styled } from "@mui/material/styles";
import variableStyles from "../../../../theme/variable-styles";
import IconLink from "../../../../assets/images/common/icon_link_line_blue.svg";

export const SuggestBoxWrapper = styled("div")(() => ({
  backgroundColor: variableStyles.NaturalColor0,
  borderRadius: "12px",
  padding: "16px",
  border: `1px solid ${variableStyles.NaturalColor300}`,
  boxShadow:
    "0px 39px 67.8px 0px #1956580A, 0px 21px 37px -15px #1956580F,0px 1px 1px 0px #00000014",
  "& .box-header": {
    display: "flex",
    justifyContent: "space-between",
    gap: "12px",
    marginBottom: "8px",
    "& .header-title": {
      color: variableStyles.NaturalColor950,
      fontWeight: variableStyles.fwSemiBold,
      fontSize: "14px",
      lineHeight: "21px"
    },
    "& .add-all-button": {
      flex: "0 0 auto",
      gap: "8px"
    }
  },
  "& .box-link": {
    gap: "4px",
    color: variableStyles.blueSecondary700,
    fontWeight: variableStyles.fwRegular,
    fontSize: "12px",
    lineHeight: "24px",
    marginBottom: "8px",
    position: "relative",
    paddingRight: "20px",
    width: "max-content",
    maxWidth: "100%",
    "&::after": {
      content: '""',
      width: "16px",
      height: "16px",
      background: `url(${IconLink}) center/contain no-repeat`,
      position: "absolute",
      top: "calc(50% - 9px)",
      right: "0"
    }
  },
  "& .collapse-button": {
    backgroundColor: variableStyles.NaturalColor200,
    color: variableStyles.NaturalColor950,
    fontWeight: variableStyles.fwSemiBold,
    fontSize: "14px",
    lineHeight: "14px",
    gap: "8px",
    boxShadow: "none",
    width: "100%",
    position: "relative",
    "&.active": {
      "& img": {
        transform: "rotate(90deg)"
      }
    },
    "&:hover": {
      backgroundColor: variableStyles.NaturalColor400
    },
    "& img": { transition: "all .2s linear", transform: "rotate(-90deg)" }
  },
  "& .box-list": {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    paddingTop: "13px",
    marginTop: "12px",
    position: "relative",
    "&::after": {
      content: '""',
      width: "100%",
      height: "1px",
      position: "absolute",
      top: "0",
      left: "0",
      backgroundColor: variableStyles.NaturalColor200
    },
    "& li": {
      display: "flex",
      justifyContent: "space-between",
      padding: "2px 0",
      gap: "8px",
      "& .content-box": {
        display: "flex",
        gap: "8px",
        flex: "1 1 auto",
        "& .item-tag-name": {
          color: variableStyles.NaturalColor1000,
          fontWeight: variableStyles.fwSemiBold,
          fontSize: "10px",
          lineHeight: "18px",
          width: "20px",
          height: "20px",
          textAlign: "center",
          borderRadius: "4px",
          flex: "0 0 auto",
          "&.h1": {
            background: variableStyles.Warning50,
            border: `1px solid ${variableStyles.Warning600}`
          },
          "&.h2": {
            background: variableStyles.Info50,
            border: `1px solid ${variableStyles.Info300}`
          },
          "&.h3": {
            background: variableStyles.NaturalColor100,
            border: `1px solid ${variableStyles.NaturalColor500}`
          },
          "&.h4": {
            background: variableStyles.blueSecondary50,
            border: `1px solid ${variableStyles.blueSecondary300}`
          }
        }
      },
      "& .add-button": {
        width: "20px",
        height: "20px",
        minWidth: "0",
        backgroundColor: variableStyles.NaturalColor200,
        borderRadius: "6px",
        boxShadow: "none"
      }
    }
  }
}));
