import { ControlForm, ImageCustom, Title } from "../../components";
import InputForm from "../../components/controls/InputForm";
import { PotentialKeywordWrapper } from "./styles";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Button } from "@mui/material";
import {
  ITableHeader,
  TableData,
} from "../../components/Table/TableData/TableData";
import { Helmet } from "react-helmet";
import IconDownload from "../../assets/images/common/icon_download_line_black.svg";

export default function PotentialKeyword() {
  const validationSchema = Yup.object().shape({
    url: Yup.string()
      .required(`${"Please enter url!"}`)
      .test("url", "Please enter conrrect url!", function (v) {
        return v.startsWith("https://");
      }),
    keyword: Yup.string().required(`${"Please enter keyword!"}`),
  }) as any;

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<any>({
    defaultValues: {
      url: null,
      keyword: null,
    },
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = () => {};

  const headersTable: ITableHeader<any>[] = [
    {
      field: "id",
      title: `STT`,
      width: "100px",
    },
    {
      field: "customerInfo",
      title: `Từ khoá`,
      width: "200px",
      handleItem: item => {
        return item?.fullName;
      },
    },
    {
      field: "driverInfo",
      title: `Search Volume`,
      width: "200px",
      handleItem: item => {
        return item?.driverInfo?.fullName;
      },
    },
    {
      field: "totalPay",
      title: `URL đối thủ`,
      width: "200px",
      handleItem: item => {
        return item.totalPay;
      },
    },
  ];

  return (
    <PotentialKeywordWrapper>
      <Helmet>
        <title>Potential Keyword</title>
      </Helmet>
      <Title
        highlightTitle="Potential"
        normalTitle="keyword"
        children={
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
        }
        marginBottom={40}
        isCenter
      />
      <div className="potential-form-wrapper">
        <form onSubmit={handleSubmit(onSubmit)} className="potential-form">
          <div className="inputs-wrapper">
            <ControlForm
              title="Nhập URL"
              infor="Làm nổi bật sản phẩm/dịch vụ bằng tiêu đề thu hút người dùng."
              classname="url-form"
              isRequired
            >
              <InputForm
                placeholder="https://..."
                required
                name="url"
                errors={errors}
                control={control}
                size="small"
              />
            </ControlForm>
            <ControlForm
              title="Từ khoá"
              infor="Làm nổi bật sản phẩm/dịch vụ bằng tiêu đề thu hút người dùng."
              classname="keyword-form"
              isRequired
            >
              <InputForm
                placeholder="Nhập từ khoá"
                required
                name="keyword"
                errors={errors}
                control={control}
                size="small"
              />
            </ControlForm>
          </div>
          <div className="actions-wrapper flex-between-align-center">
            <Button
              size="small"
              variant="contained"
              className={`submit-button`}
              onClick={handleSubmit(onSubmit)}
            >
              Phân nhóm
            </Button>
            <Button
              size="small"
              variant="contained"
              className={`reset-button`}
              onClick={handleSubmit(onSubmit)}
            >
              Đặt lại
            </Button>
          </div>
        </form>
        <div className="potential-results">
          <div className="results-header">
            <p className="header-text">
              <span>150 từ khoá</span> tiềm năng
            </p>
            <Button
              size="xsmall"
              variant="outlined"
              className={`download-button`}
              onClick={handleSubmit(onSubmit)}
            >
              <ImageCustom
                src={IconDownload}
                alt="Icon-Download"
                width={"18px"}
                height={"18px"}
              />
              <p>Tải danh sách</p>
            </Button>
          </div>
          <TableData
            single
            data={{
              items: [{ fullName: "keyword" }, { fullName: "keyword 2" }],
              total: 1,
              page: 1,
              pageSize: 10,
              isFull: false,
            }}
            headersTable={headersTable}
            isLoadmore
            handleLoadMore={() => {}}
          />
        </div>
      </div>
    </PotentialKeywordWrapper>
  );
}
