import { styled } from "@mui/material/styles";
import variableStyles from "../../../../../theme/variable-styles";

export const DomainCompetitiveBoxWrapper = styled("div")(() => ({
  margin: "4px -5px 0 -4px",
  display: "flex",
  flexDirection: "column",
  gap: "12px",
  "& .domain-competitive-form": {
    backgroundColor: variableStyles.NaturalColor0,
    display: "flex",
    position: "relative",
    gap: "12px",
    paddingTop: "6px",
    borderTop: `1px solid ${variableStyles.NaturalColor200}`,
    alignItems: "center",
    justifyContent: "space-between",
    "&:hover": { "& .delete-button": { display: "block" } },
    "& .delete-button": {
      position: "absolute",
      top: "8px",
      right: "0px",
      display: "none"
    }
  },
  "& .domain-competitive-scroll": {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    borderRadius: "12px",
    padding: "0 2px 0 4px",
    overflow: "scroll",
    "&.scroll-full": {
      maxHeight: "calc(100vh - 412px)"
    },
    "&.scroll-less": {
      maxHeight: "calc(100vh - 380px)"
    },
    "& .competitive-input-wrapper": {
      width: "100%"
    }
  },
  "& button": {
    color: variableStyles.NaturalColor700,
    fontWeight: variableStyles.fwSemiBold,
    fontSize: "14px",
    lineHeight: "19.6px",
    gap: "4px"
  },
  "& .add-more-button.MuiButton-text": {
    "&:hover": {
      color: `${variableStyles.NaturalColor700} !important`
    }
  }
}));

export const PageAuditBoxWrapper = styled("form")(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "12px",
  overflow: "auto",
  maxHeight: "calc(100vh - 366px)",
  margin: "-5px",
  "& .page-audit-wrapper": {
    padding: "5px",
    display: "flex",
    flexDirection: "column",
    gap: "16px"
  }
}));

export const PageCompetitiveBoxWrapper = styled("form")(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "12px",
  margin: "-5px",
  "& .page-competitive-wrapper": {
    padding: "5px",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    overflow: "auto",
    maxHeight: "calc(100vh - 398px)"
  },
  "& .url-target-box": {
    position: "relative",
    "&:hover": { "& .delete-button": { display: "block" } },
    "& .delete-button": {
      position: "absolute",
      top: "3px",
      right: "0",
      display: "none"
    }
  },
  "& button": {
    color: variableStyles.NaturalColor700,
    fontWeight: variableStyles.fwSemiBold,
    fontSize: "14px",
    lineHeight: "19.6px",
    gap: "4px"
  },
  "& .add-more-button.MuiButton-text": {
    "&:hover": {
      color: `${variableStyles.NaturalColor700} !important`
    }
  }
}));
