import React from "react";
import { ProgressBarWrapper } from "./styles";

interface ProgressBarProps {
  height?: string;
  type?: "normal" | "warning" | "danger";
  width?: string;
  percent: number;
  className?: string;
}

export default function ProgressBar(props: ProgressBarProps) {
  const {
    height = "7px",
    type = "normal",
    width = "100%",
    percent,
    className = "",
  } = props;
  return (
    <ProgressBarWrapper
      height={height}
      width={width}
      percent={percent}
      className={`${type?.toString()} ${className}`}
    >
      <div className="progress-bar"></div>
    </ProgressBarWrapper>
  );
}
