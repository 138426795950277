import { styled } from "@mui/material/styles";
import { MenuItem, Select } from "@mui/material";
import variableStyles from "../../theme/variable-styles";
import IconSelected from "../../assets/images/common/icon_check_line_green.svg";

export const SelectCustomWrapper = styled(Select)(({}) => ({
  padding: "0",
  "&.Mui-focused": {
    "& fieldset": {
      border: `1px solid ${variableStyles.blueSecondary600} !important`,
    },
    "& .MuiSelect-select": { boxShadow: "0px 0px 0px 4px #23B4FF33" },
  },
  "&:hover": {
    "& fieldset": {
      border: `1px solid ${variableStyles.NaturalColor600} !important`,
    },
  },
  "&.small": {
    "& .MuiSelect-select": {
      padding: "7px 12px",
    },
  },
  "& fieldset": {
    borderRadius: "12px",
    padding: "11px 12px",
    border: `1px solid ${variableStyles.NaturalColor300} !important`,
  },
  "& input": {
    color: variableStyles.NaturalColor950,
    fontWeight: variableStyles.fwMedium,
    fontSize: "13px",
    lineHeight: "18.2px",
  },
  "& .MuiSelect-select": {
    color: variableStyles.NaturalColor950,
    fontWeight: variableStyles.fwMedium,
    fontSize: "13px",
    lineHeight: "18.2px",
    minHeight: "unset",
    padding: "11px 12px",
    borderRadius: "12px !important",
  },
  "& .select-icon": {
    position: "absolute",
    right: "8px",
    pointerEvents: "none",
    "&[aria-expanded=true]": {
      transform: "rotate(-180deg)",
    },
  },
  "& .selected-box": {
    display: "flex",
    gap: "8px",
    alignItems: "center",
  },
  "&.Mui-disabled": {
    pointerEvents: "none",
    "& .select-icon": {
      opacity: ".3",
    },
  },
}));

export const MenuItemWrapper = styled(MenuItem)(({}) => ({
  color: variableStyles.NaturalColor950,
  fontWeight: variableStyles.fwMedium,
  fontSize: "13px",
  lineHeight: "18.2px",
  padding: "13px 14px",
  display: "flex",
  gap: "8px",
  alignItems: "center",
  "&:hover": {
    backgroundColor: variableStyles.NaturalColor50,
  },
  "&.Mui-selected": {
    backgroundColor: variableStyles.NaturalColor50,
    "&::after": {
      display: "block",
    },
    "&:hover": {
      backgroundColor: variableStyles.NaturalColor50,
    },
  },
  "&::after": {
    content: '""',
    width: "20px",
    height: "20px",
    position: "absolute",
    right: "8px",
    top: "calc(50% - 10px)",
    background: `url(${IconSelected}) center/contain no-repeat`,
    display: "none",
  },
  "&.clear-menuitem": {
    position: "absolute",
    left: "0",
    top: 0,
    zIndex: "9999",
    width: "100%",
    backgroundColor: variableStyles.NaturalColor0,
    borderBottom: `1.5px solid ${variableStyles.NaturalColor300}`,
    "&:hover": {
      backgroundColor: variableStyles.Error100,
    },
  },
}));
