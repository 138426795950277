import React from "react";
import { ROUTE_PATH } from "../../common/constants/app.constant";
import { CommonReportListPage } from "../../components";
import { EReportType } from "../../models/common/models.enum";
import { DomainAuditWrapper } from "./styles";

export default function DomainAudit() {
  return (
    <DomainAuditWrapper>
      <CommonReportListPage
        title="Domain Audit"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
        eiusmod tempor incididunt eiusmo"
        type={EReportType.DomainAudit}
        pathProps={ROUTE_PATH.DOMAIN_AUDIT}
        pathDetail={ROUTE_PATH.DOMAIN_AUDIT_DETAIL}
        disabledSelectType
      />
    </DomainAuditWrapper>
  );
}
