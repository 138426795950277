import { styled } from "@mui/material/styles";
import variableStyles from "../../theme/variable-styles";

export const TextEditorQuillWrapper = styled("div")(({}) => ({
  height: "100%",
  ".quill-custom": {
    border: `1px solid ${variableStyles.NaturalColor300}`,
    borderRadius: "12px",
    background: "#FFFFFF",
    height: "calc(100vh - 76px)"
  },
  "& .ql-container": {
    height: "calc(100vh - 172px)"
  },
  ".quill-custom .ql-toolbar": {
    "&.ql-snow": {
      display: "flex",
      padding: "10px",
      justifyContent: "center",
      flexWrap: "wrap",
      border: `none`,
      borderRadius: "none"
    },
    "& .ql-stroke": {
      stroke: `${variableStyles.NaturalColor950} !important`
    },
    "& .ql-fill": {
      fill: `${variableStyles.NaturalColor950} !important`
    },
    "& .ql-picker": {
      color: `${variableStyles.NaturalColor950} !important`
    },
    "& .ql-picker-label::before": {
      color: `${variableStyles.NaturalColor950} !important`
    }
  },

  ".quill-custom .ql-toolbar.ql-snow .ql-formats": {
    background: `${variableStyles.NaturalColor100}`,
    border: `none`,
    padding: "5px",
    margin: "0 0 5px 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "8px",
    flexWrap: "wrap"
  },

  ".quill-custom .ql-toolbar.ql-snow button": {
    width: "36px",
    height: "36px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: `${variableStyles.NaturalColor0}`,
    border: "none",
    background: "transparent",
    margin: "0 2px"
  },
  "& .ql-editor": {
    "&::-webkit-scrollbar": {
      width: "3px",
      height: "3px"
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "7px",
      backgroundColor: variableStyles.NaturalColor400
    }
  },

  ".quill-custom .ql-toolbar.ql-snow .ql-active": {
    background: variableStyles.NaturalColor400,
    borderRadius: "6px"
  },

  ".quill-custom .ql-toolbar.ql-snow .ql-stroke": {
    stroke: `${variableStyles.NaturalColor0}`
  },

  ".quill-custom .ql-toolbar.ql-snow .ql-fill": {
    fill: `${variableStyles.NaturalColor0}`
  },

  ".quill-custom .ql-container.ql-snow": {
    border: "none"
  },

  ".quill-custom .ql-toolbar.ql-snow .ql-formats button.ql-header.ql-active": {
    background: variableStyles.NaturalColor400
  },

  // Thêm CSS để đặt màu chữ cho size, header, font thành màu trắng
  ".quill-custom .ql-toolbar.ql-snow .ql-picker.ql-size .ql-picker-label": {
    color: `${variableStyles.NaturalColor0}`
  },
  ".quill-custom .ql-toolbar.ql-snow .ql-picker.ql-header .ql-picker-label": {
    color: `${variableStyles.NaturalColor0}`
  },
  ".quill-custom .ql-toolbar.ql-snow .ql-picker.ql-font .ql-picker-label": {
    color: `${variableStyles.NaturalColor0}`
  },
  ".quill-custom .ql-toolbar.ql-snow .ql-picker.ql-size .ql-picker-item": {
    color: `${variableStyles.NaturalColor0}`
  },
  ".quill-custom .ql-toolbar.ql-snow .ql-picker.ql-header .ql-picker-item": {
    color: `${variableStyles.NaturalColor0}`
  },
  ".quill-custom .ql-toolbar.ql-snow .ql-picker.ql-font .ql-picker-item": {
    color: `${variableStyles.NaturalColor0}`
  },
  ".quill-custom .ql-toolbar.ql-snow .ql-picker.ql-size .ql-picker-options": {
    background: `${variableStyles.NaturalColor950}`
  },
  ".quill-custom .ql-toolbar.ql-snow .ql-picker.ql-header .ql-picker-options": {
    background: `${variableStyles.NaturalColor950}`
  },
  ".quill-custom .ql-toolbar.ql-snow .ql-picker.ql-font .ql-picker-options": {
    background: `${variableStyles.NaturalColor950}`
  },

  "& .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='9px']::before": {
    content: '"9"'
  },
  "& .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='10px']::before": {
    content: '"10"'
  },
  "& .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='11px']::before": {
    content: '"11"'
  },
  "& .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='12px']::before": {
    content: '"12"'
  },
  "& .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='14px']::before": {
    content: '"14"'
  },
  "& .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='16px']::before": {
    content: '"16"'
  },
  "& .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='18px']::before": {
    content: '"18"'
  },
  "& .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='20px']::before": {
    content: '"20"'
  },
  "& .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='22px']::before": {
    content: '"22"'
  },
  "& .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='24px']::before": {
    content: '"24"'
  },
  "& .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='26px']::before": {
    content: '"26"'
  },
  "& .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='28px']::before": {
    content: '"28"'
  },

  ".ql-snow .ql-picker.ql-size .ql-picker-item[data-value='9px']::before": {
    content: '"9"',
    fontSize: "9px !important"
  },

  ".ql-snow .ql-size .ql-picker-item[data-value='10px']::before": {
    content: '"10"',
    fontSize: "10px !important"
  },

  ".ql-snow .ql-size .ql-picker-item[data-value='11px']::before": {
    content: '"11"',
    fontSize: "11px !important"
  },
  ".ql-snow .ql-size .ql-picker-item[data-value='12px']::before": {
    content: '"12"',
    fontSize: "12px !important"
  },

  ".ql-snow .ql-size .ql-picker-item[data-value='14px']::before": {
    content: '"14"',
    fontSize: "14px !important"
  },

  ".ql-snow .ql-size .ql-picker-item[data-value='16px']::before": {
    content: '"16"',
    fontSize: "16px !important"
  },
  ".ql-snow .ql-size .ql-picker-item[data-value='18px']::before": {
    content: '"18"',
    fontSize: "18px !important"
  },

  ".ql-snow .ql-size .ql-picker-item[data-value='20px']::before": {
    content: '"20"',
    fontSize: "20px !important"
  },

  ".ql-snow .ql-size .ql-picker-item[data-value='22px']::before": {
    content: '"22"',
    fontSize: "22px !important"
  },
  ".ql-snow .ql-size .ql-picker-item[data-value='24px']::before": {
    content: '"24"',
    fontSize: "24px !important"
  },

  ".ql-snow .ql-size .ql-picker-item[data-value='26px']::before": {
    content: '"26"',
    fontSize: "26px !important"
  },

  ".ql-snow .ql-size .ql-picker-item[data-value='28px']::before": {
    content: '"28"',
    fontSize: "28px !important"
  }
}));
