import { Box, SxProps, Theme, Tooltip, Typography } from "@mui/material";
import { ImageCustom } from "../../../../../../../../../components";
import IconInfor from "../../../../../../../../../assets/images/domai-audit/overview-section/icon_infor_fill_grey.svg";
import {
  CategoryAcronym,
  ContentfulPaint,
} from "../../../../../../../../../models/google/google.page.speed";
import MarkdownRenderer from "../../../../../../../../../components/MarkdownRenderer";
import { getAuditStatus } from "../../../../../../../../../common/handles/common.handles";
import DotIcon from "../../../../../../../../../components/DotIcon";
import { MetricsItemWrapper } from "./styles";

interface IProps {
  sx?: SxProps<Theme>;
  audit: ContentfulPaint | null;
  acronym?: CategoryAcronym;
  weight: number;
}

const MetricsItem = ({ sx, audit, acronym, weight }: IProps) => {
  if (!audit) {
    return null;
  }

  const status = getAuditStatus(audit, weight);
  return (
    <MetricsItemWrapper>
      <div className="performance-metrics-item-title flex-center">
        <DotIcon status={status} width={12} height={12} borderRadius={"50%"} />
        <div className="title-text-wrapper flex-start-align-center">
          <Tooltip
            title={
              <MarkdownRenderer content={audit?.title + ` (${acronym})`} />
            }
            placement="top-start"
          >
            <p className="title-text fit-one-line">
              {audit?.title + ` (${acronym})`}
            </p>
          </Tooltip>

          <Tooltip
            title={
              <MarkdownRenderer
                content={audit?.description.replace(/\.$/, "") ?? ""}
              />
            }
            placement="top-start"
          >
            <div>
              <ImageCustom
                src={IconInfor}
                alt="Icon-Infor"
                width={"14px"}
                height={"14px"}
              />
            </div>
          </Tooltip>
        </div>
      </div>
      <p className={`metrics-scores color-audit-${status} fit-one-line`}>
        {audit?.displayValue ?? ""}
      </p>
    </MetricsItemWrapper>
  );
};

export default MetricsItem;
