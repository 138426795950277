import { styled } from "@mui/material/styles";
import variableStyles from "../../../../../../../theme/variable-styles";

export const IssueChartWrapper = styled("div")(({}) => ({
  background:
    "linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 77.5%, #F7F7F7 100%)",
  boxShadow: "0px 0px 1.4px 0px #00000040",
  borderRadius: "12px",
  padding: "12px 12px 0",
  "& .chart-header": {
    marginBottom: "10px",
    gap: "12px",
    "& .header-title": {
      color: variableStyles.NaturalColor900,
      fontWeight: variableStyles.fwMedium,
      fontSize: "12px",
      lineHeight: "18px",
      backgroundColor: variableStyles.NaturalColor100,
      borderRadius: "6px",
      padding: "2px 8px",
    },
    "& .header-button": {
      color: variableStyles.GreenPrimaryColor500,
      fontWeight: variableStyles.fwMedium,
      fontSize: "12px",
      lineHeight: "14.52px",
      backgroundColor: "transparent",
    },
  },
  "& .chart-overview": {
    gap: "6px",
    marginBottom: "15px",
    "& .overview-index": {
      color: variableStyles.Warning800,
      fontWeight: variableStyles.fwMedium,
      fontSize: "24px",
      lineHeight: "29.05px",
    },
    "& .overview-difference": {
      color: variableStyles.NaturalColor950,
      fontWeight: variableStyles.fwMedium,
      fontSize: "12px",
      lineHeight: "14.52px",
    },
  },
}));
