import React from "react";
import { Helmet } from "react-helmet";
import { ReportPageHeader, TabsCustom } from "../../../components";
import { DomainAuditDetailWrapper } from "./styles";
import { IValue } from "../../../models/common/models.type";
import OverviewSection from "../components/tabs/OverviewTab";
import TechnicalViewSection from "../components/tabs/TechnicalViewTab";
import CrawledPageSection from "../components/tabs/CrawledPageTab";
import ErrorTableModal from "../components/ModalErrorTable";
import { generatePath, useNavigate, useSearchParams } from "react-router-dom";
import { ROUTE_PATH } from "../../../common/constants/app.constant";
import { useSelector } from "react-redux";
import { IStateApp } from "../../../models/redux";
import { IGetReportsRes } from "../../../models/report";
import queryString from "query-string";

const dataTabs: IValue[] = [
  { value: 0, title: "Overview" },
  { value: 1, title: "Technical view" },
  // { value: 2, title: "Crawled Page" },
];

export default function DomainAuditDetail() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const paramTab: {
    tab: string | null;
  } = {
    tab: searchParams.get("tab") || "0",
  };

  const dataReport = useSelector<IStateApp, IGetReportsRes>(
    appState => appState.project?.dataReport
  );

  return (
    <>
      <ErrorTableModal />
      <DomainAuditDetailWrapper>
        <Helmet>
          <title>Domain Audit</title>
        </Helmet>
        <ReportPageHeader title="Domain Audit" />
        <TabsCustom
          data={dataTabs}
          onChange={(v: number) => {
            const path = generatePath(ROUTE_PATH.DOMAIN_AUDIT_DETAIL, {
              id: dataReport.auditId,
            });
            const params = queryString.stringify({ tab: v });
            navigate({
              pathname: `${path}`,
              search: params,
            });
          }}
          selectedValue={Number(paramTab.tab) || 0}
        />
        {Number(paramTab.tab) === 0 ? (
          <OverviewSection />
        ) : Number(paramTab.tab) === 1 ? (
          <TechnicalViewSection />
        ) : (
          <CrawledPageSection />
        )}
      </DomainAuditDetailWrapper>
    </>
  );
}
