export interface IPageSpeedReq {
  url: string;
  strategy?: "desktop" | "mobile";
  category?: (
    | "accessibility"
    | "best-practices"
    | "performance"
    | "pwa"
    | "seo"
  )[];
  locale?: string;
}
export interface IGooglePageSpeedData {}

export interface IGooglePageSpeedRes {
  desktop: IGooglePageSpeedData;
  mobile: IGooglePageSpeedData;
}

export enum CaptchaResult {
  CAPTCHA_BLOCKING = "CAPTCHA_BLOCKING",
  CAPTCHA_MATCHED = "CAPTCHA_MATCHED",
  CAPTCHA_NEEDED = "CAPTCHA_NEEDED",
  CAPTCHA_NOT_NEEDED = "CAPTCHA_NOT_NEEDED",
  CAPTCHA_UNMATCHED = "CAPTCHA_UNMATCHED",
}
export enum CategoryMetrics {
  AVERAGE = "AVERAGE",
  FAST = "FAST",
  NONE = "NONE",
  SLOW = "SLOW",
}
export enum CategoryAcronym {
  LCP = "LCP",
  FMP = "FMP",
  FID = "FID",
  CLS = "CLS",
  TTFB = "TTFB",
  TBT = "TBT",
  FCP = "FCP",
  SI = "SI",
  INP = "INP",
  TTI = "TTI",
}

export enum ScoreDisplayMode {
  "notApplicable" = "notApplicable", // Kiểm tra này không áp dụng được cho trang hiện tại, nên không có điểm số hoặc đánh giá.
  "metricSavings" = "metricSavings",
  "informative" = "informative", // Cung cấp thông tin hữu ích nhưng không có điểm số cụ thể.
  "numeric" = "numeric", //Điểm số được biểu thị dưới dạng số (thường là từ 0 đến 1), nơi 1 là tốt nhất và 0 là kém nhất. Dùng cho các kiểm tra có thể đo lường cụ thể và có giá trị số, ví dụ: thời gian tải, kích thước tệp, v.v.
  "error" = "error", // Có lỗi xảy ra khi thực hiện kiểm tra này, và do đó không thể đưa ra điểm số.
  "manual" = "manual", // Kiểm tra này yêu cầu phải thực hiện thủ công và không thể tự động đánh giá bởi công cụ Lighthouse.
  "binary" = "binary", // Điểm số dạng nhị phân: thường là 0 (fail) hoặc 1 (pass)
}

export interface IGooglePageSpeed {
  captchaResult: CaptchaResult;
  kind: string;
  id: string; // URL cuối cùng và được chuẩn hoá cho tài liệu, sau các lần chuyển hướng trang sau đây (nếu có).
  loadingExperience: LoadingExperience; //Các chỉ số về trải nghiệm người dùng cuối trải nghiệm tải trang.
  originLoadingExperience: IOriginLoadingExperience; //Các chỉ số về trải nghiệm tải trang tổng hợp của nguồn gốc
  lighthouseResult: LighthouseResult;
  analysisUTCTimestamp: string;
}

export interface LoadingExperience {
  id: string;
  metrics: LoadingExperienceMetrics;
  overall_category: string;
  initial_url: string;
}

export interface LoadingExperienceMetrics {
  CUMULATIVE_LAYOUT_SHIFT_SCORE: ShiftScore;
  EXPERIMENTAL_TIME_TO_FIRST_BYTE: ShiftScore;
  FIRST_CONTENTFUL_PAINT_MS: ShiftScore;
  FIRST_INPUT_DELAY_MS: ShiftScore;
  INTERACTION_TO_NEXT_PAINT: ShiftScore;
  LARGEST_CONTENTFUL_PAINT_MS: ShiftScore;
}

export interface ShiftScore {
  percentile: number;
  distributions: Distribution[];
  category: string;
}

export interface Distribution {
  min: number;
  max?: number;
  proportion: number;
}

export interface IOriginLoadingExperience {
  id: string;
  metrics: LoadingExperienceMetrics;
  overall_category: string;
  initial_url: string;
}

export interface StackPacks {
  id: string;
  title: string;
  iconDataURL: string;
  descriptions: {
    [field: string]: string;
  };
}
export interface LighthouseResult {
  requestedUrl: string;
  finalUrl: string;
  mainDocumentUrl: string;
  finalDisplayedUrl: string;
  lighthouseVersion: string;
  userAgent: string;
  fetchTime: string;
  environment: Environment;
  runWarnings: any[];
  configSettings: ConfigSettings;
  audits: Audits;
  categories: Categories;
  categoryGroups: CategoryGroups;
  timing: Timing;
  i18n: LighthouseResultI18n;
  entities: Entity[];
  fullPageScreenshot: FullPageScreenshot;
  stackPacks: StackPacks[];
}

export interface Environment {
  networkUserAgent: string;
  hostUserAgent: string;
  benchmarkIndex: number;
}

export interface ConfigSettings {
  emulatedFormFactor: string;
  formFactor: string;
  locale: string;
  onlyCategories: string[];
  channel: string;
}

export interface Audits {
  [field: string]: ContentfulPaint;
}

export interface UnminifiedCss {
  id: string;
  title: string;
  description: string;
  score: number;
  scoreDisplayMode: ScoreDisplayMode;
  details: UnminifiedCssDetails;
  numericValue: number;
  numericUnit: string;
}

export interface UnminifiedCssDetails {
  headings: any[];
  debugData: DebugData;
  overallSavingsBytes: number;
  overallSavingsMs: number;
  items: any[];
  type: string;
  sortedBy: string[];
}

export interface DebugData {
  type: string;
  metricSavings: MetricSavings;
}

export interface MetricSavings {
  [field: string]: number;
}

export interface ContentfulPaint {
  [field: string]: any;
  id: string;
  title: string;
  description: string;
  score: number;
  scoreDisplayMode: ScoreDisplayMode;
  displayValue: string;
  explanation: string;
  errorMessag: string;
  numericValue: number;
  numericUnit: string;
  details: ContentfulPaintDetails;
  metricSavings: MetricSavings;
  warnings: string[];
}
export interface ContentfulPaintDetailItemNode {
  snippet: string;
  nodeLabel: string;
  lhId: string;
  type: string;
  selector: string;
  path: string;
  explanation: string;
  boundingRect: {
    height: number;
    left: number;
    right: number;
    top: number;
    bottom: number;
    width: number;
  };
}

export interface ISubItems {
  type: string;
  items: Array<{
    relatedNode: {
      boundingRect: {
        top: number;
        bottom: number;
        left: number;
        height: number;
        right: number;
        width: number;
      };
      nodeLabel: string;
      lhId: string;
      path: string;
      snippet: string;
      selector: string;
      type: string;
    };
  }>;
}

export interface ContentfulPaintDetailItem {
  [field: string]: any;
  node?: ContentfulPaintDetailItemNode;
  subItems?: ISubItems;
}

export type ValueType =
  | "text"
  | "ms"
  | "node"
  | "url"
  | "bytes"
  | "source-location"
  | "code"
  | "timespanMs"
  | "numeric";

export interface ContentfulPaintDetailHeadings {
  valueType: ValueType;
  label?: string;
  key: string;
  subItemsHeading?: {
    key: string;
    valueType: string;
  };
  granularity?: number;
}

export type DetailsType =
  | "opportunity"
  | "criticalrequestchain"
  | "debugdata"
  | "table"
  | "list"
  | "filmstrip"
  | "screenshot"
  | "timing"
  | "debug"
  | "subitems";

export interface IDetailLongestChain {
  duration: number;
  length: number;
  transferSize: number;
}

export interface IDetailsChains {
  [field: string]: {
    request: IRequestChains;
    children: IDetailsChains;
  };
}

export interface IRequestChains {
  url: string;
  endTime: number;
  responseReceivedTime: number;
  startTime: number;
  transferSize: number;
}

export type ContentfulPaintDetails = {
  [field: string]: any;
  overallSavingsBytes: number;
  type: DetailsType;
  items: ContentfulPaintDetails[];
  scale: number;
  overallSavingsMs: number;
  debugData: {
    type: string;
    metricSavings: {
      [field: string]: any;
    };
  };
  headings: ContentfulPaintDetailHeadings[];
  sortedBy: Array<string>;
  longestChain: IDetailLongestChain;
  chains: IDetailsChains;
};

export interface Categories {
  performance: CategoriesItem;
  accessibility: CategoriesItem;
  "best-practices": CategoriesItem;
  seo: CategoriesItem;
}

export interface CategoriesItem {
  id: string;
  title: string;
  score: number;
  description?: string;
  manualDescription?: string;
  auditRefs: AuditRef[];
}

export interface AuditRef {
  id: string;
  weight: number;
  group: string;
  acronym?: CategoryAcronym;
}

export interface CategoryGroups {
  [field: string]: CategoryGroupValue;
}

export interface CategoryGroupValue {
  title: string;
  description?: string;
}

export interface Timing {
  total: number;
}

export interface LighthouseResultI18n {
  rendererFormattedStrings: RendererFormattedStrings;
}

export interface RendererFormattedStrings {
  varianceDisclaimer: string;
  opportunityResourceColumnLabel: string;
  opportunitySavingsColumnLabel: string;
  errorMissingAuditInfo: string;
  errorLabel: string;
  warningHeader: string;
  passedAuditsGroupTitle: string;
  notApplicableAuditsGroupTitle: string;
  manualAuditsGroupTitle: string;
  toplevelWarningsMessage: string;
  crcLongestDurationLabel: string;
  crcInitialNavigation: string;
  lsPerformanceCategoryDescription: string;
  labDataTitle: string;
  warningAuditsGroupTitle: string;
  snippetExpandButtonLabel: string;
  snippetCollapseButtonLabel: string;
  thirdPartyResourcesLabel: string;
  runtimeDesktopEmulation: string;
  runtimeMobileEmulation: string;
  runtimeNoEmulation: string;
  runtimeSettingsBenchmark: string;
  runtimeSettingsCPUThrottling: string;
  runtimeSettingsDevice: string;
  runtimeSettingsNetworkThrottling: string;
  runtimeSettingsUANetwork: string;
  runtimeUnknown: string;
  dropdownCopyJSON: string;
  dropdownDarkTheme: string;
  dropdownPrintExpanded: string;
  dropdownPrintSummary: string;
  dropdownSaveGist: string;
  dropdownSaveHTML: string;
  dropdownSaveJSON: string;
  dropdownViewer: string;
  footerIssue: string;
  throttlingProvided: string;
  calculatorLink: string;
  runtimeSettingsAxeVersion: string;
  viewTreemapLabel: string;
  showRelevantAudits: string;
}

export interface Entity {
  name: string;
  isFirstParty?: boolean;
  isUnrecognized: boolean;
  origins: string[];
}

export interface FullPageScreenshot {
  screenshot: Screenshot;
  nodes: Nodes;
}

export interface Screenshot {
  height: number;
  data: string;
  width: number;
}

export interface Nodes {
  [field: string]: NodeData;
}

export interface NodeData {
  left: number;
  bottom: number;
  height: number;
  width: number;
  right: number;
  top: number;
}
