import { Card, Pagination, Table, TablePagination } from "@mui/material";
import { styled } from "@mui/material/styles";
import variableStyles from "../../../theme/variable-styles";

export const TableBoxWrapper = styled(Card)(() => ({
  borderRadius: "12px",
  backgroundColor: variableStyles.NaturalColor100,
  "& .loadmore-button": {
    backgroundColor: variableStyles.NaturalColor200,
    color: variableStyles.NaturalColor950,
    fontWeight: variableStyles.fwSemiBold,
    fontSize: "14px",
    lineHeight: "14px",
    width: "calc(100% - 16px)",
    gap: "6px",
    margin: "8px",
    boxShadow: "none",
    "&.Mui-disabled": {
      "& img": {
        opacity: ".5",
      },
    },
  },
  "& .MuiBox-root": {
    minWidth: "0",
  },
}));

export const TableWrapper = styled(Table)(() => ({
  "& .MuiTableHead-root": {
    backgroundColor: variableStyles.NaturalColor950,
    "& .MuiTableCell-root": {
      color: variableStyles.NaturalColor0,
      fontWeight: variableStyles.fwSemiBold,
      fontSize: "13px",
      lineHeight: "13px",
      padding: "13px 16px",
    },
  },
  "& .MuiTableBody-root": {
    "& .MuiTableRow-root": {
      "&:last-of-type": {
        "& .MuiTableCell-root": {
          borderBottom: `none`,
        },
      },
    },
    "& .MuiTableCell-root": {
      color: variableStyles.NaturalColor950,
      fontWeight: variableStyles.fwMedium,
      fontSize: "13px",
      lineHeight: "13px",
      borderBottom: `1px solid ${variableStyles.NaturalColor300}`,
    },
    "& .ps__rail-x": {},
  },
}));
export const TablePaginationWrapper = styled("div")(() => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  gap: "16px",
  marginTop: "10px",
  "& p": {
    color: variableStyles.GreenPrimaryColor700,
    fontWeight: variableStyles.fwMedium,
    fontSize: "13px",
    lineHeight: "13.31px",
  },
  "& .MuiPagination-root": {
    "& .MuiPagination-ul": {
      gap: "6px",
      "& li": {
        "& button": {
          width: "24px",
          height: "24px",
          minWidth: "0",
          borderRadius: "6px",
          margin: "0",
          border: `1px solid ${variableStyles.NaturalColor200}`,
          color: variableStyles.NaturalColor600,
          fontWeight: variableStyles.fwMedium,
          fontSize: "13px",
          lineHeight: "13px",
          backgroundColor: variableStyles.NaturalColor0,
          "&:hover": {
            backgroundColor: variableStyles.NaturalColor200,
          },
          "&.Mui-selected": {
            backgroundColor: variableStyles.GreenPrimaryColor400,
            color: variableStyles.NaturalColor0,
          },
        },
      },
    },
  },
}));
