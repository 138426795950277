import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { envConfig } from "../../config/env.config";
import NO_IMAGE from "../../assets/images/common/no_image.jpg";
import NO_IMAGE_AVATAR from "../../assets/images/common/no_image_avatar.png";

interface ImageProps {
  height?: number | string;
  width?: number | string;
  borderRadius?: string;
  objectFit?: string;
  objectPosition?: string;
  className?: string;
  src?: string | null;
  isAvatar?: boolean;
  alt?: string;
  onClick?: () => void;
  notlazy?: boolean;
}
function ImageCustom(props: ImageProps) {
  const {
    height = "",
    width = "",
    borderRadius = "",
    objectFit,
    objectPosition = "",
    className = "",
    src,
    isAvatar,
    alt = "",
    onClick,
    notlazy = false
  } = props;

  const [path, setPath] = useState<any>();
  const imageEmpty = isAvatar ? NO_IMAGE_AVATAR : NO_IMAGE;

  useEffect(() => {
    renderImage();
  }, [src]);

  const renderImage = async () => {
    const newSrc = src?.trim();

    if (newSrc && newSrc.startsWith("/Temp/")) {
      setPath(envConfig + (src || ""));
    } else {
      setPath(newSrc);
    }
  };

  return (
    <Box
      component="img"
      className={className}
      src={path || imageEmpty}
      loading={notlazy ? "eager" : "lazy"}
      onError={(e: any) => {
        const target = e.target as HTMLImageElement;
        target.onerror = null;
        target.src = imageEmpty;
      }}
      alt={alt}
      onClick={onClick}
      sx={{
        borderRadius: borderRadius,
        height: height,
        width: width,
        display: "block",
        objectFit: objectFit ? objectFit : "cover",
        objectPosition: objectPosition ? objectPosition : "center"
      }}
    />
  );
}

export default ImageCustom;
