import { styled } from "@mui/material/styles";
import variableStyles from "../../../../theme/variable-styles";

export const OverviewCommonSubTitleWrapper = styled("div")(() => ({
  marginBottom: "12px",
  width: "100%",
  "& .description": {
    color: variableStyles.NaturalColor1000,
    fontWeight: variableStyles.fwRegular,
    fontSize: "12px",
    lineHeight: "18px",
    padding: '8px 12px',
    borderRadius: '12px',
    backgroundColor: variableStyles.Info50,
    marginTop: '14px'
  },
}));
