import { styled } from "@mui/material/styles";
import variableStyles from "../../../../../../../theme/variable-styles";

export const StatusFullCircleWrapper = styled("div")(({}) => ({
  position: "relative",
  width: "max-content",
  "&.small": {
    "& svg": {
      height: "40px",
      width: "40px",
    },
    "& #progress": {
      strokeWidth: "3px",
      strokeDasharray: "87.9px",
    },
    "& #border-track": {
      strokeWidth: "3px",
    },
  },
  "&.large": {
    "& svg": {
      height: "190px",
      width: "190px",
    },
    "& #progress": {
      strokeWidth: "16px",
      strokeDasharray: "445.88px",
    },
    "& #border-track": {
      strokeWidth: " 16px",
    },
    "& #border-boxshadow": {
      strokeWidth: "7px",
    },
    "& p": {
      fontSize: "36px",
      lineHeight: "43.57px",
    },
  },
  "& svg": {
    transform: "rotate(-90deg)",
  },
  "& #progress ,& #border-track, & #border-boxshadow": {
    fill: "transparent",
  },
  "& #progress": {
    strokeLinecap: "round",
  },
  "& #border-track": {
    stroke: variableStyles.NaturalColor200,
  },
  "& #border-boxshadow": {
    stroke: "#00000005",
  },
  "& p": {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: variableStyles.GreenPrimaryColor600,
    fontWeight: variableStyles.fwBold,
  },
}));
