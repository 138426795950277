import React, { useEffect, useState } from "react";
import {
  generatePath,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { ROUTE_PATH } from "../../../../../common/constants/app.constant";
import {
  DateRangePickerPopover,
  FastFilterList,
  FilterCheckBoxCustom,
  TableNavDetailNoPagination,
} from "../../../../../components";
import {
  IBasePagingRes,
  IValue,
} from "../../../../../models/common/models.type";
import { CrawledPageDomainAuditWrapper } from "./styles";
import { ITableHeader } from "../../../../../components/Table/TableDataGrey";
import moment from "moment";
import { DATE_REQ_FORMAT } from "../../../../../config/constants";
import { DEFAULT_BASE_PAGING_RES } from "../../../../../common/constants/value.constant";
import AuditReportService from "../../../../../services/api/audit.report.service";
import { useSelector } from "react-redux";
import { IStateApp } from "../../../../../models/redux";
import { IGetReportsRes } from "../../../../../models/report";
import queryString from "query-string";
import { ISEOPagesItems } from "../../../../../models/seo-page";
import dataFilterCrawlPage from "./dataofpage";
import SelectCustom from "../../../../../components/SelectCustom";
import { checkStatusCode } from "../../../../../common/handles/common.handles";
import { EAuditErrorStatus } from "../../../../../models/common/models.enum";

const dataFastFilter: IValue[] = [
  { value: "all", title: "Tất cả" },
  { value: "title", title: "Title" },
  { value: "link", title: "Link" },
  { value: "h1", title: "H1" },
  { value: "h2", title: "H2" },
  { value: "metadescription", title: "Meta Description" },
  { value: "image", title: "Image" },
];

const DEFAULT_QUERY = {
  pageIndex: 1,
  pageSize: 10,
};

export default function CrawledPageTab() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const dataReport = useSelector<IStateApp, IGetReportsRes>(
    appState => appState.project?.dataReport
  );
  const location = useLocation();

  const [resData, setResData] = useState<ISEOPagesItems[]>();
  const [resDataPaging, setResDataPaging] = useState<
    IBasePagingRes<ISEOPagesItems>
  >({
    ...DEFAULT_BASE_PAGING_RES,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dataFilter: {
    startDate: string | null;
    tab: string | null;
    endDate: string | null;
    pageIndex: number;
    pageSize: number;
    keySearch: string | null;
    mFilter: string | null;
    sFilter: string[] | null;
  } = {
    tab: searchParams.get("tab"),
    startDate: searchParams.get("startDate"),
    endDate: searchParams.get("endDate"),
    pageIndex: Number(searchParams.get("pageIndex")) || 1,
    pageSize: Number(searchParams.get("pageSize")) || 20,
    keySearch: searchParams.get("keySearch"),
    mFilter: searchParams.get("mfilter"),
    sFilter: searchParams.getAll("sfilter"),
  };

  useEffect(() => {
    getDataOfPage();
  }, [location, dataReport.thirdPartyId]);

  const handleChangeParamUrl = (
    key: keyof any,
    value: string | string[] | null
  ) => {
    const queryParams: any = {
      ...DEFAULT_QUERY,
      ...dataFilter,
      pageIndex: key === "pageIndex" ? value : 1,
    };
    if (!value || (!!value && value.length <= 0) || value === "all") {
      delete queryParams[key];
    } else {
      if (queryParams.hasOwnProperty(key)) {
        if (key === "mFilter") {
          delete queryParams["sFilter"];
          document.dispatchEvent(new CustomEvent("reset-filter"));
          queryParams[key] = value;
        } else {
          queryParams[key] = value;
        }
      }
    }
    const removeEmptyQuery = Object.fromEntries(
      Object.entries(queryParams).filter(([_, v]) => v != null)
    );
    navigateParams(removeEmptyQuery);
  };

  const handleChangeDateRangeParamUrl = (
    startDate: Date | undefined,
    endDate: Date | undefined
  ) => {
    const filterParams = {
      ...DEFAULT_QUERY,
      ...dataFilter,
      startDate: !!startDate ? moment(startDate).format(DATE_REQ_FORMAT) : null,
      endDate: !!endDate ? moment(endDate).format(DATE_REQ_FORMAT) : null,
    };
    const removeEmptyQuery = Object.fromEntries(
      Object.entries(filterParams).filter(([_, v]) => v != null)
    );
    navigateParams(removeEmptyQuery);
  };

  const navigateParams = (value: { [k: string]: unknown }) => {
    if (!!dataReport.auditId) {
      const newParams = queryString.stringify(value);
      const path = generatePath(ROUTE_PATH.DOMAIN_AUDIT_DETAIL, {
        id: dataReport.auditId,
      });
      navigate({
        pathname: `${path}`,
        search: `${newParams.toLocaleLowerCase()}`,
      });
    }
  };

  const getDataOfPage = async () => {
    if (!!dataReport.thirdPartyId) {
      if (dataFilter.pageIndex <= 1) {
        setIsLoading(true);
      }
      let filter: {
        key: string;
        value: string;
        operator: string;
        type: string;
      }[] = [
        {
          key: "resource_type",
          value: "html",
          operator: "=",
          type: "field",
        },
      ];
      if (
        !!mainFilterSelectedData?.filter &&
        mainFilterSelectedData?.filter.length > 0 &&
        !dataFilter.sFilter
      ) {
        filter = mainFilterSelectedData?.filter;
      } else if (!!dataFilter.sFilter && dataFilter.sFilter.length > 0) {
        const childFilterFind = mainFilterSelectedData?.filterChild?.find(
          child => {
            if (!!dataFilter.sFilter) {
              return child.value === dataFilter.sFilter[0];
            }
          }
        );
        if (!!childFilterFind && !!childFilterFind.filter) {
          filter = childFilterFind.filter as any;
        }
      }
      const res = await AuditReportService.getErrorsFromPages({
        id: dataReport.thirdPartyId,
        filters: filter,
      });
      if (!res.isError && !!res.data.items && res.data.items.length > 0) {
        setResData(res.data.items);
        setResDataPaging({
          total: res.data.totalCount,
          page: 1,
          pageSize: 20,
          items: res.data.items.slice(0, 20),
          isFull: false,
        });
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    }
  };

  const headersTable: ITableHeader<any>[] = [
    {
      field: "url",
      title: `URL`,
      width: "244px",
      className: "table-cell-link",
      isSticky: true,
      handleItem: (item: any) => {
        return (
          <a
            className="fit-one-line"
            target="_blank"
            rel="noopener noreferrer"
            href={item.url}
          >
            {item.url}
          </a>
        );
      },
    },
    {
      field: "domain",
      title: `Indexable`,
      width: "120px",
      className: "table-cell-error-check",
      handleItem: (item: any) => {
        return (
          <div
            className={`cell-indexable ${!!item.indexable ? "success" : "error"}`}
          >
            <p>{!!item.indexable ? "Index" : "Noindex"}</p>
          </div>
        );
      },
    },
    {
      field: "domain",
      title: `Status`,
      width: "120px",
      className: "table-cell-error-check",
      handleItem: (item: any) => {
        return (
          <div
            className={`cell-status-code ${checkStatusCode(Number(item.status_code)).toString()}`}
          >
            <p>{item.status_code || "4xx"}</p>
          </div>
        );
      },
    },
    {
      field: "domain",
      title: `Size`,
      width: "120px",
      className: "table-cell-link",
    },
    {
      field: "domain",
      title: `Độ dài URL - ẩn`,
      width: "120px",
      className: "table-cell-link",
    },
    {
      field: "title",
      title: `Title`,
      width: "300px",
      handleItem: (item: any) => {
        return <p className="fit-one-line">{item.meta.title}</p>;
      },
    },
    {
      field: "domain",
      title: `Title Length`,
      width: "120px",
      handleItem: (item: any) => {
        return <p className="fit-one-line">{item.meta.title_length}</p>;
      },
    },
    {
      field: "h1 1",
      title: `H1 1`,
      width: "300px",
      handleItem: (item: any) => {
        return <p className="fit-one-line">{item.meta.htags.h1[0]}</p>;
      },
    },
    {
      field: "h1_1_length",
      title: `H1 1 Length`,
      width: "120px",
      handleItem: (item: any) => {
        return (
          <p className="fit-one-line">
            {item.meta.htags.h1[0]?.toString().length}
          </p>
        );
      },
    },
    {
      field: "h1 2",
      title: `H1 2`,
      width: "300px",
      handleItem: (item: any) => {
        return <p className="fit-one-line">{item.meta.htags.h1[1]}</p>;
      },
    },
    {
      field: "h1_2_length",
      title: `H1 2 Length`,
      width: "120px",
      handleItem: (item: any) => {
        return (
          <p className="fit-one-line">
            {item.meta.htags.h1[1]?.toString().length}
          </p>
        );
      },
    },
    {
      field: "h2",
      title: `H2`,
      width: "300px",
      handleItem: (item: any) => {
        return <p className="fit-one-line">{item.meta.htags.h2}</p>;
      },
    },
    {
      field: "h2_length",
      title: `H2 Length`,
      width: "120px",
      handleItem: (item: any) => {
        return (
          <p className="fit-one-line">
            {item.meta.htags.h2?.toString().length}
          </p>
        );
      },
    },
    {
      field: "meta_title",
      title: `Meta`,
      width: "300px",
      handleItem: (item: any) => {
        return <p className="fit-one-line">{item.meta.meta_title}</p>;
      },
    },
    {
      field: "domain",
      title: `Meta Length`,
      width: "120px",
      className: "table-cell-link",
    },
    {
      field: "click_depth",
      title: `Click depth`,
      width: "120px",
      handleItem: (item: any) => {
        return <p className="fit-one-line">{item.click_depth}</p>;
      },
    },
    {
      field: "domain",
      title: `Canonical link`,
      width: "120px",
      className: "table-cell-link",
    },
    {
      field: "domain",
      title: `Redirect URL`,
      width: "120px",
      className: "table-cell-link",
    },
    {
      field: "domain",
      title: `Redirect URL 2`,
      width: "120px",
      className: "table-cell-link",
    },
    {
      field: "domain",
      title: `Internal link`,
      width: "120px",
      className: "table-cell-button-modal",
      handleItem: (item: any) => {
        return <button className="button-open-modal">{"test"}</button>;
      },
    },
    {
      field: "domain",
      title: `External link`,
      width: "120px",
      className: "table-cell-link",
    },
    {
      field: "domain",
      title: `Word count`,
      width: "120px",
      className: "table-cell-link",
    },
    {
      field: "domain",
      title: `Thời gian (giây)`,
      width: "120px",
      className: "table-cell-link",
    },
    {
      field: "domain",
      title: `Text Ratio`,
      width: "120px",
      className: "table-cell-link",
    },
    {
      field: "domain",
      title: `Doc type`,
      width: "120px",
      className: "table-cell-link",
    },
    {
      field: "domain",
      title: `Spelling Errors`,
      width: "120px",
      className: "table-cell-link",
    },
    {
      field: "domain",
      title: `Reabitity`,
      width: "120px",
      className: "table-cell-link",
    },
    {
      field: "domain",
      title: `Href lang URL`,
      width: "120px",
      className: "table-cell-link",
    },
    {
      field: "domain",
      title: `Href lang URL 2`,
      width: "120px",
      className: "table-cell-link",
    },
    {
      field: "domain",
      title: `Loại Schema`,
      width: "120px",
      className: "table-cell-link",
    },
    {
      field: "domain",
      title: `Image count`,
      width: "120px",
      className: "table-cell-link",
    },
    {
      field: "domain",
      title: `From URL`,
      width: "120px",
      className: "table-cell-link",
    },
  ];

  const mainFilterData: IValue[] = dataFilterCrawlPage.map(item => {
    return {
      title: item.title,
      value: item.value,
    };
  });

  const mainFilterSelectedData = dataFilterCrawlPage.find(
    item => item.value === dataFilter.mFilter
  );
  let subFilter;
  if (mainFilterSelectedData?.filterChild) {
    subFilter = mainFilterSelectedData?.filterChild.map(item => {
      return {
        title: item.title,
        value: item.value,
      };
    });
  }

  return (
    <CrawledPageDomainAuditWrapper>
      <div className="crawled-filter-wrapper">
        <div className="filter-checkbox">
          <p className="select-filter-text">Hiển thị theo</p>
          <SelectCustom
            options={[
              { title: "Crawled link", value: "all" },
              ...mainFilterData,
            ]}
            onChange={event => {
              handleChangeParamUrl("mFilter", event.target.value);
            }}
            placeholder="Chọn loại"
            isSelectedIcon
            value={dataFilter.mFilter || "all"}
            className="filter-mfilter"
            fullWidth={false}
          />
          {!!subFilter && (
            <>
              {mainFilterSelectedData?.isMultiple ? (
                <FilterCheckBoxCustom
                  options={subFilter}
                  onChange={v => handleChangeParamUrl("sFilter", v)}
                  placeholder="Bộ lọc"
                  valueProps={dataFilter.sFilter || []}
                  name={""}
                  filterText="Bộ lọc: "
                  isHaveAll
                />
              ) : (
                <SelectCustom
                  options={subFilter}
                  onChange={event => {
                    handleChangeParamUrl("sFilter", event.target.value);
                  }}
                  placeholder="Bộ lọc chi tiết"
                  isSelectedIcon
                  value={!!dataFilter.sFilter ? dataFilter.sFilter[0] : ""}
                  fullWidth={false}
                  className="filter-sfilter"
                  isHasClear
                />
              )}
            </>
          )}
        </div>
        <DateRangePickerPopover
          onDataChange={v => {
            handleChangeDateRangeParamUrl(v.startDate, v.endDate);
          }}
          maxDate={Date.now()}
          value={{
            startDate: dataFilter.startDate
              ? new Date(dataFilter.startDate)
              : undefined,
            endDate: dataFilter.endDate
              ? new Date(dataFilter.endDate)
              : undefined,
          }}
        />
      </div>
      <FastFilterList
        selectedValue={dataFilter.mFilter || "all"}
        onChange={(v: string | null) => {
          handleChangeParamUrl("mFilter", v);
        }}
        data={dataFastFilter}
        isClearable
      />
      <TableNavDetailNoPagination
        data={resDataPaging}
        headersTable={
          !!mainFilterSelectedData && !!mainFilterSelectedData.headerData
            ? mainFilterSelectedData.headerData
            : headersTable
        }
        maxHeight={"calc(100vh - 302px)"}
        loading={isLoading}
        scrollbarSize="large"
      />
    </CrawledPageDomainAuditWrapper>
  );
}
