import { styled } from "@mui/styles";
import variableStyles from "../../../../../../../theme/variable-styles";

export const CrawlDataRenderBoxWrapper = styled("div")(() => ({
  margin: "10px 12px 12px",
  "& .common-text": {
    fontWeight: variableStyles.fwMedium,
    fontSize: "12px",
    lineHeight: "18px",
    color: variableStyles.NaturalColor1000,
  },
  "& .children-wrapper": {
    marginTop: "12px",
  },
  "& .table-wrapper": {
    margin: "0",
  },
}));
