import { Button } from "@mui/material";
import { useState } from "react";
import { Helmet } from "react-helmet";
import CommonHandle, { b64toBlob } from "../../common/handles/common.handles";
import { DragAndDropImage, ModalPreviewPhoto, Title } from "../../components";
import { toggleConfirmModal } from "../../components/ConfirmModal/ConfirmModal";
import { toggleMessage } from "../../components/Toast/Toast";
import CompressPhotoService from "../../services/api/compress.photo.service";
import PhotoBox from "./components/PhotoBox";
import { CompressPhotoWrapper } from "./styles";

export interface IPhotoShow {
  error: string | null;
  src: string;
  data: File;
  oldSize: number | null;
  newSize?: number | null;
  percent?: number;
  name: string;
}

export default function CompressPhoto() {
  const [photoError, setPhotoError] = useState<IPhotoShow[] | null>(null);
  const [photoCompress, setPhotoCompress] = useState<IPhotoShow[] | null>(null);
  const [photoPreview, setPhotoPreview] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dataForApi, setDataForApi] = useState<
    { data: any; error: string | null }[] | null
  >(null);
  const [fileDownloaded, setFileDownloaded] = useState<string[]>();

  const onDeleteImagesConfirm = () => {
    toggleConfirmModal({
      open: true,
      title: "Xoá tất cả ảnh",
      content: `Bạn có chắc chắn muốn xoá tất cả ảnh không?`,
      onSubmit: async () => {
        setPhotoCompress(null);
        setPhotoError(null);
        setFileDownloaded(undefined);
        setDataForApi(null);
      },
    });
  };

  const onDeleteImage = (v: string) => {
    const checkData = photoCompress?.findIndex(item => item.data.name === v);
    if (checkData !== -1 && !!photoCompress) {
      const removePhoto = photoCompress.filter(item => item.data.name !== v);
      setPhotoCompress(removePhoto);
    } else if (!!photoError && photoError.length > 0) {
      const removePhoto = photoError.filter(item => item.data.name !== v);
      setPhotoError(removePhoto);
    }
  };

  const onSubmit = async (
    value: {
      data: any;
      error: string | null;
    }[]
  ) => {
    if (value.length > 0) {
      setIsLoading(true);
      let dataApi: {
        data: any;
        error: string | null;
      }[] = [];

      let dataCompressState: IPhotoShow[] = [];

      if (!!photoCompress && photoCompress.length > 0) {
        const valueFilter = value.filter(item => !item.error);
        const mergeArray = [...(photoError || []), ...(photoCompress || [])];

        const removeDuplicate = valueFilter.filter(function (objFromA) {
          return !mergeArray.find(function (objFromB) {
            return objFromA.data.name === objFromB.data.name;
          });
        });
        if (removeDuplicate.length > 0) {
          dataApi = removeDuplicate;
          const dataNoDuplicateState = removeDuplicate.map(item => ({
            ...item,
            src: URL.createObjectURL(item.data),
            oldSize: Number(
              item.data.size < 100000
                ? (item.data.size / 1024).toFixed(2)
                : (item.data.size / 1024 / 1024).toFixed(2)
            ),
            newSize: null,
            name: item.data.name,
          }));

          dataCompressState = [...photoCompress, ...dataNoDuplicateState];
        }
        if (removeDuplicate.length !== valueFilter.length) {
          toggleMessage({
            type: "info",
            message: "1 hoặc 1 vài hình ảnh đã tồn tại!",
            title: "Ảnh đã tồn tại",
          });
        }
      } else {
        const valueFilter = value.filter(item => !item.error);
        dataApi = valueFilter;
        dataCompressState = valueFilter.map(item => ({
          ...item,
          src: URL.createObjectURL(item.data),
          oldSize: Number(
            item.data.size < 100000
              ? (item.data.size / 1024).toFixed(2)
              : (item.data.size / 1024 / 1024).toFixed(2)
          ),
          newSize: null,
          name: item.data.name,
        }));
      }

      if (!!photoError && photoError.length > 0) {
        const valueErrorFilter = value.filter(item => !!item.error);
        const removeDuplicate = valueErrorFilter.filter(function (objFromA) {
          return !photoError.find(function (objFromB) {
            return objFromA.data.name === objFromB.data.name;
          });
        });
        if (removeDuplicate.length > 0) {
          const mergeArray: IPhotoShow[] = [
            ...removeDuplicate.map(item => ({
              ...item,
              src: URL.createObjectURL(item.data),
              oldSize: Number(
                item.data.size < 100000
                  ? (item.data.size / 1024).toFixed(2)
                  : (item.data.size / 1024 / 1024).toFixed(2)
              ),
              newSize: null,
              name: item.data.name,
            })),
            ...photoError,
          ];
          setPhotoError(mergeArray);
        }
        if (removeDuplicate.length !== valueErrorFilter.length) {
          toggleMessage({
            type: "info",
            message: "1 hoặc 1 vài hình ảnh đã tồn tại!",
            title: "Ảnh đã tồn tại",
          });
        }
      } else {
        const valueErrorFilter = value
          .filter(item => !!item.error)
          .map(item => ({
            ...item,
            src: URL.createObjectURL(item.data),
            oldSize: Number(
              item.data.size < 100000
                ? (item.data.size / 1024).toFixed(2)
                : (item.data.size / 1024 / 1024).toFixed(2)
            ),
            newSize: null,
            name: item.data.name,
          }));
        setPhotoError(valueErrorFilter);
      }

      if (dataCompressState.length > 0) {
        setPhotoCompress(dataCompressState);
      }

      const dataReq = new FormData();
      if (dataApi.length > 0) {
        setDataForApi(dataApi);
        dataApi?.forEach(item => {
          dataReq.append("file", item.data);
        });

        const res = await CompressPhotoService.compressPhoto(dataReq);
        if (res.success) {
          let merged: IPhotoShow[] = [];

          const dataCompressCurrent = dataCompressState.map(item => ({
            ...item,
            name: item.data.name,
          }));
          const dataBlob: any[] = res.data.map(item => {
            return {
              ...item,
              newFile: b64toBlob(
                item?.newFile.fileContent,
                item?.newFile.fileName
              ),
              oldFile: b64toBlob(
                item?.oldFile.fileContent,
                item?.oldFile.fileName
              ),
            };
          });

          for (let i = 0; i < dataCompressCurrent.length; i++) {
            const matchPhoto = dataBlob.find(
              itmInner => itmInner?.newFile.name === dataCompressCurrent[i].name
            );
            if (!!matchPhoto) {
              merged.push({
                ...dataCompressCurrent[i],
                data: matchPhoto?.newFile,
                src: URL.createObjectURL(matchPhoto?.newFile),
                oldSize: Number(
                  matchPhoto.oldFile.size < 100000
                    ? (matchPhoto.oldFile.size / 1024).toFixed(2)
                    : (matchPhoto.oldFile.size / 1024 / 1024).toFixed(2)
                ),
                newSize: Number(
                  matchPhoto.newFile.size < 100000
                    ? (matchPhoto.newFile.size / 1024).toFixed(2)
                    : (matchPhoto.newFile.size / 1024 / 1024).toFixed(2)
                ),
                percent: (matchPhoto?.reductionPercentage).toFixed(1),
              });
            }
          }
          if (!!photoCompress && photoCompress?.length > 0) {
            const c = photoCompress.filter(function (objFromA) {
              return !merged.find(function (objFromB) {
                return objFromA.name === objFromB.name;
              });
            });
            setPhotoCompress([...c, ...merged]);
          } else {
            setPhotoCompress(merged);
          }
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    }
  };

  const onDownloadZip = async () => {
    if (photoCompress?.length === 1) {
      setFileDownloaded(prev => {
        if (!!prev) {
          if (!prev.includes(photoCompress[0].name)) {
            return [...prev, photoCompress[0].name];
          }
        } else {
          return [photoCompress[0].name];
        }
      });
      CommonHandle.startDownloadBlob(
        photoCompress[0].data,
        photoCompress[0].name
      );
    } else {
      const dataZip = photoCompress
        ?.filter(item => item.newSize !== null)
        ?.map(item => item.data);
      if (dataZip) {
        CommonHandle.onDownloadZip(dataZip);
        setFileDownloaded(prev => {
          const dataState = photoCompress?.map(item => item.name);
          if (!!prev) {
            const uniqueArray = [...prev, ...(dataState || [])].filter(
              function (item, pos) {
                return [...prev, ...(dataState || [])].indexOf(item) === pos;
              }
            );
            return uniqueArray;
          } else {
            return [...(dataState || [])];
          }
        });
      }
    }
  };

  return (
    <>
      <ModalPreviewPhoto
        open={!!photoPreview}
        src={photoPreview || ""}
        onClose={() => setPhotoPreview(undefined)}
      />
      <CompressPhotoWrapper>
        <Helmet>
          <title>Compress Photo</title>
        </Helmet>
        <Title
          highlightTitle="Compress"
          normalTitle="photo"
          children={
            <p>
              Nén ảnh <span>JPG</span>, <span>PNG</span>, <span>SVG</span> hoặc{" "}
              <span>GIF</span> với chất lượng nén tốt nhất.
              <br /> Giảm kích thước nhiều ảnh cùng lúc. Tối đa 5mb/ảnh
            </p>
          }
          marginBottom={24}
          isCenter
          className="photo-compress-title"
        />
        <DragAndDropImage
          title="Kéo tệp ảnh vào đây"
          classname="upload-files"
          onUploaded={onSubmit}
          disabled={isLoading}
        />
        {((!!photoError && photoError.length > 0) ||
          (!!photoCompress && photoCompress.length > 0)) && (
          <div className="photo-box-wrapper">
            <p className="total-photo">
              Đã tải lên{" "}
              <span>
                {(photoCompress?.length || 0) + (photoError?.length || 0)} ảnh
              </span>
            </p>
            <ul className="photo-box-list scrollbar-small">
              {!!photoCompress &&
                photoCompress.map(photo => (
                  <li key={photo.src}>
                    <PhotoBox
                      data={photo}
                      onDelete={v => onDeleteImage(v)}
                      isLoading={
                        isLoading &&
                        !!dataForApi?.find(
                          item => item.data.name === photo.data.name
                        )
                      }
                      handlePreview={v => setPhotoPreview(v)}
                      onDownloadSuccess={v =>
                        setFileDownloaded(prev => {
                          if (prev) {
                            if (!prev.includes(v)) {
                              return [...prev, v];
                            }
                          } else {
                            setFileDownloaded([v]);
                          }
                        })
                      }
                      dataDownloaded={fileDownloaded}
                    />
                  </li>
                ))}
              {!!photoError &&
                photoError.map(photo => (
                  <li key={photo.src}>
                    <PhotoBox
                      data={photo}
                      onDelete={v => onDeleteImage(v)}
                      isLoading={false}
                      handlePreview={v => setPhotoPreview(v)}
                      onDownloadSuccess={() => {}}
                      dataDownloaded={fileDownloaded}
                    />
                  </li>
                ))}
            </ul>
            <div className="photo-box-actions">
              <Button
                size="large"
                variant="outlined"
                className={`cancel-button`}
                onClick={onDeleteImagesConfirm}
                disabled={isLoading}
              >
                Xóa tất cả
              </Button>
              <Button
                size="large"
                variant="contained"
                className={`submit-button`}
                onClick={onDownloadZip}
                disabled={
                  !(
                    !!photoCompress &&
                    photoCompress.some(
                      photoCompress => photoCompress.newSize !== null
                    )
                  )
                }
              >
                Tải tất cả{" "}
                {photoCompress?.filter(item => item.newSize !== null)?.length}{" "}
                ảnh
              </Button>
            </div>
          </div>
        )}
      </CompressPhotoWrapper>
    </>
  );
}
