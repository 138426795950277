import { styled } from "@mui/material/styles";
import variableStyles from "../../../../../../../../../theme/variable-styles";

export const AuditAccordionWrapper = styled("div")(() => ({
  backgroundColor: variableStyles.NaturalColor0,
  border: `1px solid ${variableStyles.NaturalColor200}`,
  boxShadow: "0px 0px 18.3px 0px #0000000A",
  borderRadius: "6px",
  "& .accordion-header-wrapper": {
    padding: "10px 12px",
    cursor: "pointer",
    "& .header-infor": {
      gap: "8px",
      "& .header-title": {
        color: variableStyles.NaturalColor1000,
        fontWeight: variableStyles.fwMedium,
        fontSize: "12px",
        lineHeight: "18px",
      },
    },
    "& .header-arrow": {
      transition: "all .2s linear",
      pointerEvents: "none",
    },
    "&.active": {
      "& .header-arrow": {
        transform: "rotate(-180deg)",
      },
    },
  },
  "& .collapse-wrapper": {
    margin: "10px 12px 12px",
    "& p, & a": {
      fontWeight: variableStyles.fwMedium,
      fontSize: "12px",
      lineHeight: "18px",
    },
    "& a": {
      color: variableStyles.blueSecondary700,
      cursor: "pointer",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    "& .accordion-description": {
      color: variableStyles.NaturalColor1000,
    },
    "& .accordion-stack-pack": {
      marginTop: "10px",
      gap: "8px",
      "& img": {
        flex: "0 0 auto",
      },

      "& .stack-pack-text": {
        color: variableStyles.NaturalColor1000,
      },
    },
    "& .accordion-warning-list": {
      "& p": {
        color: variableStyles.Warning600,
      },
    },
    "& .accordion-error": {
      "& p": {
        color: variableStyles.Error600,
      },
    },
    "& .accordion-detail-wrapper": {
      marginTop: "12px",
    },
  },
}));
