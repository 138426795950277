import { styled } from "@mui/material/styles";
import variableStyles from "../../../../theme/variable-styles";

export const CheckToolNavCardWrapper = styled("button")(() => ({
  width: "100%",
  height: "174px",
  padding: "20px",
  borderRadius: "12px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "flex-start",
  overflow: "hidden",
  textAlign: "left",
  "@keyframes growAnimation": {
    "0%": { transform: "translate(-50%, -50%) scale(1)" },
    "100%": {
      transform: "translate(-50%, -50%) scale(1.5)",
    },
  },
  "@keyframes fadeAnimation": {
    "0%": { transform: "translate(-50%, -50%) scale(1.5)" },
    "100%": {
      transform: "translate(-50%, -50%) scale(1)",
    },
  },
  "&:hover": {
    "& .card-icon-wrapper": {
      "& .card-icon": {
        "&::after": {
          animation: "growAnimation .4s forwards linear",
        },
        "& img": {
          transform: "rotate(15deg)",
        },
      },
    },
  },

  "& .card-icon-wrapper": {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    padding: "2px",
    "& .card-icon": {
      position: "relative",
      "&::before, &::after": {
        content: '""',
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: variableStyles.NaturalColor0,
        borderRadius: "100%",
      },
      "&::before": {
        width: "120px",
        height: "120px",
        opacity: ".3",
      },
      "&::after": {
        width: "120px",
        height: "120px",
        opacity: ".2",
        animation: "fadeAnimation .4s forwards linear",
      },
      "& img": {
        position: "relative",
        zIndex: "1",
        transform: "rotate(0deg)",
        transition: "all .4s linear",
      },
    },
  },

  "& p": {
    position: "relative",
    zIndex: "1",
    "&.card-title": {
      color: variableStyles.Info900,
      fontWeight: variableStyles.fwSemiBold,
      fontSize: "22px",
      lineHeight: "26.63px",
      marginBottom: "4px",
    },
    "&.card-description": {
      color: variableStyles.Info900,
      fontWeight: variableStyles.fwMedium,
      fontSize: "12px",
      lineHeight: "14.52px",
    },
  },
}));
