import React from "react";
import { Button } from "@mui/material";
import { ImageCustom } from "../..";
import IconSearch from "../../../assets/images/common/icon_search_line_blue.svg";
import IconAdd from "../../../assets/images/common/icon_plus_line_white.svg";
import { NoSearchDataBoxWrapper } from "./styles";

interface NoSearchDataBoxProps {
  inputSearch: string;
  openCreateModal: () => void;
  onClearSearch: () => void;
  openText: string;
}

export default function NoSearchDataBox(props: NoSearchDataBoxProps) {
  const { inputSearch, openCreateModal, onClearSearch, openText } = props;
  return (
    <NoSearchDataBoxWrapper>
      <div className="outline-nodata">
        <figure className="nodata-icon-wrapper">
          <ImageCustom
            src={IconSearch}
            alt="Icon-Search"
            width={"24px"}
            height={"24px"}
            className="nodata-icon-search"
          />
        </figure>
        <p className="nodata-title">Không tìm thấy dự án</p>
        {!!inputSearch && inputSearch.length > 0 && (
          <p className="nodata-description">
            Your search "{inputSearch}" did not match any vendors. Please try
            again or create add a new vendor.
          </p>
        )}

        <div className="no-data-action">
          <Button
            color="primary"
            size="xsmall"
            variant="outlined"
            onClick={onClearSearch}
            className="action-delete-search"
          >
            Xóa tìm kiếm
          </Button>
          <Button
            color="primary"
            size="xsmall"
            variant="contained"
            onClick={_ => openCreateModal()}
            className="add-create"
          >
            <ImageCustom
              src={IconAdd}
              alt="Icon-Add"
              width={"18px"}
              height={"18px"}
            />
            <p>{openText}</p>
          </Button>
        </div>
      </div>
    </NoSearchDataBoxWrapper>
  );
}
