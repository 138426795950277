import { styled } from "@mui/material/styles";
import variableStyles from "../../../../../../../theme/variable-styles";

export const TechnicalContentsWrapper = styled("div")(() => ({
  "& .technical-wrapper": {
    padding: "0 40px 24px",
    "& .SEO-subtitle": {
      marginBottom: "16px",
    },
    "& .technical-box": {
      border: `1px solid ${variableStyles.NaturalColor400}`,
      borderRadius: "12px",
      "& .technical-caption": {
        padding: "24px",
      },
      "& .technical-classify": {
        backgroundColor: variableStyles.NaturalColor50,
        borderTop: `1px solid ${variableStyles.NaturalColor200}`,
        padding: "24px",
        gap: "85px",
        borderRadius: "0 0 12px 12px",
        "& li": {
          width: "154px",
          gap: "8px",
          position: "relative",
          "&:not(:last-of-type)::after": {
            content: '""',
            width: "1px",
            height: "calc(100% - 8px)",
            backgroundColor: variableStyles.NaturalColor500,
            position: "absolute",
            right: "-43px",
            top: "50%",
            transform: "translateY(-50%)",
          },
          "& .classify-title": {
            gap: "4px",
            height: "26px",
            "& p": {
              color: variableStyles.NaturalColor1000,
              fontWeight: variableStyles.fwSemiBold,
              fontSize: "14px",
              lineHeight: "15px",
            },
          },
          "&:first-of-type": {
            "& .classify-scores": {
              color: variableStyles.Error600,
            },
          },
          "&:last-of-type": {
            "& .classify-scores": {
              color: variableStyles.Info500,
            },
          },
          "& .classify-scores": {
            fontWeight: variableStyles.fwSemiBold,
            fontSize: "24px",
            lineHeight: "49.05px",
            color: variableStyles.Warning600,
            backgroundColor: "transparent",
          },
        },
      },
    },
  },
  "& .tabs-wrapper": {
    padding: "40px",
    backgroundColor: variableStyles.NaturalColor50,
    borderTop: `1px solid ${variableStyles.NaturalColor200}`,
  },
}));
