import { styled } from "@mui/material/styles";
import variableStyles from "../../../../../../../../../theme/variable-styles";

export const MetricsItemWrapper = styled("div")(() => ({
  border: `1px solid ${variableStyles.NaturalColor300}`,
  borderRadius: "6px",
  backgroundColor: variableStyles.NaturalColor0,
  boxShadow: "0px 0px 18.3px 0px #0000000A",
  "& .performance-metrics-item-title": {
    padding: "11px 16px",
    backgroundColor: variableStyles.NaturalColor100,
    borderRadius: "6px 6px 0 0",
    borderBottom: `1px solid ${variableStyles.NaturalColor300}`,
    gap: "8px",
    "& .title-text": {
      color: variableStyles.NaturalColor950,
      fontWeight: variableStyles.fwMedium,
      fontSize: "12px",
      lineHeight: "14.52px",
      marginRight: "4px",
    },
  },
  "& .metrics-scores": {
    fontWeight: variableStyles.fwSemiBold,
    fontSize: "20px",
    lineHeight: "25.55px",
    padding: "13px",
    textAlign: "center",
  },
}));
