import { styled } from "@mui/material/styles";
import variableStyles from "../../../../theme/variable-styles";

export const KeyWordGroupDataGridWrapper = styled("div")(() => ({
  width: "100%",
  border: `1px solid ${variableStyles.NaturalColor300}`,
  borderRadius: "12px",
  overflow: "hidden",
  "& .datagrid-header": {
    backgroundColor: variableStyles.NaturalColor200,
    width: "100%",

    padding: "4px 12px",
    display: "flex",
    alignItems: "center",
    "& .header-text": {
      color: variableStyles.NaturalColor950,
      fontWeight: variableStyles.fwSemiBold,
      fontSize: "13px",
      lineHeight: "13px",
      padding: "10px 11px"
    },
    "& .header-name": {
      width: "100px",
      flex: "0 0 auto"
    },
    "& .header-keyword": { flex: "1 1 auto" },
    "& .header-volume": {
      maxWidth: "180px",
      flex: "1 1 auto",
      minWidth: "60px"
    },
    "& .button-expanded": {
      flex: "0 0 auto",
      padding: "2px",
      borderRadius: "50%",
      backgroundColor: variableStyles.NaturalColor0,
      transform: "rotate(180deg)",
      transition: "all 0.2s linear",
      "&.is-collapse": {
        transform: "rotate(0deg)"
      }
    }
  },
  "& .datagrid-list": {
    backgroundColor: variableStyles.NaturalColor50,
    "& .list-item": {
      display: "flex",
      alignItems: "center",
      padding: "4px 12px",
      "&:not(:last-of-type)": {
        borderBottom: `1px solid ${variableStyles.NaturalColor200}`
      },
      "& .item-text": {
        color: variableStyles.NaturalColor950,
        fontWeight: variableStyles.fwMedium,
        fontSize: "13px",
        lineHeight: "13px",
        padding: "10px"
      },
      "& .item-name": {
        width: "100px",
        flex: "0 0 auto"
      },
      "& .item-keyword": { flex: "1 1 auto" },
      "& .item-volume": {
        maxWidth: "202px",
        minWidth: "202px",
        flex: "1 1 auto",
        padding: "4px 34px 4px 12px"
      }
    }
  },
  
}));
