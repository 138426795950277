import { AlertColor } from "@mui/material";
import { useEffect, useState } from "react";
import { BehaviorSubject } from "rxjs";
import { SnackbarWrapper } from "./ToastStyles";
import IconInfor from "../../assets/images/common/icon_toast_blue_black.svg";
import IconWarning from "../../assets/images/common/icon_toast_yellow_black.svg";
import IconError from "../../assets/images/common/icon_toast_red_black.svg";
import IconSuccess from "../../assets/images/common/icon_toast_green_black.svg";
import { ImageCustom } from "..";

interface INotification {
  title?: string;
  message: string;
  type?: AlertColor;
}

//handle error
const handleErrorSubject = new BehaviorSubject<INotification | null>(null);

export const toggleMessage = (value: INotification) => {
  handleErrorSubject.next(value);
};

export default function Toast() {
  const [notify, setNotify] = useState<INotification & { isOpen: boolean }>({
    isOpen: false,
    title: "Saved Successfully",
    message: "Your changes have been saved successfully",
    type: "success",
  });

  useEffect(() => {
    const subscribe = handleErrorSubject.subscribe(noti => {
      if (noti && noti.message) {
        setNotify({
          isOpen: true,
          title: noti.title,
          message: noti.message,
          type: noti.type || "success",
        });
      }
    });
    return () => {
      subscribe.unsubscribe();
    };
  }, []);

  const handleClose = (event: any) => {
    handleErrorSubject.next(null);
    setNotify(pre => {
      return {
        ...pre,
        isOpen: false,
      };
    });
  };
  const renderIcon = () => {
    switch (notify.type) {
      case "error":
        return IconError;
      case "warning":
        return IconWarning;
      case "info":
        return IconInfor;
      default:
        return IconSuccess;
    }
  };

  const renderClassname = () => {
    switch (notify.type) {
      case "error":
        return "error";
      case "warning":
        return "warning";
      case "info":
        return "info";
      default:
        return "success";
    }
  };

  return (
    <SnackbarWrapper
      open={notify.isOpen}
      autoHideDuration={3000}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      onClose={handleClose}
      className={`${renderClassname()}`}
    >
      <div className="snackbar-wrapper">
        <figure className="toast-icon">
          <ImageCustom
            src={renderIcon()}
            alt="toast-icon"
            width={"32px"}
            height={"32px"}
          />
        </figure>
        <div className="toast-content">
          <p className="content-title">{notify.title}</p>
          <p className="content-detail">{notify.message}</p>
        </div>
      </div>
    </SnackbarWrapper>
  );
}
