import { Popover } from "@mui/material";
import { styled } from "@mui/material/styles";
import variableStyles from "../../theme/variable-styles";
import IconArrowFull from "../../assets/images/common/icon_arrow_full_line_blue.svg";

export const NumberRangePickerWrapper = styled("div")(({}) => ({
  "&.has-value": {
    "& .picker-button": {
      border: `1px solid ${variableStyles.NaturalColor900}`,
      "& .picker-placeholder": {
        color: variableStyles.NaturalColor950,
      },
    },
  },
  "& .picker-button": {
    minWidth: "100px",
    justifyContent: "space-between",
    gap: "14px",
    border: `1px solid ${variableStyles.NaturalColor300}`,
    "& .picker-placeholder": {
      color: variableStyles.NaturalColor700,
      fontWeight: variableStyles.fwMedium,
      fontSize: "13px",
      lineHeight: "18.2px",
    },
  },
}));

export const NumberRangePickerPopoverWrapper = styled(Popover)(({}) => ({
  "& .MuiPaper-root": {
    borderRadius: "12px",
    border: `1px solid ${variableStyles.NaturalColor100}`,
    boxShadow: "0px 5px 24px 0px #0000003D",
    padding: "12px",
    width: "248px",
    "& .popover-title": {
      color: variableStyles.NaturalColor950,
      fontWeight: variableStyles.fwMedium,
      fontSize: "14px",
      lineHeight: "19.6px",
      marginBottom: "16px",
    },
    "& .popover-input-wrapper": {
      gap: "46px",
      position: "relative",
      marginBottom: "33px",
      "&::after": {
        content: '""',
        position: "absolute",
        top: "50%",
        left: "50%",
        width: "22px",
        height: "10px",
        transform: "translate(-50%, -50%)",
        background: `url(${IconArrowFull})  center/contain no-repeat`,
      },
      "&::before": {
        content: '""',
        position: "absolute",
        bottom: "-17px",
        left: "0",
        width: "100%",
        height: "1px",
        background: variableStyles.NaturalColor200,
      },
      "& .MuiInputBase-root": {
        paddingLeft: "0",
      },
    },
    "& .popover-action": {
      "& .action-clear": {
        color: variableStyles.blueSecondary700,
        fontWeight: variableStyles.fwSemiBold,
        fontSize: "12px",
        lineHeight: "12px",
        textDecoration: "underline",
        backgroundColor: "transparent",
        "&:hover": {
          textDecoration: "none",
        },
      },
    },
  },
}));
