import queryString from "query-string";
import React, { useRef, useState } from "react";
import { IDataFilterBacklinkType } from "../../..";
import { DEFAULT_BASE_PAGING_RES } from "../../../../../../../common/constants/value.constant";
import {
  NumberRangePicker,
  TableNavDetailNoPagination,
} from "../../../../../../../components";
import InputSearch from "../../../../../../../components/controls/InputSearch";
import { ITableNavDetailNoPaginationHeader } from "../../../../../../../components/Table/TableNavDetailNoPagination";

interface BacklinkReferringDomainTabProps {
  onChangeParamsURL: (value: string) => void;
  dataFilter: IDataFilterBacklinkType;
}

export default function BacklinkReferringDomainTab(
  props: BacklinkReferringDomainTabProps
) {
  const { onChangeParamsURL, dataFilter } = props;

  const timerOut = useRef<number>();

  const [keySearchState, setKeySearchState] = useState<string | null>(null);

  const onChangeSearch = (e: any) => {
    const value = e?.target?.value;
    if (timerOut.current) {
      clearTimeout(timerOut.current);
    }
    timerOut.current = window.setTimeout(() => {
      handleChangeParams([
        { keyParams: "domainSearch", valueParams: value.toString() },
      ]);
    }, 700);
  };

  const handleChangeParams = (
    value: { keyParams: string; valueParams: string | null }[]
  ) => {
    const newData: any = { ...dataFilter };
    value.forEach(value => {
      if (
        !value.valueParams ||
        (!!value.valueParams && value.valueParams.length <= 0)
      ) {
        delete newData[value.keyParams];
      } else {
        if (newData.hasOwnProperty(value.keyParams)) {
          newData[value.keyParams] = value.valueParams;
        }
      }
    });

    for (const [key, valueFor] of Object.entries(
      newData as IDataFilterBacklinkType
    )) {
      if (!valueFor || (!!valueFor && valueFor.length <= 0)) {
        delete newData[key];
      }
    }
    const newParams = queryString.stringify(newData);
    onChangeParamsURL(newParams.toLocaleLowerCase());
  };

  const headersOrganicTable: ITableNavDetailNoPaginationHeader<any>[] = [
    {
      field: "organicTraffic",
      title: `Refering domain`,
      width: "260px",
    },
    {
      field: "organicTraffic",
      title: `Điểm uy tín`,
      width: "100px",
    },
    {
      field: "organicKeywords",
      title: `Backlink`,
      width: "120px",
    },
    {
      field: "commonKeywords",
      title: `First seen`,
      width: "140px",
    },
    {
      field: "commonKeywords",
      title: `Last seen`,
      width: "140px",
    },
  ];
  return (
    <div className="tab-common-wrapper">
      <div className="tab-header-filter flex-between-align-center">
        <div className="filter-left flex-start-align-center">
          <InputSearch
            placeholder={"Tìm kiếm"}
            onChange={(e: any) => {
              setKeySearchState(e.target.value);
              onChangeSearch(e);
            }}
            value={keySearchState}
            size={"medium"}
            width={"340px"}
            typeStyle="outlined"
          />
        </div>
        <div className="filter-right flex-end-align-center">
          <NumberRangePicker
            onSubmit={(value: { from: number | null; to: number | null }) => {
              handleChangeParams([
                {
                  keyParams: "reputationFrom",
                  valueParams: value.from?.toString() || "",
                },
                {
                  keyParams: "reputationTo",
                  valueParams: value.to?.toString() || "",
                },
              ]);
            }}
            onClear={() => {
              handleChangeParams([
                {
                  keyParams: "reputationFrom",
                  valueParams: "",
                },
                {
                  keyParams: "reputationTo",
                  valueParams: "",
                },
              ]);
            }}
            placeholder="Điểm uy tín"
            modalTitle="Điểm uy tín"
            value={{
              from: Number(dataFilter.reputationFrom),
              to: Number(dataFilter.reputationTo),
            }}
            isFormatNumber
            size="small"
          />
        </div>
      </div>
      <div className="overview-table">
        <TableNavDetailNoPagination
          headersTable={headersOrganicTable}
          data={{
            ...DEFAULT_BASE_PAGING_RES,
          }}
        />
      </div>
    </div>
  );
}
