import { Box } from "@mui/material";
import { styled } from "@mui/styles";
import variableStyles from "../../../../../../../theme/variable-styles";

export const PageSpeedOnDeviceWrapper = styled(Box)(() => ({
  background: variableStyles.NaturalColor50,

  "& .page-wrapper": {
    margin: "12px 0 2px",
    padding: "12px",
    boxShadow: "0px 1px 3px 1px #3C404326",
    borderRadius: "8px",
    "& .category-wrapper": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "20px",
      marginBottom: "10px",
    },
    "& .performance-wrapper": {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      borderRadius: "8px",
      border: `1px solid ${variableStyles.NaturalColor400}`,
      overflow: "hidden",
      "& .performance-left-content, & .performance-right-content": {
        flex: "1 1 auto",
      },
      "@media (min-width:1200px)": {
        alignItems: "stretch",
      },
      "@media (max-width:1199.98px)": {
        flexDirection: "column",
        alignItems: "center",
      },
      "& .performance-left-content": {
        flex: "1 1 auto",
        padding: "45.5px 38px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        marginTop: "-1px",
        "& .description": {
          marginTop: 24,
          textAlign: "center",
          color: variableStyles.NaturalColor1000,
          fontWeight: variableStyles.fwMedium,
          fontSize: "11px",
          lineHeight: "16.5px",
          "& a": {
            color: variableStyles.blueSecondary700,
            "&:hover": {
              textDecoration: "underline",
            },
          },
        },
        "& .point-wrapper": {
          marginTop: "57px",
          gap: "12px",
          flexWrap: "wrap",
          "& .point-range-item": {
            gap: "8px",
            backgroundColor: variableStyles.NaturalColor100,
            border: `1px solid ${variableStyles.NaturalColor400}`,
            padding: "3px 8px",
            borderRadius: "50px",
            minWidth: "max-content",
            "& .point-range-number": {
              color: variableStyles.NaturalColor950,
              fontWeight: variableStyles.fwMedium,
              fontSize: "12px",
              lineHeight: "12px",
              marginBottom: "-1px",
            },
          },
        },
      },
      "& .performance-right-content": {
        borderRadius: "0 8px 8px 0",
        background: "linear-gradient(180deg, #FFFFFF 0%, #C1FFE9 122.79%)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "33.5px 20px",
        marginTop: "-1px",
      },
    },
    "& .metrics-list-wrapper": {
      display: "grid",
      gap: "8px",
      marginTop: "16px",
      "@media (min-width:1200px)": { gridTemplateColumns: "1fr 1fr 1fr" },
      "@media (max-width:1199.98px) and (min-width:900px)": {
        gridTemplateColumns: "1fr 1fr",
      },
      "@media (max-width:899.98px)": {
        gridTemplateColumns: "1fr",
      },
      "& li": {
        maxWidth: "100%",
      },
    },
    "& .performance-treemap-wrapper": {
      marginTop: "24px",
      marginBottom: "24px",
    },
    "& .group-audit-wrapper": {
      marginTop: "24px",
      "& .group-audit-title": { marginBottom: "16px" },
      "& .audit-accordion-list": {
        display: "flex",
        flexDirection: "column",
        gap: "4px",
      },
      "&.group-audit-other-wrapper": {
        backgroundColor: variableStyles.NaturalColor0,
        border: `1px solid ${variableStyles.NaturalColor200}`,
        boxShadow: "0px 0px 18.3px 0px #0000000A",
        borderRadius: "6px",
        cursor: "pointer",
        "& .group-audit-title": {
          margin: 0,
          padding: "10px 12px",
          "& .header-arrow": {
            transition: "all .2s linear",
            "&.active": {
              transform: "rotate(-180deg)",
            },
          },
        },
        "& .audit-accordion-list": {
          marginTop: "12px",
          padding: "0 12px 10px",
        },
      },
    },
    "& .orther-category-wrapper": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      overflow: "hidden",
      "& .orther-category-content": {
        width: "80%",
        padding: "33.5px 38px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",

        "& .description": {
          marginTop: 24,
          textAlign: "center",
          color: variableStyles.NaturalColor1000,
          fontWeight: variableStyles.fwMedium,
          fontSize: "11px",
          lineHeight: "16.5px",
          "& a": {
            color: variableStyles.blueSecondary700,
            "&:hover": {
              textDecoration: "underline",
            },
          },
        },
      },
    },
    "& .icon-overview-audit": {
      flex: " 0 0 auto",
    },
    "& .collapse-pagespeed-wrapper": {
      "& .pagespeed-error-list": {
        display: "flex",
        flexDirection: "column",
        gap: "6px",
        padding: "0 10px 10px",
      },
      "& .collapse-pagespeed": {
        padding: "0 10px",
      },
      "& .accordion-description": { padding: "0 0 10px" },
    },
    "& .error-solution-box": {
      display: "flex",
      flexDirection: "column",
      gap: "6px",
    },
  },
}));
