import { styled } from "@mui/material/styles";

export const StarRatingWrapper = styled("div")(({}) => ({
  display: "inline-flex",
  position: "relative",
  cursor: "pointer",
  textAlign: "left",
  gap: "1px",
  "&.disabled": {
    pointerEvents: "none",
  },
  "& .star-list": {
    position: "relative",
    cursor: "pointer",
  },
}));
