import { styled } from "@mui/material/styles";
import variableStyles from "../../theme/variable-styles";

export const OutlineWrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  minHeight: "100%",
  padding: "24px",
  position: "relative",
  "& .outline-header": {
    marginBottom: "24px",
    display: "flex",
    justifyContent: "space-between",
    "& .title-text": {
      margin: "0"
    },
    "& .header-action": {
      display: "flex",
      gap: "12px",
      "& .add-button": {
        gap: "6px"
      }
    }
  },
  "& .loading-indicator": {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)"
  }
}));
