import React from "react";
import { TitleWrapper } from "./styles";

interface TitleProps {
  normalTitle?: string;
  highlightTitle?: string;
  children?: React.ReactNode;
  isCenter?: boolean;
  className?: string;
  marginBottom?: number;
}

export default function Title(props: TitleProps) {
  const {
    normalTitle,
    highlightTitle,
    children,
    isCenter = false,
    className = "",
    marginBottom
  } = props;
  return (
    <TitleWrapper
      className={`${className} ${isCenter ? "is-center" : ""}`}
      marginbottom={marginBottom}
    >
      <p className="title-text fit-one-line">
        <span>{!!highlightTitle && highlightTitle}</span>{" "}
        {!!normalTitle && normalTitle}
      </p>
      {!!children && <div className="children-wrapper">{children}</div>}
    </TitleWrapper>
  );
}
