import { Button, ClickAwayListener, PopoverOrigin } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ImageCustom } from "..";
import { IValue } from "../../models/common/models.type";
import { FilterSelectCustomWrapper, PopoverWrapper } from "./styles";
import IconArrow from "../../assets/images/common/icon_arrow_down_line_black.svg";
import IconCheck from "../../assets/images/common/icon_check_line_green.svg";

interface SelectCustomProps {
  data: IValue[];
  onChange: (value: string | number) => void;
  placeholder: string;
  name: string;
  errors?: any;
  classname?: string;
  onFocus?: () => void;
  defaultValue?: string | number;
  popoverWidth?: number;
  endIcon?: string;
  value: string | number;
  disabled?: boolean;
  anchorOrigin?: PopoverOrigin;
  filterText: string;
}

export default function Select(props: SelectCustomProps) {
  const {
    onChange,
    data,
    placeholder,
    errors,
    name = "",
    classname = "",
    onFocus,
    defaultValue = "",
    popoverWidth,
    endIcon,
    value,
    disabled,
    anchorOrigin,
    filterText,
  } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const boxRef = React.useRef<any>();

  useEffect(() => {
    if (typeof defaultValue === "string") {
      if (!value && defaultValue && defaultValue.length > 0) {
        onChange(defaultValue);
      }
    } else if (typeof defaultValue === "number") {
      if (!value && defaultValue !== undefined) {
        onChange(defaultValue);
      }
    }
  }, [defaultValue]);

  useEffect(() => {
    if (typeof value === "string") {
      if (value != null && value != undefined) {
        onChange(value);
      }
    } else if (typeof value === "number") {
      if (value !== undefined) {
        onChange(value);
      }
    }
  }, [value]);

  const onClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (valueData: string) => {
    if (valueData !== value) {
      onChange(valueData);
      onClose();
    }
  };

  const onOpenSelect = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (!disabled) {
      setAnchorEl(event.currentTarget);
    }
  };

  const renderText = (valueData: any) => {
    const result = data.find(data => data.value === valueData);
    return result?.title;
  };

  return (
    <FilterSelectCustomWrapper>
      <PopoverWrapper
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={
          anchorOrigin
            ? anchorOrigin
            : {
                vertical: "bottom",
                horizontal: "left",
              }
        }
        boxref={boxRef}
        popover_width={popoverWidth}
      >
        <ClickAwayListener onClickAway={() => onClose()}>
          <ul className="select-list scrollbar-small">
            {data.map(item => (
              <li
                key={item.value}
                className={`${value === item.value ? "active" : ""}`}
              >
                <Button onClick={() => handleClick(item.value)}>
                  <p>{item.title}</p>
                  {value === item.value && (
                    <figure className="active-icon">
                      <ImageCustom
                        src={IconCheck}
                        alt="Icon-Check"
                        width={"18px"}
                        height={"18px"}
                        className={`filter-arrow ${!!anchorEl ? "active" : ""}`}
                      />
                    </figure>
                  )}
                </Button>
              </li>
            ))}
          </ul>
        </ClickAwayListener>
      </PopoverWrapper>
      <div
        onFocus={onFocus}
        className={` ${
          errors && !!errors[name] ? "select-box-error" : ""
        }  ${classname} filter-select-wrapper ${!!anchorEl ? "active" : ""}`}
        onClick={e => onOpenSelect(e)}
        ref={boxRef}
      >
        <p className="filter-text">{filterText}</p>
        {typeof value === "string" && (
          <>
            {value !== undefined && value.length > 0 ? (
              <p className="select-selected-text">{renderText(value)}</p>
            ) : (
              <p className="select-placeholder">{placeholder}</p>
            )}
          </>
        )}
        {typeof value === "number" && (
          <>
            {value !== undefined ? (
              <p className="select-selected-text">{renderText(value)}</p>
            ) : (
              <p className="select-placeholder">{placeholder}</p>
            )}
          </>
        )}
        <ImageCustom
          src={IconArrow}
          alt="Icon-Arrow"
          width={"20px"}
          height={"20px"}
          className={`filter-arrow ${!!anchorEl ? "active" : ""}`}
        />
      </div>
      {errors && errors[name]?.message && (
        <p className="error-text">{errors[name]?.message}</p>
      )}
    </FilterSelectCustomWrapper>
  );
}
