import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Popover,
  SxProps,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { ArrowDropDown, ArrowDropUp, FilterAlt } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { IBasePagingRes } from "../../../models/common/models.type";
import { OrderDirection } from "../../../models/common/models.enum";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import variableStyles from "../../../theme/variable-styles";
import { theme } from "../../../theme/theme";
import { TableBoxWrapper, TableWrapper } from "./styles";
import LoadingIndicator from "../../LoadingIndicator";
import { ImageCustom } from "../..";
import IconLoad from "../../../assets/images/common/icon_refresh_line_black.svg";
import GridPagination from "./GridPagination";

export interface ITableHeader<T> {
  field?: keyof T;
  title?: string;
  handleItem?: (item: T) => React.ReactNode;
  isHide?: boolean;
  align?: "left" | "right" | "center";
  notFilter?: boolean;
  width?: number | string;
  sx?: SxProps<Theme>;
  className?: string;
}

export interface IDataActionType {
  text: string;
  icon?: React.ReactNode;
  key: string;
}

export interface ITableData<T> {
  data: IBasePagingRes<T>;
  headersTable: ITableHeader<T>[];
  handleChangeSearchParams?: (
    field: "pageSize" | "pageIndex",
    value: string | Date | number
  ) => void;
  handleChangeSelectItems?: (value: number[]) => void;
  sortNames?: string[];
  sortDirections?: OrderDirection[];
  handleSort?: (sortNames: string[], sortDirections: OrderDirection[]) => void;
  minWidth?: number | string;
  single?: boolean;
  footer?: boolean;
  isLoadmore?: boolean;
  loading?: boolean;
  keyField?: string;
  dataAction?: (item: T) => IDataActionType[];
  handleAction?: (key: string, item: T) => void;
  handleLoadMore?: () => void;
}

export const TableData = (props: ITableData<any>) => {
  const { t } = useTranslation();
  const {
    loading,
    data,
    headersTable,
    handleChangeSearchParams,
    handleChangeSelectItems,
    sortNames,
    sortDirections,
    handleSort,
    minWidth = 1050,
    single = false,
    footer = true,
    keyField = "id",
    dataAction,
    handleAction,
    isLoadmore,
    handleLoadMore,
  } = props;

  const [anchorEl, setAnchorEl] = useState<any>();
  const [selected, setSelected] = useState<any>(null);
  const [options, setOptions] = useState<IDataActionType[]>([]);

  const [selectedItems, setSelectedItems] = useState<number[]>([]);

  const renderIcon = (field: string) => {
    let icon = <FilterAlt className={"icon"} />;

    const index = sortNames?.findIndex(item => item === field) ?? -1;

    if (index > -1) {
      const sortType = sortDirections ? sortDirections[index] : null;

      if (sortType === OrderDirection.ASC) {
        icon = <ArrowDropUp className={"icon"} />;
      }
      if (sortType === OrderDirection.DESC) {
        icon = <ArrowDropDown className={"icon"} />;
      }
    }

    return icon;
  };

  const handleSortTable = (field: string) => {
    const index = sortNames?.findIndex(item => item === field) ?? -1;

    let newSortNames: string[] = sortNames ?? [];
    let newSortDirections: OrderDirection[] = sortDirections ?? [];

    if (index > -1) {
      const sortType = newSortDirections[index];

      if (sortType === OrderDirection.DESC) {
        newSortDirections[index] = OrderDirection.ASC;
      }

      if (sortType === OrderDirection.ASC) {
        newSortNames = newSortNames.filter((_, i) => i !== index);
        newSortDirections = newSortDirections.filter((_, i) => i !== index);
      }
    } else {
      newSortNames.push(field);
      newSortDirections.push(OrderDirection.DESC);
    }

    handleSort && handleSort(newSortNames, newSortDirections);
  };

  const handleSelectAll = (event: any) => {
    let newSelectedItems: number[] = [];

    if (event.target.checked) {
      newSelectedItems = data.items.map((customer: any) => customer.id);
    } else {
      newSelectedItems = [];
    }

    setSelectedItems(newSelectedItems);
    handleChangeSelectItems && handleChangeSelectItems(newSelectedItems);
  };

  const handleSelectOne = (event: any, id: number) => {
    const selectedIndex = selectedItems.indexOf(id);
    let newSelectedItems: number[] = [];

    if (selectedIndex === -1) {
      newSelectedItems = newSelectedItems.concat(selectedItems, id);
    } else if (selectedIndex === 0) {
      newSelectedItems = newSelectedItems.concat(selectedItems.slice(1));
    } else if (selectedIndex === selectedItems.length - 1) {
      newSelectedItems = newSelectedItems.concat(selectedItems.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedItems = newSelectedItems.concat(
        selectedItems.slice(0, selectedIndex),
        selectedItems.slice(selectedIndex + 1)
      );
    }

    setSelectedItems(newSelectedItems);
    handleChangeSelectItems && handleChangeSelectItems(newSelectedItems);
  };
  const handleLimitChange = (newPageSize: number) => {
    handleChangeSearchParams &&
      handleChangeSearchParams("pageSize", newPageSize);
  };
  const handlePageChange = (newPage: number) => {
    handleChangeSearchParams && handleChangeSearchParams("pageIndex", newPage);
  };

  const onOpenOptions = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: any
  ) => {
    if (dataAction) {
      setAnchorEl(e.currentTarget);
      setSelected(item);
      setOptions(dataAction(item));
    }
  };

  const onClickAction = (key: string) => {
    setAnchorEl(undefined);
    handleAction && handleAction(key, selected);
  };

  const rowsTable: ITableHeader<any>[] = [...headersTable];
  if (dataAction && handleAction) {
    rowsTable.push({
      title: "",
      isHide: true,
      width: 55,
      align: "right",
      handleItem: item => {
        return (
          <IconButton
            onClick={e => onOpenOptions(e, item)}
            className="button-action"
          >
            <MoreHorizIcon width={4} height={16} className="icon-deactive" />
          </IconButton>
        );
      },
    });
  }

  return (
    <TableBoxWrapper>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(undefined)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        slotProps={{
          paper: {
            sx: {
              minWidth: "183px",
              borderRadius: "8px",
              boxShadow: "0px 5px 16px 0px #0000001F",
              "& .action-list": {
                "& .MuiButtonBase-root": {
                  gap: "10px",
                  padding: "12px",
                  width: "100%",
                  justifyContent: "flex-start",
                  "&:hover": {
                    backgroundColor: theme.palette.primary.main,
                    "& .item-text": {
                      color: "#fff",
                    },
                  },
                  "& .item-text": {
                    fontWeight: variableStyles.fwMedium,
                    fontSize: "14px",
                    lineHeight: "19.6px",
                    color: theme.palette.text.primary,
                    transition: "all .25s linear",
                  },
                },
              },
            },
          },
        }}
      >
        <ul className="action-list">
          {options.map((action, index) => (
            <li className="action-item" key={index}>
              <Button onClick={() => onClickAction(action.key)}>
                {!!action.icon && action.icon}
                <p className="item-text">{action.text}</p>
              </Button>
            </li>
          ))}
        </ul>
      </Popover>
      <PerfectScrollbar>
        <Box sx={{ minWidth: minWidth }}>
          {loading ? (
            <LoadingIndicator />
          ) : (
            <TableWrapper>
              <TableHead>
                <TableRow>
                  {!single && (
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedItems.length === data.items.length}
                        color="primary"
                        indeterminate={
                          selectedItems.length > 0 &&
                          selectedItems.length < data.items.length
                        }
                        onChange={handleSelectAll}
                      />
                    </TableCell>
                  )}

                  {rowsTable.map((item, key) => {
                    if (item.isHide) {
                      return (
                        <TableCell
                          key={key}
                          sx={{
                            ...item.sx,
                            width: item.width,
                          }}
                          className={item.className}
                        ></TableCell>
                      );
                    } else {
                      return (
                        <TableCell
                          sx={{
                            ...item.sx,
                            textAlign: item.align,
                            width: item.width,
                          }}
                          key={key}
                          className={item.className}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {t(item.title || "")}{" "}
                            {!item.notFilter && handleSort && (
                              <div
                                className={"wrapper-filter-icon"}
                                onClick={() =>
                                  handleSortTable(item.field as string)
                                }
                              >
                                {renderIcon(item.field as string)}
                              </div>
                            )}
                          </div>
                        </TableCell>
                      );
                    }
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.items.length > 0 &&
                  data.items.map(item => (
                    <TableRow
                      hover
                      key={item[keyField]}
                      selected={selectedItems.indexOf(item.id) !== -1}
                    >
                      {!single && (
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={selectedItems.indexOf(item.id) !== -1}
                            onChange={event => handleSelectOne(event, item.id)}
                            value="true"
                          />
                        </TableCell>
                      )}

                      {rowsTable.map((field, key) => {
                        if (field.handleItem) {
                          return (
                            <TableCell
                              key={key}
                              sx={{
                                ...field.sx,
                                textAlign: field.align,
                                width: field.width,
                              }}
                              className={field.className}
                            >
                              {field.handleItem(item)}
                            </TableCell>
                          );
                        } else {
                          return (
                            <TableCell
                              sx={{
                                ...field.sx,
                                textAlign: field.align,
                                width: field.width,
                              }}
                              className={field.className}
                              key={key}
                            >
                              {field.field
                                ? typeof item[field.field] === "object"
                                  ? null
                                  : item[field.field]
                                : null}
                            </TableCell>
                          );
                        }
                      })}
                    </TableRow>
                  ))}
                {data.items.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={rowsTable.length + (!single ? 1 : 0)}>
                      <Box className={"box-empty"}>
                        <Typography variant="body2" className={"text-empty"}>
                          No data
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </TableWrapper>
          )}
        </Box>
      </PerfectScrollbar>
      {footer && (
        <>
          {isLoadmore ? (
            <>
              {Math.ceil((data?.total || 0) / data.pageSize) > 1 && (
                <Button
                  size="small"
                  variant="contained"
                  className={`loadmore-button`}
                  onClick={handleLoadMore}
                  disabled={Math.ceil((data?.total || 0) / data.pageSize) > 1}
                >
                  <ImageCustom
                    src={IconLoad}
                    alt="Icon-Arrow"
                    width={"18px"}
                    height={"18px"}
                  />
                  <p>Tải thêm</p>
                </Button>
              )}
            </>
          ) : (
            <GridPagination
              total={data.total}
              page={data.page}
              pageSize={data.pageSize}
              onPageChange={handlePageChange}
            />
          )}
        </>
      )}
    </TableBoxWrapper>
  );
};
