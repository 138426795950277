import React from "react";
import { ImageCustom } from "../../../../components";
import { OverviewCommonCaptionWrapper } from "./styles";
import IconInfor from "../../../../assets/images/domai-audit/overview-section/icon_infor_fill_grey.svg";
import { Tooltip, Typography } from "@mui/material";

interface OverviewCommonCaptionProps {
  caption: string;
  description?: string;
  isSummary?: boolean;
  icon?: string;
  width?: string;
  className?: string;
  tooltip?: string;
}

export default function OverviewCommonCaption(
  props: OverviewCommonCaptionProps
) {
  const {
    caption,
    description,
    icon,
    width,
    className = "",
    isSummary = false,
    tooltip,
  } = props;
  return (
    <OverviewCommonCaptionWrapper width={width} className={className}>
      <div className="caption-header flex-start-align-center">
        {!!icon && (
          <ImageCustom
            src={icon}
            alt="Icon-Caption"
            width={"20px"}
            height={"20px"}
            className="icon-caption"
          />
        )}
        <Typography variant="h4" className="fit-one-line">
          {caption}
        </Typography>
        {!!tooltip && (
          <Tooltip title={tooltip} placement="top-start">
            <div>
              <ImageCustom
                src={IconInfor}
                alt="Icon-Infor"
                width={"14px"}
                height={"14px"}
              />
            </div>
          </Tooltip>
        )}
      </div>
      {!!description && (
        <p className={`caption-description ${isSummary ? "is-summary" : ""}`}>
          {description}
        </p>
      )}
    </OverviewCommonCaptionWrapper>
  );
}
