import { InputLabelProps, TextField } from "@mui/material";
import React from "react";
import { Control, Controller } from "react-hook-form";
import ImageCustom from "../../ImageCustom";
import CommonHandle, { safeRead } from "../../../common/handles/common.handles";
import { styled } from "@mui/material/styles";
import variableStyles from "../../../theme/variable-styles";
import IconError from "../../../assets/images/common/icon_error_fill_orange.svg";

const StartTextAndIcon = styled("div")(({ theme }) => ({
  display: "flex",
  gap: "8px",
  alignItems: "center",
  "& p": {
    color: variableStyles.NaturalColor700,
    fontWeight: variableStyles.fwMedium,
    fontSize: "13px",
    lineHeight: "18.2px14px",
  },
}));

export interface InputFormProps {
  name: string;
  control: Control<any, object>;
  errors: any;
  label?: React.ReactNode;
  InputLabelProps?: Partial<InputLabelProps> | undefined;
  required?: boolean;
  onClick?: () => void;
  onChange?: (value: any) => void;
  disabled?: boolean;
  helperText?: any;
  placeholder?: string;
  classname?: string;
  isFormatNumber?: boolean;
  rules?: any;
  startIcon?: string;
  startText?: string;
  type?: React.HTMLInputTypeAttribute;
  size?: "small" | "medium" | "large";
  isPasteLink?: boolean;
  isLink?: boolean;
  isNotSafeRead?: boolean;
}
export default function InputForm({
  name,
  label,
  control,
  errors,
  InputLabelProps = {
    shrink: true,
  },
  required,
  helperText,
  disabled,
  placeholder,
  onChange,
  onClick,
  isFormatNumber,
  rules,
  startIcon,
  startText,
  type,
  size,
  isPasteLink = false,
  isLink = false,
  isNotSafeRead = false,
  classname = "",
}: InputFormProps) {
  const error = safeRead(errors, name);

  const iconAdornment =
    !!startIcon && !startText
      ? {
          startAdornment: (
            <ImageCustom
              src={startIcon}
              width={size === "medium" ? 24 : 18}
              height={size === "medium" ? 24 : 18}
            />
          ),
        }
      : !!startIcon && !!startText
        ? {
            startAdornment: (
              <StartTextAndIcon className="text-icon-start">
                <ImageCustom
                  src={startIcon}
                  width={size === "medium" || size === "large" ? 24 : 18}
                  height={size === "medium" || size === "large" ? 24 : 18}
                />
                <p>{startText}</p>
              </StartTextAndIcon>
            ),
          }
        : {};

  return (
    <Controller
      name={name}
      rules={rules}
      render={({ field }) => (
        <TextField
          className={classname}
          id={name}
          size={size}
          onClick={onClick}
          fullWidth
          label={label}
          error={
            isNotSafeRead
              ? !!errors?.message || !!helperText
              : !!error?.message || !!helperText
          }
          required={required}
          type={type}
          {...field}
          sx={
            !!isPasteLink
              ? {
                  borderRadius: "12px",
                  "& .MuiInputBase-root": {
                    borderRadius: "12px",
                    backgroundColor: variableStyles.NaturalColor100,
                  },
                  "& fieldset": {
                    border: "none",
                  },
                  "&:hover": {
                    "& .MuiInputBase-root": {
                      backgroundColor: variableStyles.NaturalColor200,
                    },
                    "& fieldset": {
                      border: "none !important",
                    },
                  },
                  "& .Mui-focused": {
                    borderRadius: "12px",
                    "& .MuiInputBase-root": {
                      backgroundColor: variableStyles.NaturalColor200,
                    },
                    "& fieldset": {
                      boxShadow: "none",
                      border: "1px solid #0C97FF !important",
                    },
                  },
                  "& .Mui-error": {
                    "& fieldset": {
                      boxShadow: `0px 0px 0px 4px #90580433`,
                      border: `1px solid ${variableStyles.Warning800} !important`,
                    },
                  },
                  "& input": {
                    color: `${variableStyles.blueSecondary600} !important`,
                  },
                }
              : !!isLink
                ? {
                    "& input": {
                      color: `${variableStyles.blueSecondary600} !important`,
                    },
                  }
                : undefined
          }
          InputLabelProps={InputLabelProps}
          disabled={disabled}
          InputProps={iconAdornment}
          placeholder={placeholder && placeholder}
          value={
            isFormatNumber
              ? CommonHandle.formatNumber(field.value)
              : (field.value ?? null)
          }
          onChange={e => {
            const value = e.target.value;
            onChange ? onChange(value) : field.onChange(value);
          }}
          helperText={
            helperText ? (
              helperText
            ) : !helperText &&
              (isNotSafeRead ? errors?.message : error?.message) ? (
              <>
                <ImageCustom
                  src={IconError}
                  alt="Icon-Download"
                  width={"18px"}
                  height={"18px"}
                />
                {isNotSafeRead ? errors?.message : error?.message}
              </>
            ) : null
          }
        />
      )}
      control={control}
    />
  );
}
