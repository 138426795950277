import { styled } from "@mui/material/styles";
import variableStyles from "../../../../../../../../../../theme/variable-styles";

export const CriticalRequestChainWrapper = styled("ul")<{ level: number }>(
  ({ theme, level }) => ({
    "& a": {
      width: `calc(50% - ${(level / 2) * 24}px)`,
      paddingLeft: `12px`,
      marginLeft: `${level * 12 - 12}px`,
      position: "relative",
      "&::before": {
        content: '""',
        width: `12px`,
        height: "50%",
        position: "absolute",
        left: "0",
        top: "0",
        display: level === 0 ? "none" : "block",
        borderBottom: `2px solid ${variableStyles.NaturalColor200}`,
        borderLeft: `2px solid ${variableStyles.NaturalColor200}`,
      },
    },
    "& p": { width: "60px", textAlign: "right" },
  })
);
