import { styled } from "@mui/material/styles";
import variableStyles from "../../../theme/variable-styles";

export const CreateProjectFormWrapper = styled("form")(() => ({
  backgroundColor: variableStyles.NaturalColor0,
  padding: "16px 12px",
  marginBottom: "16px",
  borderRadius: "12px",
  display: "flex",
  flexDirection: "column",
  gap: "16px"
}));
