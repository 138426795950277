import { styled } from "@mui/material/styles";
import variableStyles from "../../../theme/variable-styles";

export const LineChartCustomWrapper = styled("div")<{
  width: string;
  height: string;
}>(({ theme, width, height }) => ({
  width: width,
  height: height,
  "& .custom-tooltip": {
    backgroundColor: variableStyles.NaturalColor0,
    border: `1px solid ${variableStyles.NaturalColor200}`,
    borderRadius: "5px",
    padding: "6px",
    textAlign: "center",
    width: "128px",
    boxShadow: "0px 4px 4.9px 0px #00000012",
    "& .YAxis-text": {
      color: variableStyles.NaturalColor950,
      fontWeight: variableStyles.fwSemiBold,
      fontSize: "12px",
      lineHeight: "20px",
    },
    "& .XAxis-text": {
      color: variableStyles.NaturalColor800,
      fontWeight: variableStyles.fwRegular,
      fontSize: "12px",
      lineHeight: "15px",
    },
  },
  "& .legend-list": {
    gap: "20px",
    padding: "0 20px 0 60px",
    marginBottom: "20px",
    flexWrap: "wrap"
  },
}));
