import {
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  TooltipProps,
  CartesianGrid,
  LineChart,
  Line,
} from "recharts";
import { LineChartCustomWrapper } from "./styles";
import variableStyles from "../../../theme/variable-styles";
import moment from "moment";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";
import { IChartValue } from "../../../models/common/models.type";
import CheckBoxCustom from "../../Checkbox/CheckBoxCustom";
import Icon3 from "../../../assets/images/checkbox/icon_checkbox_3.svg";
import IconActive3 from "../../../assets/images/checkbox/icon_checkbox_active_3.svg";
import { CurveType } from "recharts/types/shape/Curve";

interface LineChartCustomProps {
  data: IChartValue[];
  listFilter?: string[];
  onChangeFilter: (value: string) => void;
  filterValue?: string;
  filterText?: string;
  toolTipText?: string;
  YAxisKey: string;
  XAxisKey: string;
  YAxisWidth?: number;
  type?: CurveType;
  width?: string;
  height?: string;
}

export default function LineChartCustom(props: LineChartCustomProps) {
  const {
    data,
    listFilter,
    onChangeFilter,
    filterValue,
    filterText,
    XAxisKey,
    YAxisKey,
    toolTipText,
    type = "monotone",
    width = "100%",
    height = "250px",
    YAxisWidth = 60,
  } = props;

  const formatXAxis = (tickFormat: string) => {
    return moment(tickFormat).format("DD/MM/YYYY");
  };

  const CustomTooltip = ({
    active,
    payload,
    label,
  }: TooltipProps<ValueType, NameType>) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="YAxis-text">
            {toolTipText ? `${toolTipText}: ` : ""}
            {payload[0].value}
          </p>
          <p className="XAxis-text">{label}</p>
        </div>
      );
    }
    return <></>;
  };

  return (
    <LineChartCustomWrapper width={width} height={height}>
      {!!listFilter && (
        <ul className="legend-list flex-start-align-center">
          {listFilter?.map((entry: string, index: any) => (
            <li key={`item-${index}`}>
              <CheckBoxCustom
                onChange={() => {
                  onChangeFilter(entry);
                }}
                label={`${entry} ${filterText}`}
                value={entry}
                iconCheck={Icon3}
                iconCheckActive={IconActive3}
                checked={filterValue === entry}
                width="16px"
                height="16px"
              />
            </li>
          ))}
        </ul>
      )}
      <ResponsiveContainer width="100%">
        <LineChart
          data={data}
          margin={{
            top: 10,
            right: 0,
            left: 10,
            bottom: 0,
          }}
        >
          <CartesianGrid
            strokeWidth={1}
            stroke={`${variableStyles.NaturalColor200}`}
            vertical={false}
          />
          <XAxis
            dataKey={XAxisKey}
            tick={{
              color: variableStyles.NaturalColor950,
              fontWeight: variableStyles.fwMedium,
              fontSize: "10px",
            }}
            axisLine={false}
            tickLine={false}
            reversed={true}
            interval="preserveStartEnd"
          />
          <YAxis
            tick={{
              color: variableStyles.NaturalColor800,
              fontWeight: variableStyles.fwRegular,
              fontSize: "10px",
            }}
            tickLine={false}
            axisLine={{
              strokeWidth: 1,
              stroke: variableStyles.NaturalColor200,
            }}
            width={YAxisWidth}
            orientation="right"
          />
          <Tooltip
            content={<CustomTooltip />}
            cursor={{
              stroke: `${variableStyles.blueSecondary200}`,
              strokeWidth: 1,
            }}
          />
          <Line
            type={type}
            dataKey={YAxisKey}
            fill="transparent"
            fillOpacity={0.3}
            dot={{
              stroke: `${variableStyles.blueSecondary700}`,
              strokeWidth: 1,
              fill: `${variableStyles.blueSecondary100}`,
              fillOpacity: 1,
            }}
            activeDot={{ fill: `${variableStyles.Warning600}`, strokeWidth: 0 }}
            stroke={variableStyles.blueSecondary700}
            strokeOpacity={1}
            strokeWidth={2}
          />
        </LineChart>
      </ResponsiveContainer>
    </LineChartCustomWrapper>
  );
}
