import api from "../remote/baseApi";
import ApiEndpoints from "../remote/apiEndpoints";
import { envConfig } from "../../config/env.config";
import { IBaseResponseModel } from "../../models/common/models.type";
import { ICompressPhotoRes } from "../../models/compress-photo";

const API_COMPRESS_PHOTO = ApiEndpoints.COMPRESS_PHOTO;
const baseURL = envConfig.API_KEY_UPLOAD_ENDPOINT;

const compressPhoto = (
  params: FormData
): Promise<IBaseResponseModel<ICompressPhotoRes[]>> => {
  return api.post(baseURL, API_COMPRESS_PHOTO.COMPRESS_PHOTO.url, params, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

const CompressPhotoService = {
  compressPhoto
};

export default CompressPhotoService;
