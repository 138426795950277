export enum Roles {
  SuperAdmin = "SuperAdmin",
  Admin = "Admin",
  Customer = "Customer",
  CustomerServices = "CustomerServices",
  Owner = "Owner",
  Editor = "Editor",
  Advertiser = "Advertiser",
  Stores = "Stores",
  Staff = "Staff",
  ViewReport = "ViewReport",
}

export enum ObjectStatus {
  Active = "active",
  DeActive = "deactive",
}
export enum OrderDirection {
  DESC = "DESC",
  ASC = "ASC",
}
export enum EImageType {
  png = ".png",
  jpeg = ".jpeg",
  jpg = ".jpg",
}

export enum ECurrencyCode {
  VND = "VND",
  USD = "USD",
  EUR = "EUR",
}

export enum ELanguage {
  Vie = "vi-VN",
  Eng = "en-US",
}

export enum EFlow {
  Inflow = 0,
  Outflow = 1,
}

export enum EModeModal {
  CREATE = "CREATE",
  UPDATE = "UPDATE",
  NOTHING = "NOTHING",
}

export enum EPlans {
  NONE = "NONE",
}

export enum ExchangeRateType {
  USDtoVND = "USD/VND",
  EURtoVND = "EUR/VND",
  EURtoUSD = "EUR/USD",
}

export enum TypeLink {
  internal = "internal",
  external = "external",
}

export enum EReportType {
  DomainAudit = 0,
  DomainCompetitive = 1,
  PageAudit = 2,
  PageCompetitive = 3,
  DomainAuditUX = 4,
  DomainAuditSEO = 5,
  All = 6,
}

export enum EReportStatus {
  Running = 0,
  Done = 1,
  Cancel = 2,
  Error = 3,
}

export enum EBackLinkTag {
  Text = 0,
  Form = 1,
  Frame = 2,
  Image = 3,
  Newlink = 4,
  Lostlink = 5,
  NoFollow = 6,
  Follow = 7,
}

export enum EAuditErrorStatus {
  Error = "error",
  Warning = "warning",
  Infor = "info",
  Success = "success",
  Content = "content",
  Recommended = "recommended",
}

export enum EIntentsPositions {
  Commercial = "0",
  Informational = "1",
  Navigational = "2",
  Transactional = "3",
}
export enum ETypeLinkCheck {
  RootDomain = "domain",
  ExactUrl = "url",
}

export enum ESectionOfAuditReport {
  OPERATIONALPERFORMANCE = "0",
  PAGESPEED = "1",
  SEOWEBSITEOVERVIEW = "2",
  SEOWEBSITEPROBLEM = "3",
  CONTENT = "4",
  COMPANYPROFILE = "5",
}
