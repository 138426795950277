import { styled } from "@mui/material/styles";
import variableStyles from "../../../../theme/variable-styles";

export const DomainAuditUXDetailWrapper = styled("div")(() => ({
  "& .report-header": {
    borderBottom: `1px solid ${variableStyles.NaturalColor300}`,
  },
  "& .report-wrapper": {
    height: "calc(100vh - 168px)",
    overflow: "auto",
    maxWidth: "832px",
    margin: "30px auto 12px",
    padding: "0 12px",
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  "& .common-layout": {
    border: `1px solid ${variableStyles.NaturalColor300}`,
    borderRadius: "4px",
    boxShadow: "0px 0px 17.4px -3px #0000001F ",
    backgroundColor: variableStyles.NaturalColor0,
  },
}));
