import { Collapse } from "@mui/material";
import MarkdownRenderer from "../../../../../../../../../components/MarkdownRenderer";
import {
  ContentfulPaint,
  ScoreDisplayMode,
  StackPacks,
} from "../../../../../../../../../models/google/google.page.speed";
import { ImageCustom } from "../../../../../../../../../components";
import RenderDetails from "../RenderDetails";
import IconByScore from "../IconByScore";
import IconArrowDown from "../../../../../../../../../assets/images/common/icon_arrow_down_line_black.svg";
import { AuditAccordionWrapper } from "./styles";
import { useState } from "react";
import { getAuditStatus } from "../../../../../../../../../common/handles/common.handles";

interface IProps {
  data?: ContentfulPaint;
  stackPacks?: StackPacks[];
  weight: number;
}

export default function AuditAccordion({ data, stackPacks, weight }: IProps) {
  const [openCollapse, setOpenCollapse] = useState<boolean>();

  if (!data) {
    return null;
  }

  const getStackPacksValue = () => {
    if (!stackPacks) {
      return null;
    }

    const value = stackPacks.filter(t => t.descriptions[data.id]);

    return value;
  };

  const stackPackValue = getStackPacksValue();
  const status = getAuditStatus(data, weight);
  return (
    <AuditAccordionWrapper>
      <div
        className={`flex-between-align-center accordion-header-wrapper ${openCollapse ? "active" : ""}`}
        onClick={() => setOpenCollapse(!openCollapse)}
      >
        <div className="header-infor flex-start-align-center">
          <IconByScore data={data} weight={weight} />
          <p className="header-title">
            {`${data?.title}`}
            {!!data?.displayValue && (
              <span className={`color-text-audit-${status}`}>
                {" - "}
                {data?.displayValue}
              </span>
            )}
          </p>
        </div>
        <ImageCustom
          src={IconArrowDown}
          alt="Icon-Arrow-Down"
          width={"22px"}
          height={"22px"}
          className="header-arrow"
        />
      </div>
      <Collapse in={openCollapse}>
        <div className="collapse-wrapper">
          {data.description && (
            <div className="accordion-description">
              <MarkdownRenderer
                components={{
                  a: v => {
                    return (
                      <a href={v.href} target="_blank" rel="noreferrer">
                        {v.children}
                      </a>
                    );
                  },
                }}
                content={
                  `${data.description.replace(/\.$/, "")} ${
                    data.scoreDisplayMode === ScoreDisplayMode.metricSavings &&
                    data.metricSavings
                      ? Object.keys(data.metricSavings).map(item => `<${item}>`)
                      : ""
                  }`.replace(/\.$/, "") ?? ""
                }
              />
            </div>
          )}
          {stackPackValue?.map(item => {
            return (
              <div
                className="flex-start-align-center accordion-stack-pack"
                key={item.id}
              >
                {item?.iconDataURL && (
                  <ImageCustom src={item?.iconDataURL} width={30} height={30} />
                )}
                {item?.descriptions[data.id] && (
                  <div className="stack-pack-text">
                    <MarkdownRenderer
                      components={{
                        a: v => {
                          return (
                            <a href={v.href} target="_blank" rel="noreferrer">
                              {v.children}
                            </a>
                          );
                        },
                      }}
                      content={
                        `${item?.descriptions[data.id]}`.replace(/\.$/, "") ??
                        ""
                      }
                    />
                  </div>
                )}
              </div>
            );
          })}
          {!!data.warnings && (
            <ul className="accordion-warning-list">
              {data?.warnings?.map((item, key) => (
                <li key={key}>
                  <div>
                    <MarkdownRenderer
                      components={{
                        a: v => {
                          return (
                            <a href={v.href} target="_blank" rel="noreferrer">
                              {v.children}
                            </a>
                          );
                        },
                      }}
                      content={item ?? ""}
                    />
                  </div>
                </li>
              ))}
            </ul>
          )}
          {!!data.errorMessage && (
            <div className="accordion-error">
              <div>
                <MarkdownRenderer content={data?.errorMessage ?? ""} />
              </div>
            </div>
          )}
          {data.details && (
            <div className="accordion-detail-wrapper">
              <RenderDetails data={data.details} />
            </div>
          )}
        </div>
      </Collapse>
    </AuditAccordionWrapper>
  );
}
