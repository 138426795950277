import { Box } from "@mui/material";
import {
  ContentfulPaintDetails,
  ValueType,
} from "../../../../../../../../../models/google/google.page.speed";
import DetailTable from "./DetailTable";
import CriticalRequestChain from "./Criticalrequestchain";
import Filmstrip from "./Filmstrip";
import Screenshot from "./Screenshot";
import {
  convertBytesToKiB,
  convertTimeSpanMs,
} from "../../../../../../../../../common/handles/common.handles";

const RenderDetails = ({ data }: { data?: ContentfulPaintDetails }) => {
  if (!data) {
    return null;
  }
  const { type, items, headings, chains } = data;

  switch (type) {
    case "opportunity": {
      return <DetailTable headers={headings} data={items} />;
    }
    case "criticalrequestchain": {
      return (
        <div
          style={{ maxHeight: 400, overflow: "auto", padding: "6px 0" }}
          className="scrollbar-small"
        >
          <CriticalRequestChain chains={chains} />
        </div>
      );
    }
    case "table": {
      return <DetailTable headers={headings} data={items} />;
    }
    case "list": {
      return (
        <Box>
          {items.map((item, key) => {
            return <RenderDetails data={item} key={key} />;
          })}
        </Box>
      );
    }
    case "filmstrip": {
      return <Filmstrip items={items} />;
    }
    case "screenshot": {
      return (
        <Screenshot
          data={data.data}
          timing={data.timing}
          timestamp={data.timestamp}
        />
      );
    }
    default: {
      return <BaseDetail />;
    }
  }
};

const BaseDetail = () => {
  return <Box></Box>;
};

export const renderValueByType = (
  valueType: ValueType,
  value: string | number
): string => {
  if (!value) {
    return "";
  }

  switch (valueType) {
    case "text":
      return value.toString();
    case "ms":
      return `${Number(value).toFixed(3)} ms`;
    case "node":
      return value.toString();
    case "url":
      return value.toString();
    case "bytes":
      return `${convertBytesToKiB(Number(value))} KiB`;
    case "source-location":
      return value.toString();
    case "code":
      return value.toString();
    case "timespanMs":
      return convertTimeSpanMs(Number(value));
    case "numeric":
      return value.toString();
    default:
      return value.toString();
  }
};

export default RenderDetails;
