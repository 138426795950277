import { ILang } from "../i18n";

const VI: ILang = {
  FilterDate: {
    Default: "Mặc định",
    Daily: "Theo ngày",
    Weekly: "Theo tuần",
    Monthly: "Theo tháng",
    ViewBy: "Hiển thị theo",
    ErrorSelectDateMessage:
      "Vui lòng chọn khoản thời gian ít nhất 3 ngày và cao nhất 15 ngày!"
  },
  Lang: {
    Code: "VN",
    TextEN: "Tiếng Anh",
    TextVN: "Tiếng Việt"
  },
  Login: {
    ErrorEmail: "Vui lòng nhập email!",

    LoginDescription: "Nhập email và mật khẩu của bạn để đăng nhập.",
    ButtonText: "Đăng nhập"
  },
  Sidebar: {
    Overview: "Tổng Quan",
    Manage: "Quản Lý",
    Account: "Tài Khoản",
    Dashboard: "Dashboard",
    ManageUser: "Quản Lý Người Dùng",
    ManageCashflow: "Quản Lý Dòng Tiền",
    RecordingBalance: "Bản Ghi Số Dư",
    AccountName: "Nhóm dòng Tiền",
    BankAccount: "Tài Khoản Ngân Hàng",
    ManageExchangeRate: "Quản Lý Tỉ Giá Hoán Đổi",
    Transaction: "Giao dịch",
    Setting: "Cài Đặt",
    Notification: "Thông Báo",
    FAQ: "Câu Hỏi Thường Gặp",
    Cashflow: "Dòng tiền",
    ManageAdminUser: "Manage Admin User",
    ManagePartnerUser: "Manage Partner User"
  },
  Modal: {
    CancelText: "Huỷ",
    SubmitText: "Xác nhận"
  },
  Setting: {
    AccountInformation: "Thông tin tài khoản",
    TimeRegister: "Tài khoản được đăng ký từ",
    ModalTitle: "Đổi mật khẩu",
    ModalOldPassword: "Mật khẩu cũ",
    ModalPassword: "Mật khẩu mới",
    ModalOldPasswordPlaceholder: "Nhập mật khẩu cũ",
    ModalPasswordPlaceholder: "Nhập mật khẩu mới",
    ModalConfirmPassword: "Xác nhận mật khẩu mới",
    UpdateSuccess: "Chỉnh sửa mật khẩu thành công",
    UpdateFail: "Chỉnh sửa mật khẩu không thành công!"
  },
  Role: {
    Owner: "Chủ sở hữu",
    Admin: "Quản trị viên",
    ViewReport: "Xem báo cáo"
  },
  Common: {
    InsertNew: "Thêm mới",
    Edit: "Cập nhật",
    Update: "Cập nhật",
    Delete: "Xóa",
    Status: "Trạng thái",
    DateCreated: "Ngày tạo",
    RowPerPage: "Số dòng",
    Of: "của",
    More: "hơn",
    Code: "Code",
    Name: "Tên",
    NameValidate: "Vui lòng nhập tên!",
    Description: "Mô tả",
    Title: "Tiêu đề",
    Content: "Nội dung",
    DiscountPrice: "Giá giảm",
    Price: "Giá",
    Type: "Loại",
    Prominent: "Nổi bật",
    StartDate: "Ngày bắt đầu",
    EndDate: "Ngày kết thúc",
    Quantity: "Số lượng",
    Product: "Sản phẩm",
    Customer: "Người dùng",
    Detail: "Xem chi tiết",
    Cancel: "Huỷ",
    Confirm: "Xác nhận",
    Inflow: "Dòng thu",
    Outflow: "Dòng chi",
    Active: "Hoạt động",
    Deactive: "Không hoạt động",
    BankNumber: "Số tài khoản",
    Currency: "Đơn vị",
    ApiError: "Có lỗi! Vui lòng thử lại sau.",
    Amount: "Số tiền",
    AmountPlaceholder: "Nhập số tiền",
    Search: "Tìm kiếm",
    Role: "Phân quyền",
    Date: "Ngày",
    LastName: "Họ",
    FirstName: "Tên",
    Password: "Mật khẩu",
    Logout: "Đăng xuất tài khoản",
    PasswordValidate: "Vui lòng nhập mật khẩu!",
    PasswordOldValidate: "Vui lòng nhập mật khẩu hiện tại!",
    PasswordConfirmValidate: "Vui lòng nhập chính xác mật khẩu!",
    ResetPassword: "Đặt lại mật khẩu",
    UpdateCashflow: "Chỉnh sửa dòng tiền",
    AddMore: "Thêm mới",
    TimeDefault: "Thời gian mặc định",
    AllAccountBank: "Tất cả tài khoản ngân hàng",
    CompanyName: "Tên công ty",
    PhoneNumber: "Số điện thoại",
    QuantityMember: "Số lượng nhân viên",
    Limit: "Giới hạn",
    PercentComplete: "Phần trăn hoàn thành",
    Close: "Đóng"
  }
};

export default VI;
