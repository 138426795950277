import { styled } from "@mui/material/styles";
import variableStyles from "../../../theme/variable-styles";

export const PieChartDonutShapeWrapper = styled("div")(({ theme }) => ({
  width: "100%",
  height: "300px",
  "&.isLegendBottom": {
    "& .legend-list": { flexDirection: "row", justifyContent: "center" },
  },
  "& .legend-list": {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    "& li": {
      padding: "6px 8px",
      backgroundColor: variableStyles.NaturalColor100,
      borderRadius: "8px",
      display: "flex",
      gap: "8px",
      alignItems: "center",
      flex: "0 0 auto",
      "&.pointer": {
        cursor: "pointer",
      },
    },
    "& .legend-color": {
      width: "14px",
      height: "14px",
      borderRadius: "4px",
    },
    "& .legend-text": {
      color: variableStyles.NaturalColor1000,
      fontWeight: variableStyles.fwRegular,
      fontSize: "12px",
      lineHeight: "18px",
    },
  },
  "& .recharts-legend-wrapper": {
    zIndex: "99999",
    "@media (min-width:1180px)": { right: "100px !important" },
    "@media (max-width:1179.98px)": { right: ".5rem !important" },
  },
}));
