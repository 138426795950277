import queryString from "query-string";
import React, { forwardRef, useImperativeHandle } from "react";
import { useSelector } from "react-redux";
import { ROUTE_PATH } from "../../../../../../../../common/constants/app.constant";
import { CollapseHandleError } from "../../../../../../../../components";
import { EAuditErrorStatus } from "../../../../../../../../models/common/models.enum";
import { IStateApp } from "../../../../../../../../models/redux";
import { IIssueListData } from "../TechnicalOverviewTab";
import { TechnicalIssueTabWrapper } from "./styles";
import mapData, { IIssueCommon } from "./tempData";

const TechnicalIssueTab = forwardRef<any, any>(
  ({ keyFilter, dataHeading, dataImage, dataIssue, dataNofollow }, ref) => {
    const errorList = useSelector<IStateApp, string[]>(
      appState => appState.project?.errorList
    );

    const childFunction = () => {
      const element = document.getElementById(keyFilter);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    };
    useImperativeHandle(ref, () => ({
      childFunction,
    }));

    const issueHeading: {
      pageHeadingDuplicate: IIssueCommon;
      pagesMoreThanHeading: IIssueCommon;
      pageHeadingTooLong: IIssueCommon;
      pageHeadingAndTileDuplicate: IIssueCommon;
    } = {
      pageHeadingDuplicate: {
        title: "Trang trùng lặp thẻ Heading H1",
        key: "duplicate_h1",
        type: "page",
        data: dataHeading?.pageHeadingDuplicate?.slice(0, 30) || [],
        headerData: [
          {
            field: "url",
            title: `URL`,
            className: "table-cell-link",
            handleItem: (item: any) => {
              return (
                <a
                  className="fit-one-line"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={item.url}
                >
                  {item.url}
                </a>
              );
            },
          },
          {
            field: "h1",
            title: `H1`,
            handleItem: (item: any) => {
              return <p className="fit-one-line">{item.h1}</p>;
            },
          },
        ],
        navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
        navigateFilter: queryString.stringify({
          tab: 2,
          mFilter: "h1",
          sFilter: "duplicate_h1",
        }),
      },
      pagesMoreThanHeading: {
        title: "Trang có nhiều hơn 1 thẻ H1",
        key: "many_h1",
        type: "page",
        data: dataHeading?.pagesMoreThanHeading?.slice(0, 30) || [],
        infor:
          "Điều này xảy ra khi nhiều phần tử trên trang được gắn thẻ H1 thay vì chỉ có một thẻ H1 duy nhất khiến công cụ tìm kiếm sẽ khó xác định nội dung chính của trang, làm giảm khả năng xếp hạng.",
        recommendAction:
          "Đảm bảo mỗi trang chỉ có một thẻ H1 duy nhất, phản ánh đúng nội dung chính của trang. Loại bỏ các thẻ H1 thừa hoặc thay thế bằng các thẻ H2, H3...cho phù hợp.",
        headerData: [
          {
            field: "url",
            title: `URL`,
            className: "table-cell-link",
            handleItem: (item: any) => {
              return (
                <a
                  className="fit-one-line"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={item.url}
                >
                  {item.url}
                </a>
              );
            },
          },
          {
            field: "domain",
            title: `Số lượng H1`,
            className: " ",
            handleItem: (item: any) => {
              return <p className="fit-one-line">{item.createdAt}</p>;
            },
          },
        ],
        navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
        navigateFilter: queryString.stringify({
          tab: 2,
          mFilter: "h1",
          sFilter: "many_h1",
        }),
      },
      pageHeadingTooLong: {
        title: "Trang có thẻ Heading 1 quá dài",
        key: "h1_long",
        type: "page",
        data: dataHeading?.pageHeadingTooLong?.slice(0, 30) || [],
        headerData: [
          {
            field: "url",
            title: `URL`,
            className: "table-cell-link",
            handleItem: (item: any) => {
              return (
                <a
                  className="fit-one-line"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={item.url}
                >
                  {item.url}
                </a>
              );
            },
          },
          {
            field: "domain",
            title: `Độ dài H1`,
            className: " ",
            handleItem: (item: any) => {
              return <p className="fit-one-line">{item.h1.length}</p>;
            },
          },
        ],
        navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
        navigateFilter: queryString.stringify({
          tab: 2,
          mFilter: "h1",
          sFilter: "h1_long",
        }),
      },
      pageHeadingAndTileDuplicate: {
        title: "Trang có thẻ tiêu đề và H1 trùng lặp",
        key: "h1_title_duplicate",
        type: "page",
        infor:
          "Mặc dù không gây ảnh hưởng tiêu cực nhưng việc trùng lặp tiêu đề và Heading 1 làm lãng phí cơ hội xếp hạng nhiều từ khóa hơn cho trang web.",
        recommendAction:
          "Diễn đạt tiêu đề hoặc thẻ H1 theo một cách khác để tạo sự khác biệt, bổ sung thêm các từ khóa phụ vào tiêu đề hoặc H1 để tăng hiệu quả tối ưu từ khóa.",
        data: dataHeading?.pageHeadingAndTileDuplicate?.slice(0, 30) || [],
        headerData: [
          {
            field: "url",
            title: `URL`,
            className: "table-cell-link",
            handleItem: (item: any) => {
              return (
                <a
                  className="fit-one-line"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={item.url}
                >
                  {item.url}
                </a>
              );
            },
          },
          {
            field: "title",
            title: `Tiêu đề`,
            handleItem: (item: any) => {
              return <p className="fit-one-line">{item.title}</p>;
            },
          },
          {
            field: "h1",
            title: `Heading 1`,
            handleItem: (item: any) => {
              return <p className="fit-one-line">{item.h1}</p>;
            },
          },
        ],
        navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
        navigateFilter: queryString.stringify({
          tab: 2,
          mFilter: "h1",
          sFilter: "h1_title_duplicate",
        }),
      },
    };

    const issueNoAlt: IIssueCommon = {
      title: "Hình ảnh thiếu thẻ ALT",
      key: "alternative_text",
      type: "resources",
      infor:
        "Thẻ Alt (Alternative text) là văn bản mô tả nội dung hình ảnh trên trang web. Hình ảnh thiếu thẻ ALT là các ảnh có thẻ ALT text để trống, điều này khiến công cụ tìm kiếm khó hiểu nội dung hình ảnh, dẫn đến giảm khả năng tiếp cận và tối ưu SEO.",
      recommendAction:
        "Bổ sung thẻ ALT riêng và mô tả ngắn gọn, chính xác nội dung của từng hình ảnh. Thêm từ khóa chính vào thẻ Alt của ảnh có nội dung thích hợp giúp tối ưu điểm SEO.",
      headerData: [
        {
          field: "url",
          title: `URL`,
          className: "table-cell-link",
          handleItem: (item: any) => {
            return (
              <a
                className="fit-one-line"
                target="_blank"
                rel="noopener noreferrer"
                href={item.url}
              >
                {item.url}
              </a>
            );
          },
        },
      ],
      navigatePath: ROUTE_PATH.DOMAIN_AUDIT_ERROR_DETAIL,
      navigateFilter: queryString.stringify({
        tab: "image",
        fkey: "alternative_text",
      }),
      data: dataImage,
    };

    const issueNofollow: IIssueCommon = {
      title: "Trang chứa liên kết nội bộ đi gắn thẻ Nofollow",
      key: "nofollow",
      type: "links",
      infor:
        "Khi một trang chứa các liên kết nội bộ nhưng được gắn thẻ Nofollow nghĩa là công cụ tìm kiếm sẽ không truyền sức mạnh từ trang đó đến trang đích của liên kết.",
      recommendAction:
        "Chỉ sử dụng thẻ nofollow khi liên kết đến các trang không quan trọng (ví dụ: trang đăng nhập, trang điều khoản sử dụng) hoặc các trang không mong muốn công cụ tìm kiếm thu thập dữ liệu.",
      headerData: [
        {
          field: "url",
          title: `From URL`,
          className: "table-cell-link",
          handleItem: (item: any) => {
            return (
              <a
                className="fit-one-line"
                target="_blank"
                rel="noopener noreferrer"
                href={item.link_from}
              >
                {item.link_from}
              </a>
            );
          },
        },
      ],
      navigatePath: ROUTE_PATH.DOMAIN_AUDIT_ERROR_DETAIL,
      navigateFilter: queryString.stringify({
        tab: "inlink",
        fkey: "nofollow",
      }),
      data: dataNofollow,
    };

    const dataIssueWaring: IIssueCommon[] = mapData.dataIssueWaring.map(
      issue => {
        if (issue.key === "duplicate_h1") {
          return issueHeading.pageHeadingDuplicate;
        } else if (issue.key === "many_h1") {
          return issueHeading.pagesMoreThanHeading;
        } else if (issue.key === "h1_long") {
          return issueHeading.pageHeadingTooLong;
        } else if (issue.key === "h1_title_duplicate") {
          return issueHeading.pageHeadingAndTileDuplicate;
        } else if (issue.key === "alternative_text") {
          return issueNoAlt;
        } else if (issue.key === "nofollow") {
          return issueNofollow;
        } else {
          return issue;
        }
      }
    );

    const newParent = dataIssue
      .map(
        (issue: {
          title?: string;
          list?: IIssueListData[];
          children?: { title?: string; list: IIssueListData[] }[];
        }) => {
          if (!!issue.list) {
            return issue.list;
          } else {
            return [];
          }
        }
      )
      .flat(1);
    const newChildren = dataIssue
      .map(
        (issue: {
          title?: string;
          list?: IIssueListData[];
          children?: { title?: string; list: IIssueListData[] }[];
        }) => {
          if (!!issue.children) {
            return issue.children;
          } else {
            return [];
          }
        }
      )
      .flat(1)
      .map((issueChild: { title?: string; list: IIssueListData[] }) => {
        if (!!issueChild.list) {
          return issueChild.list;
        } else {
          return [];
        }
      })
      .flat(1);

    const newArrayIssue: IIssueListData[] = [...newParent, ...newChildren];

    return (
      <TechnicalIssueTabWrapper>
        <ul className="issue-list" id="technical-error">
          <p className="list-title error">LỖI NGHIÊM TRỌNG</p>
          {mapData.dataIssueError
            .filter(item => errorList.includes(item.key))
            .map(content => {
              const countError = newArrayIssue.find(
                itemIssue => itemIssue.key === content.key
              );
              return (
                <li key={content.title} id={content.key}>
                  <CollapseHandleError
                    headersTable={content.headerData}
                    typeError={EAuditErrorStatus.Error}
                    title={content.title}
                    filter={content.filter}
                    type={content.type}
                    isOpenKey={keyFilter}
                    keyFilter={content.key}
                    isCountError={countError?.issueCount || 0}
                    isNavigateToLink={content.isNavigateToLink}
                    navigatePath={content.navigatePath}
                    navigateFilter={content.navigateFilter}
                    data={content.data}
                    infor={content.infor}
                    recommendAction={content.recommendAction}
                  />
                </li>
              );
            })}
        </ul>
        <ul className="issue-list" id="technical-warning">
          <p className="list-title warning">CẢNH BÁO</p>
          {dataIssueWaring
            .filter(item => errorList.includes(item.key))
            .map(content => {
              const countError = newArrayIssue.find(
                itemIssue => itemIssue.key === content.key
              );
              return (
                <li key={content.title} id={content.key}>
                  <CollapseHandleError
                    headersTable={content.headerData}
                    typeError={EAuditErrorStatus.Warning}
                    title={content.title}
                    filter={content.filter}
                    type={content.type}
                    isOpenKey={keyFilter}
                    keyFilter={content.key}
                    isNavigateToLink={content.isNavigateToLink}
                    navigatePath={content.navigatePath}
                    navigateFilter={content.navigateFilter}
                    data={content.data}
                    infor={content.infor}
                    recommendAction={content.recommendAction}
                    isCountError={countError?.issueCount || 0}
                  />
                </li>
              );
            })}
        </ul>
        {mapData.dataIssueInfor.filter(item => errorList.includes(item.key))
          .length > 0 && (
          <ul className="issue-list" id="technical-notes">
            <p className="list-title infor">CẦN CHÚ Ý</p>
            {mapData.dataIssueInfor
              .filter(item => errorList.includes(item.key))
              .map(content => {
                const countError = newArrayIssue.find(
                  itemIssue => itemIssue.key === content.key
                );
                return (
                  <li key={content.title} id={content.key}>
                    <CollapseHandleError
                      headersTable={content.headerData}
                      typeError={EAuditErrorStatus.Infor}
                      title={content.title}
                      filter={content.filter}
                      type={content.type}
                      isOpenKey={keyFilter}
                      keyFilter={content.key}
                      isNavigateToLink={content.isNavigateToLink}
                      navigatePath={content.navigatePath}
                      navigateFilter={content.navigateFilter}
                      data={content.data}
                      infor={content.infor}
                      recommendAction={content.recommendAction}
                      isCountError={countError?.issueCount || 0}
                    />
                  </li>
                );
              })}
          </ul>
        )}
      </TechnicalIssueTabWrapper>
    );
  }
);
export default TechnicalIssueTab;
