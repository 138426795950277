import { Pagination, PaginationItem } from "@mui/material";
import { TablePaginationWrapper } from "./styles";
import IconPrev from "../../../assets/images/common/icon_pagination_arrow_left.svg";
import IconNext from "../../../assets/images/common/icon_pagination_arrow_right.svg";
import { ImageCustom } from "../..";

type Props = {
  page: number;
  pageSize: number;
  total: number;
  onPageChange: (newPage: number) => void;
  text?: string;
};

export default function PaginationCustom({
  page,
  pageSize,
  total,
  onPageChange,
  text = "projects",
}: Props) {
  return (
    <TablePaginationWrapper>
      <p className="pagination-text">
        {pageSize * page - (pageSize - 1)}-
        {pageSize * page <= total ? pageSize * page : total} of {total} {text}
      </p>
      <Pagination
        count={Math.ceil(total / pageSize)}
        page={page}
        boundaryCount={2}
        siblingCount={1}
        onChange={(_, page: number) => {
          onPageChange(page);
        }}
        renderItem={item => (
          <PaginationItem
            slots={{
              previous: () => (
                <ImageCustom
                  src={IconPrev}
                  alt="Icon-Prev"
                  width={"4px"}
                  height={"8px"}
                  className="pagination-arrow"
                />
              ),
              next: () => (
                <ImageCustom
                  src={IconNext}
                  alt="Icon-Next"
                  width={"4px"}
                  height={"8px"}
                  className="pagination-arrow"
                />
              ),
            }}
            {...item}
          />
        )}
      />
    </TablePaginationWrapper>
  );
}
