import { Popover } from "@mui/material";
import { styled } from "@mui/material/styles";
import variableStyles from "../../theme/variable-styles";

interface PopoverCustomProps {
  boxref: any;
  popover_width?: number;
}

interface WrapperProps {
  disabled?: boolean;
  width?: string;
  minWidth: string;
}

export const FilterCheckBoxCustomWrapper = styled("div")<WrapperProps>(
  ({ theme, disabled, width, minWidth }) => ({
    cursor: "pointer",
    minWidth: minWidth,
    "&.small": { "& .filter-select-wrapper": { padding: "5px 12px" } },
    "&.medium": { "& .filter-select-wrapper": { padding: "9px 12px" } },
    "& .filter-select-wrapper": {
      border: `1px solid ${variableStyles.NaturalColor300}`,
      borderRadius: "12px",
      boxShadow: "0px 0px 2px 0px #00000033 ",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      gap: ".5ch",
      width: !!width ? width : "unset",
      "&.active": {
        boxShadow: "0px 0px 0px 4px #23B4FF33",
        border: "1px solid #0C97FF !important",
      },
      "&.select-box-error": {
        boxShadow: `0px 0px 0px 4px #90580433`,
        border: `1px solid ${variableStyles.Warning800} !important`,
      },
      "& .filter-text": {
        color: variableStyles.NaturalColor900,
        fontWeight: variableStyles.fwMedium,
        fontSize: "14px",
        lineHeight: "19.6px",
        flex: "0 0 auto",
      },
      "& .select-selected-text": {
        color: variableStyles.NaturalColor1000,
        fontWeight: variableStyles.fwMedium,
        fontSize: "14px",
        lineHeight: "19.6px",
      },
      "& .select-placeholder": {
        color: variableStyles.NaturalColor1000,
        fontWeight: variableStyles.fwMedium,
        fontSize: "14px",
        lineHeight: "19.6px",
      },
      "& .filter-arrow": {
        opacity: "0.8",
        transition: "all .2s linear",
        "&.active": {
          transform: "rotate(-180deg)",
        },
      },
    },
    "& .error-text": {
      color: variableStyles.Warning800,
      fontSize: "13px",
      lineHeight: "13px",
      display: "flex",
      alignItems: "center",
      gap: "4px",
      margin: "6px 0 0",
    },
  })
);

export const PopoverWrapper = styled(Popover)<PopoverCustomProps>(
  ({ theme, boxref, popover_width: popoverWidth }) => ({
    "& .MuiPaper-root": {
      marginTop: "4px",
      width: popoverWidth ? popoverWidth : `${boxref.current?.offsetWidth}px`,
      borderRadius: "12px",
      boxShadow: "0px 5px 24px 0px #0000003D",
      padding: "8px",
      "& .checkbox-wrapper": {
        "& .checkbox-list": {
          display: "flex",
          flexDirection: "column",
          gap: "4px",
          "& .MuiFormControlLabel-root": {
            width: "100%",
            padding: "11px 8px",
            borderRadius: "12px",
            transition: `all .1s linear`,
            "&:hover": {
              backgroundColor: variableStyles.NaturalColor100,
            },
          },
        },
      },
    },
  })
);
