import { Tooltip } from "@mui/material";
import React from "react";
import { ImageCustom } from "../../../../../../../components";
import { TechnicalTitleWrapper } from "./styles";
import IconInfor from "../../../../../../../assets/images/domai-audit/overview-section/icon_infor_fill_grey.svg";

interface TechnicalTitleProps {
  title: string;
  infor?: string;
  marginBottom?: "";
  className?: string;
}

export default function TechnicalTitle(props: TechnicalTitleProps) {
  const { title, infor, marginBottom, className = "" } = props;
  return (
    <TechnicalTitleWrapper marginbottom={marginBottom} className={className}>
      <p>{title}</p>
      {!!infor && (
        <Tooltip title={infor} placement="left-start">
          <div>
            <ImageCustom
              src={IconInfor}
              alt="Icon-Infor"
              width={"14px"}
              height={"14px"}
            />
          </div>
        </Tooltip>
      )}
    </TechnicalTitleWrapper>
  );
}
