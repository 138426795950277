import React from "react";

interface IProps {
  fill?: string;
}

const ErrorIcon: React.FC<IProps> = ({ fill = "#ED3E47" }: IProps) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.1654 12.0002C24.1654 18.4435 18.942 23.6668 12.4987 23.6668C6.05538 23.6668 0.832031 18.4435 0.832031 12.0002C0.832031 5.55684 6.05538 0.333496 12.4987 0.333496C18.942 0.333496 24.1654 5.55684 24.1654 12.0002ZM8.96326 8.46475C9.30497 8.12305 9.85899 8.12304 10.2007 8.46475L12.4987 10.7627L14.7966 8.46478C15.1383 8.12307 15.6923 8.12307 16.034 8.46478C16.3757 8.80649 16.3757 9.36051 16.034 9.70222L13.7361 12.0002L16.034 14.2981C16.3757 14.6398 16.3757 15.1938 16.034 15.5355C15.6923 15.8772 15.1383 15.8772 14.7966 15.5355L12.4987 13.2376L10.2007 15.5355C9.85902 15.8772 9.305 15.8772 8.96329 15.5355C8.62158 15.1938 8.62158 14.6398 8.96329 14.2981L11.2612 12.0002L8.96326 9.70219C8.62155 9.36048 8.62155 8.80646 8.96326 8.46475Z"
      fill={fill}
    />
  </svg>
);

export default ErrorIcon;
