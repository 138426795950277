import { IWwwRedirect } from "./check-www";
import { TypeLink } from "./common/models.enum";
import { IBasePagingReq } from "./common/models.type";
import { ICTAData } from "./cta";
import { IBaseElement, IImageTag } from "./element";
import { IMeta } from "./meta";
import { IRobotTxt } from "./robots";
import { IUrlRules } from "./urlRules";

export interface IAuditDomainData {
  hasRedirectHttps: boolean;
  domainUrl: string;
  hasSSL: boolean;
  wwwRedirect: IWwwRedirect | null;
  robots: IRobotTxt | null;
  sitemaps: string[] | null;
}
export interface IAuditURLData extends IPageElementData {
  statusCode: number;
  loadTime: number;
  screenshotUrl: string;
  urlRules: IUrlRules;
  htmlContent: string;
}

export interface IHeading {
  h1: IBaseElement;
  h2: IBaseElement;
  h3: IBaseElement;
  h4: IBaseElement;
  h5: IBaseElement;
  h6: IBaseElement;
}

export interface ILink {
  total: number;
  internalLink: string[];
  externalLink: string[];
}

export interface IPageElementData {
  canonicalTag: string;
  lang: string;
  titleTag: IBaseElement;
  noindexTag: boolean;
  meta: IMeta;
  heading: IHeading;
  imageTags: IImageTag;
  links: ILink;
  phoneNumbers: string[];
  mails: string[];
  ctas: ICTAData[];
  messageBox: ICTAData[];
  social: ICTAData[];
  shareButton: ICTAData[];
  schemas: {
    schemaData: any[];
    schemaErrors: string[];
  };
}

export enum AuditTaskStatus {
  Waiting = "Waiting",
  Running = "Running",
  Done = "Done",
  Cancelled = "Cancelled",
  Error = "Error"
}

export interface IGetAuditTaskStatusRes {
  id: number;
  domainUrl: string;
  status: AuditTaskStatus;
  percentComplete: number;
  limit: number;
  createdAt: string;
}

export interface IAuditDomainRes extends IAuditDomainData {
  id: number;
  taskId: number;
}

export interface IGetAuditSitemapRes {
  id: number;
  taskId: number;
  url: string;
  createdAt: string;
}

export interface IGetAuditUrlRes extends IPageElementData {
  id: number;
  taskId: number;
  createdAt: string;
}

export interface IGetAuditLinkRes {
  id: number;
  taskId: number;
  url: string;
  auditUrlId?: number;
  type?: TypeLink;
  createdAt: string;
}

export interface IAuditTaskSearchParams extends IBasePagingReq {}
export interface IAuditSitemapSearchParams extends IBasePagingReq {
  taskId?: number;
}
