import React, { useEffect } from "react";
import { Control, FieldErrors, UseFormReset } from "react-hook-form";
import { ControlForm } from "../../../../..";
import InputForm from "../../../../../controls/InputForm";
import { PageAuditBoxWrapper } from "../styles";
import IconLink from "../../../../../../assets/images/common/icon_link_line_grey.svg";
import { ICreatePageAuditReq } from "../../../../../../models/report";

interface PageAuditReportProps {
  control: Control<ICreatePageAuditReq, any>;
  errors: FieldErrors<ICreatePageAuditReq>;
  reset: UseFormReset<ICreatePageAuditReq>;
}

export default function PageAuditReport(props: PageAuditReportProps) {
  const { control, errors, reset } = props;

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  return (
    <PageAuditBoxWrapper className="scrollbar-small">
      <div className="page-audit-wrapper">
        <ControlForm
          title="URL"
          infor="Làm nổi bật sản phẩm/dịch vụ bằng tiêu đề thu hút người dùng."
        >
          <InputForm
            placeholder="https://..."
            required
            name={"target"}
            errors={errors}
            control={control}
            size="small"
            startIcon={IconLink}
            isLink
          />
        </ControlForm>
        <ControlForm
          title="Từ khóa SEO"
          infor="Làm nổi bật sản phẩm/dịch vụ bằng tiêu đề thu hút người dùng."
        >
          <InputForm
            placeholder="Nhập từ khóa"
            required
            name="keyword"
            errors={errors}
            control={control}
            size="small"
          />
        </ControlForm>
      </div>
    </PageAuditBoxWrapper>
  );
}
