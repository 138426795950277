import { styled } from "@mui/material/styles";
import { Dialog } from "@mui/material";

export const ModalPhotoWrapper = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    margin: "0",
    backgroundColor: "transparent",
    boxShadow: "none",
    alignItems: "center",
    justifyContent: "center",
    position: "static",
    "& button": {
      position: "absolute",
      top: "64px",
      right: "18px",
      borderRadius: "50%",
      boxShadow:
        "0px 39px 67.8px 0px #1956580A, 0px 21px 37px -15px #1956580F, 0px 1px 1px 0px #00000014"
    },
    "& img": {
      objectFit: "contain"
    }
  }
}));
