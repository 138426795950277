import React, { useEffect, useState } from "react";
import { ImageCustom, NoDataBox } from "../../../../../../../components";
import { GoogleMyBusinessProfileWrapper } from "./styles";
import IconGMBLogo from "../../../../../../../assets/images/common/icon_GMB_logo.svg";
import { IValue } from "../../../../../../../models/common/models.type";
import ImageNoData from "../../../../../../../assets/images/common/img_project_empty.png";

interface GoogleMyBusinessProfileProps {
  data: IGoogleMyBusinessProfile[];
}

interface IGoogleMyBusinessProfile {
  title: string;
  description?:
    | string
    | {
        date: string;
        time: {
          open: string;
          close: string;
        } | null;
        status: boolean;
      }[];
  comment: {
    title: string;
    content: string;
  };
  status?: boolean;
}

export default function GoogleMyBusinessProfile(
  props: GoogleMyBusinessProfileProps
) {
  const { data } = props;
  const [activeTab, setActiveTab] = useState<number>(0);
  const [dataFilter, setDataFilter] =
    useState<IGoogleMyBusinessProfile[]>(data);

  const tabsData: IValue[] = [
    { title: "Tất cả", value: 0 },
    { title: "Passed", value: 1 },
    { title: "Failed", value: 2 },
  ];
  const handleFilter = () => {
    const newData = [...data];
    switch (activeTab) {
      case 1:
        const newDataPass = newData.filter(item => !!item.status);
        setDataFilter(newDataPass);
        break;
      case 2:
        const newDataFail = newData.filter(item => !item.status);
        setDataFilter(newDataFail);
        break;
      default:
        setDataFilter(newData);
        break;
    }
  };

  useEffect(() => {
    handleFilter();
  }, [activeTab]);

  return (
    <GoogleMyBusinessProfileWrapper>
      {/* <div className="profile-title flex-start-align-center">
        <ImageCustom
          src={IconGMBLogo}
          alt="Icon-Infor"
          width={"24px"}
          height={"20px"}
        />
        <p>My Google Business Profile</p>
      </div>
      <p className="profile-description">
        I think self-publishing is probably a lot easier now because of social
        media and other ways to marke I think self-publishing is probably a lot
        easier now because of social media and other ways to marke
      </p> */}
      <div className="profile-analysis-wrapper">
        <ul className="analysis-tabs flex-start-align-center">
          {tabsData.map(tab => (
            <li
              key={tab.value}
              className={activeTab === tab.value ? "active" : ""}
            >
              <button onClick={() => setActiveTab(tab.value)}>
                {tab.title}
              </button>
            </li>
          ))}
        </ul>
        <ul className="analysis-list">
          {dataFilter.length > 0 ? (
            <>
              {dataFilter.map(item => (
                <li key={item.title} className="list-item">
                  <div className="analysis-header flex-between-align-top">
                    <div className="header-infor">
                      <p className="infor-title">{item.title}</p>
                      {!!item.description && (
                        <>
                          {typeof item.description === "string" ? (
                            <p className="infor-description">
                              {item.description}
                            </p>
                          ) : (
                            <ul className="infor-description infor-description-time">
                              {item.description.length > 0 && (
                                <>
                                  {item.description.map((item, idx) => (
                                    <li key={idx}>
                                      <p className="date-text">{item.date}</p>
                                      <p className="date-time">
                                        {!!item.time ? (
                                          <>
                                            {item.time?.open} -{" "}
                                            {item.time?.close}
                                          </>
                                        ) : (
                                          "Close"
                                        )}
                                      </p>
                                    </li>
                                  ))}
                                </>
                              )}
                            </ul>
                          )}
                        </>
                      )}
                    </div>
                    <div
                      className={`header-status ${item.status ? "passed" : "failed"}`}
                    >
                      <p className="status-text">Status</p>
                      <p className="status-content">
                        {item.status ? "Passed" : "Failed"}
                      </p>
                    </div>
                  </div>
                  <div className="analysis-comment">
                    <p className="comment-title">{item.comment.title}</p>
                    <p className="comment-detail">{item.comment.content}</p>
                  </div>
                </li>
              ))}
            </>
          ) : (
            <>
              <div className={"box-empty flex-column-center"}>
                <ImageCustom
                  src={ImageNoData}
                  alt="Icon-NoData"
                  width={"120px"}
                  height={"120px"}
                />
                <p className={"text-empty"}>No data</p>
              </div>
            </>
          )}
        </ul>
      </div>
    </GoogleMyBusinessProfileWrapper>
  );
}
