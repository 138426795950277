import React, { useState } from "react";
import { FilterDatePopoverWrapper, FilterDateWrapper } from "./styles";
import DateRangePickerCustom, { IRangeDate } from "../DateRangePickerCustom";
import IconCalendar from "../../../assets/images/sidebar/icon_calendar_line_grey.svg";
import { ImageCustom } from "../..";
import CommonHandle from "../../../common/handles/common.handles";

interface IProps {
  onDataChange: (value: {
    startDate: Date | undefined;
    endDate: Date | undefined;
  }) => void;
  maxDate?: string | number;
  minDate?: string | number;
  value: { startDate: Date | undefined; endDate: Date | undefined };
}

export default function DateRangePickerPopover(props: IProps) {
  const { onDataChange, maxDate, minDate, value } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClosePopup = () => {
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover-filter-date" : undefined;

  return (
    <>
      <FilterDatePopoverWrapper
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClosePopup}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
      >
        <DateRangePickerCustom
          selection={{ ...value, key: "selection" }}
          maxDate={maxDate}
          minDate={minDate}
          onSubmit={v => {
            onDataChange({ startDate: v.startDate, endDate: v.endDate });
            onClosePopup();
          }}
          onClose={onClosePopup}
        />
      </FilterDatePopoverWrapper>
      <FilterDateWrapper
        onClick={e => setAnchorEl(e.currentTarget)}
        className={`${open ? "active" : ""}`}
      >
        <ImageCustom
          src={IconCalendar}
          alt="Icon-Calendar"
          width={"18px"}
          height={"18px"}
        />
        {!!value.startDate && !!value.endDate ? (
          <p className="has-time-box">
            {CommonHandle.formatDate(value.startDate)} -{" "}
            {CommonHandle.formatDate(value.endDate)}
          </p>
        ) : (
          <p className="no-time-box">Chọn thời gian</p>
        )}
      </FilterDateWrapper>
    </>
  );
}
