import { envConfig } from "../../config/env.config";
import {
  IBacklinkCheckOverviewReq,
  IBacklinkCheckOverviewRes,
} from "../../models/backlink-check";
import { IBaseResponseModel } from "../../models/common/models.type";
import ApiEndpoints from "../remote/apiEndpoints";
import api from "../remote/baseApi";

const API_BACKLINK_CHECK = ApiEndpoints.BACKLINK_CHECK;
const baseURL = envConfig.API_KEY_PROJECT_ENDPOINT;

const getBackLinkCheckOverview = (
  data: IBacklinkCheckOverviewReq
): Promise<IBaseResponseModel<IBacklinkCheckOverviewRes>> => {
  return api.post(
    baseURL,
    API_BACKLINK_CHECK.GET_BACKLINK_CHECK_OVERVIEW.url,
    data
  );
};

const BacklinkCheckService = {
  getBackLinkCheckOverview,
};

export default BacklinkCheckService;
