import { styled } from "@mui/material/styles";
import variableStyles from "../../theme/variable-styles";

export const FullWidthTabsTimeWrapper = styled("div")(({ theme }) => ({
  backgroundColor: variableStyles.NaturalColor50,
  overflow: "hidden",
  "@keyframes hidden-box": {
    "0%": {
      transform: "translateX(0)",
    },
    "75%": {
      transform: "translateX(calc(-100% - 10px))",
    },
    "100%": {
      display: "none",
      transform: "translateX(calc(-100% - 10px))",
    },
  },

  "@keyframes show-box": {
    "0%": {
      transform: "translateX(calc(-100% - 10px))",
    },
    "100%": { transform: "translateX(0)" },
  },

  "& .technical-detail-header": {
    gap: "24px",
    marginBottom: "40px",
    "& .header-tabs": {
      gap: "24px",
      borderBottom: `1px solid ${variableStyles.NaturalColor500}`,
      "& li": {
        "& button": {
          fontWeight: variableStyles.fwMedium,
          fontSize: "20px",
          lineHeight: "30px",
          color: variableStyles.NaturalColor600,
          backgroundColor: "transparent",
          transition: "all .2s linear",
          marginBottom: "-1px",
          position: "relative",
          overflow: "hidden",
          paddingBottom: "12px",
          gap: "12px",
          "&:hover": { color: variableStyles.blueSecondary800 },
          "&::after": {
            content: '""',
            width: "100%",
            height: "2px",
            position: "absolute",
            backgroundColor: variableStyles.blueSecondary800,
            bottom: "0",
            left: "-100%",
            transition: "all .2s linear",
          },
          "& .icon-wrapper": {
            width: "24px",
            height: "24px",
            overflow: "hidden",
            "& img": {
              position: "absolute",
              top: "2px",
              left: "0",
              transition: "transform .2s linear",
              "&.icon-deactive": {
                transform: "scaleX(1)",
              },
              "&.icon-active": { transform: "scaleX(0)" },
            },
          },
        },
        "&.active": {
          "& button": {
            color: variableStyles.blueSecondary800,
            "&::after": {
              left: "0",
            },
            "& .icon-wrapper": {
              "& img": {
                "&.icon-deactive": {
                  transform: "scaleX(0)",
                },
                "&.icon-active": { transform: "scaleX(1)" },
              },
            },
          },
        },
      },
    },
    "& .header-time": {
      fontWeight: variableStyles.fwMedium,
      fontSize: "12px",
      lineHeight: "18px",
      color: variableStyles.NaturalColor1000,
      padding: "4px 10px",
      borderRadius: "8px",
      border: `1px solid ${variableStyles.NaturalColor200}`,
      backgroundColor: variableStyles.NaturalColor100,
    },
  },
  "& .tab-content-wrapper": {
    padding: "0 3px",
    position: "relative",
    "& .tab-content": {
      "&.visible-box": {
        willChange: "display, transform",
        animation: "show-box .2s linear forwards",
      },
      "&.hidden-box": {
        willChange: "display, transform",
        animation: "hidden-box .2s linear forwards",
      },
    },
  },
}));

export const FullWidthTabTimeWrapper = styled("div")(({ theme }) => ({}));
