import { Button } from "@mui/material";
import { Helmet } from "react-helmet";
import {
  ControlForm,
  DragAndDropFile,
  ImageCustom,
  LoadingIndicator,
  TextArea,
  Title
} from "../../components";
import { KeyWordGroupWrapper } from "./styles";
import IconDownload from "../../assets/images/common/icon_download_line_white.svg";
import KeywordDataGrid from "./components/KeywordDataGrid";
import { useState } from "react";
import KeywordGroupService from "../../services/api/keyword.group.service";
import { IGroupKeywordRes } from "../../models/keyword-group";
import { toggleMessage } from "../../components/Toast/Toast";

const KeywordGroup = () => {
  const [isUploadError, setIsUploadError] = useState<boolean>(false);
  const [fileData, setFileData] = useState<File | null>(null);
  const [dataRes, setDataRes] = useState<IGroupKeywordRes | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [textAreaInput, setTextAreaInput] = useState<string | undefined>();

  const downloadExcelTemplate = async () => {
    await KeywordGroupService.getExcelTemplate();
  };

  const onSubmitKeyword = async () => {
    setIsLoading(true);

    if (fileData) {
      const file = new FormData();
      file.append("file", fileData);
      const res = await KeywordGroupService.groupKeywordByExcel(file);
      if (res.success) {
        setDataRes(res.data);
      } else {
        toggleMessage({
          message: "Không thể phân tích file này!",
          type: "error",
          title: "Error Occurred"
        });
      }
      setIsLoading(false);
    } else if (!!textAreaInput && textAreaInput?.length > 0) {
      const textArea = textAreaInput?.split(/\r?\n|\r|\n/g);

      const params = new URLSearchParams();

      textArea.forEach(text => params.append("keywords", text));

      const res = await KeywordGroupService.groupKeyword(params);
      if (res.success) {
        setDataRes(res.data);
      } else {
        toggleMessage({
          message: "Không thể phân tích file này!",
          type: "error",
          title: "Error Occurred"
        });
      }
      setIsLoading(false);
    }
  };

  const downloadKeyword = async () => {
    if (dataRes) {
      const res = await KeywordGroupService.downloadGroupKeywordExcel(dataRes);
      if (!res) {
        toggleMessage({
          message: "Không thể tải file!",
          type: "error",
          title: "Error Occurred"
        });
      }
    }
  };

  return (
    <KeyWordGroupWrapper>
      <Helmet>
        <title>Keyword Group</title>
      </Helmet>
      <div className="group-left-box">
        <Title
          highlightTitle="Keyword"
          normalTitle="Group"
          children={
            <p>
              Tải file excel chứa danh sách từ khóa của bạn lên để bắt đầu phân
              tích. Tải về file mẫu{" "}
              <Button
                size="large"
                variant="text"
                onClick={downloadExcelTemplate}
              >
                tại đây
              </Button>
            </p>
          }
          marginBottom={24}
        />
        <div className="right-box-form">
          <DragAndDropFile
            classname="upload-file"
            handleUploadError={v => setIsUploadError(v)}
            onUploaded={v => setFileData(v)}
            name="upload-file"
            handleRemoveFile={() => setFileData(null)}
          />
          <ControlForm
            title="Danh sách từ khoá"
            infor="Danh sách từ khoá."
            classname="text-area-box"
            isRequired
          >
            <TextArea
              minRows={15}
              maxRows={15}
              placeholder="Nhập danh sách từ khoá bạn muốn"
              disabled={!!fileData}
              onChange={v => setTextAreaInput(v)}
            />
          </ControlForm>
          <Button
            size="xsmall"
            variant="contained"
            className={`submit-button`}
            onClick={onSubmitKeyword}
            disabled={isUploadError || !(!!fileData || !!textAreaInput)}
          >
            Phân nhóm
          </Button>
        </div>
      </div>
      <div className="group-right-box">
        <div className="right-header">
          <p className="header-text">Kết quả phân nhóm</p>
          <Button
            size="xsmall"
            variant="contained"
            className={`download-button`}
            onClick={downloadKeyword}
            disabled={!dataRes}
          >
            <ImageCustom
              src={IconDownload}
              alt="Icon-Download"
              width={"18px"}
              height={"18px"}
            />
            Tải kết quả
          </Button>
        </div>
        <div className="right-content scrollbar-small">
          {isLoading ? (
            <LoadingIndicator className="content-loading" />
          ) : (
            <>
              {!!dataRes && dataRes?.groupKeywords.length > 0 && (
                <ul className="content-list">
                  {dataRes?.groupKeywords.map((keyword, idx) => (
                    <li className="list-item" key={keyword.key}>
                      <KeywordDataGrid data={keyword.list} order={idx + 1} />
                    </li>
                  ))}
                </ul>
              )}
            </>
          )}
        </div>
      </div>
    </KeyWordGroupWrapper>
  );
};

export default KeywordGroup;
