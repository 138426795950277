import { styled } from "@mui/material/styles";
import variableStyles from "../../theme/variable-styles";

export const FormCheckLinkWrapper = styled("div")(({}) => ({
  "@media (min-width:1005px)": {
    width: "716px",
  },
  "@media (max-width:1004.98px)": {
    width: "100%",
  },
  "& .form-wrapper": {
    background: variableStyles.NaturalColor0,
    border: `1px solid ${variableStyles.NaturalColor300}`,
    padding: "12px",
    borderRadius: "12px",
    boxShadow: "1px 2px 24px 0px #0000000F",
    "& .input-wrapper": {
      gap: "8px",
      marginBottom: "12px",
      "& .control-form-link": {
        flex: "1 1 auto",
      },
      "& .control-form-type": {
        flex: "0 0 auto",
        width: "150px",
      },
    },
    "& .button-submit": {
      "@media (min-width:1005px)": {
        width: "160px",
      },
      "@media (max-width:1004.98px)": {
        width: "100px",
      },
    },
  },
}));
