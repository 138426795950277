import React, { useEffect, useRef, useState } from "react";
import { IValue } from "../../../models/common/models.type";
import {
  BubbleCirclePopoverWrapper,
  BubbleCircleWrapper,
  BubbleNoAxisChartWrapper,
} from "./styles";

interface BubbleNoAxisChartProps {
  data: IValue[];
  tooltipText?: string;
  defaultWidth?: number;
}

export default function BubbleNoAxisChart(props: BubbleNoAxisChartProps) {
  const { data, tooltipText, defaultWidth = 60 } = props;
  const ref = useRef<any>(null);

  const [openTooltip, setOpenTooltip] = useState<{
    anchorEl: HTMLElement | null;
    tooltip: { value: number; title: string } | null;
  }>({
    anchorEl: null,
    tooltip: null,
  });

  const handleDetectRatio = (min: number, max: number, value: number) => {
    const positionInWidth = 60 * ((value - min) / (max - min)) + defaultWidth;
    return Math.round(positionInWidth);
  };

  const MAX_NUMBER = Math.max.apply(
    Math,
    data.map(function (o) {
      return o.value;
    })
  );
  const MIN_NUMBER = Math.min.apply(
    Math,
    data.map(function (o) {
      return o.value;
    })
  );
  const colors = [
    "#54B4DC4d",
    "#23B4FF4d",
    "#DB9F004d",
    "#1C7F5E4d",
    "#122e3f4d",
    "#FDCF004d",
    "#1A648A4d",
    "#54BD954d",
    "#9058044d",
    "#920eff4d",
  ];

  const checkCondition = (classname: string) => {
    let result = false;
    const elems = document.querySelectorAll<HTMLElement>(
      "[class*=circle-item]"
    );

    const targetClass = document.querySelector<HTMLElement>(`.${classname}`);
    const rect1 = targetClass?.getBoundingClientRect();
    const radius = (targetClass?.getBoundingClientRect().width || 0) / 2;
    const left = (targetClass?.getBoundingClientRect().left || 0) + radius;
    const top = (targetClass?.getBoundingClientRect().top || 0) + radius;
    for (const itemChild of elems) {
      if (itemChild !== targetClass && !!rect1) {
        const radiusChild = itemChild.getBoundingClientRect().width / 2;
        const leftChild = itemChild.getBoundingClientRect().left + radiusChild;
        const topChild = itemChild.getBoundingClientRect().top + radiusChild;
        const dist = Math.sqrt(
          (leftChild - left) * (leftChild - left) +
            (topChild - top) * (topChild - top)
        );

        if (radius && radiusChild && dist < radius + radiusChild + 10) {
          result = true;
        }
      }
    }
    return result;
  };

  let timer1: NodeJS.Timeout | null = null;

  const handleDetectPosition = () => {
    if (!!ref.current.offsetWidth) {
      const elem = document.querySelectorAll<HTMLElement>(
        "[class*=circle-item]"
      );
      for (const item of elem) {
        const classname = item.className.split(" ")[0];
        do {
          const posx = Math.round(
            Math.random() * (ref.current.offsetHeight - item.offsetWidth)
          );
          const posy = Math.round(
            Math.random() * (ref.current.offsetWidth - item.offsetWidth)
          );
          item.style.top = `${posx}px`;
          item.style.left = `${posy}px`;
          item.style.fontSize = `${item.offsetWidth / 8}px`;
          item.style.lineHeight = `${(item.offsetWidth / 8) * 1.5}px`;
        } while (checkCondition(classname));
      }
      if (!!timer1) {
        clearTimeout(timer1);
        timer1 = null;
      }
    }
  };

  useEffect(() => {
    handleDetectPosition();
  }, [data]);

  useEffect(() => {
    const getwidth = () => {
      if (!timer1) {
        timer1 = setTimeout(() => handleDetectPosition(), 700);
      }
    };
    window.addEventListener("resize", getwidth);
    return () => {
      if (!!timer1) {
        clearTimeout(timer1);
        timer1 = null;
      }
      window.removeEventListener("resize", getwidth);
    };
  }, []);

  return (
    <>
      <BubbleCirclePopoverWrapper
        id="mouse-over-popover"
        sx={{ pointerEvents: "none" }}
        open={!!openTooltip.anchorEl}
        anchorEl={openTooltip.anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        onClose={() => {
          setOpenTooltip({
            anchorEl: null,
            tooltip: null,
          });
        }}
        disableRestoreFocus
      >
        <p className="tooltip-title">{openTooltip.tooltip?.title}</p>
        <p className="tooltip-description">
          {tooltipText}: <span>{openTooltip.tooltip?.value}</span>
        </p>
      </BubbleCirclePopoverWrapper>
      <BubbleNoAxisChartWrapper ref={ref}>
        {data.map((data, idx) => {
          const ratio = handleDetectRatio(MIN_NUMBER, MAX_NUMBER, data.value);
          return (
            <BubbleCircleWrapper
              className={`circle-item-${idx + 1}`}
              ratio={ratio}
              key={data.value}
              background={colors[idx]}
              onMouseEnter={e => {
                setOpenTooltip({
                  anchorEl: e.currentTarget,
                  tooltip: data,
                });
              }}
              onMouseLeave={() => {
                setOpenTooltip({
                  anchorEl: null,
                  tooltip: null,
                });
              }}
            >
              <p
                className="fit-two-line"
                style={{ color: `${colors[idx].replace("4d", "")}` }}
              >
                {data.title}
              </p>
            </BubbleCircleWrapper>
          );
        })}
      </BubbleNoAxisChartWrapper>
    </>
  );
}
