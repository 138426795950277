import api from "../remote/baseApi";
import ApiEndpoints from "../remote/apiEndpoints";
import { envConfig } from "../../config/env.config";
import {
  IBasePagingRes,
  IBaseResponseModel,
} from "../../models/common/models.type";
import {
  IAuditDomainRes,
  IAuditSitemapSearchParams,
  IAuditTaskSearchParams,
  IGetAuditSitemapRes,
  IGetAuditTaskStatusRes,
} from "../../models/audit";

const API_AUTH = ApiEndpoints.AUDIT;
const baseURL = envConfig.API_KEY_ENDPOINT;

const postAuditDomain = (
  domain: string,
  limit?: number
): Promise<IBaseResponseModel<boolean>> => {
  return api.post(baseURL, API_AUTH.POST_START_AUDIT.url, { domain, limit });
};

const getStatusTask = (
  taskId: number
): Promise<IBaseResponseModel<IGetAuditTaskStatusRes>> => {
  return api.get(baseURL, API_AUTH.GET_STATUS_TASK.url, {
    params: { taskId },
  });
};

const cancelAuditData = (
  taskId: number
): Promise<IBaseResponseModel<boolean>> => {
  return api.post(baseURL, API_AUTH.CANCEL_TASK.url, {
    taskId,
  });
};

const getTasks = (
  params: IAuditTaskSearchParams
): Promise<IBaseResponseModel<IBasePagingRes<IGetAuditTaskStatusRes>>> => {
  return api.get(baseURL, API_AUTH.GET_LIST_TASK.url, {
    params: params,
  });
};

const getDetailTask = (
  taskId: number
): Promise<IBaseResponseModel<IAuditDomainRes>> => {
  return api.get(baseURL, API_AUTH.GET_DETAIL_TASK.url, {
    params: { taskId },
  });
};

const getSitemaps = (
  params: IAuditSitemapSearchParams
): Promise<IBaseResponseModel<IBasePagingRes<IGetAuditSitemapRes>>> => {
  return api.get(baseURL, API_AUTH.GET_LIST_TASK.url, {
    params: params,
  });
};

const AuditService = {
  postAuditDomain,
  getStatusTask,
  cancelAuditData,
  getTasks,
  getDetailTask,
  getSitemaps,
};

export default AuditService;
