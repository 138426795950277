import { Snackbar } from "@mui/material";
import { styled } from "@mui/styles";
import variableStyles from "../../theme/variable-styles";

export const SnackbarWrapper = styled(Snackbar)(() => ({
  "&.success": {
    "& .snackbar-wrapper": {
      "& .toast-icon": {
        "&::before": {
          background:
            "radial-gradient(50% 50% at 50% 50%, rgba(0, 237, 81, 0.12) 0%, rgba(0, 237, 123, 0) 100%)"
        }
      }
    }
  },
  "&.error": {
    "& .snackbar-wrapper": {
      "& .toast-icon": {
        "&::before": {
          background:
            "radial-gradient(50% 50% at 50% 50%, rgba(240, 66, 72, 0.24) 0%, rgba(240, 66, 72, 0) 100%)"
        }
      }
    }
  },
  "&.warning": {
    "& .snackbar-wrapper": {
      "& .toast-icon": {
        "&::before": {
          background:
            "radial-gradient(50% 50% at 50% 50%, rgba(255, 212, 38, 0.11) 0%, rgba(255, 212, 38, 0) 100%)"
        }
      }
    }
  },
  "&.info": {
    "& .snackbar-wrapper": {
      "& .toast-icon": {
        "&::before": {
          background:
            "radial-gradient(50% 50% at 50% 50%, rgba(141, 206, 230, 0.12) 0%, rgba(141, 206, 230, 0) 100%)"
        }
      }
    }
  },
  "& .snackbar-wrapper": {
    backgroundColor: variableStyles.GreenPrimaryColor950,
    padding: "12px 16px",
    borderRadius: "8px",
    boxShadow:
      "0px 8px 10px 0px #00000033, 0px 6px 30px 0px #0000001F, 0px 16px 24px 0px #00000024",
    display: "flex",
    alignItems: "center",
    gap: "16px",
    width: "340px",
    overflow: "hidden",
    "& .toast-icon": {
      position: "relative",
      "&::before": {
        content: '""',
        width: "212px",
        height: "212px",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        pointerEvents: "none",
        borderRadius: "50%"
      }
    },
    "& .toast-content": {
      "& .content-title": {
        color: variableStyles.NaturalColor0,
        fontWeight: variableStyles.fwBold,
        fontSize: "14px",
        lineHeight: "22px"
      },
      "& .content-detail": {
        fontFamily: variableStyles.Poppins,
        color: variableStyles.NaturalColor200,
        fontWeight: variableStyles.fwRegular,
        fontSize: "13px",
        lineHeight: "18px"
      }
    }
  }
}));
