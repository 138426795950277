import { forwardRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IGetAuditOverviewErrorRes } from "../../../../../models/audit-report";
import { IStateApp } from "../../../../../models/redux";
import { IGetReportsRes } from "../../../../../models/report";
import AuditReportService from "../../../../../services/api/audit.report.service";
import OverviewCommonTitle from "../../OverviewCommonTitle";
import BackLinkContents from "./components/BackLinkContents";
import ContentContents from "./components/ContentContents";
import TechnicalContents from "./components/TechnicalContents";
import { SEOSectionWrapper } from "./styles";

const SEOSection = forwardRef<any>(function (props, ref) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dataReport = useSelector<IStateApp, IGetReportsRes>(
    appState => appState.project?.dataReport
  );
  const [resDataOverview, setResDataOverview] =
    useState<IGetAuditOverviewErrorRes | null>(null);

  useEffect(() => {
    getDataOfPage();
  }, [dataReport.thirdPartyId]);

  const getDataOfPage = async () => {
    if (!!dataReport.thirdPartyId) {
      setIsLoading(true);
      const resOverview = await AuditReportService.getAuditReportOverviewError(
        dataReport.thirdPartyId
      );

      if (!resOverview.isError && !!resOverview.data) {
        setResDataOverview(resOverview.data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    }
  };

  return (
    <SEOSectionWrapper className="common-layout" id={"seo-section"} ref={ref}>
      <OverviewCommonTitle
        title="SEO Website"
        description="SEO là quá trình giúp website dễ dàng được tìm thấy bởi người dùng khi họ tìm kiếm thông tin trên công cụ tìm kiếm. Việc đánh giá SEO website sẽ chỉ ra những điểm mạnh, điểm yếu và cơ hội cải thiện để website có thể đạt được thứ hạng cao hơn trên kết quả tìm kiếm, từ đó thu hút nhiều lượt truy cập hơn so với đối thủ."
        className="SEO-title"
        scores={78}
      />
      <TechnicalContents
        dataOverview={resDataOverview}
        isLoadingProps={isLoading}
      />
      <ContentContents
        dataOverview={resDataOverview}
        isLoadingProps={isLoading}
      />
      <BackLinkContents />
    </SEOSectionWrapper>
  );
});
export default SEOSection;
