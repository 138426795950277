import { styled } from "@mui/material/styles";
import variableStyles from "../../../theme/variable-styles";

export const TextareaWrapper = styled("div")(({}) => ({
  "& textarea": {
    border: `1px solid ${variableStyles.NaturalColor300}`,
    borderRadius: "8px",
    color: variableStyles.NaturalColor950,
    fontWeight: variableStyles.fwRegular,
    fontSize: "13px",
    lineHeight: "18.2px",
    padding: "12px 8px",
    width: "100%",
    fontFamily: variableStyles.Inter,
    "&::placeholder": {
      color: variableStyles.NaturalColor700,
      fontWeight: variableStyles.fwRegular,
      fontSize: "13px",
      lineHeight: "18.2px",
      fontFamily: variableStyles.Inter
    }
  }
}));
