import React from "react";
import DashboardNavbar from "../../components/dashboard/DashboardNavbar";
import ImgNotFound from "../../assets/images/common/img_notfound_grey.png";
import { ImageCustom } from "../../components";
import { styled } from "@mui/material";
import { NotFoundWrapper } from "./styles";
import DashboardSidebar from "../../components/dashboard/DashboardSidebar";

export default function NotFound() {
  const DashboardLayoutContainer = styled("div")({
    display: "flex",
    overflow: "hidden"
  });

  const DashboardLayoutWrapper = styled("div")(({ theme }) => ({
    flex: "1 1 auto",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column"
  }));

  return (
    <NotFoundWrapper>
      <DashboardNavbar />
      <DashboardLayoutContainer>
        <DashboardSidebar />
        <DashboardLayoutWrapper>
          <div className="notfound-content">
            <p className="notfound-title">Page not found</p>
            <p className="notfound-description">
              Duis dolor sit amet, consectetur adipiscing elitvestibulum in
              pharetra.
            </p>
            <ImageCustom
              src={ImgNotFound}
              alt="Icon-NotFound}"
              width={"768px"}
              height={"294px"}
              className="notfound-image"
            />
          </div>
        </DashboardLayoutWrapper>
      </DashboardLayoutContainer>
    </NotFoundWrapper>
  );
}
