import { ClickAwayListener, PopoverOrigin } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CheckBoxGroupCustom, ImageCustom } from "..";
import IconArrow from "../../assets/images/common/icon_arrow_down_line_black.svg";
import { IValue } from "../../models/common/models.type";
import { FilterCheckBoxCustomWrapper, PopoverWrapper } from "./styles";

interface FilterCheckCustomProps {
  options: IValue[];
  onChange: (value: string[]) => void;
  placeholder: string;
  name: string;
  errors?: any;
  classname?: string;
  defaultValue?: string[];
  popoverWidth?: number;
  valueProps: string[];
  disabled?: boolean;
  anchorOrigin?: PopoverOrigin;
  filterText?: string;
  width?: string;
  isHaveAll?: boolean;
  allText?: string;
  isUntilSubmit?: boolean;
  minWidth?: string;
  size?: "small" | "medium" | "large";
}

export default function FilterCheckBoxCustom(props: FilterCheckCustomProps) {
  const {
    onChange,
    options,
    placeholder,
    errors,
    name = "",
    classname = "",
    defaultValue,
    popoverWidth,
    disabled,
    anchorOrigin,
    filterText,
    width,
    valueProps,
    isHaveAll = false,
    minWidth = "200px",
    size = "medium",
    allText = "All",
  } = props;
  const boxRef = React.useRef<any>();

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [checkedData, setCheckedData] = useState<string[]>(valueProps);
  const [textSelected, setTextSelected] = useState<string | null>(null);

  useEffect(() => {
    return () => {
      setCheckedData([]);
      setTextSelected(null);
    };
  }, []);

  useEffect(() => {
    document.addEventListener("reset-filter", e => {
      setCheckedData(["All"]);
      renderText([allText]);
      onChange([]);
    }); //adding event listener when the component mounts. When dispatch is called, the doThing method gets called.

    return () => {
      document.removeEventListener("reset-filter", () => {});
    }; //removing the listener when the component unmounts.
  }, []);

  useEffect(() => {
    if (valueProps.length > 0) {
      renderText(checkedData);
      setCheckedData(checkedData);
    } else if (isHaveAll && checkedData.length <= 0) {
      setTextSelected("All");
      setCheckedData(["all"]);
    }
  }, [valueProps]);

  const onClose = () => {
    setAnchorEl(null);
    if (checkedData.includes("all")) {
      onChange([]);
    } else {
      onChange(checkedData);
    }
    renderText(checkedData);
  };

  const onOpenSelect = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (!disabled) {
      setAnchorEl(event.currentTarget);
    }
  };

  const renderText = (value: string[]) => {
    const newOptions = isHaveAll
      ? [{ title: allText, value: "all" }, ...options]
      : [...options];
    if (value.length > 1) {
      return setTextSelected(`${value.length} đã chọn`);
    } else if (value.length === 1) {
      const checkedItem = newOptions.find(
        option => option.value.toString() === value[0].toString()
      );
      if (!!checkedItem) {
        return setTextSelected(`${checkedItem.title}`);
      }
    } else {
      return setTextSelected(`${placeholder}`);
    }
  };

  const handleChange = (value: string[]) => {
    if (isHaveAll) {
      if (value.length > 0) {
        if (value.includes("all") && !checkedData.includes("all")) {
          setCheckedData(["all"]);
        } else if (value.includes("all") && checkedData.includes("all")) {
          const newState = value.filter(item => item !== "all");
          setCheckedData(newState);
        } else if (!value.includes("all") && value.length === options.length) {
          const intersection = options.filter(element =>
            checkedData.includes(element.value)
          );
          if (intersection.length + 1 === options.length) {
            setCheckedData(["all"]);
          }
        } else {
          setCheckedData(value);
        }
      } else {
        setCheckedData(["all"]);
      }
    } else {
      setCheckedData(value);
    }
  };
  return (
    <FilterCheckBoxCustomWrapper
      width={width}
      minWidth={minWidth}
      className={`${size}`}
    >
      <PopoverWrapper
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={
          anchorOrigin
            ? anchorOrigin
            : {
                vertical: "bottom",
                horizontal: "left",
              }
        }
        boxref={boxRef}
        popover_width={popoverWidth}
      >
        <ClickAwayListener onClickAway={() => onClose()}>
          <div className="checkbox-wrapper">
            <CheckBoxGroupCustom
              options={
                isHaveAll
                  ? [{ title: allText, value: "all" }, ...options]
                  : options
              }
              onChange={(v: string[]) => handleChange(v)}
              checkedData={checkedData}
              className="checkbox-list"
            />
          </div>
        </ClickAwayListener>
      </PopoverWrapper>
      <div
        className={`${
          errors && !!errors[name] ? "select-box-error" : ""
        }  ${classname} filter-select-wrapper ${!!anchorEl ? "active" : ""}`}
        onClick={e => onOpenSelect(e)}
        ref={boxRef}
      >
        {!!filterText && (
          <p className="filter-text fit-one-line">{filterText}</p>
        )}

        {!!textSelected ? (
          <p className="select-selected-text fit-one-line">{textSelected}</p>
        ) : (
          <p className="select-placeholder fit-one-line">{placeholder}</p>
        )}

        <ImageCustom
          src={IconArrow}
          alt="Icon-Arrow"
          width={"20px"}
          height={"20px"}
          className={`filter-arrow ${!!anchorEl ? "active" : ""}`}
        />
      </div>
      {errors && errors[name]?.message && (
        <p className="error-text">{errors[name]?.message}</p>
      )}
    </FilterCheckBoxCustomWrapper>
  );
}
