import React from "react";
import { StatusFullCircleWrapper } from "./styles";

interface StatusFullCircleProps {
  percent: number;
  className?: string;
  size?: "large" | "small";
  type: "good" | "normal" | "bad";
}

export default function StatusFullCircle(props: StatusFullCircleProps) {
  const { percent, className = "", size = "large", type } = props;
  const Circumference = Math.PI * ((size === "large" ? 71 : 14) * 2);
  return (
    <StatusFullCircleWrapper className={`${className} ${size}`}>
      {size === "large" && <p>{percent}%</p>}
      <svg>
        <linearGradient id="linearColorsGood" x1="0" y1="0" x2="1" y2="1">
          <stop offset="0%" stop-color="#55EBB5"></stop>
          <stop offset="100%" stop-color="#0ACB86"></stop>
        </linearGradient>
        <linearGradient id="linearColorsNormal" x1="0" y1="0" x2="1" y2="1">
          <stop offset="0%" stop-color="#54B4DC"></stop>
          <stop offset="100%" stop-color="#70CBF1"></stop>
        </linearGradient>
        <linearGradient id="linearColorsBad" x1="0" y1="0" x2="1" y2="1">
          <stop offset="0%" stop-color="#DB9F00"></stop>
          <stop offset="100%" stop-color="#F1B822"></stop>
        </linearGradient>
        <circle
          id="border-track"
          cx={`${size === "small" ? "20" : "95"}`}
          cy={`${size === "small" ? "20" : "95"}`}
          r={`${size === "small" ? "14" : "81"}`}
        ></circle>
        <circle
          id="progress"
          cx={`${size === "small" ? "20" : "95"}`}
          cy={`${size === "small" ? "20" : "95"}`}
          r={`${size === "small" ? "14" : "81"}`}
          strokeDashoffset={Circumference - (Circumference / 100) * percent}
          stroke={`url(#${type === "good" ? "linearColorsGood" : type === "normal" ? "linearColorsNormal" : "linearColorsBad"})`}
        ></circle>
        {size === "large" && (
          <circle id="border-boxshadow" cx={`95`} cy={`95`} r="92"></circle>
        )}
      </svg>
    </StatusFullCircleWrapper>
  );
}
