import { styled } from "@mui/material/styles";
import variableStyles from "../../theme/variable-styles";

export const BackLinkTagWrapper = styled("div")(() => ({
  fontWeight: variableStyles.fwRegular,
  fontSize: "10px",
  lineHeight: "20px",
  border: "1px solid",
  width: "max-content",
  padding: "0 8px",
  borderRadius: "100px",
  color: variableStyles.Info800,
  backgroundColor: variableStyles.Info50,
  borderColor: variableStyles.Info300,
  flex: "0 0 auto",
  "&.follow": {
    color: variableStyles.Success600,
    backgroundColor: variableStyles.Success50,
    borderColor: variableStyles.Success400,
  },
  "&.no-follow": {
    color: variableStyles.Error600,
    backgroundColor: variableStyles.Error50,
    borderColor: variableStyles.Error400,
  },
}));
