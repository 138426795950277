import { styled } from "@mui/material/styles";
import { Dialog } from "@mui/material";
import variableStyles from "../../theme/variable-styles";

interface Props {
  width?: number;
}

export const Wrapper = styled(Dialog)<Props>(({ width }) => ({
  "& .MuiPaper-root": {
    minWidth: "344px",
    borderRadius: "12px",
    justifyContent: "center",
    alignItems: "center",
    width: width ? width : "344px",
    "& .modal-icon": {
      margin: "29px 0 20px",
    },
    "& .modal-title": {
      color: variableStyles.NaturalColor950,
      fontWeight: variableStyles.fwSemiBold,
      fontSize: "18px",
      lineHeight: "18px",
      marginBottom: "12px",
      padding: "0 16px",
      textAlign: "center",
    },
    "& .modal-cotent": {
      color: variableStyles.NaturalColor600,
      fontWeight: variableStyles.fwMedium,
      fontSize: "14px",
      lineHeight: "19.6px",
      marginBottom: "30px",
      padding: "0 16px",
      textAlign: "center",
    },
    "& .modal-action": {
      width: "100%",
      borderTop: `1px solid ${variableStyles.NaturalColor200}`,
      backgroundColor: variableStyles.NaturalColor100,
      padding: "16px 20px",
      gap: "10px",
      "& button": {
        height: "40px",
        padding: "12px",
        borderRadius: "10px",
        boxShadow: "none",
        width: "calc(50% - 5px)",
        "&.action-cancel": {
          border: `1px solid ${variableStyles.NaturalColor400}`,
          color: variableStyles.NaturalColor950,
          fontWeight: variableStyles.fwSemiBold,
          fontSize: "14px",
          lineHeight: "14px",
          "&:hover": { backgroundColor: variableStyles.NaturalColor50 },
        },
        "&.action-confirm": {
          color: variableStyles.NaturalColor0,
          fontWeight: variableStyles.fwSemiBold,
          fontSize: "14px",
          lineHeight: "14px",
          backgroundColor: variableStyles.GreenPrimaryColor400,
          "&:hover": { backgroundColor: variableStyles.GreenPrimaryColor500 },
          "&.warning-red": {
            backgroundColor: variableStyles.Error400,
            "&:hover": {
              backgroundColor: variableStyles.Error200,
              color: variableStyles.Error400,
            },
          },
        },
      },
    },
  },
}));
