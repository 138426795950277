import React from "react";
import { EBackLinkTag } from "../../models/common/models.enum";
import { BackLinkTagWrapper } from "./styles";

interface BackLinkTagProps {
  tag: number | string;
}

export default function BackLinkTag(props: BackLinkTagProps) {
  const { tag } = props;

  const renderText = () => {
    switch (tag) {
      case EBackLinkTag.Text:
        return "Text";
      case EBackLinkTag.Form:
        return "Form";
      case EBackLinkTag.Frame:
        return "Frame";
      case EBackLinkTag.Image:
        return "Image";
      case EBackLinkTag.Newlink:
        return "New Link";
      case EBackLinkTag.Lostlink:
        return "Lost Link";
      case EBackLinkTag.Follow:
        return "Follow";
      default:
        return "No follow";
    }
  };
  const renderClassname = () => {
    switch (tag) {
      case EBackLinkTag.Follow:
        return "follow";
      case EBackLinkTag.NoFollow:
        return "no-follow";
      default:
        return "normal";
    }
  };
  return (
    <BackLinkTagWrapper className={renderClassname()}>
      {renderText()}
    </BackLinkTagWrapper>
  );
}
