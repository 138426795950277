import { Box, SxProps, Theme } from "@mui/material";
import { styled } from "@mui/styles";
import Desktop from "../../../../../../../../assets/images/domai-audit/security-section/desktop.png";
import Mobile from "../../../../../../../../assets/images/domai-audit/security-section/mobile.png";
import classNames from "classnames";
import { EStrategy } from "../../ScreenshotOnDevice";
import EmptyImage from "../../../../../../../../assets/images/common/img_project_empty.png";

interface IProps {
  strategy: EStrategy;
  screenshot?: string;
  sx?: SxProps<Theme>;
}

export const Wrapper = styled(Box)(() => ({
  width: "253px",
  height: "211px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",

  "& .wrapper-screenshot": {
    position: "relative",
    width: "253px",
    height: "211px",
    "&.mobile": {
      width: "138px",
      height: "224px"
    },
    "& .wrapper-img": {
      position: "absolute",
      width: "253px",
      height: "211px",
      zIndex: 1,
      "&.mobile": {
        width: "138px",
        height: "224px"
      }
    },
    "& .screenshot-img": {
      objectFit: "cover",
      objectPosition: "top",
      backgroundRepeat: "no-repeat",
      zIndex: 2,
      width: "233px",
      height: "131px",
      position: "absolute",
      top: 11,
      left: 10,
      "&.mobile": {
        borderRadius: 6,
        top: 4,
        left: 18,
        width: "97px",
        height: "195px"
      }
    }
  }
}));

const PerformanceScreenshot = ({ strategy, screenshot, sx }: IProps) => {
  return (
    <Wrapper sx={sx}>
      <Box
        className={classNames(
          "wrapper-screenshot",
          strategy === "mobile" && "mobile"
        )}
      >
        <img
          className={classNames(
            "wrapper-img",
            strategy === "mobile" && "mobile"
          )}
          src={strategy === "desktop" ? Desktop : Mobile}
          alt={
            strategy === "desktop" ? "Desktop screenshot" : "Mobile screenshot"
          }
          width={
            strategy === "desktop" ? "Desktop screenshot" : "Mobile screenshot"
          }
          height={
            strategy === "desktop" ? "Desktop screenshot" : "Mobile screenshot"
          }
        />
        <img
          className={classNames(
            "screenshot-img",
            strategy === "mobile" && "mobile"
          )}
          src={screenshot ?? EmptyImage}
          alt={
            strategy === "desktop" ? "Desktop screenshot" : "Mobile screenshot"
          }
          width={
            strategy === "desktop" ? "Desktop screenshot" : "Mobile screenshot"
          }
          height={
            strategy === "desktop" ? "Desktop screenshot" : "Mobile screenshot"
          }
        />
      </Box>
    </Wrapper>
  );
};

export default PerformanceScreenshot;
