import { styled } from "@mui/material/styles";
import variableStyles from "../../theme/variable-styles";

interface ProgressBarWrapperProps {
  height: string;
  width: string;
  percent: number;
}

export const ProgressBarWrapper = styled("div")<ProgressBarWrapperProps>(
  ({ height, width, percent }) => ({
    width: width,
    height: height,
    backgroundColor: variableStyles.NaturalColor200,
    borderRadius: "50px",
    "&.normal": {
      "& .progress-bar": {
        backgroundColor: variableStyles.blueSecondary500
      }
    },
    "&.warning": {
      "& .progress-bar": {
        backgroundColor: variableStyles.Warning600
      }
    },
    "&.danger": {
      "& .progress-bar": {
        backgroundColor: variableStyles.Error500
      }
    },
    "& .progress-bar": {
      borderRadius: "50px",
      height: height,
      width: `${percent}%`
    }
  })
);
