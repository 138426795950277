import { styled } from "@mui/material/styles";
import variableStyles from "../../theme/variable-styles";

export const CompressPhotoWrapper = styled("div")(() => ({
  padding: "24px",
  width: "584px",
  margin: "0 auto",
  height: "100%",
  "& .upload-files": {
    marginBottom: "12px"
  },
  "& .photo-box-wrapper": {
    backgroundColor: variableStyles.NaturalColor0,
    borderRadius: "12px",
    display: "flex",
    flexDirection: "column",
    height: "calc(100% - 282px)",
    boxShadow:
      "0px 39px 67.8px 0px #1956580A, 0px 21px 37px -15px #1956580F,0px 1px 1px 0px #00000014",
    "& .total-photo": {
      padding: "9.5px 12px",
      borderBottom: `1px solid ${variableStyles.NaturalColor300}`,
      flex: "0 0 auto",
      color: variableStyles.NaturalColor950,
      fontWeight: variableStyles.fwMedium,
      fontSize: "14px",
      lineHeight: "20px",
      "& span": { fontWeight: variableStyles.fwBold }
    }
  },
  "& .photo-box-list": {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    padding: "12px",
    borderBottom: `1px solid ${variableStyles.NaturalColor300}`,
    overflow: "auto",
    flex: "1 1 auto",
    scrollbarGutter: "stable"
  },
  "& .photo-box-actions": {
    padding: "12px",
    display: "flex",
    gap: "10px",
    "& .cancel-button": {
      flex: "0 0 auto",
      width: "108px",
      color: variableStyles.NaturalColor950,
      fontWeight: variableStyles.fwSemiBold,
      fontSize: "14px",
      lineHeight: "14px",
      border: `1px solid ${variableStyles.NaturalColor400}`,
      "&.MuiButton-outlined.Mui-disabled": {
        color: `${variableStyles.NaturalColor800} !important`
      }
    },
    "& .submit-button": {
      flex: "1 1 auto"
    }
  },
  "@media (max-width:830px)": {
    width: "500px"
  }
}));
