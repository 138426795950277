import { styled } from "@mui/material/styles";
import variableStyles from "../../../../../theme/variable-styles";
import ImageTitleUnderline from "../../../../../assets/images/domai-audit/overview-section/image_underline_title_green.png";

export const SEOSectionWrapper = styled("div")(() => ({
  "& .SEO-title": {
    padding: "20px 40px 40px",
  },
  
}));
