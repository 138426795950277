import React from "react";
import ReactMarkdown, { Components } from "react-markdown";

interface MarkdownRendererProps {
  content: string;
  components?: Components | null;
}

const MarkdownRenderer: React.FC<MarkdownRendererProps> = ({
  content,
  components,
}) => {
  return <ReactMarkdown components={components}>{content}</ReactMarkdown>;
};

export default MarkdownRenderer;
