import React from "react";
import { ImageCustom } from "..";
import { ProjectBoxWrapper } from "./styles";
import IconCheck from "../../assets/images/common/icon_check_line_green.svg";

interface ProjectInforProps {
  data: { name: string; domain: string; href: string };
  isActive: boolean;
  disabled?: boolean;
  className?: string;
}

export default function ProjectInforBox(props: ProjectInforProps) {
  const { data, isActive, disabled = false, className = "" } = props;
  return (
    <ProjectBoxWrapper
      onClick={() => {}}
      disabled={disabled}
      className={className}
    >
      <div className="infor-box">
        <figure className="project-avatar">
          <ImageCustom
            src={`https://t1.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=http://${data.domain}&size=24`}
            alt="Avatar-Image"
            width={"24px"}
            height={"24px"}
          />
        </figure>
        <div className="project-name">
          <p className="name-project fit-one-line">{data.name}</p>
          <p className="name-domain fit-one-line">{data.domain}</p>
        </div>
      </div>
      {isActive && (
        <ImageCustom
          src={IconCheck}
          alt="Icon-Check"
          width={"18px"}
          height={"18px"}
          className="icon-check"
        />
      )}
    </ProjectBoxWrapper>
  );
}
