import React from "react";
import { ROUTE_PATH } from "../../../common/constants/app.constant";
import { CommonReportListPage } from "../../../components";
import { EReportType } from "../../../models/common/models.enum";
import { DomainAuditSEOPageWrapper } from "./styles";

export default function DomainAuditSEOPage() {
  return (
    <DomainAuditSEOPageWrapper>
      <CommonReportListPage
        title="Domain Audit SEO"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
        eiusmod tempor incididunt eiusmo"
        type={EReportType.DomainAuditSEO}
        pathProps={ROUTE_PATH.DOMAIN_AUDIT_SEO}
        pathDetail={ROUTE_PATH.DOMAIN_AUDIT_SEO_DETAIL}
        disabledSelectType
      />
    </DomainAuditSEOPageWrapper>
  );
}
