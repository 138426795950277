import { Box } from "@mui/material";
import classNames from "classnames";
import { EAuditErrorStatus } from "../../models/common/models.enum";

interface IProps {
  status?: EAuditErrorStatus;
  height?: string | number;
  width?: string | number;
  borderRadius?: string | number;
  className?: string;
}

const DotIcon = ({
  status = EAuditErrorStatus.Success,
  height = "8px",
  width = "8px",
  borderRadius = "50%",
  className = "",
}: IProps) => {
  return (
    <Box
      sx={{ height, width, borderRadius, flex: "0 0 auto" }}
      className={classNames(
        status === EAuditErrorStatus.Success
          ? "bg-audit-success"
          : status === EAuditErrorStatus.Warning
            ? "bg-audit-warning"
            : status === EAuditErrorStatus.Error
              ? "bg-audit-error"
              : "bg-audit-info",
        className
      )}
    />
  );
};

export default DotIcon;
