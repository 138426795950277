import moment from "moment";
import {
  EAuditErrorStatus,
  ECurrencyCode,
} from "../../models/common/models.enum";
import { CountryLanguage } from "../../i18n/i18n";
import { DATE_FORMAT, DATE_SELECT_FORMAT } from "../../config/constants";
import JSZip from "jszip";
import variableStyles from "../../theme/variable-styles";
import { ContentfulPaint } from "../../models/google/google.page.speed";

const formatNumber = (currency: number = 0, locales?: string) => {
  if (currency === undefined) return;
  if (typeof currency === "string") currency = parseInt(currency);

  return currency?.toLocaleString(locales ?? "it-IT");
};
export function formatNumberCurrency(
  num: number | undefined | null,
  isCurrency?: boolean
) {
  if (!num) {
    return `${0}${isCurrency ? "đ" : ""}`;
  } else {
    try {
      return `${Number(num)
        .toFixed(0)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}${isCurrency ? "đ" : ""}`;
    } catch (error) {
      return `${0}${isCurrency ? "đ" : ""}`;
    }
  }
}

export function nFormatter(num: number, digits: number = 0) {
  const lookup = [
    { value: 1, symbol: "", more: "" },
    { value: 1e3, symbol: "k", more: "+" },
    { value: 1e6, symbol: "M", more: "+" },
    { value: 1e9, symbol: "G", more: "+" },
    { value: 1e12, symbol: "T", more: "+" },
    { value: 1e15, symbol: "P", more: "+" },
    { value: 1e18, symbol: "E", more: "+" },
  ];
  const rx = /\.0+$|(\.\d*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? item.more +
        (Number(num) / item.value).toFixed(digits).replace(rx, "") +
        item.symbol
    : "0";
}

const formatCurrency = (currency: number = 0, currencyCode?: ECurrencyCode) => {
  if (currencyCode === ECurrencyCode.USD) {
    return currency >= 0
      ? `$ ${formatNumber(currency, "en-US")}`
      : `- $ ${formatNumber(-currency, "en-US")}`;
  } else if (currencyCode === ECurrencyCode.VND) {
    return currency >= 0
      ? `VND ${formatNumber(currency, "en-US")}`
      : `- VND ${formatNumber(-currency, "en-US")}`;
  } else if (currencyCode === ECurrencyCode.EUR) {
    return currency >= 0
      ? `€ ${formatNumber(currency, "en-US")}`
      : `- € ${formatNumber(-currency, "en-US")}`;
  }
  return formatNumber(currency);
};
const formatDateTime = (date?: string | Date | null) => {
  const locale = localStorage.getItem("language") as CountryLanguage | null;

  if (!date) return null;
  return moment(date)
    .locale(locale === CountryLanguage.EN ? "en" : "vi")
    .format(DATE_SELECT_FORMAT);
};
const formatDate = (date?: string | Date | null) => {
  const locale = localStorage.getItem("language") as CountryLanguage | null;
  if (!date) return "";
  return moment(date)
    .locale(locale === CountryLanguage.EN ? "en" : "vi")
    .format(DATE_FORMAT);
};
const formatNumbertoDate = (date: number | undefined) => {
  const locale = localStorage.getItem("language") as CountryLanguage | null;

  if (date && date > 0) {
    let dateNew = new Date(date);
    return moment(dateNew.toUTCString())
      .locale(locale === CountryLanguage.EN ? "en" : "vi")
      .format("DD MMM YYYY hh:mm:ss");
  }
  return "--";
};
const generateId = (prefix?: string): string => {
  const _prefix = prefix ? prefix : "";
  const id = `${Math.floor(Math.random() * Math.floor(9999)) + 1111}`;

  return _prefix + Math.floor(Date.now() / 1000) + id;
};
const generateGuidId = (): string => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

const generateUUID = (length: number): string => {
  const stringValue = [...Array(length)].map(t => "x").join("");

  return stringValue.replace(/[x]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

const generateWeek = () => {
  const arr = [0, 1, 2, 3, 4, 5, 6];
  let dayOfWeek = arr.map(item => {
    const day = moment().day(item).format("DD");
    return {
      label: day,
      value: 0,
      gift: [],
    };
  });
  return dayOfWeek;
};
const hasEmail = (email: string) => {
  return !!email.match("[a-zA-Z0-9_\\.\\+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-\\.]+");
};
const hasImage = (mime: string): boolean => {
  return ["image/jpeg", "image/png"].indexOf(mime) === -1;
};
const capitalize = (value: string | undefined) => {
  if (!value) return "";
  return value.charAt(0).toUpperCase() + value.slice(1);
};

function startDownloadFile(file: Buffer, fileName: string) {
  const url = window.URL.createObjectURL(new Blob([file]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
}

function startDownloadBlob(file: Blob, fileName: string) {
  const url = window.URL.createObjectURL(file);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
}

function startDownloadMultipleFiles(files: Buffer[], fileNames: string[]) {
  const link = document.createElement("a");

  link.style.display = "none";

  document.body.appendChild(link);

  for (let i = 0; i < files.length; i++) {
    const url = window.URL.createObjectURL(new Blob([files[i]]));
    link.href = url;
    link.setAttribute("href", fileNames[i]);
    link.click();
  }

  document.body.removeChild(link);
}

export const safeRead = function (obj: any, strfield: string): any {
  let current, prop, props, val, _i, _len;

  //obj = arguments[0], props = 2 <= arguments.length ? [].slice.call(arguments, 1) : [];
  props = strfield.split(".");

  let read = function (obj: any, prop: any) {
    if ((obj != null ? obj[prop] : void 0) == null) {
      return;
    }
    return obj[prop];
  };

  current = obj;
  for (_i = 0, _len = props.length; _i < _len; _i++) {
    prop = props[_i];

    if ((val = read(current, prop))) {
      current = val;
    } else {
      return "";
    }
  }
  return current;
};

export function removeVietnameseAccents(str: string) {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export function renderValue(value: any) {
  if (value) {
    return value;
  }
  return "\u00A0";
}

export async function copyToClipboard(textToCopy: string) {
  // Navigator clipboard api needs a secure context (https)
  if (navigator && navigator.clipboard && window.isSecureContext) {
    await navigator.clipboard?.writeText(textToCopy);
  } else {
    // Use the 'out of viewport hidden text area' trick
    const element = document.createElement("div");
    element.innerHTML = textToCopy;

    // Move textarea out of the viewport so it's not visible
    element.style.position = "absolute";
    element.style.left = "-999999px";

    document.body.prepend(element);

    try {
      let r = document.createRange();

      r.selectNode(element);
      window.getSelection()?.removeAllRanges();
      window.getSelection()?.addRange(r);
      document.execCommand("copy");
    } catch (error) {
      console.error(error);
    } finally {
      window.getSelection()?.removeAllRanges();
      element.remove();
    }
  }
}

export function isValidDomain(domain: string) {
  // Regular expression for checking if string is a valid domain
  const domainRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}\/?/;

  return domainRegex.test(domain);
}

export function hasNoneHttp(domain: string) {
  // Regular expression for checking if string is a valid domain
  const domainRegex = /^([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}/;

  return domainRegex.test(domain);
}

export function truncateText(source: string, size: number) {
  return source.length > size ? source.slice(0, size - 1) + "…" : source;
}

export const b64toBlob = (b64Data: any, filename: string, sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new File(byteArrays, filename);
  return blob;
};

export const onDownloadZip = async (data: File[], name?: string) => {
  const zip = new JSZip();

  if (!!data) {
    for (let i = 0; i < data.length; i++) {
      zip.file(data[i].name, data[i]);
    }
    // Generate the zip file
    const zipData = await zip.generateAsync({
      type: "blob",
      streamFiles: true,
    });

    // Create a download link for the zip file
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(zipData);
    link.download = name || "compress-zip-folder.zip";
    link.click();
  }
};

export const getColorByStatus = (status: EAuditErrorStatus) => {
  switch (status) {
    case EAuditErrorStatus.Success: {
      return {
        primary: variableStyles.Success500,
        secondary: variableStyles.Success100,
      };
    }
    case EAuditErrorStatus.Warning: {
      return {
        primary: variableStyles.Warning500,
        secondary: "rgba(253, 207, 0, .3)",
      };
    }
    case EAuditErrorStatus.Error: {
      return {
        primary: variableStyles.Error500,
        secondary: variableStyles.Error200,
      };
    }
    case EAuditErrorStatus.Infor: {
      return {
        primary: variableStyles.Info500,
        secondary: variableStyles.Info200,
      };
    }
    default: {
      return {
        primary: variableStyles.Info500,
        secondary: variableStyles.Info200,
      };
    }
  }
};
export const getStatusByPoint = (point: number): EAuditErrorStatus => {
  if (point > 0.89) {
    return EAuditErrorStatus.Success;
  } else if (point > 0.49) {
    return EAuditErrorStatus.Warning;
  } else {
    return EAuditErrorStatus.Error;
  }
};

export const getAuditStatus = (data: ContentfulPaint, weight: number) => {
  switch (data.id) {
    case "first-contentful-paint": {
      const value = data.numericValue / 1000;
      // color = variableStyles.Success500;

      if (value > 3) return EAuditErrorStatus.Error;
      else if (value >= 1.8) return EAuditErrorStatus.Warning;
      else if (value < 1.8) return EAuditErrorStatus.Success;
      break;
    }
    case "largest-contentful-paint": {
      const value = data.numericValue / 1000;
      // color = variableStyles.Success500;

      if (value > 4) return EAuditErrorStatus.Error;
      else if (value >= 2.5) return EAuditErrorStatus.Warning;
      else if (value < 2.5) return EAuditErrorStatus.Success;
      break;
    }
    case "total-blocking-time": {
      const value = data.numericValue;
      // color = variableStyles.Success500;

      if (value > 600) return EAuditErrorStatus.Error;
      else if (value >= 200) return EAuditErrorStatus.Warning;
      else if (value < 200) return EAuditErrorStatus.Success;
      break;
    }
    case "cumulative-layout-shift": {
      const value = data.numericValue;
      // color = variableStyles.Success500;

      if (value > 0.25) return EAuditErrorStatus.Error;
      else if (value >= 0.1) return EAuditErrorStatus.Warning;
      else if (value < 0.1) return EAuditErrorStatus.Success;
      break;
    }
    case "speed-index": {
      const value = data.numericValue / 1000;
      // color = variableStyles.Success500;

      if (value > 5.8) return EAuditErrorStatus.Error;
      else if (value >= 3.4) return EAuditErrorStatus.Warning;
      else if (value < 3.4) return EAuditErrorStatus.Success;
      break;
    }
    default: {
      if (
        data.scoreDisplayMode === "informative" ||
        data.scoreDisplayMode === "notApplicable" ||
        data.scoreDisplayMode === "manual"
      ) {
        return EAuditErrorStatus.Infor;
      } else if (data.scoreDisplayMode === "binary") {
        if (data.score === 0) {
          return EAuditErrorStatus.Error;
        } else {
          return EAuditErrorStatus.Success;
        }
      } else if (data.scoreDisplayMode === "metricSavings") {
        if (data.score === 0) {
          return EAuditErrorStatus.Error;
        } else if (data.score === 0.5) {
          return EAuditErrorStatus.Warning;
        } else {
          return EAuditErrorStatus.Success;
        }
      }
      return EAuditErrorStatus.Infor;
    }
  }

  return EAuditErrorStatus.Infor;
};

export const getLevelByStatus = (level: EAuditErrorStatus) => {
  switch (level) {
    case EAuditErrorStatus.Error:
      return 4;
    case EAuditErrorStatus.Warning:
      return 3;
    case EAuditErrorStatus.Success:
      return 2;
    case EAuditErrorStatus.Infor:
      return 1;
    default:
      return 1;
  }
};

export const convertTimeSpanMs = (ms: number, decimal?: number) => {
  const milliseconds = ms % 1000;
  if (!!decimal) {
    return `${milliseconds.toFixed(decimal)}ms`;
  } else {
    return `${milliseconds}ms`;
  }
};

export const convertBytesToKiB = (bytes: number) => (bytes / 1024).toFixed(2);

export const formatTime = (ms: number) => {
  if (ms < 100) {
    return `${ms.toFixed(2)}ms`;
  }

  const second = ms / 1000;

  return `${second.toFixed(2)}s`;
};

export const checkStatusCode = (statusCode: number) => {
  if (statusCode === 200) {
    return EAuditErrorStatus.Success;
  } else if (statusCode === 301) {
    return EAuditErrorStatus.Warning;
  } else {
    return EAuditErrorStatus.Error;
  }
};

const CommonHandle = {
  formatNumber,
  nFormatter,
  formatCurrency,
  generateId,
  generateWeek,
  formatDateTime,
  formatDate,
  formatNumbertoDate,
  generateGuidId,
  hasEmail,
  hasImage,
  capitalize,
  startDownloadFile,
  safeRead,
  generateUUID,
  removeVietnameseAccents,
  renderValue,
  copyToClipboard,
  formatNumberCurrency,
  truncateText,
  startDownloadMultipleFiles,
  startDownloadBlob,
  b64toBlob,
  onDownloadZip,
  convertBytesToKiB,
  convertTimeSpanMs,
  getAuditStatus,
  checkStatusCode,
};

export default CommonHandle;
