import { Popover } from "@mui/material";
import { styled } from "@mui/material/styles";
import variableStyles from "../../theme/variable-styles";

export const TabletFilterModalWrapper = styled(Popover)(() => ({
  "& .MuiPaper-root": {
    padding: "20px",
    borderRadius: "12px",
    width: "492px"
  },
  "& .filter-modal-text": {
    color: variableStyles.NaturalColor950,
    fontWeight: variableStyles.fwMedium,
    fontSize: "14px",
    lineHeight: "19.6px",
    marginBottom: "16px"
  },
  "& .filter-checkboxs": {
    display: "flex",
    alignItems: "center",
    gap: "12px",
    paddingBottom: "16px",
    marginBottom: "16px",
    borderBottom: `1px solid ${variableStyles.NaturalColor200}`,
    flexWrap: "wrap"
  },
  "& .filter-action": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    gap: "12px",
    "& .clear-date-button": {
      color: variableStyles.blueSecondary700,
      fontFamily: variableStyles.Inter,
      fontWeight: variableStyles.fwSemiBold,
      fontSize: "12px",
      lineHeight: "12px",
      textDecoration: "underline",
      width: "max-content",
      backgroundColor: "transparent"
    }
  }
}));
