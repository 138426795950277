import React, { useEffect } from "react";
import {
  Control,
  FieldErrors,
  useFieldArray,
  UseFormReset
} from "react-hook-form";

import { ControlForm, ImageCustom } from "../../../../..";
import InputForm from "../../../../../controls/InputForm";
import { Button } from "@mui/material";
import IconLink from "../../../../../../assets/images/common/icon_link_line_grey.svg";
import IconPlusGrey from "../../../../../../assets/images/common/icon_plus_circle_grey_line_white.svg";
import { DomainCompetitiveBoxWrapper } from "../styles";
import IconDelete from "../../../../../../assets/images/common/icon_minus_circle_red_line_white.svg";
import { ICreateDomainCompatitiveReq } from "../../../../../../models/report";

interface DomainCompetitiveReportProps {
  control: Control<ICreateDomainCompatitiveReq, any>;
  errors: FieldErrors<ICreateDomainCompatitiveReq>;
  reset: UseFormReset<ICreateDomainCompatitiveReq>;
}

export default function DomainCompetitiveReport(
  props: DomainCompetitiveReportProps
) {
  const { control, errors, reset } = props;

  const MAX_ITEMS = 2;
  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "compareWith"
  });

  return (
    <DomainCompetitiveBoxWrapper>
      <div
        className={`domain-competitive-scroll ${fields.length < MAX_ITEMS ? "scroll-full" : "scroll-less"} scrollbar-small`}
      >
        <ControlForm
          title="Tên thương hiệu của bạn"
          infor="Làm nổi bật sản phẩm/dịch vụ bằng tiêu đề thu hút người dùng."
        >
          <InputForm
            placeholder="Tên thương hiệu của bạn"
            required
            name="brandName"
            errors={errors}
            control={control}
            size="small"
          />
        </ControlForm>
        <ControlForm
          title="Domain doanh nghiệp của bạn"
          infor="Làm nổi bật sản phẩm/dịch vụ bằng tiêu đề thu hút người dùng."
        >
          <InputForm
            placeholder="https://..."
            required
            name={"target"}
            errors={errors}
            control={control}
            size="small"
            startIcon={IconLink}
            isPasteLink
          />
        </ControlForm>
        {fields.map((field, idx) => (
          <div className="domain-competitive-form" key={idx}>
            <ControlForm
              title={`Tên doanh nghiệp ${idx + 1}`}
              infor="Làm nổi bật sản phẩm/dịch vụ bằng tiêu đề thu hút người dùng."
              classname="competitive-input-wrapper"
            >
              <InputForm
                placeholder="Tên doanh nghiệp"
                required
                name={`compareWith[${idx}].brandName`}
                errors={errors.compareWith?.[idx]?.brandName}
                control={control}
                size="small"
                isNotSafeRead
              />
            </ControlForm>
            <ControlForm
              title={`Domain doanh nghiệp ${idx + 1}`}
              infor="Làm nổi bật sản phẩm/dịch vụ bằng tiêu đề thu hút người dùng."
              classname="competitive-input-wrapper"
            >
              <InputForm
                placeholder="https://..."
                required
                name={`compareWith.[${idx}].domainUrl`}
                errors={errors.compareWith?.[idx]?.domainUrl}
                control={control}
                size="small"
                startIcon={IconLink}
                isLink
                isNotSafeRead
              />
            </ControlForm>
            {fields.length > 1 && (
              <Button onClick={() => remove(idx)} className="delete-button">
                <ImageCustom
                  src={IconDelete}
                  alt="Icon-Delete"
                  width={"18px"}
                  height={"18px"}
                />
              </Button>
            )}
          </div>
        ))}
      </div>
      {fields.length < MAX_ITEMS && (
        <Button
          onClick={() => append({ brandName: "", domainUrl: "" })}
          className="add-more-button"
        >
          <ImageCustom
            src={IconPlusGrey}
            alt="Icon-Plus"
            width={"20px"}
            height={"20px"}
          />
          <p>Add more</p>
        </Button>
      )}
    </DomainCompetitiveBoxWrapper>
  );
}
