import { styled } from "@mui/material/styles";
import variableStyles from "../../theme/variable-styles";

export const CommonReportListPageWrapper = styled("div")(({ theme }) => ({
  "& .domain-audit-header": {
    padding: "12px 24px 20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: `1px solid ${variableStyles.NaturalColor200}`,
    "& .header-title-wrapper": {
      maxWidth: "388px",
      "& .header-title": {
        display: "flex",
        gap: "8px",
        alignItems: "center",
        marginBottom: "8px",
        "& .header-name": {
          color: variableStyles.NaturalColor950,
          fontWeight: variableStyles.fwSemiBold,
          fontSize: "24px",
          lineHeight: "29.05px",
        },
        "& .header-count": {
          color: variableStyles.blueSecondary700,
          fontWeight: variableStyles.fwMedium,
          fontSize: "12px",
          lineHeight: "18px",
          padding: "2px 8px",
          backgroundColor: variableStyles.blueSecondary100,
          borderRadius: "16px",
          flex: "0 0 auto",
        },
      },
      "& .header-description": {
        color: variableStyles.NaturalColor950,
        fontWeight: variableStyles.fwRegular,
        fontSize: "14px",
        lineHeight: "22.4px",
      },
    },
    "& .header-action": { display: "flex", gap: "12px", flex: "0 0 auto" },
  },
  "& .domain-audit-content-wrapper": {
    padding: "20px 24px",
    "& .audit-filter-box": {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      gap: "12px",
      marginBottom: "14px",
    },
  },
}));
