import { styled } from "@mui/material/styles";

export const CrawledPageDomainAuditWrapper = styled("div")(() => ({
  padding: "22px 24px 24px",
  height: "100%",
  "& .crawled-filter-wrapper": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "12px",
    "& .filter-checkbox": {
      display: "flex",
      alignItems: "center",
      gap: "12px",
    },
    "& .filter-mfilter, & .filter-sfilter": { minWidth: "200px" },
  },
}));
