import api from "../remote/baseApi";
import ApiEndpoints from "../remote/apiEndpoints";
import { envConfig } from "../../config/env.config";
import { IBaseResponseModel } from "../../models/common/models.type";
import CommonHandle from "../../common/handles/common.handles";
import {
  IDownloadGroupKeyword,
  IGroupKeywordRes,
} from "../../models/keyword-group";
import { Buffer } from "buffer";

const API_KEYWORD_GROUP = ApiEndpoints.KEYWORD_GROUP;
const baseURL = envConfig.API_KEY_ENDPOINT;

const groupKeyword = (
  params: FormData
): Promise<IBaseResponseModel<IGroupKeywordRes>> => {
  return api.post(baseURL, API_KEYWORD_GROUP.GROUP_KEYWORD.url, params, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};

const groupKeywordByExcel = (
  params: FormData
): Promise<IBaseResponseModel<IGroupKeywordRes>> => {
  return api.post(
    baseURL,
    API_KEYWORD_GROUP.GROUP_KEYWORD_BY_EXCEL.url,
    params,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

const getExcelTemplate = async (): Promise<void> => {
  const res: any = await api.post(
    baseURL,
    API_KEYWORD_GROUP.GROUP_KEYWORD_DOWNLOAD_EXCEL_TEMPLATE.url,
    undefined,
    {
      responseType: "blob",
    }
  );
  if (!!res) {
    const fileName = `excel-keyword-temlate.xlsx`;
    CommonHandle.startDownloadFile(res, fileName);
  }
};

const downloadGroupKeywordExcel = async (
  params: IGroupKeywordRes
): Promise<boolean> => {
  const res: IBaseResponseModel<IDownloadGroupKeyword> = await api.post(
    baseURL,
    API_KEYWORD_GROUP.GROUP_KEYWORD_DOWNLOAD_EXCEL.url,
    params
  );
  if (!!res.success) {
    const fileName = `excel-keyword-group.xlsx`;
    const bufferResult = Buffer.from(res.data.data);
    CommonHandle.startDownloadFile(bufferResult, fileName);
    return true;
  }
  return false;
};

const KeywordGroupService = {
  groupKeywordByExcel,
  getExcelTemplate,
  downloadGroupKeywordExcel,
  groupKeyword,
};

export default KeywordGroupService;
