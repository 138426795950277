import { styled } from "@mui/material/styles";
import variableStyles from "../../../../../theme/variable-styles";

export const PostWrapper = styled("div")(() => ({
  display: "flex",
  backgroundColor: variableStyles.NaturalColor100,
  padding: "12px",
  gap: "12px",
  height: "100%",
  "& .post-box-left": {
    minWidth: "300px",
    maxWidth: "452px",
    position: "relative",
    "& .left-form-wrapper": {
      backgroundColor: variableStyles.NaturalColor0,
      padding: "16px",
      borderRadius: "12px",
      border: `1px solid ${variableStyles.NaturalColor300}`,
      boxShadow:
        "0px 39px 67.8px 0px #1956580A, 0px 21px 37px -15px #1956580F,0px 1px 1px 0px #00000014",
      marginBottom: "12px",
      "& .left-form": {
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        "& .select-wrapper": {
          display: "flex",
          gap: "12px"
        }
      }
    },
    "& .suggest-loading": {
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translateX(-50%)"
    },
    "& .suggest-list": {
      display: "flex",
      flexDirection: "column",
      gap: "12px",
      overflow: "auto",
      height: "calc(100vh - 426px)",
      paddingBottom: "12px"
    }
  },
  "& .post-box-right": {
    flex: "1 1 auto",
    minWidth: "370px",
    "& .right-action": {
      display: "flex",
      justifyContent: "flex-end",
      gap: "14px",
      alignItems: "center",
      marginBottom: "12px",
      "& .peformance-box": {
        display: "flex",
        alignItems: "center",
        gap: "6px",
        "& p": {
          color: variableStyles.NaturalColor950,
          fontWeight: variableStyles.fwSemiBold,
          fontSize: "14px",
          lineHeight: "21px"
        }
      }
    },

    "& .text-editor": {
      height: "unset",
      "@media (min-width:1393px)": {
        "& .quill-custom": {
          height: "calc(100vh - 187px)",
          "& .ql-container": {
            height: "calc(100vh - 296px)",
            overflow: "auto"
          }
        }
      },
      "@media (max-width:1392px) and (min-width:1071px)": {
        "& .quill-custom": {
          height: "calc(100vh - 187px)",
          "& .ql-container": {
            height: "calc(100vh - 335px)",
            overflow: "auto"
          }
        }
      },

      "@media (max-width:1070px)": {
        "& .quill-custom": {
          height: "calc(100vh - 187px)",
          "& .ql-container": {
            height: "calc(100vh - 368px)",
            overflow: "auto"
          }
        }
      }
    }
  },
  "@media (max-width:1230px)": {
    "& .post-box-left .left-form-wrapper .left-form  .select-wrapper": {
      flexDirection: "column",
      gap: "8px"
    },
    "& .post-box-left .suggest-list": { height: "calc(100vh - 486px)" }
  }
}));
