import React from "react";
import moment from "moment";
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from "recharts";
import {
  ContentType,
  Payload,
} from "recharts/types/component/DefaultLegendContent";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";
import variableStyles from "../../../theme/variable-styles";
import { BarChartCustomWrapper } from "./styles";
import { IChartValue } from "../../../models/common/models.type";
import CommonHandle from "../../../common/handles/common.handles";

interface BarChartCustomProps {
  data: IChartValue[];
  listKeyField: string[];
  XAxisKey?: string;
  YAxisKey?: string;
  XAxisType?: "number" | "category";
  YAxisType?: "number" | "category";
  unit?: string;
  isDateXAxisKey?: boolean;
  layout?: "horizontal" | "vertical";
  height?: string;
  reversedYAxis?: boolean;
  colorList?: string[];
  colorFillList?: string[];
  colorTextList?: string[];
  isHasLabelList?: boolean;
  reversed?: boolean;
  fieldTextList?: { value: string; color: string }[];
}

export default function BarChartCustom(props: BarChartCustomProps) {
  const {
    data,
    XAxisKey,
    YAxisKey,
    XAxisType = "category",
    YAxisType = "category",
    listKeyField,
    unit = "",
    isDateXAxisKey,
    layout = "horizontal",
    height,
    reversedYAxis = false,
    colorFillList = [variableStyles.blueSecondary200, variableStyles.Info600],
    colorTextList = [variableStyles.Info900, variableStyles.NaturalColor0],
    isHasLabelList = true,
    reversed = false,
    fieldTextList,
  } = props;

  const renderLabel = (props: any) => {
    return `${props}${unit}`;
  };

  const CustomTooltip = ({
    active,
    payload,
    label,
  }: TooltipProps<ValueType, NameType>) => {
    if (!!fieldTextList && !!payload && !!payload.length) {
      return (
        <div className="custom-tooltip">
          {fieldTextList.map((item, idx) => (
            <p className="YAxis-text" key={idx}>
              {item.value}: {payload[idx].value}
              {unit}
            </p>
          ))}
          <p className="XAxis-text">{label}</p>
        </div>
      );
    } else {
      if (active && payload && payload.length) {
        return (
          <div className="custom-tooltip">
            {payload.map((item, idx) => (
              <p className="YAxis-text" key={idx}>
                {item.name}: {item.value}
                {unit}
              </p>
            ))}
            <p className="XAxis-text">{label}</p>
          </div>
        );
      }
    }

    return <></>;
  };

  interface IPropsType {
    payload?: Payload[];
  }
  const renderLegend: ContentType = (props: IPropsType) => {
    const { payload } = props;

    return (
      <ul className="legend-list">
        {!!fieldTextList ? (
          <>
            {fieldTextList?.map((entry: any, index: any) => (
              <li key={`item-${index}`}>
                <div style={{ backgroundColor: entry.color }} />
                <p> {entry.value}</p>
              </li>
            ))}
          </>
        ) : (
          <>
            {payload?.map((entry: any, index: any) => (
              <li key={`item-${index}`}>
                <div style={{ backgroundColor: entry.color }} />
                <p> {entry.value}</p>
              </li>
            ))}
          </>
        )}
      </ul>
    );
  };

  const formatXAxis = (tickFormat: string) => {
    return moment(tickFormat).format("DD/MM/YYYY");
  };

  return (
    <BarChartCustomWrapper height={height}>
      <ResponsiveContainer width="100%" height={undefined}>
        <BarChart
          data={data}
          margin={{
            top: 0,
            right: 10,
            left: 10,
            bottom: 0,
          }}
          layout={layout}
        >
          <CartesianGrid
            strokeWidth={1}
            stroke={`${variableStyles.NaturalColor200}`}
            horizontal={layout === "horizontal"}
            vertical={layout === "vertical"}
          />
          <XAxis
            dataKey={XAxisKey}
            tickFormatter={tick => {
              if (isDateXAxisKey) {
                return formatXAxis(tick);
              } else {
                return tick;
              }
            }}
            type={XAxisType}
            tick={{
              color: variableStyles.NaturalColor950,
              fontWeight: variableStyles.fwMedium,
              fontSize: "10px",
            }}
            axisLine={false}
            tickLine={false}
            reversed={reversed}
          />
          <YAxis
            type={YAxisType}
            tick={{
              color: variableStyles.NaturalColor900,
              fontWeight: variableStyles.fwRegular,
              fontSize: "10px",
            }}
            tickFormatter={tick => {
              if (isDateXAxisKey) {
                return formatXAxis(tick);
              } else {
                return CommonHandle.truncateText(tick, 10);
              }
            }}
            dataKey={YAxisKey}
            axisLine={false}
            tickLine={false}
            reversed={reversedYAxis}
          />
          <Tooltip
            content={<CustomTooltip />}
            cursor={{
              stroke: `${variableStyles.blueSecondary200}`,
              strokeWidth: 1,
              fill: "transparent",
            }}
          />
          <Legend
            content={renderLegend}
            verticalAlign="top"
            wrapperStyle={{ top: "-30px", left: "30px" }}
          />
          {listKeyField.map((field, idx) => (
            <Bar
              dataKey={field}
              fill={colorFillList[idx]}
              minPointSize={5}
              stackId="a"
              barSize={32}
              radius={2}
              key={field}
            >
              {isHasLabelList && (
                <LabelList
                  dataKey={field}
                  position="center"
                  fill={colorTextList[idx]}
                  fontWeight={`${variableStyles.fwMedium}`}
                  fontSize={`${"10px"}`}
                  formatter={renderLabel}
                />
              )}
            </Bar>
          ))}
        </BarChart>
      </ResponsiveContainer>
    </BarChartCustomWrapper>
  );
}
