export default function Select(theme: any) {
  return {
    MuiSelect: {
      styleOverrides: {
        root: {
          "& .MuiSelect-select": {
            padding: "12.5px 12px"
          },
          "& .MuiInputBase-inputSizeSmall": {
            padding: "8.5px 12px"
          }
        },
        icon: {}
      }
    }
  };
}
