import React from "react";
import { Avatar, ImageCustom, ProgressBar } from "..";
import IconInfor from "../../assets/images/domai-audit/overview-section/icon_infor_fill_grey.svg";
import { OverviewIndexBoxWrapper } from "./styles";
import IconArrowUp from "../../assets/images/domai-audit/overview-section/icon_arrowup_full_line_green.svg";
import IconArrowDownOrgane from "../../assets/images/domai-audit/overview-section/icon_arrowdown_full_line_organe.svg";
import variableStyles from "../../theme/variable-styles";
import { Tooltip } from "@mui/material";

interface OverviewIndexBoxProps {
  type: "normal" | "warning" | "danger";
  percentHistory?: number;
  percent: number;
  title: string;
  icon?: string;
  logo?: string;
  maxWidth?: string; infor: string
}

export default function OverviewIndexBox(props: OverviewIndexBoxProps) {
  const {
    type,
    percent,
    percentHistory,
    title,
    icon,
    logo,
    maxWidth = "277px", infor
  } = props;
  return (
    <OverviewIndexBoxWrapper
      className={`${type.toString()}`}
      maxwidth={maxWidth}
    >
      <div className="box-header">
        <div className="header-title">
          <p className="title-text fit-one-line">{title}</p>
          <Tooltip title={infor} placement="top-start">
            <div>
              <ImageCustom
                src={IconInfor}
                alt="Icon-Infor"
                width={"14px"}
                height={"14px"}
              />
            </div>
          </Tooltip>
        </div>
        <figure>
          {!!logo ? (
            <Avatar
              src={logo}
              alt="Icon-Title"
              width={40}
              height={40}
              borderColor={variableStyles.NaturalColor300}
            />
          ) : (
            <ImageCustom
              src={icon}
              alt="Icon-Title"
              width={"36px"}
              height={"36px"}
            />
          )}
        </figure>
      </div>
      <p className="box-index">{percent}%</p>
      <ProgressBar percent={percent} className="progress-bar" type={type} />
      {!!percentHistory && (
        <div className="analysis-wrapper">
          <ImageCustom
            src={type === "normal" ? IconArrowUp : IconArrowDownOrgane}
            alt="Icon-Index"
            width={"12px"}
            height={"12px"}
          />

          <p className="analysis-text fit-one-line">
            <span>
              {type === "normal" ? "+" : "-"}
              {percentHistory}%
            </span>{" "}
            so với tuần trước
          </p>
        </div>
      )}
    </OverviewIndexBoxWrapper>
  );
}
