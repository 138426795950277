import { Control, Controller } from "react-hook-form";
import { UIEventHandler } from "react";
import InputAutocomplete from "../Autocomplete";
import { Box } from "@mui/material";
import { IValue } from "../../models/common/models.type";

export interface FormAutocompleteProps {
  name: string;
  control: Control<any, object>;
  errors: any;
  options: IValue[];
  label?: React.ReactNode;
  onScroll?: UIEventHandler<HTMLUListElement>;
  onFocus?: () => void;
  onBlur?: () => void;
  maxHeight?: string | number;
  disabled?: boolean;
  onChange?: (value: string | number) => void;
  value?: string | number | null;
  placeholder?: string;
  isFreeSolo?: boolean;
  classname?: string;
  onInputChange?: (text: string) => void;
  startIcon?: string;
  endIcon?: string;
}

export const FormAutocomplete = ({
  name,
  label,
  control,
  errors,
  options,
  onScroll,
  maxHeight,
  disabled,
  onChange,
  value,
  placeholder,
  onFocus,
  onBlur,
  isFreeSolo,
  classname = "classname",
  onInputChange,
  startIcon,
  endIcon
}: FormAutocompleteProps) => {
  return (
    <Box
      component="div"
      sx={{
        position: "relative",
        "&:hover .buttonInformation": { display: "block" }
      }}
      className={classname}
    >
      <Controller
        control={control}
        name={name}
        render={field => {
          return (
            <InputAutocomplete
              label={label}
              id={"combo-box-" + name}
              options={options}
              onChange={
                onChange
                  ? e => {
                      onChange(e);
                      field.field.onChange(e);
                    }
                  : field.field.onChange
              }
              value={value || field.field.value}
              onScroll={onScroll}
              onFocus={onFocus}
              maxHeight={maxHeight}
              disabled={disabled}
              errors={errors[name]}
              placeholder={placeholder}
              onBlur={onBlur}
              isFreeSolo={isFreeSolo}
              onInputChange={onInputChange}
              startIcon={startIcon}
              endIcon={endIcon}
            />
          );
        }}
      />
    </Box>
  );
};
