import { styled } from "@mui/material/styles";
import variableStyles from "../../theme/variable-styles";
import background from "../../assets/images/login/login_img_background.png";
import underline from "../../assets/images/login/login_img_underline.svg";

export const RegisterWrapper = styled("div")(() => ({
  display: "flex",
  height: "100vh",
  "& .login-box-left": {
    width: "566px",
    flex: "0 0 auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "97px",
    "& .left-logo": {
      marginBottom: "82px",
      display: "inline-block",
      width: "fit-content",
    },
    "& h2": {
      color: variableStyles.NaturalColor1000,
      fontWeight: variableStyles.fwSemiBold,
      fontSize: "32px",
      lineHeight: "38.73px",
      marginBottom: "40px",
    },
    "& form": {
      display: "flex",
      flexDirection: "column",
      gap: "16px",
      marginBottom: "27px",
    },
    "& .forgot-button": {
      margin: "0 auto 16px",
    },
    "& .left-divider": {
      fontFamily: variableStyles.Poppins,
      color: variableStyles.NaturalColor600,
      fontWeight: variableStyles.fwMedium,
      fontSize: "14px",
      lineHeight: "21px",
      marginBottom: "27px",
      "& .MuiDivider-wrapper": {
        paddingLeft: "24px",
        paddingRight: "24px",
      },
    },
    "& .left-google-button": {
      border: `1px solid ${variableStyles.NaturalColor300}`,
      gap: "12px",
      color: "#171725",
      fontWeight: variableStyles.fwSemiBold,
      fontSize: "14px",
      lineHeight: "16px",
      marginBottom: "40px",
    },
    "& .sign-up-question": {
      display: "flex",
      gap: "0.5ch",
      justifyContent: "center",
      "& button": {
        color: variableStyles.GreenPrimaryColor400,
        fontWeight: variableStyles.fwSemiBold,
        fontSize: "15px",
        lineHeight: "24px",
      },
      "& p": {
        fontWeight: variableStyles.fwMedium,
        fontSize: "15px",
        lineHeight: "24px",
      },
    },
  },
  "& .login-box-right": {
    flex: "1 1 auto",
    background: `url(${background}) center/cover no-repeat`,
    position: "relative",
    "& .right-text": {
      color: variableStyles.colorBlack,
      fontWeight: variableStyles.fwBold,
      fontSize: "34px",
      lineHeight: "38px",
      margin: "105px 90px 0",
      position: "relative",
      "&::after": {
        content: '""',
        width: "385px",
        height: "22px",
        position: "absolute",
        bottom: "-36px",
        left: "0",
        background: `url(${underline})  center/contain no-repeat`,
      },
    },
    "& .right-banner": {
      left: "calc(50% + 20px)",
      transform: "translateX(-50%)",
      top: "32%",
      position: "absolute",
    },
  },
  "& .button-submit": {
    "& .text-submit": {
      "&.is-loading": {
        visibility: "hidden",
      },
    },
  },
  "@media (max-width:1240px) and (min-width: 868px)": {
    "& .login-box-left": {
      width: "400px",
      padding: "40px",
    },
    "& .login-box-right": {
      "& .right-text": {
        fontSize: "30px",
        lineHeight: "36px",
        margin: "80px 30px 0",
      },
      "& .right-banner": {
        transform: "translateX(-50%) scale(0.7)",
      },
    },
  },
  "@media (max-width: 868px)": {
    "& .login-box-left": {
      width: "400px",
      padding: "40px",
    },
    "& .login-box-right": {
      "& .right-text": {
        fontSize: "28px",
        lineHeight: "34px",
        margin: "80px 30px 0",
      },
      "& .right-banner": {
        transform: "translateX(-50%) scale(0.55)",
      },
    },
  },
}));
