import { OutlinedInput, InputAdornment } from "@mui/material";
import { InputSearchWrapper } from "./styles";
import SearchIcon from "../../../assets/images/common/icon_search_line_grey.svg";
import { ImageCustom } from "../..";
import IconError from "../../../assets/images/common/icon_error_fill_orange.svg";
import SelectCustom from "../../SelectCustom";
import { ETypeLinkCheck } from "../../../models/common/models.enum";
import { IValue } from "../../../models/common/models.type";

type Props = {
  id?: string;
  name?: string;
  type?: React.InputHTMLAttributes<unknown>["type"];
  required?: boolean;
  value?: unknown;
  onChange?: (event: any) => void;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  onBlur?: (event: any) => void;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  width?: string;
  typeStyle?: "outlined" | "contained";
  size?: "small" | "medium";
  error?: boolean;
  helperText?: any;
  selectData?: IValue[];
  onChangeSelect?: (value: string | number) => void;
  valueSelect?: string | number;
};

const InputSearch = ({
  type,
  id,
  placeholder,
  value,
  onChange,
  onKeyDown,
  onBlur,
  disabled,
  className,
  width,
  typeStyle,
  size = "small",
  error,
  helperText,
  selectData,
  onChangeSelect,
  valueSelect,
}: Props) => {
  return (
    <InputSearchWrapper
      className={`${className} search-wrapper`}
      width={width}
      typestyle={typeStyle}
      size={size}
    >
      <OutlinedInput
        id={id}
        disabled={disabled}
        type={type}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        error={error}
        onBlur={onBlur}
        placeholder={placeholder}
        startAdornment={
          <InputAdornment position="start">
            <ImageCustom
              src={SearchIcon}
              alt="Notification-Icon"
              width={"24px"}
              height={"24px"}
            />
          </InputAdornment>
        }
        endAdornment={
          <>
            {!!selectData && !!onChangeSelect && (
              <SelectCustom
                size="small"
                options={selectData}
                onChange={e => {
                  onChangeSelect(e.target.value);
                }}
                placeholder="Chọn loại"
                isSelectedIcon
                value={valueSelect}
                className="search-select"
              />
            )}
          </>
        }
      />
      {error && !!helperText && (
        <div className="helper-text-error">
          <ImageCustom
            src={IconError}
            alt="Icon-Error"
            width={"18px"}
            height={"18px"}
          />
          <p className="input-search-error">{helperText}</p>
        </div>
      )}
    </InputSearchWrapper>
  );
};

export default InputSearch;
