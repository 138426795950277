import { Checkbox, FormControlLabel } from "@mui/material";
import { styled } from "@mui/material/styles";
import variableStyles from "../../../theme/variable-styles";

export const CheckBoxWrapper = styled(Checkbox)(({ theme }) => ({
  padding: 0,
  marginRight: "8px"
}));

export const FormControlLabelWrapper = styled(FormControlLabel)(
  ({ theme }) => ({
    margin: "0",
    "& .radio-label": {
      color: variableStyles.NaturalColor900,
      fontWeight: variableStyles.fwMedium,
      fontSize: "14px",
      lineHeight: "14px",
      "&.active": {
        color: variableStyles.NaturalColor1000
      }
    }
  })
);
