import { Box, Divider, Paper, SxProps, Theme, Typography } from "@mui/material";
import { EStrategy } from "../ScreenshotOnDevice";
import { PageSpeedOnDeviceWrapper } from "./styles";
import ProgressHalfCircle from "./components/ProgressHalfCircle";
import {
  AuditRef,
  CategoryAcronym,
  CategoryGroups,
  ContentfulPaint,
  IGooglePageSpeed,
  LighthouseResultI18n,
  ScoreDisplayMode,
} from "../../../../../../../models/google/google.page.speed";
import PerformanceSection, {
  getAuditDiagnosticsIds,
  getAuditMetricsIds,
} from "./components/PerformanceSection";
import OrtherCategorySection from "./components/OrtherCategorySection";
import LoadingExperience from "./components/LoadingExperience";
import MarkdownRenderer from "../../../../../../../components/MarkdownRenderer";
import PerformanceScreenshot from "./components/PerformanceScreenshot";
import DotIcon from "../../../../../../../components/DotIcon";
import { EAuditErrorStatus } from "../../../../../../../models/common/models.enum";
import MetricsItem from "./components/MetricsItem";
import ScreenshotThumbnails from "./components/ScreenshotThumbnails";
import {
  CollapseHandleError,
  TableNavDetailNoPagination,
} from "../../../../../../../components";
import { DEFAULT_BASE_PAGING_RES } from "../../../../../../../common/constants/value.constant";
import {
  getAuditStatus,
  getLevelByStatus,
} from "../../../../../../../common/handles/common.handles";
import RenderDetails from "./components/RenderDetails";
import { IValue } from "../../../../../../../models/common/models.type";
import AuditAccordion from "./components/AuditAccordion";

interface IProps {
  strategy: EStrategy;
  sx?: SxProps<Theme>;
  pageSpeedData?: IGooglePageSpeed;
}

export const getPassedAuditIds = (auditRefs?: AuditRef[]) => {
  if (!auditRefs) {
    return [];
  }
  return auditRefs.filter(f =>
    f.group ? f.group === "hidden" && f.id !== "screenshot-thumbnails" : false
  );
};

const PageSpeedOnDevice = ({ pageSpeedData, sx, strategy }: IProps) => {
  if (!pageSpeedData) {
    return null;
  }
  const performance = pageSpeedData?.lighthouseResult.categories.performance;

  const accessibility =
    pageSpeedData?.lighthouseResult.categories.accessibility ?? 0;

  const bestPractices =
    pageSpeedData?.lighthouseResult.categories["best-practices"] ?? 0;

  const seo = pageSpeedData?.lighthouseResult.categories.seo ?? 0;

  const score =
    pageSpeedData.lighthouseResult.categories.performance?.score ?? 0;

  const pointRangeData = [
    { status: EAuditErrorStatus.Error, text: "0-49" },
    { status: EAuditErrorStatus.Warning, text: "50-89" },
    { status: EAuditErrorStatus.Success, text: "90-100" },
  ];

  const metricsTitle =
    pageSpeedData.lighthouseResult.categoryGroups["metrics"].title;

  const metrics = getAuditMetricsIds(
    pageSpeedData.lighthouseResult.categories.performance?.auditRefs
  );

  const getGroups = (
    auditRefs: AuditRef[],
    categoryGroups: CategoryGroups,
    i18n: LighthouseResultI18n
  ) => {
    let groupKeys: string[] = [];

    for (let index = 0; index < auditRefs.length; index++) {
      const element = auditRefs[index];
      const keyGroup = element.group ? element.group : "orther";

      if (!groupKeys.includes(keyGroup)) {
        groupKeys.push(keyGroup);
      }
    }
    const groups = groupKeys.map(key => {
      const group = categoryGroups[key];
      const keyGroup = key === "orther" ? undefined : key;

      const childrens = auditRefs.filter(a => a.group === keyGroup);

      const title =
        key === "orther"
          ? i18n.rendererFormattedStrings.notApplicableAuditsGroupTitle
          : key === "hidden"
            ? i18n.rendererFormattedStrings.passedAuditsGroupTitle
            : group?.title;

      return {
        key: key,
        title: title,
        description: group?.description ?? "",
        children: childrens,
      };
    });

    return groups;
  };

  const inforsData: {
    value: string;
    title: string;
    description: string;
  }[] = [
    {
      value: "first-contentful-paint",
      title: "Thời gian hiển thị nội dung đầu tiên",
      description:
        "Thời gian hiển thị nội dung đầu tiên cho biết thời gian cần thiết để trình duyệt web hiển thị nội dung đầu tiên trên một trang web như văn bản, hình ảnh hoặc nền màu,...",
    },
    {
      value: "largest-contentful-paint",
      title: "Thời gian hiển thị nội dung lớn nhất",
      description:
        "Thời gian hiển thị nội dung lớn nhất cho biết thời gian để phần nội dung lớn nhất trên trang được tải xong và hiển thị đầy đủ cho người dùng.",
    },
    {
      value: "total-blocking-time",
      title: "Tổng Thời Gian Bị Chặn",
      description:
        "Tổng thời gian bị chặn cho biết thời gian từ khi một trang hiển thị nội dung đầu tiên đến khi trang web có thể phản hồi lại các hành động của người dùng trên web như nhấp chuột, cuộn trang.",
    },
    {
      value: "cumulative-layout-shift",
      title: "Tổng độ dịch chuyển bố cục",
      description:
        "Tổng độ dịch chuyển bố cục cho biết mức độ ổn định của các phần tử trên trang (như hình ảnh, văn bản, nút bấm), chỉ  số này giúp xác định tần suất người dùng gặp phải những thay đổi về bố cục ngoài dự kiến.",
    },
    {
      value: "speed-index",
      title: "Chỉ số tốc độ tải trang",
      description:
        "Chỉ số tốc độ tải trang cho biết thời gian để hiển thị toàn bộ nội dung trên trang web. Chỉ số này càng thấp, tức là trang web tải nhanh hơn và người dùng thấy nội dung sớm hơn.",
    },
  ];

  const groups = getGroups(
    [
      ...pageSpeedData.lighthouseResult.categories.performance.auditRefs,
      ...pageSpeedData.lighthouseResult.categories.accessibility.auditRefs,
      ...pageSpeedData.lighthouseResult.categories["best-practices"].auditRefs,
      ...pageSpeedData.lighthouseResult.categories.seo.auditRefs,
    ],
    pageSpeedData.lighthouseResult.categoryGroups,
    pageSpeedData.lighthouseResult.i18n
  );

  const sortData = (data: AuditRef[]) => {
    const result = data
      .filter(sort => {
        const auditSort = pageSpeedData.lighthouseResult.audits
          ? pageSpeedData.lighthouseResult.audits[sort.id]
          : null;
        if (!!auditSort) {
          const status = getAuditStatus(auditSort, sort.weight);
          if (
            (status === EAuditErrorStatus.Warning ||
              status === EAuditErrorStatus.Error) &&
            !inforsData.some(infor => infor.value === auditSort.id)
          ) {
            return sort;
          }
        }
      })
      .map(items => {
        const auditSort = pageSpeedData.lighthouseResult.audits
          ? pageSpeedData.lighthouseResult.audits[items.id]
          : null;
        if (!!auditSort) {
          return { ...items, audit: auditSort };
        } else {
          return items;
        }
      });
    return result;
  };

  const resultas: any = groups.map(group => {
    const dataResult = sortData(group.children);
    return dataResult;
  });

  const diagnostics = resultas
    .flat(1)
    .filter((r: any) => r.group === "diagnostics");

  const resultasOther = resultas
    .flat(1)
    .filter((r: any) => r.group !== "diagnostics");

  return (
    <PageSpeedOnDeviceWrapper sx={sx}>
      <Typography variant="subtitle2">
        Khám phá nội dung người dùng thực tế của bạn đang trải nghiệm
      </Typography>

      <LoadingExperience
        loadingExperience={pageSpeedData.loadingExperience}
        companyName="Branch name"
      />

      <Typography variant="subtitle2" sx={{ mt: "24px" }}>
        Chẩn đoán các vấn đề về hiệu suất
      </Typography>

      <Paper className="page-wrapper">
        <Box className="category-wrapper">
          <Box
            className="flex-center-align-top"
            sx={{ gap: "12px", flexWrap: "wrap" }}
          >
            <ProgressHalfCircle
              value={performance?.score}
              lable={performance?.title}
            />
            <ProgressHalfCircle
              value={accessibility?.score}
              lable={accessibility?.title}
            />
            <ProgressHalfCircle
              value={bestPractices?.score}
              lable={bestPractices?.title}
            />
            <ProgressHalfCircle value={seo?.score} lable={seo?.title} />
          </Box>
        </Box>
        {/* <div className="performance-wrapper">
          <div className="performance-left-content">
            <ProgressHalfCircle
              value={score}
              lable={
                pageSpeedData.lighthouseResult.categories.performance?.title ??
                ""
              }
              size="large"
            />
            {pageSpeedData.lighthouseResult.categories.performance
              ?.description ? (
              <p className="description">
                <MarkdownRenderer
                  content={
                    pageSpeedData.lighthouseResult.categories.performance
                      ?.description ?? ""
                  }
                />
                <MarkdownRenderer
                  content={
                    pageSpeedData.lighthouseResult.categories.performance
                      ?.manualDescription ?? ""
                  }
                />
              </p>
            ) : (
              <p className="description">
                Các giá trị chỉ là ước tính và có thể thay đổi.{" "}
                <a
                  href="https://developer.chrome.com/docs/lighthouse/performance/performance-scoring?utm_source=lighthouse&utm_medium=devtools&hl=vi"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Điểm hiệu quả được tính
                </a>{" "}
                trực tiếp từ những chỉ số này.{" "}
                <a
                  href="https://googlechrome.github.io/lighthouse/scorecalc/#FCP=645&LCP=1072&TBT=67&CLS=0&SI=1047&TTI=1940&device=desktop&version=12.2.0"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Xem máy tính.
                </a>
              </p>
            )}

            <div className="point-wrapper flex-center">
              {pointRangeData.map(point => (
                <div
                  className="point-range-item flex-between-align-center"
                  key={point.text}
                >
                  <DotIcon status={point.status} />
                  <p className="point-range-number">{point.text}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="performance-right-content">
            <PerformanceScreenshot
              strategy={strategy}
              screenshot={
                pageSpeedData.lighthouseResult.fullPageScreenshot.screenshot
                  .data
              }
            />
          </div>
        </div> */}
        <Typography variant="subtitle2" sx={{ mt: "24px" }}>
          {metricsTitle}
        </Typography>
        <ul className="metrics-list-wrapper">
          {metrics.map(item => {
            const inforData = inforsData.find(infor => infor.value === item.id);
            let audit = { ...pageSpeedData.lighthouseResult.audits }
              ? { ...pageSpeedData.lighthouseResult.audits[item.id] }
              : null;
            if (!!inforData && !!audit) {
              audit["description"] = inforData.description;
              audit["title"] = inforData.title;
            }
            return (
              <li key={item.id}>
                <MetricsItem
                  acronym={item.acronym}
                  audit={audit}
                  weight={item.weight}
                />
              </li>
            );
          })}
        </ul>
        {/* Metrics  */}
        {/* View treemap */}
        <Box className="performance-treemap-wrapper">
          <Typography
            variant="subtitle2"
            sx={{ mt: "24px", mb: 1 }}
          ></Typography>

          <ScreenshotThumbnails
            data={
              pageSpeedData.lighthouseResult.audits["screenshot-thumbnails"]
            }
            isMobile={strategy === EStrategy.mobile}
          />
        </Box>
        <div className="error-solution-box">
          <CollapsePageSpeedError
            resultas={resultasOther}
            pageSpeedData={pageSpeedData}
            title="Các lỗi đang làm chậm website"
            typeError={EAuditErrorStatus.Error}
          />
          {!!diagnostics && diagnostics.length > 0 && (
            <CollapsePageSpeedError
              resultas={diagnostics}
              pageSpeedData={pageSpeedData}
              title="Đề xuất tối ưu"
              typeError={EAuditErrorStatus.Recommended}
            />
          )}
        </div>
        {/* <PerformanceSection
          data={pageSpeedData.lighthouseResult.categories.performance}
          fullPageScreenshot={pageSpeedData.lighthouseResult.fullPageScreenshot}
          audits={pageSpeedData.lighthouseResult.audits}
          stackPacks={pageSpeedData.lighthouseResult.stackPacks}
          i18n={pageSpeedData.lighthouseResult.i18n}
          categoryGroups={pageSpeedData.lighthouseResult.categoryGroups}
          strategy={strategy}
        /> */}
        {/* <Divider sx={{ mt: 3 }} /> */}
        {/* <OrtherCategorySection
          data={pageSpeedData.lighthouseResult.categories.accessibility}
          audits={pageSpeedData.lighthouseResult.audits}
          stackPacks={pageSpeedData.lighthouseResult.stackPacks}
          categoryGroups={pageSpeedData.lighthouseResult.categoryGroups}
          i18n={pageSpeedData.lighthouseResult.i18n}
        /> */}
        {/* <Divider sx={{ mt: 3 }} /> */}
        {/* <OrtherCategorySection
          data={pageSpeedData.lighthouseResult.categories["best-practices"]}
          audits={pageSpeedData.lighthouseResult.audits}
          stackPacks={pageSpeedData.lighthouseResult.stackPacks}
          categoryGroups={pageSpeedData.lighthouseResult.categoryGroups}
          i18n={pageSpeedData.lighthouseResult.i18n}
        /> */}
        {/* <Divider sx={{ mt: 3 }} /> */}
        {/* <OrtherCategorySection
          data={pageSpeedData.lighthouseResult.categories.seo}
          audits={pageSpeedData.lighthouseResult.audits}
          stackPacks={pageSpeedData.lighthouseResult.stackPacks}
          categoryGroups={pageSpeedData.lighthouseResult.categoryGroups}
          i18n={pageSpeedData.lighthouseResult.i18n}
        /> */}
      </Paper>
    </PageSpeedOnDeviceWrapper>
  );
};

export default PageSpeedOnDevice;

const CollapsePageSpeedError = (props: {
  resultas: any;
  pageSpeedData: IGooglePageSpeed;
  title: string;
  typeError: EAuditErrorStatus;
}) => {
  const { resultas, pageSpeedData, title, typeError } = props;
  return (
    <CollapseHandleError
      typeError={typeError}
      title={title}
      type={"crawl"}
      dataArray={
        <div className="pagespeed-error-list">
          {resultas
            .flat(1)
            .sort((a: any, b: any) => {
              if (pageSpeedData.lighthouseResult.audits) {
                const auditA = pageSpeedData.lighthouseResult.audits[a.id];
                const auditB = pageSpeedData.lighthouseResult.audits[b.id];
                const statusA = getLevelByStatus(
                  getAuditStatus(auditA, a.weight)
                );
                const statusB = getLevelByStatus(
                  getAuditStatus(auditB, b.weight)
                );

                return statusB - statusA;
              }
              return b.weight - a.weight;
            })
            .map((result: any, idx: any) => {
              return (
                <AuditAccordion
                  data={result.audit}
                  key={result.audit.id}
                  weight={result.audit.weight}
                />
              );
            })}
        </div>
      }
      infor={""}
      recommendAction={""}
      className="collapse-pagespeed-wrapper"
    />
  );
};
