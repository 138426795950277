import { ILoginRequest } from "../../models/auth";

import { NavigateFunction } from "react-router-dom";
import {
  EAuthType,
  IActionAdminLoginWithPassword,
  IActionLoginWithPassword,
  IActionLogout
} from "../../models/redux/auth";

export const loginWithPassword = (
  loginRequest: ILoginRequest,
  navigate: NavigateFunction
): IActionLoginWithPassword => {
  return {
    type: EAuthType.LOGIN_WITH_PASSWORD,
    payload: { loginRequest, navigate }
  };
};

export const adminLoginWithPassword = (
  loginRequest: ILoginRequest,
  navigate: NavigateFunction
): IActionAdminLoginWithPassword => {
  return {
    type: EAuthType.ADMIN_LOGIN_WITH_PASSWORD,
    payload: { loginRequest, navigate }
  };
};

export const logoutUser = (navigate?: NavigateFunction): IActionLogout => {
  return {
    type: EAuthType.LOGOUT,
    payload: { navigate }
  };
};
