import React, { useEffect, useRef, useState } from "react";
import { StarRatingWrapper } from "./styles";

import IconStarFill from "../../assets/images/common/icon_star_fill_yellow.svg";
import IconStarEmpty from "../../assets/images/common/icon_star_line_yellow.svg";
import { ImageCustom } from "..";

interface StarRatingProps {
  precision?: number;
  totalStars?: number;
  emptyIcon?: string;
  filledIcon?: string;
  activeStarProps?: number;
  disabled?: boolean;
}

export default function StarRating(props: StarRatingProps) {
  const {
    precision = 0.5,
    totalStars = 5,
    emptyIcon = IconStarEmpty,
    filledIcon = IconStarFill,
    activeStarProps = 5,
    disabled = false,
  } = props;

  const [activeStar, setActiveStar] = useState(-1);
  const [hoverActiveStar, setHoverActiveStar] = useState(-1);
  const [isHovered, setIsHovered] = useState(false);
  const ratingContainerRef = useRef<any>(null);

  useEffect(() => {
    if (!!activeStarProps) {
      setActiveStar(activeStarProps);
    }
  }, [activeStarProps]);

  const calculateRating = (e: any) => {
    if (!!ratingContainerRef.current) {
      const { width, left } =
        ratingContainerRef.current.getBoundingClientRect();
      let percent = (e.clientX - left) / width;
      const numberInStars = percent * totalStars;
      const nearestNumber =
        Math.round((numberInStars + precision / 2) / precision) * precision;

      return Number(
        nearestNumber.toFixed(precision.toString().split(".")[1]?.length || 0)
      );
    }
    return -1;
  };

  const handleClick = (e: any) => {
    if (!disabled) {
      setIsHovered(false);
      setActiveStar(calculateRating(e));
    }
  };

  const handleMouseMove = (e: any) => {
    if (!disabled) {
      setIsHovered(true);
      setHoverActiveStar(calculateRating(e));
    }
  };

  const handleMouseLeave = (e: any) => {
    if (!disabled) {
      setHoverActiveStar(-1); // Reset to default state
      setIsHovered(false);
    }
  };
  const EmptyIcon = emptyIcon;
  const FilledIcon = filledIcon;

  return (
    <StarRatingWrapper
      onClick={handleClick}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      ref={ratingContainerRef}
      className={`${disabled ? "disabled" : ""}`}
    >
      {[...new Array(totalStars)].map((arr, index) => {
        const activeState = isHovered ? hoverActiveStar : activeStar;

        const showEmptyIcon = activeState === -1 || activeState < index + 1;

        const isActiveRating = activeState !== 1;
        const isRatingWithPrecision = activeState % 1 !== 0;
        const isRatingEqualToIndex = Math.ceil(activeState) === index + 1;
        const showRatingWithPrecision =
          isActiveRating && isRatingWithPrecision && isRatingEqualToIndex;

        return (
          <div key={index} className="star-list">
            <div
              style={{
                width: showRatingWithPrecision
                  ? `${(activeState % 1) * 100}%`
                  : "0%",
                overflow: "hidden",
                position: "absolute",
              }}
            >
              <ImageCustom
                src={FilledIcon}
                alt="Sidebar-Icon"
                width={"12px"}
                height={"12px"}
              />
            </div>
            <div
              style={{
                color: showEmptyIcon ? "gray" : "inherit",
              }}
            >
              {showEmptyIcon ? (
                <ImageCustom
                  src={EmptyIcon}
                  alt="Sidebar-Icon"
                  width={"12px"}
                  height={"12px"}
                />
              ) : (
                <ImageCustom
                  src={FilledIcon}
                  alt="Sidebar-Icon"
                  width={"12px"}
                  height={"12px"}
                />
              )}
            </div>
          </div>
        );
      })}
    </StarRatingWrapper>
  );
}
