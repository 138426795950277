import { SxProps, Theme, Typography } from "@mui/material";
import variableStyles from "../../../../theme/variable-styles";

interface IProps {
  content: string;
  sx?: SxProps<Theme>;
}

const Quote = ({ content, sx }: IProps) => {
  return (
    <Typography
      variant="caption"
      component={"div"}
      sx={{
        ...sx,
        backgroundColor: variableStyles.Info50,
        padding: "8px 12px"
      }}
    >
      {content}
    </Typography>
  );
};

export default Quote;
