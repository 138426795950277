import { styled } from "@mui/material/styles";
import variableStyles from "../../theme/variable-styles";

export const ReportTagsWrapper = styled("div")(() => ({
  borderRadius: "14px",
  padding: "3px 8px",
  fontWeight: variableStyles.fwRegular,
  fontSize: "14px",
  lineHeight: "20px",
  textAlign: "center",
  width: "max-content",
  "&.green": {
    color: variableStyles.GreenPrimaryColor600,
    backgroundColor: variableStyles.GreenPrimaryColor50,
  },
  "&.brown": {
    color: "#9D7200",
    backgroundColor: "#F4F0E4",
  },
  "&.purple": {
    color: "#928EF2",
    backgroundColor: "#D8DFFF",
  },
  "&.blue": {
    color: variableStyles.blueSecondary700,
    backgroundColor: variableStyles.blueSecondary50,
  },
  "&.yellow": {
    color: variableStyles.Warning700,
    backgroundColor: variableStyles.Warning50,
  },
  "&.grey": {
    color: variableStyles.NaturalColor800,
    backgroundColor: variableStyles.NaturalColor50,
  },
}));
