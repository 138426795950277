import { styled } from "@mui/material/styles";
import variableStyles from "../../../../../theme/variable-styles";

export const TechnicalViewDomainAuditWrapper = styled("div")(() => ({
  padding: "24px",
  display: "grid",
  height: "calc(100vh - 153px)",
  overflow: "auto",
  "@media (min-width: 1280px)": {
    gridTemplateColumns: "repeat(4, 1fr)",
    "& .index-list": {
      gridColumn: "1/5",
      gridTemplateColumns: "1fr 1fr 1fr 1fr",
    },
    "& .technical-detail-wrapper": {
      gridColumn: "1/5",
      gridTemplateColumns: "repeat(4, 1fr)",
      "& .detail-wrapper-left": {
        gridColumn: "1",
      },
      "& .detail-wrapper-right": {
        gridColumn: "2/5",
        "& .right-issue": {
          "& .issue-list": {
            gridTemplateColumns: "1fr 1fr 1fr",
          },
        },
        "& .right-thematic": {
          "& .thematic-list": { gridTemplateColumns: "1fr 1fr 1fr 1fr" },
        },
      },
    },
  },
  "@media (max-width: 1279.98px) and (min-width: 800px)": {
    gridTemplateColumns: "repeat(2, 1fr)",
    "& .index-list": { gridColumn: "1/5", gridTemplateColumns: "1fr 1fr" },
    "& .technical-detail-wrapper": {
      gridColumn: "1/3",
      gridTemplateColumns: "calc(50% - 8px) calc(50% - 8px)",
      "& .detail-wrapper-left": {},
      "& .detail-wrapper-right": {
        "& .right-thematic": {
          "& .thematic-list": { gridTemplateColumns: "1fr 1fr" },
        },
      },
    },
  },

  "@media (max-width: 799.98px)": {
    gridTemplateColumns: "1fr",
    "& .index-list": { gridColumn: "1", gridTemplateColumns: "1fr" },
    "& .technical-detail-wrapper": {
      gridColumn: "1",
      gridTemplateColumns: "1fr",
      "& .detail-wrapper-left": {
        gridColumn: "1",
      },
      "& .detail-wrapper-right": {
        gridColumn: "1",
        "& .right-thematic": {
          "& .thematic-list": { gridTemplateColumns: "1fr 1fr" },
        },
      },
    },
  },
  "@media (max-width: 930px) and (min-width: 800px)": {
    "& .detail-wrapper-right": {
      "& .right-thematic": {
        "& .thematic-list": { gridTemplateColumns: "1fr !important" },
      },
    },
  },
  "& .common-box-layout": {
    backgroundColor: variableStyles.NaturalColor0,
    borderRadius: "12px",
    border: `1px solid ${variableStyles.NaturalColor200}`,
    boxShadow:
      "0px 39px 67.8px 0px #1956580A,0px 21px 37px -15px #1956580F,0px 1px 1px 0px #00000014",
    padding: "16px",
  },
  "& .index-list": {
    display: "grid",
    gap: "16px",
    marginBottom: "16px",
  },
  "& .technical-detail-wrapper": {
    display: "grid",
    gap: "16px",
    "& .detail-wrapper-left": {
      flex: "0 0 auto",
      "& .left-site-health": {
        marginBottom: "16px",
        "& .site-health-status": {
          color: variableStyles.NaturalColor950,
          fontWeight: variableStyles.fwMedium,
          fontSize: "12px",
          lineHeight: "14.52px",
          marginBottom: "20px",
          "& span": {
            color: variableStyles.GreenPrimaryColor400,
            fontWeight: variableStyles.fwBold,
          },
        },
        "& .status-circle-progress": {
          margin: "0 auto 30px",
        },
        "& button": {
          backgroundColor: variableStyles.GreenPrimaryColor50,
          border: `1px solid ${variableStyles.NaturalColor300}`,
          borderRadius: "4px",
          padding: "6.5px",
          width: "100%",
          color: variableStyles.NaturalColor950,
          fontWeight: variableStyles.fwMedium,
          fontSize: "12px",
          lineHeight: "14.52px",
        },
      },
    },
    "& .detail-wrapper-right": {
      flex: "1 1 auto",
      "& .right-issue": {
        marginBottom: "16px",
        "& .issue-list": {
          display: "grid",
          gap: "16px",
        },
      },
      "& .right-thematic": {
        "& .thematic-list": {
          display: "grid",
          gap: "16px",
        },
      },
    },
  },
}));
