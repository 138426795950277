import { Link } from "react-router-dom";

import { IBreadcrumbs } from "../../models/redux/breadcrumbs";
import { BreadcrumbsWrapper } from "./styles";

interface BreadcrumbsProps {
  breadcrumbs: IBreadcrumbs[];
}

export default function Breadcrumbs(props: BreadcrumbsProps) {
  const { breadcrumbs } = props;

  return (
    <BreadcrumbsWrapper
      className={`${!breadcrumbs || (!!breadcrumbs && breadcrumbs.length <= 1) ? "hidden" : ""}`}
    >
      <ul className="breadcrumb-list flex-start-align-center">
        {!!breadcrumbs &&
          breadcrumbs.length > 0 &&
          breadcrumbs.map((b, index) =>
            index !== breadcrumbs.length - 1 ? (
              <li key={b.title} className="item">
                <Link to={b.link || ""}>{b.title}</Link>
              </li>
            ) : (
              <li key={b.title} className="last-item">
                {b.title}
              </li>
            )
          )}
      </ul>
    </BreadcrumbsWrapper>
  );
}
