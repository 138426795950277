import {
  IDetailsChains,
  IRequestChains,
} from "../../../../../../../../../../models/google/google.page.speed";
import CommonHandle from "../../../../../../../../../../common/handles/common.handles";
import { CriticalRequestChainWrapper } from "./styles";

interface IProps {
  chains: IDetailsChains;
  level?: number;
}

const CriticalRequestChain = ({ chains, level = 0 }: IProps) => {
  return (
    <CriticalRequestChainWrapper level={level}>
      {Object.keys(chains).map(chainKey => {
        const chain = chains[chainKey];
        return (
          <li key={chainKey}>
            <Item {...chain.request} />
            {/* Iterate over children of the chain */}
            {chain.children &&
              Object.keys(chain.children).map(childKey => {
                const child = chain.children;
                return (
                  <CriticalRequestChain
                    key={childKey}
                    chains={child}
                    level={level + 1}
                  />
                );
              })}
          </li>
        );
      })}
    </CriticalRequestChainWrapper>
  );
};

const Item = ({ url, startTime, endTime, transferSize }: IRequestChains) => {
  return (
    <div className="flex-between-align-center">
      <a href={url} className="fit-two-line" target="_blank" rel="noopener">
        {url}
      </a>
      <p>{CommonHandle.convertTimeSpanMs(endTime - startTime, 2)}</p>
      <p>{CommonHandle.convertBytesToKiB(transferSize) + " KiB"}</p>
    </div>
  );
};

export default CriticalRequestChain;
