import queryString from "query-string";
import { ROUTE_PATH } from "../../../../../../../../common/constants/app.constant";
import CommonHandle, {
  checkStatusCode,
} from "../../../../../../../../common/handles/common.handles";
import { ITableHeader } from "../../../../../../../../components/CollapseHandleError";
import { toggleErrorTableModal } from "../../../../../ModalErrorTable";

export interface IIssueCommon {
  title: string;
  key: string;
  data?: any[];
  filter?: any[];
  type?: string;
  isNavigateToLink?: boolean;
  infor?: string;
  recommendAction?: string;
  headerData?: ITableHeader<any>[];
  navigatePath?: string;
  navigateFilter?: string;
}

const dataIssueError: IIssueCommon[] = [
  {
    title: "Trang thiếu thẻ tiêu đề",
    key: "no_title",
    type: "page",
    infor:
      "Title là một thẻ HTML được dùng để xác định nội dung chính của trang. Các công cụ tìm kiếm dựa vào tiêu đề để hiểu nội dung của trang và hiển thị trên trang kết quả tìm kiếm,  giúp người dùng dễ dàng tìm thấy thông tin cần thiết.",
    recommendAction:
      "Title nên dài từ 50-60 ký tự, chứa từ khóa chính và nêu lên nội dung chính của bài viết.",
    filter: [
      {
        key: "checks.no_title",
        value: "true",
        operator: "=",
        type: "field",
      },
    ],
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "title",
      sFilter: "no_title",
    }),
  },
  {
    title: "Trang trùng lặp thẻ tiêu đề",
    key: "duplicate_title",
    type: "page",
    filter: [
      {
        key: "duplicate_title",
        value: "true",
        operator: "=",
        type: "field",
      },
    ],
    infor:
      "Trang trùng lặp thẻ tiêu đề là khi nhiều trang trên website có cùng một thẻ tiêu đề, điều này có thể gây nhầm lẫn cho Google và công cụ tìm kiếm dẫn đến giảm thứ hạng trên trang kết quả tìm kiếm.",
    recommendAction:
      "Đảm bảo mỗi trang trên website của bạn có một tiêu đề duy nhất, mô tả chính xác nội dung của trang đó.",
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "title",
        title: `Tiêu đề`,
        className: " ",
        handleItem: (item: any) => {
          return <p className="fit-one-line">{item.meta.title}</p>;
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "title",
      sFilter: "duplicate_title",
    }),
  },
  {
    title: "Trang có nhiều hơn 1 thẻ tiêu đề",
    key: "duplicate_title_tag",
    type: "page",
    filter: [
      {
        key: "checks.duplicate_title_tag",
        value: "true",
        operator: "=",
        type: "field",
      },
    ],
    infor:
      "Trang có nhiều hơn 1 thẻ tiêu đề là khi một trang web chứa nhiều thẻ <title> trong mã HTML của nó. Một trang có nhiều thẻ tiêu đề sẽ khiến công cụ tìm kiếm khó hiểu dẫn đến xếp hạng thấp trên trang kết quả tìm kiếm.",
    recommendAction:
      "Kiểm tra và loại bỏ các thẻ tiêu đề dư thừa, đảm bảo rằng mỗi trang chỉ có một thẻ tiêu đề duy nhất và chính xác.",
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "h1",
        title: `Số lượng tiêu đề`,
        className: " ",
        handleItem: (item: any) => {
          return (
            <p className="fit-one-line">{item.meta?.htags?.h1?.length || 0}</p>
          );
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "title",
      sFilter: "duplicate_title_tag",
    }),
  },
  {
    title: "Trang thiếu thẻ H1",
    key: "no_h1_tags",
    type: "page",
    filter: [
      {
        key: "checks.no_h1_tags",
        value: "true",
        operator: "=",
        type: "field",
      },
    ],
    infor:
      "Thẻ H1 là thẻ HTML dùng để xác định chủ đề của nội dung chính trên trang.Thiếu thẻ H1 khiến công cụ tìm kiếm khó hiểu nội dung chính của trang đồng thời làm trang thiếu tổ chức rõ ràng.",
    recommendAction:
      "Thêm thẻ H1 chứa từ khóa chính, phản ánh nội dung trang, đồng thời định dạng thẻ H1 nổi bật để gia tăng trải nghiệm người dùng. ",
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "h1",
      sFilter: "no_h1_tags",
    }),
  },
  {
    title: "Trang trùng lặp thẻ Meta description",
    key: "duplicate_description",
    type: "page",
    filter: [
      {
        key: "duplicate_description",
        value: "true",
        operator: "=",
        type: "field",
      },
    ],
    infor:
      "Khi website có nhiều trang với các thẻ meta description giống nhau sẽ khiến công cụ tìm kiếm gặp khó khăn trong việc xác định nội dung chính của từng trang.",
    recommendAction:
      "Mỗi trang trên website cần có một thẻ meta description riêng biệt và phản ánh chính xác nội dung của trang đó. Đảm bảo  mỗi mô tả đều nêu rõ chủ đề của trang và chứa từ khóa mục tiêu mong muốn xếp hạng nếu có.",
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "description",
        title: `Meta Description`,
        className: "table-cell-link ",
        handleItem: (item: any) => {
          return <p className="fit-one-line">{item.meta.description}</p>;
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "metadescription",
      sFilter: "duplicatemeta",
    }),
  },
  {
    title: "Trang lỗi 4xx",
    key: "is_4xx_code",
    type: "page",
    filter: [
      {
        key: "checks.is_4xx_code",
        value: "true",
        operator: "=",
        type: "field",
      },
    ],
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "domain",
        title: `Status code`,
        className: "table-cell-error-tag ",
        handleItem: (item: any) => {
          return <p className="fit-one-line">{item.status_code || "404"}</p>;
        },
      },
    ],
    infor:
      "Lỗi 4xx xảy ra khi trên website tồn tại các trang không thể truy cập, gây cản trở việc lập chỉ mục của công cụ tìm kiếm và giảm trải nghiệm người dùng, ảnh hưởng SEO",
    recommendAction:
      "Kểm tra và sửa các URL bị hỏng, đảm bảo tất cả các liên kết trên web đều có thể truy cập và các trang để tối ưu hóa SEO.",
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "statuscode",
      sFilter: ["code404", "code401", "code403"],
    }),
  },
  {
    title: "Trang lỗi 5xx",
    key: "is_5xx_code",
    type: "page",
    filter: [
      {
        key: "checks.is_5xx_code",
        value: "true",
        operator: "=",
        type: "field",
      },
    ],
    infor:
      "Trang lỗi 5xx là mã trạng thái HTTP cho biết máy chủ web gặp sự cố khi xử lý yêu cầu từ trình duyệt web.",
    recommendAction:
      "Trang lỗi 5xx là mã trạng thái HTTP cho biết máy chủ web gặp sự cố khi xử lý yêu cầu từ trình duyệt web.",
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "domain",
        title: `Status code`,
        className: "table-cell-error-tag  ",
        handleItem: (item: any) => {
          return <p className="fit-one-line">{item.status_code || "505"}</p>;
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "statuscode",
      sFilter: ["code505", "code501", "code502"],
    }),
  },
  {
    title: "Trang có liên kết nội bộ bị hỏng",
    key: "broken_links",
    type: "page",
    filter: [
      {
        key: "broken_links",
        value: "true",
        operator: "=",
        type: "field",
      },
    ],
    infor: `Khi trang có liên kết nội bộ bị hỏng (trang lỗi) có thể khiến người dùng thất vọng do không tìm được nội dung mong muốn. Đồng thời các công cụ tìm kiếm đánh giá thấp chất lượng của các trang web chứa liên kết 4xx, ảnh hưởng xấu đến thứ hạng trang web.`,
    recommendAction:
      "Xem xét và sửa chữa hoặc loại bỏ các liên kết hỏng, đảm bảo mọi liên kết nội bộ dẫn đến trang hoạt động để cải thiện trải nghiệm và hiệu suất SEO.",
    headerData: [
      {
        field: "url",
        title: `From URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      // {
      //   field: "url",
      //   title: `To URL`,
      //   className: "table-cell-link table-cell-error-tag",
      //   handleItem: (item: any) => {
      //     return (
      //       <div className="table-cell-link-error">
      //         <button
      //           className="fit-one-line"
      //           onClick={() => {
      //             toggleErrorTableModal({
      //               open: true,
      //               onCancel: () => {},
      //               isHasFilter: true,
      //               url: item.url,
      //               filter: [
      //                 {
      //                   key: "page_to_status_code",
      //                   value: "404",
      //                   operator: "=",
      //                   type: "field",
      //                 },
      //               ],
      //               headersTable: [
      //                 {
      //                   field: "link_from",
      //                   title: `From URL`,
      //                   width: "244px",
      //                   className: "table-cell-link",
      //                   isSticky: true,
      //                   handleItem: (item: any) => {
      //                     return (
      //                       <a
      //                         className="fit-one-line"
      //                         target="_blank"
      //                         rel="noopener noreferrer"
      //                         href={item.link_from}
      //                       >
      //                         {item.link_from}
      //                       </a>
      //                     );
      //                   },
      //                 },
      //                 {
      //                   field: "link_to",
      //                   title: `To URL`,
      //                   width: "244px",
      //                   className: "table-cell-link table-cell-error-check",
      //                   handleItem: (item: any) => {
      //                     return (
      //                       <div className="table-cell-link-error">
      //                         <a
      //                           className="fit-one-line"
      //                           target="_blank"
      //                           rel="noopener noreferrer"
      //                           href={item.link_to}
      //                         >
      //                           {item.link_to}
      //                         </a>
      //                         <div
      //                           className={`cell-status-code ${checkStatusCode(Number(item.page_to_status_code)).toString()}`}
      //                         >
      //                           <p>{item.page_to_status_code || "4xx"}</p>
      //                         </div>
      //                       </div>
      //                     );
      //                   },
      //                 },
      //                 {
      //                   field: "text",
      //                   title: `Anchor text`,
      //                   width: "120px",
      //                 },
      //                 {
      //                   field: "dofollow",
      //                   title: `Follow`,
      //                   width: "100px",
      //                   className: "table-cell-link table-cell-error-check",
      //                   handleItem: (item: any) => {
      //                     return (
      //                       <div
      //                         className={`cell-status-code ${item.dofollow ? "error" : "success"}`}
      //                       >
      //                         <p>{item.dofollow ? "False" : "True"}</p>
      //                       </div>
      //                     );
      //                   },
      //                 },
      //               ],
      //               activeTab: "0",
      //             });
      //           }}
      //         >
      //           Xem danh sách liên kết
      //         </button>
      //       </div>
      //     );
      //   },
      // },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_ERROR_DETAIL,
    navigateFilter: queryString.stringify({
      tab: "inlink",
      code: ["code404", "code401", "code403"],
      fkey: "broken_links",
    }),
  },
  {
    title: "Trang lỗi thẻ canonical",
    key: "canonical_to_broken",
    type: "page",
    filter: [
      {
        key: "checks.canonical_to_broken",
        value: "true",
        operator: "=",
        type: "field",
      },
    ],
    infor:
      "Trang lỗi thẻ canonical xảy ra khi thẻ canonical trỏ đến một trang bị lỗi (mã 4xx hoặc 5xx). Điều này gây khó khăn cho công cụ tìm kiếm trong việc thu thập dữ liệu và xếp hạng trang đích mong muốn. ",
    recommendAction:
      "Đảm bảo thẻ canonical trỏ đến URL chính xác và hoạt động bình thường (mã trả về 200).",
    headerData: [
      {
        field: "url",
        title: `From URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "canonical",
        title: `Canonical link`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.meta.canonical}
            >
              {item.meta.canonical}
            </a>
          );
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "canocical",
      sFilter: "errorcanonical",
    }),
  },
  {
    title: "Trang có thẻ canonical trả về mã 3xx",
    key: "canonical_to_redirect",
    type: "page",
    filter: [
      {
        key: "checks.canonical_to_redirect",
        value: "true",
        operator: "=",
        type: "field",
      },
    ],
    infor:
      "Trang có thẻ canonical trả về mã 3xx khiến các công cụ tìm kiếm bị chuyển hướng không cần thiết. Điều này làm lãng phí ngân sách thu thập dữ liệu và giảm khả năng xếp hạng trang.",
    recommendAction:
      "Đảm bảo thẻ canonical trỏ trực tiếp đến URL cuối cùng không có chuyển hướng (URL trả về mã 200).",
    headerData: [
      {
        field: "url",
        title: `From URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "canonical",
        title: `Canonical link`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.meta.canonical}
            >
              {item.meta.canonical}
            </a>
          );
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "canocical",
      sFilter: "3xxcanonical",
    }),
  },
  {
    title: "Trang có liên kết trỏ đến phiên bản Http",
    key: "https_to_http_links",
    type: "page",
    filter: [
      {
        key: "checks.https_to_http_links",
        value: "true",
        operator: "=",
        type: "field",
      },
    ],
    infor:
      "Trang có liên kết trỏ đến phiên bản HTTP là khi trên website của bạn có các liên kết (URL) trỏ đến các trang sử dụng giao thức HTTP thay vì HTTPS.",
    recommendAction:
      "Tìm và thay thế các liên kết HTTP trong nội dung trang web của bạn (bao gồm cả các liên kết trong HTML, CSS, JavaScript) bằng liên kết HTTPS.",
    headerData: [
      {
        field: "url",
        title: `From URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      // {
      //   field: "domain",
      //   title: `To URL`,
      //   className: "table-cell-link",
      //   handleItem: (item: any) => {
      //     return (
      //       <div className="table-cell-link-error">
      //         <button
      //           className="fit-one-line"
      //           onClick={() => {
      //             toggleErrorTableModal({
      //               open: true,
      //               onCancel: () => {},
      //               isHasFilter: true,
      //               url: item.url,
      //               filter: [
      //                 {
      //                   key: "page_to_scheme",
      //                   value: "https",
      //                   operator: "=",
      //                   type: "field",
      //                 },
      //               ],
      //               headersTable: [
      //                 {
      //                   field: "link_from",
      //                   title: `From URL`,
      //                   width: "244px",
      //                   className: "table-cell-link",
      //                   isSticky: true,
      //                   handleItem: (item: any) => {
      //                     return (
      //                       <a
      //                         className="fit-one-line"
      //                         target="_blank"
      //                         rel="noopener noreferrer"
      //                         href={item.link_from}
      //                       >
      //                         {item.link_from}
      //                       </a>
      //                     );
      //                   },
      //                 },
      //                 {
      //                   field: "link_to",
      //                   title: `To URL`,
      //                   width: "244px",
      //                   className: "table-cell-link table-cell-error-check",
      //                   handleItem: (item: any) => {
      //                     return (
      //                       <div className="table-cell-link-error">
      //                         <a
      //                           className="fit-one-line"
      //                           target="_blank"
      //                           rel="noopener noreferrer"
      //                           href={item.link_to}
      //                         >
      //                           {item.link_to}
      //                         </a>
      //                       </div>
      //                     );
      //                   },
      //                 },
      //                 {
      //                   field: "text",
      //                   title: `Anchor text`,
      //                   width: "120px",
      //                 },
      //                 {
      //                   field: "dofollow",
      //                   title: `Follow`,
      //                   width: "100px",
      //                   className: "table-cell-link table-cell-error-check",
      //                   handleItem: (item: any) => {
      //                     return (
      //                       <div
      //                         className={`cell-status-code ${item.dofollow ? "error" : "success"}`}
      //                       >
      //                         <p>{item.dofollow ? "False" : "True"}</p>
      //                       </div>
      //                     );
      //                   },
      //                 },
      //               ],
      //               activeTab: "0",
      //             });
      //           }}
      //         >
      //           Xem danh sách liên kết
      //         </button>
      //       </div>
      //     );
      //   },
      // },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_ERROR_DETAIL,
    navigateFilter: queryString.stringify({
      tab: "inlink",
      fkey: "https_to_http_links",
    }),
  },
  {
    title: "Trang có nội dung hỗn hợp",
    key: "page_to_scheme",
    type: "links",
    filter: [
      {
        key: "type",
        value: "image",
        operator: "=",
        type: "field",
      },
      {
        key: "",
        value: "and",
        operator: "",
        type: "operator",
      },
      {
        key: "page_to_scheme",
        value: "hntp",
        operator: "=",
        type: "field",
      },
    ],
    infor:
      "Trang có nội dung hỗn hợp là trang web được tải qua kết nối HTTPS bảo mật nhưng lại chứa một số thành phần được tải qua kết nối HTTP không bảo mật.",
    recommendAction:
      "Đảm bảo tất cả các thành phần trên trang web được tải qua HTTPS để bảo vệ thông tin người dùng và duy trì uy tín cho website.",
    headerData: [
      {
        field: "url",
        title: `From URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      // {
      //   field: "domain",
      //   title: `To URL`,
      //   className: "table-cell-link",
      //   handleItem: (item: any) => {
      //     return (
      //       <div className="table-cell-link-error">
      //         <button
      //           className="fit-one-line"
      //           onClick={() => {
      //             toggleErrorTableModal({
      //               open: true,
      //               onCancel: () => {},
      //               isHasFilter: true,
      //               url: item.url,
      //               filter: [
      //                 {
      //                   key: "resource_type",
      //                   value: "html",
      //                   operator: "=",
      //                   type: "field",
      //                 },
      //                 {
      //                   key: "",
      //                   value: "and",
      //                   operator: "",

      //                   type: "operator",
      //                 },
      //                 {
      //                   key: "checks.https_to_http_links",
      //                   value: "true",
      //                   operator: "=",
      //                   type: "field",
      //                 },
      //               ],
      //               headersTable: [
      //                 {
      //                   field: "url",
      //                   title: `From URL`,
      //                   width: "244px",
      //                   className: "table-cell-link",
      //                   isSticky: true,
      //                   handleItem: (item: any) => {
      //                     return (
      //                       <a
      //                         className="fit-one-line"
      //                         target="_blank"
      //                         rel="noopener noreferrer"
      //                         href={item.url}
      //                       >
      //                         {item.url}
      //                       </a>
      //                     );
      //                   },
      //                 },
      //                 {
      //                   field: "domain",
      //                   title: `From URL`,
      //                   width: "244px",
      //                   className: "table-cell-link table-cell-error-check",
      //                   handleItem: (item: any) => {
      //                     return (
      //                       <div className="table-cell-link-error">
      //                         <a
      //                           className="fit-one-line"
      //                           target="_blank"
      //                           rel="noopener noreferrer"
      //                           href={item.url}
      //                         >
      //                           {item.url}
      //                         </a>
      //                         <div
      //                           className={`cell-status-code ${checkStatusCode(Number(item.status_code)).toString()}`}
      //                         >
      //                           <p>{item.status_code || "4xx"}</p>
      //                         </div>
      //                       </div>
      //                     );
      //                   },
      //                 },
      //                 {
      //                   field: "domain",
      //                   title: `Anchor text`,
      //                   width: "120px",
      //                 },
      //                 {
      //                   field: "follow",
      //                   title: `Follow`,
      //                   width: "100px",
      //                   className: "table-cell-link table-cell-error-check",
      //                   handleItem: (item: any) => {
      //                     return (
      //                       <div
      //                         className={`cell-status-code ${!!item.dofollow ? "success" : "error"}`}
      //                       >
      //                         <p>{item.status || "True"}</p>
      //                       </div>
      //                     );
      //                   },
      //                 },
      //               ],
      //               activeTab: "0",
      //             });
      //           }}
      //         >
      //           Xem danh sách liên kết
      //         </button>
      //       </div>
      //     );
      //   },
      // },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "https",
      sFilter: "redirectmix",
    }),
  },
  {
    title: "Trang sử dụng sai thẻ Hreflang",
    key: "",

    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "hreflang",
      sFilter: "wronghref",
    }),
  },
  {
    title: "Trang sử dụng ngôn ngữ hỗn hợp",
    key: "",
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "hreflang",
      sFilter: "mixhref",
    }),
  },
  {
    title: "Trang có lỗi dữ liệu có cấu trúc",
    key: "",

    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
    ],
  },
  {
    title: "Trang có tốc độ tải chậm",
    key: "high_loading_time",
    type: "page",
    filter: [
      {
        key: "checks.high_loading_time",
        value: "true",
        operator: "=",
        type: "field",
      },
    ],
    infor:
      "Trang có tốc độ tải chậm gây khó chịu cho người dùng và bị xếp hạng thấp trên kết quả tìm kiếm.",
    recommendAction:
      "Tốc độ tải trang càng nhanh càng tốt, lý tưởng là dưới 2 giây cho máy tính để bàn và dưới 3 giây cho thiết bị di động.",
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "domain",
        title: `Thời gian`,
        className: "table-cell-text-tag ",
        handleItem: (item: any) => {
          return (
            <p className="fit-one-line">
              {(item.page_timing.time_to_interactive / 1000).toFixed(2)}s
            </p>
          );
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "data_crawler",
      sFilter: "time_to_interactive",
    }),
  },
  {
    title: "Trang có kích thước quá lớn",
    key: "size_greater_than_3mb",
    type: "page",
    filter: [
      {
        key: "checks.size_greater_than_3mb",
        value: "true",
        operator: "=",
        type: "field",
      },
    ],
    infor:
      "Kích thước trang web là tổng dung lượng của tất cả các thành phần trên một trang web (HTML, CSS, JavaScript, hình ảnh...). ",
    recommendAction:
      "Giữ kích thước trang web càng nhỏ càng tốt, lý tưởng là dưới 3MB.",
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "domain",
        title: `Kích thước (KB)`,
        className: "table-cell-text-tag ",
        handleItem: (item: any) => {
          return (
            <p className="fit-one-line">
              {CommonHandle.formatNumberCurrency(item.size || 0)}
            </p>
          );
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "data_crawler",
      sFilter: "size_greater_than_3mb",
    }),
  },
  {
    title: "Trang có chứa chuỗi chuyển hướng",
    key: "redirect_chain",
    type: "page",
    filter: [
      {
        key: "checks.redirect_chain",
        value: "true",
        operator: "=",
        type: "field",
      },
    ],
    infor:
      "Trang chứa chuỗi chuyển hướng (Redirect Chain) là một tình huống khi một trang web chuyển hướng đến một URL khác, và sau đó URL đó lại chuyển hướng tiếp đến một URL khác tạo thành một chuỗi. ",
    recommendAction:
      "Cách tốt nhất là chuyển hướng URL ban đầu đến URL đích cuối cùng. Nếu không thể tránh khỏi chuỗi chuyển hướng, hãy cố gắng giảm thiểu số lượng chuyển hướng xuống mức tối thiểu.",
    headerData: [
      {
        field: "url",
        title: `From URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "domain",
        title: `Redirect URL 1`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "domain",
        title: `URL 2`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "stringredirect",
    }),
  },
  {
    title: "301 redirect phiên bản www va non www",
    key: "www_redirect_status_code",
    infor:
      "301 redirect phiên bản www và non-www là cách bạn chuyển hướng vĩnh viễn một phiên bản của website sang phiên bản còn lại.",
    recommendAction: `Công cụ tìm kiếm coi "www.example.com" và "example.com" là hai website khác nhau, dẫn đến trùng lặp nội dung. Cần thực hiện 301 redirect, chọn một phiên bản làm chính và chuyển hướng phiên bản còn lại về phiên bản chính.`,
  },
  {
    title: "Có chứng chỉ HTTPs",
    key: "valid_certificate",
  },
  {
    title: "301 redirect phiên bản Http sang https",
    key: "test_https_redirect",
    infor:
      "Chuyển hướng 301 từ HTTP sang HTTPS giúp chuyển đổi website của bạn sang một kết nối an toàn và bảo mật hơn.",
    recommendAction:
      "Để thực hiện chuyển hướng 301 từ HTTP sang HTTPS, bạn cần phải cài đặt chứng chỉ SSL/TLS trên website và cấu hình máy chủ của bạn để thực hiện chuyển hướng.",
  },
];

const dataIssueWaring: IIssueCommon[] = [
  {
    title: "Trang có tiêu đề quá dài",
    key: "title_too_long",
    type: "page",
    filter: [
      {
        key: "checks.title_too_long",
        value: "true",
        operator: "=",
        type: "field",
      },
    ],
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "title_length",
        title: `Chiều dài tiêu đề`,
        handleItem: (item: any) => {
          return <p className="fit-one-line">{item.meta.title_length}</p>;
        },
      },
    ],
    infor:
      "Trang có tiêu đề quá dài là khi thẻ <title> của trang vượt quá chiều dài tối ưu số lượng ký tự khuyến nghị, thường là khoảng 60 ký tự. Tiêu đề dài sẽ bị cắt ngắn trong các kết quả tìm kiếm khiến người dùng không thấy đầy đủ thông tin về trang.",
    recommendAction:
      "Đảm bảo rằng thẻ tiêu đề của mỗi trang có chiều dài từ 50-60 ký tự để tiêu đề được hiển thị đầy đủ trong kết quả tìm kiếm.",
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "title",
      sFilter: "title_too_long",
    }),
  },
  {
    title: "Trang trùng lặp thẻ H1",
    key: "duplicate_h1",
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "domain",
        title: `H1`,
        className: " ",
        handleItem: (item: any) => {
          return <p className="fit-one-line">{item.createdAt}</p>;
        },
      },
    ],
    infor:
      "Lỗi này xảy ra khi hai hay nhiều trang trên website có thẻ H1 giống nhau, khiến công cụ tìm kiếm khó khăn khi xác định trang ưu tiên cho từ khóa liên quan. Nghiêm trọng hơn, lỗi này có thể khiến trang bị đánh giá là trùng lặp nội dung.",
    recommendAction:
      "Điều chỉnh nhằm đảm bảo mỗi trang có một thẻ H1 riêng biệt. Với các trang có nội dung gần giống nhau, xem xét gộp nội dung, dùng thẻ canonical hoặc chuyển hướng 301 để tránh lỗi trùng lặp.",
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "h1",
      sFilter: "duplicate_h1",
    }),
  },
  {
    title: "Trang có nhiều hơn 1 thẻ H1",
    key: "many_h1",
    infor:
      "Điều này xảy ra khi nhiều phần tử trên trang được gắn thẻ H1 thay vì chỉ có một thẻ H1 duy nhất khiến công cụ tìm kiếm sẽ khó xác định nội dung chính của trang, làm giảm khả năng xếp hạng.",
    recommendAction:
      "Đảm bảo mỗi trang chỉ có một thẻ H1 duy nhất, phản ánh đúng nội dung chính của trang. Loại bỏ các thẻ H1 thừa hoặc thay thế bằng các thẻ H2, H3...cho phù hợp.",
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "domain",
        title: `Số lượng H1`,
        className: " ",
        handleItem: (item: any) => {
          return <p className="fit-one-line">{item.createdAt}</p>;
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "h1",
      sFilter: "many_h1",
    }),
  },
  {
    title: "Trang có thẻ H1 quá dài",
    key: "h1_long",
    infor:
      "Trang có thẻ Heading 1 quá dài là khi thẻ H1 của trang vượt quá số lượng ký tự khuyến nghị, thường là 67 ký tự. Thẻ Heading 1 dài làm giảm tính thẩm mỹ đồng thời khiến người dùng và công cụ tìm kiếm khó hiểu nội dung chính của trang.",
    recommendAction:
      "Giảm bớt từ ngữ, giữ cho thẻ H1 rõ ràng, tập trung vào nội dung chính của trang và chứa từ khóa chính.",
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "domain",
        title: `Độ dài H1`,
        className: " ",
        handleItem: (item: any) => {
          return <p className="fit-one-line">{item.createdAt}</p>;
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "h1",
      sFilter: "h1_long",
    }),
  },
  {
    title: "Trang có thẻ tiêu đề và H1 trùng lặp",
    key: "h1_title_duplicate",
    infor:
      "Mặc dù không gây ảnh hưởng tiêu cực nhưng việc trùng lặp tiêu đề và Heading 1 làm lãng phí cơ hội xếp hạng nhiều từ khóa hơn cho trang web.",
    recommendAction:
      "Diễn đạt tiêu đề hoặc thẻ H1 theo một cách khác để tạo sự khác biệt, bổ sung thêm các từ khóa phụ vào tiêu đề hoặc H1 để tăng hiệu quả tối ưu từ khóa.",
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "domain",
        title: `Tiêu đề`,
        handleItem: (item: any) => {
          return <p className="fit-one-line">{item.createdAt}</p>;
        },
      },
      {
        field: "domain",
        title: `Heading 1`,
        handleItem: (item: any) => {
          return <p className="fit-one-line">{item.createdAt}</p>;
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "h1",
      sFilter: "h1_title_duplicate",
    }),
  },
  {
    title: "Trang thiếu thẻ Meta description",
    key: "no_description",
    type: "page",
    filter: [
      {
        key: "checks.no_description",
        value: "true",
        operator: "=",
        type: "field",
      },
    ],
    infor:
      "Khi trang thiếu thẻ Meta description, công cụ tìm kiếm sẽ trích xuất từ nội dung trang một đoạn văn bản ngẫu nhiên phù hợp với từ khóa tìm kiếm. Tuy nhiên, đoạn văn này có thể không rõ ràng hoặc không hấp dẫn.",
    recommendAction:
      "Bổ sung meta description chứa từ khóa chính và được viết hấp dẫn, lôi cuốn để thu hút người dùng click vào.",
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "metadescription",
      sFilter: "missingmeta",
    }),
  },
  {
    title: "Trang có thẻ meta description quá dài",
    key: "meta.description_length",
    type: "page",
    filter: [
      {
        key: "meta.description_length",
        value: "155",
        operator: ">=",
        type: "field",
      },
    ],
    infor:
      "Thẻ meta description quá dài là khi meta description vượt quá độ dài mà các công cụ tìm kiếm như Google có thể hiển thị đầy đủ trong kết quả tìm kiếm.",
    recommendAction:
      "Đảm bảo độ dài meta description chỉ trong khoảng từ 150-160 ký tự.",
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "description_length",
        title: `Độ dài thẻ Meta description`,
        handleItem: (item: any) => {
          return <p className="fit-one-line">{item.meta.description_length}</p>;
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "metadescription",
      sFilter: "longmeta",
    }),
  },
  {
    title: "Hình ảnh thiếu thẻ ALT",
    key: "alternative_text",
    type: "resources",
    filter: [
      {
        key: "resource_type",
        value: "image ",
        operator: "=",
        type: "field",
      },
      {
        key: "",
        value: "and",
        operator: "",
        type: "operator",
      },
      {
        key: "meta.alternative_text",
        value: "",
        operator: "=",
        type: "field",
      },
    ],
    infor:
      "Thẻ Alt (Alternative text) là văn bản mô tả nội dung hình ảnh trên trang web. Hình ảnh thiếu thẻ ALT là các ảnh có thẻ ALT text để trống, điều này khiến công cụ tìm kiếm khó hiểu nội dung hình ảnh, dẫn đến giảm khả năng tiếp cận và tối ưu SEO.",
    recommendAction:
      "Bổ sung thẻ ALT riêng và mô tả ngắn gọn, chính xác nội dung của từng hình ảnh. Thêm từ khóa chính vào thẻ Alt của ảnh có nội dung thích hợp giúp tối ưu điểm SEO.",
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_ERROR_DETAIL,
    navigateFilter: queryString.stringify({
      tab: "image",
      fkey: "alternative_text",
    }),
  },
  {
    title: "Hình ảnh lớn hơn 100KB",
    key: "image_size",
    type: "resources",
    filter: [
      {
        key: "resource_type",
        value: "image",
        operator: "=",
        type: "field",
      },
      {
        key: "",
        value: "and",
        operator: "",
        type: "operator",
      },
      {
        key: "size",
        value: "102400",
        operator: ">=",
        type: "field",
      },
    ],
    infor:
      "Hình ảnh có kích thước lớn (trên 100KB) có thể làm tăng thời gian tải trang, đặc biệt nếu trang web có nhiều hình ảnh tương tự.",
    recommendAction:
      "Nén hình ảnh bằng cách sử dụng các công cụ trực tuyến như TinyPNG, JPEG-Optimizer, hoặc ImageOptim (trên Mac) để giảm kích thước mà không ảnh hưởng đến chất lượng hình ảnh.",
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "size",
        title: `Kích thước (KB)`,
        className: "table-cell-text-tag ",
        handleItem: (item: any) => {
          return (
            <p className="fit-one-line">{(item.size / 1000).toFixed(1)} KB</p>
          );
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_ERROR_DETAIL,
    navigateFilter: queryString.stringify({
      tab: "image",
      fkey: "image_size",
    }),
  },
  {
    title: "Trang có số lượng chữ thấp",
    key: "low_character_count",
    type: "page",
    filter: [
      {
        key: "checks.low_character_count",
        value: "true",
        operator: "=",
        type: "field",
      },
    ],
    infor:
      "Trang có số lượng chữ thấp là những trang web chứa rất ít nội dung văn bản, khiến trang có thể bị coi là cung cấp ít giá trị cho người đọc và bị đánh giá thấp trên trang kết quả của công cụ tìm kiếm.",
    recommendAction:
      "Bổ sung thêm nội dung chất lượng, cung cấp giá trị cho người đọc. Nội dung trên trang nên có ít nhất từ 300 từ và dài khoảng 1.000 - 2.000 cho nội dung blog chuyên sâu.",
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "plain_text_word_count",
        title: `Words count`,
        className: " ",
        handleItem: (item: any) => {
          return (
            <p className="fit-one-line">
              {item.meta?.content?.plain_text_word_count}
            </p>
          );
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "data_crawler",
      sFilter: "low_character_count",
    }),
  },
  {
    title: "Trang chưa khai báo loại tài liệu",
    key: "has_html_doctype",
    type: "page",
    filter: [
      {
        key: "checks.has_html_doctype",
        value: "false",
        operator: "=",
        type: "field",
      },
    ],
    infor:
      "Trang chưa khai báo loại tài liệu là trang web không khai báo Doctype ở đầu mã nguồn HTML. Điều này khiến trình duyệt không biết phiên bản HTML nào đang được sử dụng dẫn đến lỗi hiển thị hoặc hiển thị không đúng.",
    recommendAction:
      "Thêm thẻ <!DOCTYPE html> vào đầu mỗi trang web để đảm bảo trang web được hiển thị chính xác và tuân thủ các tiêu chuẩn web hiện đại.",
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "has_html_doctype",
        title: `Doc type`,
        className: `align-end`,
        handleItem: (item: any) => {
          return (
            <div
              className={`${item.checks.has_html_doctype ? "table-cell-success-tag" : "table-cell-error-tag"}`}
            >
              <p className="fit-one-line">
                {item.checks.has_html_doctype ? "True" : "False"}
              </p>
            </div>
          );
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "data_crawler",
      sFilter: "has_html_doctype",
    }),
  },
  {
    title: "Trang sử dụng thẻ Href lang sai vị trí",
    key: "",
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "domain",
        title: `Vị trí`,
        className: " ",
        handleItem: (item: any) => {
          return <p className="fit-one-line">{item.createdAt}</p>;
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "hreflang",
      sFilter: "hrefwrongposition",
    }),
  },
  {
    title: "Trang có quá nhiều liên kết nội bộ đến trang khác",
    key: "internal_links_count",
    filter: [
      {
        key: "meta.internal_links_count",
        value: "100",
        operator: ">=",
        type: "field",
      },
    ],
    infor:
      "Trang có quá nhiều liên kết nội bộ đến trang khác là khi một trang web chứa quá nhiều liên kết trỏ đến các trang khác trên cùng một website.",
    recommendAction:
      "Giới hạn số lượng liên kết nội bộ trong mỗi trang không quá 100",
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "domain",
        title: `Internal link`,
        className: "",
        handleItem: (item: any) => {
          return <p className="fit-one-line">{item.createdAt}</p>;
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_ERROR_DETAIL,
    navigateFilter: queryString.stringify({
      tab: "inlink",
      fkey: "internal_links_count",
    }),
  },
  {
    title: "Trang chứa liên kết nội bộ tới trang 3xx",
    key: "has_links_to_redirects",
    type: "page",
    filter: [
      {
        key: "checks.has_links_to_redirects",
        value: "true",
        operator: "=",
        type: "field",
      },
    ],

    headerData: [
      {
        field: "url",
        title: `From URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      // {
      //   field: "domain",
      //   title: `To URL`,
      //   className: "table-cell-link",
      //   handleItem: (item: any) => {
      //     return (
      //       <div className="table-cell-link-error">
      //         <button
      //           className="fit-one-line"
      //           onClick={() => {
      //             toggleErrorTableModal({
      //               open: true,
      //               onCancel: () => {},
      //               isHasFilter: true,
      //               url: item.url,
      //               filter: [
      //                 {
      //                   key: "type",
      //                   value: "anchor",
      //                   operator: "=",
      //                   type: "field",
      //                 },
      //                 {
      //                   key: "",
      //                   value: "and",
      //                   operator: "",
      //                   type: "operator",
      //                 },
      //                 {
      //                   key: "type",
      //                   value: "link",
      //                   operator: "=",
      //                   type: "field",
      //                 },
      //               ],
      //               headersTable: [
      //                 {
      //                   field: "link_from",
      //                   title: `From URL`,
      //                   width: "244px",
      //                   className: "table-cell-link",
      //                   isSticky: true,
      //                   handleItem: (item: any) => {
      //                     return (
      //                       <a
      //                         className="fit-one-line"
      //                         target="_blank"
      //                         rel="noopener noreferrer"
      //                         href={item.link_from}
      //                       >
      //                         {item.link_from}
      //                       </a>
      //                     );
      //                   },
      //                 },
      //                 {
      //                   field: "link_to",
      //                   title: `To URL`,
      //                   width: "244px",
      //                   className: "table-cell-link table-cell-error-check",
      //                   handleItem: (item: any) => {
      //                     return (
      //                       <div className="table-cell-link-error">
      //                         <a
      //                           className="fit-one-line"
      //                           target="_blank"
      //                           rel="noopener noreferrer"
      //                           href={item.link_to}
      //                         >
      //                           {item.link_to}
      //                         </a>
      //                         <div
      //                           className={`cell-status-code ${checkStatusCode(Number(item.page_to_status_code)).toString()}`}
      //                         >
      //                           <p>{item.page_to_status_code || "4xx"}</p>
      //                         </div>
      //                       </div>
      //                     );
      //                   },
      //                 },
      //                 {
      //                   field: "text",
      //                   title: `Anchor text`,
      //                   width: "120px",
      //                 },
      //                 {
      //                   field: "follow",
      //                   title: `Follow`,
      //                   width: "100px",
      //                   className: "table-cell-link table-cell-error-check",
      //                   handleItem: (item: any) => {
      //                     return (
      //                       <div
      //                         className={`cell-status-code ${item.dofollow ? "error" : "success"}`}
      //                       >
      //                         <p>{item.dofollow ? "False" : "True"}</p>
      //                       </div>
      //                     );
      //                   },
      //                 },
      //               ],
      //               activeTab: "0",
      //             });
      //           }}
      //         >
      //           Xem danh sách liên kết
      //         </button>
      //       </div>
      //     );
      //   },
      // },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_ERROR_DETAIL,
    navigateFilter: queryString.stringify({
      tab: "inlink",
      code: ["code301", "code302"],
      fkey: "has_links_to_redirects",
    }),
  },
  {
    title: "Trang gắn thẻ Noindex đặt trong sitemap",
    key: "noindex_sitemap",
    type: "NoIndexPagesInSitemap",
    filter: [
      {
        key: "noindex_sitemap",
        value: "",
        operator: "",
        type: "",
      },
    ],
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
    ],
    infor: `Một trang web chứa thẻ <"noindex"> trong mã HTML (thẻ thông báo cho công cụ tìm kiếm không index trang) khi được liệt kê trong Sitemap (Nơi liệt kê các trang cần được index) sẽ gây lãng phí tài nguyên lập chỉ mục.`,
    recommendAction: `Xóa các trang có thẻ "noindex" khỏi sitemap để giúp giảm thiểu sự lãng phí tài nguyên và giúp công cụ tìm kiếm hiểu rõ hơn về cấu trúc của trang web.`,
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "data_crawler",
      sFilter: "noindex_sitemap",
    }),
  },
  {
    title: "Trang 404 đặt trong sitemap",
    key: "404_sitemap",
    type: "PagesNotfoundInSitemap",
    infor:
      "Các công cụ tìm kiếm có sự giới hạn về số trang thu thập dữ liệu trên website. Việc đặt trang 404 trong sitemap (danh sách trang ưu tiên) gây lãng phí tài nguyên, hạn chế khả năng lập chỉ mục của các trang quan trọng.",
    recommendAction:
      "Xóa các trang lỗi 404 trong sitemap. Với các trang quan trọng cần ưu tiên thu thập dữ liệu, cần đảm bảo đặt URL chính xác của trang vào sitemap.",
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "domain",
        title: `Status code`,
        className: "table-cell-error-tag ",
        handleItem: (item: any) => {
          return <p className="fit-one-line">{item.createdAt}</p>;
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "data_crawler",
      sFilter: "404_sitemap",
    }),
  },
  {
    title: "Trang không phải trang chuẩn đặt trong sitemap",
    key: "canonical_sitemap",
    type: "IncorrectCanonicalPagesInSitemap",
    filter: [
      {
        key: "canonical_sitemap",
        value: "",
        operator: "",
        type: "",
      },
    ],
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "data_crawler",
      sFilter: "canonical_sitemap",
    }),
  },
  {
    title: "Trang có phiên bản HTTP đặt trong sitemap.xml",
    key: "httpinsitemap",
    type: "HttpPagesInSitemap",
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "https",
      sFilter: "httpinsitemap",
    }),
  },
  {
    title: "Trang có quá nhiều liên kết ngoài",
    key: "external_links_count",
    filter: [
      {
        key: "meta.external_links_count",
        value: "100",
        operator: ">=",
        type: "field",
      },
    ],
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "domain",
        title: `External link`,
        className: " ",
        handleItem: (item: any) => {
          return <p className="fit-one-line">{item.createdAt}</p>;
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_ERROR_DETAIL,
    navigateFilter: queryString.stringify({
      tab: "outlink",
      fkey: "external_links_count",
    }),
  },
  {
    title: "Trang có liên kết ngoài bị hỏng",
    key: "external_broken_link",
    filter: [
      {
        key: "direction",
        value: "external",
        operator: "=",
        type: "field",
      },
      {
        key: "",
        value: "and",
        operator: "",
        type: "operator",
      },
      {
        key: "is_broken",
        value: "true",
        operator: "=",
        type: "field",
      },
    ],
    type: "links",
    headerData: [
      {
        field: "url",
        title: `From URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      // {
      //   field: "domain",
      //   title: `To URL`,
      //   className: "table-cell-link",
      //   handleItem: (item: any) => {
      //     return (
      //       <div className="table-cell-link-error">
      //         <button
      //           className="fit-one-line"
      //           onClick={() => {
      //             toggleErrorTableModal({
      //               open: true,
      //               onCancel: () => {},
      //               isHasFilter: true,
      //               url: item.url,
      //               filter: [
      //                 {
      //                   key: "resource_type",
      //                   value: "html",
      //                   operator: "=",
      //                   type: "field",
      //                 },
      //                 {
      //                   key: "",
      //                   value: "and",
      //                   operator: "",
      //                   type: "operator",
      //                 },
      //                 {
      //                   key: "checks.https_to_http_links",
      //                   value: "true",
      //                   operator: "=",
      //                   type: "field",
      //                 },
      //               ],
      //               headersTable: [
      //                 {
      //                   field: "url",
      //                   title: `From URL`,
      //                   width: "244px",
      //                   className: "table-cell-link",
      //                   isSticky: true,
      //                   handleItem: (item: any) => {
      //                     return (
      //                       <a
      //                         className="fit-one-line"
      //                         target="_blank"
      //                         rel="noopener noreferrer"
      //                         href={item.url}
      //                       >
      //                         {item.url}
      //                       </a>
      //                     );
      //                   },
      //                 },
      //                 {
      //                   field: "domain",
      //                   title: `From URL`,
      //                   width: "244px",
      //                   className: "table-cell-link table-cell-error-check",
      //                   handleItem: (item: any) => {
      //                     return (
      //                       <div className="table-cell-link-error">
      //                         <a
      //                           className="fit-one-line"
      //                           target="_blank"
      //                           rel="noopener noreferrer"
      //                           href={item.url}
      //                         >
      //                           {item.url}
      //                         </a>
      //                         <div
      //                           className={`cell-status-code ${checkStatusCode(Number(item.status_code)).toString()}`}
      //                         >
      //                           <p>{item.status_code || "4xx"}</p>
      //                         </div>
      //                       </div>
      //                     );
      //                   },
      //                 },
      //                 {
      //                   field: "domain",
      //                   title: `Anchor text`,
      //                   width: "120px",
      //                 },
      //                 {
      //                   field: "follow",
      //                   title: `Follow`,
      //                   width: "100px",
      //                   className: "table-cell-link table-cell-error-check",
      //                   handleItem: (item: any) => {
      //                     return (
      //                       <div
      //                         className={`cell-status-code ${!!item.dofollow ? "success" : "error"}`}
      //                       >
      //                         <p>{item.dofollow || "True"}</p>
      //                       </div>
      //                     );
      //                   },
      //                 },
      //               ],
      //               activeTab: "1",
      //             });
      //           }}
      //         >
      //           Xem danh sách liên kết
      //         </button>
      //       </div>
      //     );
      //   },
      // },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_ERROR_DETAIL,
    navigateFilter: queryString.stringify({
      tab: "outlink",
      fkey: "external_broken_link",
    }),
  },
  {
    title: "Trang chuyển hướng tạm thời",
    key: "status_code",
    type: "page",
    filter: [
      {
        key: "status_code",
        value: "302",
        operator: "<>",
        type: "field",
      },
    ],
    infor:
      "Trang chuyển hướng tạm thời là trang web sử dụng mã trạng thái HTTP 302 hoặc 307 để chuyển hướng người dùng từ một URL này sang một URL khác tạm thời.",
    recommendAction:
      "Chuyển hướng 302 chỉ nên được sử dụng trong thời gian ngắn. Nếu việc chuyển hướng kéo dài, hãy cân nhắc sử dụng chuyển hướng vĩnh viễn (301).",
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "status_code",
        title: `Status code`,
        className: "table-cell-error-tag ",
        handleItem: (item: any) => {
          return <p className="fit-one-line">{item.status_code || "302"}</p>;
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "statuscode",
      sFilter: "code302",
    }),
  },
  {
    title: "Trang có đường dẫn chứa tham số động",
    key: "seo_friendly_url_dynamic_check",
    type: "page",
    filter: [
      {
        key: "checks.seo_friendly_url_dynamic_check",
        value: "false",
        operator: "=",
        type: "field",
      },
    ],
    infor:
      "Trang có đường dẫn chứa tham số động là những trang có URL chứa các tham số được thay đổi tùy theo ngữ cảnh hoặc hành động của người dùng. Các tham số này thường xuất hiện sau dấu chấm hỏi (?) trong URL và có thể thay đổi dựa trên các giá trị khác nhau.",
    recommendAction:
      "Sử dụng thẻ canonical để chỉ định phiên bản URL chính thức cho các trang có nội dung trùng lặp do tham số động. ",
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "data_crawler",
      sFilter: "seo_friendly_url_dynamic_check",
    }),
  },
  {
    title: "Trang có đường dẫn chứa kí tự đặc biệt",
    key: "seo_friendly_url_characters_check",
    type: "page",
    filter: [
      {
        key: "checks.seo_friendly_url_characters_check",
        value: "false",
        operator: "=",
        type: "field",
      },
    ],
    infor:
      "Trang có đường dẫn chứa ký tự đặc biệt là những trang web có URL bao gồm các ký tự không phải chữ cái hoặc số thông thường.  Những ký tự này có thể bao gồm dấu cách, dấu chấm phẩy, dấu ngoặc kép, dấu chấm than, v.v.",
    recommendAction:
      "Cách tốt nhất là loại bỏ hoàn toàn các ký tự đặc biệt khỏi URL. Thay vì sử dụng dấu cách, hãy sử dụng dấu gạch ngang (-).",
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "data_crawler",
      sFilter: "seo_friendly_url_characters_check",
    }),
  },
  {
    title: "Trang có đường dẫn quá dài",
    key: "seo_friendly_url_relative_length_check",
    type: "page",
    filter: [
      {
        key: "checks.seo_friendly_url_relative_length_check",
        value: "false",
        operator: "=",
        type: "field",
      },
    ],
    infor:
      "Trang có đường dẫn quá dài là trang web có URL dài hơn mức hợp lý, có thể chứa nhiều từ khóa, tham số động hoặc các yếu tố không cần thiết. ",
    recommendAction:
      "Sử dụng URL ngắn gọn và dễ hiểu, tránh việc đưa quá nhiều thông tin vào URL.",
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "url_length",
        title: `URL Length`,
        className: "table-cell-text-tag ",
        handleItem: (item: any) => {
          return <p className="fit-one-line">{item.url.length}</p>;
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "data_crawler",
      sFilter: "seo_friendly_url_relative_length_check",
    }),
  },
  {
    title: "Trang chứa liên kết nội bộ đi gắn thẻ Nofollow",
    key: "nofollow",
    type: "links",
    filter: [
      ["dofollow", "=", "false"],
      "and",
      [["type", "=", "link"], "or", ["type", "=", "anchor"]],
    ],
    infor:
      "Khi một trang chứa các liên kết nội bộ nhưng được gắn thẻ Nofollow nghĩa là công cụ tìm kiếm sẽ không truyền sức mạnh từ trang đó đến trang đích của liên kết.",
    recommendAction:
      "Chỉ sử dụng thẻ nofollow khi liên kết đến các trang không quan trọng (ví dụ: trang đăng nhập, trang điều khoản sử dụng) hoặc các trang không mong muốn công cụ tìm kiếm thu thập dữ liệu.",
    headerData: [
      {
        field: "url",
        title: `From URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.link_from}
            >
              {item.link_from}
            </a>
          );
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_ERROR_DETAIL,
    navigateFilter: queryString.stringify({
      tab: "inlink",
      fkey: "nofollow",
    }),
  },
  {
    title: "Trang có tiêu đề quá ngắn",
    key: "title_too_short",
    type: "page",
    filter: [
      {
        key: "checks.title_too_short",
        value: "true",
        operator: "=",
        type: "field",
      },
    ],
    infor:
      "Trang có tiêu đề quá ngắn là khi thẻ <title> của trang chứa quá ít kí tự. Một tiêu đề quá ngắn có thể không cung cấp đầy đủ nội dung của trang đồng thời hạn chế khả năng tối ưu hiện thị trên trang kết quả tìm kiếm. ",
    recommendAction:
      "Đảm bảo rằng thẻ tiêu đề của mỗi trang có chiều dài từ 50-60 ký tự và nêu nội dung chính của bài viết.",
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "title_length",
        title: `Chiều dài tiêu đề`,
        className: "",
        handleItem: (item: any) => {
          return <p className="fit-one-line">{item.meta.title_length}</p>;
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "title",
      sFilter: "title_too_short",
    }),
  },
  {
    title: "Trang không được thu thập dữ liệu",
    key: "noindex",
    type: "PageNoIndex",
    filter: [
      {
        key: "noindex",
        value: "",
        operator: "",
        type: "",
      },
    ],
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
    ],
    infor:
      "Trang không được thu thập dữ liệu là các trang web mà công cụ tìm kiếm không thể truy cập và lấy thông tin. Các trang không được thu thập dữ liệu sẽ không xuất hiện trong trang kết quả của công cụ tìm kiếm.",
    recommendAction: `Trang không được thu thập dữ liệu có thể do bị chặn qua tệp robots.txt, thẻ "noindex", lỗi máy chủ hoặc các vấn đề khác. Kiểm tra và xử lý các nguyên nhân này, đảm bảo các trang quan trọng được thu thập dữ liệu và lập chỉ mục bởi công cụ tìm kiếm.`,
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "data_crawler",
      sFilter: "noindex",
    }),
  },
  {
    title: "Trang cần nhiều hơn 3 click để truy cập",
    key: "click_depth",
    type: "page",
    filter: [
      {
        key: "click_depth",
        value: "3",
        operator: ">",
        type: "field",
      },
    ],
    infor:
      "Các trang cần nhiều hơn 3 click để truy cập có thể bị công cụ tìm kiếm coi là ít quan trọng và xếp hạng thấp hơn trong kết quả tìm kiếm đồng thời người dùng có thể sẽ gặp khó khăn để truy cập thông tin từ các trang này.",
    recommendAction:
      "Sắp xếp các mục menu theo thứ tự logic, dễ hiểu hoặc sử dụng liên kết nội bộ thông minh và breadcrumb để người dùng khám phá nội dung dễ dàng hơn.",
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "click_depth",
        title: `Lượt click`,
        handleItem: (item: any) => {
          return <p className="fit-one-line">{item.click_depth}</p>;
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "clickdepth",
      sFilter: ["depth4", "depth5", "depth5+"],
    }),
  },
  {
    title: "Cài đặt file Robot.txt",
    key: "robots_txt",
    infor:
      "Cài đặt file robots.txt giúp tiết kiệm ngân sách thu thập dữ liệu của công cụ tìm kiếm, tăng khả năng lập chỉ mục các trang quan trọng và bảo vệ các trang không công khai khỏi bị lập chỉ mục.",
    recommendAction:
      "Tạo và tải tệp robots.txt lên thư mục gốc của website, trong đó thêm các chỉ thị như User-agent, Disallow, Allow, và Sitemap để chỉ rõ các quy tắc mà bạn muốn áp dụng cho các công cụ tìm kiếm.",
  },
  {
    title: "Có XML sitemap",
    key: "sitemap",
    infor:
      "XML sitemap là một tệp tin định dạng XML chứa danh sách các URL của website, giúp các công cụ tìm kiếm như Google dễ dàng khám phá, hiểu cấu trúc và lập chỉ mục (index) tất cả các trang trên website.",
    recommendAction:
      "Tạo và cập nhật sitemap đầy đủ, gửi sitemap lên Google Search Console để cải thiện khả năng lập chỉ mục và tăng khả năng hiển thị của trang web.",
  },
  {
    title: "XML sitemap được bỏ vào Robot.txt",
    key: "XMLsitemapRobot",
    infor:
      "Đặt XML sitemap vào robots.txt là một cách giúp công cụ tìm kiếm dễ dàng tìm thấy và index website của bạn. ",
    recommendAction:
      "Để thêm XML sitemap vào robots.txt, bạn chỉ cần sử dụng lệnh Sitemap: <URL của sitemap> trong tệp robots.txt ",
  },
  {
    title: "Trang không có liên kết nội bộ trỏ đến",
    key: "is_orphan_page",
    type: "page",
    filter: [
      {
        key: "checks.is_orphan_page",
        value: "true",
        operator: "=",
        type: "field",
      },
    ],
    infor:
      "Trang không có liên kết nội bộ trỏ đến là khi một trang không có bất kỳ liên kết nào từ các trang khác trên website trỏ đến nó. Điều này làm giảm khả năng thu thập dữ liệu trên trang cũng như giảm thứ hạng SEO của trang.",
    recommendAction:
      "Thêm các liên kết nội bộ từ các trang liên quan khác để tăng cường cấu trúc liên kết, cải thiện khả năng lập chỉ mục và tối ưu hóa thứ hạng SEO.",
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_ERROR_DETAIL,
    navigateFilter: queryString.stringify({
      tab: "inlink",
      fkey: "is_orphan_page",
    }),
  },
];

const dataIssueInfor: IIssueCommon[] = [
  {
    title: "Trang có liên kết nội bộ trỏ tới với anchortext không đa dạng",
    key: "internal_link_anchortext",
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "domain",
        title: `Chi tiết`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return <button className="fit-one-line">Chi tiết</button>;
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_ERROR_DETAIL,
    navigateFilter: queryString.stringify({
      tab: "inlink",
      fkey: "internal_link_anchortext",
    }),
  },
  {
    title: "Trang chỉ có duy nhất 1 liên kết nội bộ trỏ đến",
    key: "internal_link_direction",
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "domain",
        title: `Chi tiết`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return <button className="fit-one-line">Chi tiết</button>;
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_ERROR_DETAIL,
    navigateFilter: queryString.stringify({
      tab: "inlink",
      fkey: "internal_link_direction",
    }),
  },
  {
    title: "Có schema Organization",
    key: "",
  },
  {
    title: "Có Schema Local Business",
    key: "",
  },
  {
    title: "Trang không được xem là trang chuẩn",
    key: "no_canonical",
    infor: `Trang thiếu thẻ canonical là khi trang không sử dụng thẻ rel="canonical" để thông báo cho công cụ tìm kiếm biết URL nào là phiên bản chính của trang, điều này có thể làm giảm xếp hạng khi website có nhiều phiên bản URL trùng lặp.`,
    recommendAction:
      "Thêm thẻ canonical vào thẻ <head> của trang để chỉ định URL chính của trang. Điều này đặc biệt quan trọng nếu website có nhiều trang với nội dung gần giống nhau hoặc các trang có tham số URL (?utm_source=..., ?page=2,...).",
    type: "page",
    filter: [
      {
        key: "checks.canonical",
        value: "false",
        operator: "=",
        type: "field",
      },
    ],
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "canocical",
      sFilter: "missingcanonical",
    }),
  },
];

const mapData = { dataIssueError, dataIssueInfor, dataIssueWaring };

export default mapData;
