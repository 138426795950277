import { styled } from "@mui/material/styles";
import variableStyles from "../../../../../../../../../theme/variable-styles";

export const ProgressHalfCircleWrapper = styled("div")<{
  value: number;
  progressfill: string;
  valuefill: string;
}>(({ value, progressfill, valuefill }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  "@keyframes show": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "50%": { transform: "rotate(180deg)" },
  },
  "&.large": {
    width: "144px",
    "& .circle-wrapper": {
      width: "134px",
      height: "66px",
      marginBottom: "16px",
      "&::before": {
        width: "134px",
        height: "66px",
        border: `10px solid ${progressfill}`,
      },
      "& .graph": {
        width: "134px",
        height: "67px",
        "&::before, &::after": {
          width: "134px",
          height: "67px",
          border: `10px solid ${valuefill}`,
        },
      },
      "& .progress-value": {
        fontSize: "24px",
        lineHeight: "24px",
      },
    },
    "& .lable": {
      fontWeight: variableStyles.fwMedium,
      fontSize: "14px",
      lineHeight: "16.94px",
      padding: "0 10px",
    },
  },
  "&.small": {
    width: "120px",
    "& .circle-wrapper": {
      width: "92px",
      height: "46px",
      marginBottom: "12px",
      "&::before": {
        width: "92px",
        height: "46px",
        border: `8px solid ${progressfill}`,
      },
      "& .graph": {
        width: "92px",
        height: "46px",
        "&::before, &::after": {
          width: "92px",
          height: "46px",
          border: `8px solid ${valuefill}`,
        },
      },
      "& .progress-value": {
        fontSize: "18px",
        lineHeight: "18px",
      },
    },

    "& .lable": {
      fontWeight: variableStyles.fwMedium,
      fontSize: "14px",
      lineHeight: "16.94px",
      padding: "0 10px",
    },
  },
  "& .circle-wrapper": {
    position: "relative",
    display: "flex",
    "&::before": {
      content: "''",
      position: "absolute",
      borderRadius: "300px 300px 0 0",
      borderBottom: "none !important",
      left: "50%",
      transform: "translateX(-50%)",
    },
    "& .graph": {
      position: "absolute",
      overflow: "hidden",
      left: "50%",
      transform: "translateX(-50%)",
      "&::before, &::after": {
        position: "absolute",
        top: "100%",
        left: "0",
        content: "''",
        borderRadius: "0 0 300px 300px",
        borderTop: "none !important",
        animation: "show 3s",
        transformOrigin: "center top",
      },
      "&::after": { transform: `rotate(calc(180deg / 100 * ${value}))` },
    },
    "& .progress-value": {
      position: "absolute",
      color: valuefill,
      left: "50%",
      transform: "translateX(-50%)",
      bottom: "-3px",
      fontWeight: variableStyles.fwSemiBold,
    },
  },

  "& .lable": {
    color: variableStyles.NaturalColor1000,
    fontWeight: variableStyles.fwMedium,
    fontSize: "14px",
    lineHeight: "16.94px",
    textAlign: "center",
    width: "100%",
    flex: "1 1 auto",
    padding: "0 10px",
  },
}));
