import React, { useEffect, useState } from "react";
import { IPieChartValue } from "../../../../../../../../models/common/models.type";
import OverviewCommonCaption from "../../../../../OverviewCommonCaption";
import {
  TechnicalOverviewChartWrapper,
  TechnicalOverviewTabWrapper,
} from "./styles";
import IconChart from "../../../../../../../../assets/images/common/icon_chart_line_green.svg";
import {
  ImageCustom,
  LoadingIndicator,
  NoDataBox,
  PieChartDonutShape,
} from "../../../../../../../../components";
import IconError from "../../../../../../../../assets/images/common/icon_error_fill_red_border.svg";
import IconWarning from "../../../../../../../../assets/images/common/icon_warning_fill_yellow_border.svg";
import IconSuccess from "../../../../../../../../assets/images/common/icon_success_fill_green_border.svg";
import IconInforBlue from "../../../../../../../../assets/images/common/icon_infor_fill_blue_border.svg";
import IconInfor from "../../../../../../../../assets/images/common/icon_infor_line_grey.svg";
import { Tooltip, Typography } from "@mui/material";
import {
  EAuditErrorStatus,
  ESectionOfAuditReport,
} from "../../../../../../../../models/common/models.enum";
import AuditReportService from "../../../../../../../../services/api/audit.report.service";
import { ISEOErrorSummaryRes } from "../../../../../../../../models/seo";
import { useDispatch, useSelector } from "react-redux";
import { setErrorListState } from "../../../../../../../../redux/project/project.duck";
import { IStateApp } from "../../../../../../../../models/redux";
import { IGetReportsRes } from "../../../../../../../../models/report";
import {
  IGetAuditOverviewErrorRes,
  IGetHeadingErrorRes,
  IGetImageErrorRes,
  IGetInformationAbilityRes,
} from "../../../../../../../../models/audit-report";
import { generatePath } from "react-router-dom";
import { ROUTE_PATH } from "../../../../../../../../common/constants/app.constant";

export interface IIssueListData {
  title: string;
  type: EAuditErrorStatus;
  issueCount?: number;
  infor: string;
  key?: string;
  recommendAction?: any;
}

export default function TechnicalOverviewTab(props: {
  onNavigateIssue: (key: string) => void;
  onShowScores: (data: {
    errorCount: number;
    warningCount: number;
    notesCount: number;
  }) => void;
  dataIssue: {
    title?: string;
    list?: IIssueListData[];
    children?: { title?: string; list: IIssueListData[] }[];
  }[];
  dataAbility: IGetInformationAbilityRes | null;
  isLoading?: boolean;
  isLoadingAbility?: boolean;
}) {
  const {
    onNavigateIssue,
    onShowScores,
    dataIssue,
    dataAbility,
    isLoading = false,
    isLoadingAbility = false,
  } = props;

  const dispatch = useDispatch();

  let errorCount = 0;
  let warningCount = 0;
  let notesCount = 0;

  const errorList = dataIssue.map(issue => {
    const keyList: string[] = [];

    if (!!issue.children && issue.children.length > 0) {
      issue.children?.map(child => {
        child.list?.map(itemChild => {
          if (itemChild.type !== EAuditErrorStatus.Success) {
            if (!!itemChild?.key) {
              keyList.push(itemChild.key);
            }
            if (itemChild.type === EAuditErrorStatus.Error) {
              errorCount = errorCount + (itemChild.issueCount || 0);
            } else if (itemChild.type === EAuditErrorStatus.Warning) {
              warningCount = warningCount + (itemChild.issueCount || 0);
            } else if (itemChild.type === EAuditErrorStatus.Infor) {
              notesCount = notesCount + (itemChild.issueCount || 0);
            }
          }
        });
      });
    } else {
      issue.list?.map(item => {
        if (item.type !== EAuditErrorStatus.Success) {
          if (!!item?.key) {
            keyList.push(item.key);
          }
          if (item.type === EAuditErrorStatus.Error) {
            errorCount = errorCount + (item.issueCount || 0);
          } else if (item.type === EAuditErrorStatus.Warning) {
            warningCount = warningCount + (item.issueCount || 0);
          } else if (item.type === EAuditErrorStatus.Infor) {
            notesCount = notesCount + (item.issueCount || 0);
          }
        }
      });
    }

    return keyList;
  });

  useEffect(() => {
    dispatch(setErrorListState(errorList.flat(1)));
  }, [errorList]);

  useEffect(() => {
    onShowScores({ errorCount, warningCount, notesCount });
  }, [errorCount, warningCount, notesCount]);

  // navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
  // navigateFilter: queryString.stringify({
  //   tab: 2,
  //   mFilter: "statuscode",
  //   sFilter: ["code505", "code501", "code502"],
  // }),

  const chartData: TechnicalOverviewChartBoxProps[] = [
    {
      title: "Khả năng lập chỉ mục",
      description:
        "Là khả năng Robot của các công cụ tìm kiếm thu thập, và xử lý thông tin trên website.",
      dataChart: !!dataAbility?.pagesIndexAbility?.indexTotalPages
        ? [
            {
              name: "Index",
              value: dataAbility?.pagesIndexAbility?.indexTotalPages || 0,
            },
            {
              name: "No Index",
              value: dataAbility?.pagesIndexAbility?.nonIndexTotalPages || 0,
            },
          ]
        : null,
      // dataNavigate: [
      //   {
      //     keyFilter: "Index",
      //     navigateFilter: queryString.stringify({
      //       tab: "2",
      //       mFilter: "data_crawler",
      //       sFilter: "indexable",
      //     }),
      //   },
      //   {
      //     keyFilter: "No Index",
      //     navigateFilter: queryString.stringify({
      //       tab: "2",
      //       mFilter: "data_crawler",
      //       sFilter: "noindex",
      //     }),
      //   },
      // ],
    },
    {
      title: "Page crawl depth",
      description:
        "Đề cập đến số lượng click chuột cần thiết từ trang chủ của một website để đến được một trang cụ thể.",
      infor:
        "Page crawl depth càng thấp càng tốt, lý tưởng là trong khoảng từ 0 đến 3.",
      dataChart: !!dataAbility?.crawlDepth?.pagesCrawlDepth
        ? [
            {
              name: "1 click",
              value: dataAbility?.crawlDepth?.pagesCrawlDepth?.firstClicks || 0,
            },
            {
              name: "2 click",
              value:
                dataAbility?.crawlDepth?.pagesCrawlDepth?.secondClicks || 0,
            },
            {
              name: "3 click",
              value: dataAbility?.crawlDepth?.pagesCrawlDepth?.thirdClick || 0,
            },
            {
              name: "4 click",
              value: dataAbility?.crawlDepth?.pagesCrawlDepth?.fourthClick || 0,
            },
            {
              name: ">= 5 click",
              value: dataAbility?.crawlDepth?.pagesCrawlDepth?.fifthClick || 0,
            },
          ]
        : null,
      // dataNavigate: [
      //   {
      //     keyFilter: "1 click",
      //     navigateFilter: queryString.stringify({
      //       tab: "2",
      //       mFilter: "clickdepth",
      //       sFilter: "depth1",
      //     }),
      //   },
      //   {
      //     keyFilter: "2 click",
      //     navigateFilter: queryString.stringify({
      //       tab: "2",
      //       mFilter: "clickdepth",
      //       sFilter: "depth2",
      //     }),
      //   },
      //   {
      //     keyFilter: "3 click",
      //     navigateFilter: queryString.stringify({
      //       tab: "2",
      //       mFilter: "clickdepth",
      //       sFilter: "depth3",
      //     }),
      //   },
      //   {
      //     keyFilter: "4 click",
      //     navigateFilter: queryString.stringify({
      //       tab: "2",
      //       mFilter: "clickdepth",
      //       sFilter: "depth4",
      //     }),
      //   },
      //   {
      //     keyFilter: ">= 5 click",
      //     navigateFilter: queryString.stringify({
      //       tab: "2",
      //       mFilter: "clickdepth",
      //       sFilter: "depth5+",
      //     }),
      //   },
      // ],
    },
    {
      title: "HTTPS Status code",
      description:
        "Một mã số gồm ba chữ số mà máy chủ web gửi về trình duyệt web để phản hồi lại yêu cầu của trình duyệt, cho biết trạng thái của yêu cầu đó.",
      dataChart: !!dataAbility?.crawlDepth?.pagesStatusCodes
        ? [
            {
              name: "2xx",
              value:
                dataAbility?.crawlDepth?.pagesStatusCodes?.statusCode2X || 0,
            },
            {
              name: "3xx",
              value:
                dataAbility?.crawlDepth?.pagesStatusCodes?.statusCode3X || 0,
            },
            {
              name: "4xx",
              value:
                dataAbility?.crawlDepth?.pagesStatusCodes?.statusCode4X || 0,
            },
            {
              name: "5xx",
              value:
                dataAbility?.crawlDepth?.pagesStatusCodes?.statusCode5X || 0,
            },
          ]
        : null,
      // dataNavigate: [
      //   {
      //     keyFilter: "2xx",
      //     navigateFilter: queryString.stringify({
      //       tab: "2",
      //       mFilter: "statuscode",
      //       sFilter: "code200",
      //     }),
      //   },
      //   {
      //     keyFilter: "3xx",
      //     navigateFilter: queryString.stringify({
      //       tab: "2",
      //       mFilter: "statuscode",
      //       sFilter: ["code301", "code302"],
      //     }),
      //   },
      //   {
      //     keyFilter: "4xx",
      //     navigateFilter: queryString.stringify({
      //       tab: "2",
      //       mFilter: "statuscode",
      //       sFilter: ["code401", "code403", "code404"],
      //     }),
      //   },
      //   {
      //     keyFilter: "5xx",
      //     navigateFilter: queryString.stringify({
      //       tab: "2",
      //       mFilter: "statuscode",
      //       sFilter: ["code501", "code502", "code505"],
      //     }),
      //   },
      // ],
    },
  ];

  return (
    <TechnicalOverviewTabWrapper>
      <p className="technical-overview-title">Khả năng thu thập dữ liệu</p>
      <ul className="chart-list">
        {chartData.map(temp => (
          <li key={temp.title}>
            <TechnicalOverviewChartBox
              title={temp.title}
              description={temp.description}
              infor={temp.infor}
              dataChart={temp.dataChart}
              isLoading={isLoadingAbility}
              dataNavigate={temp.dataNavigate}
            />
          </li>
        ))}
      </ul>
      <div className="overview-list">
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            {!!dataIssue ? (
              <TechnicalOverviewIssueBox
                data={dataIssue}
                onClickIssue={(key: string) => {
                  if (!!key) {
                    onNavigateIssue(key);
                  }
                }}
              />
            ) : (
              <div className="overview-nodata flex-column-center">
                <NoDataBox title="No data" hasImage />
              </div>
            )}
          </>
        )}
      </div>
    </TechnicalOverviewTabWrapper>
  );
}

interface TechnicalOverviewBoxProps extends TechnicalOverviewChartBoxProps {
  isLoading: boolean;
}

interface TechnicalOverviewChartBoxProps {
  title: string;
  description: string;
  infor?: string;
  dataChart: IPieChartValue[] | null;
  dataNavigate?: { keyFilter: string; navigateFilter: string }[];
}

const TechnicalOverviewChartBox = (props: TechnicalOverviewBoxProps) => {
  const { title, description, infor, dataChart, isLoading, dataNavigate } =
    props;

  const dataReport = useSelector<IStateApp, IGetReportsRes>(
    appState => appState.project?.dataReport
  );
  return (
    <TechnicalOverviewChartWrapper>
      <OverviewCommonCaption
        className="overview-chart-caption"
        width="420px"
        caption={title}
        icon={IconChart}
        tooltip={infor}
        description={description}
      />
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          {!!dataChart ? (
            <div className="chart-wrapper">
              <PieChartDonutShape
                data={dataChart}
                onClickNavigate={
                  !!dataNavigate
                    ? v => {
                        if (!!dataNavigate && dataNavigate.length > 0) {
                          const path = generatePath(
                            ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
                            {
                              id: dataReport.auditId,
                            }
                          );
                          const result = dataNavigate.find(
                            d => d.keyFilter === v
                          );
                          if (!!result) {
                            const newWindow = window.open(
                              `${path}?${result.navigateFilter?.toLocaleLowerCase()}`,
                              "_blank",
                              "noopener,noreferrer"
                            );
                            if (newWindow) newWindow.opener = null;
                          }
                        }
                      }
                    : undefined
                }
              />
            </div>
          ) : (
            <div className="overview-nodata flex-column-center">
              <NoDataBox title="No data" hasImage />
            </div>
          )}
        </>
      )}
    </TechnicalOverviewChartWrapper>
  );
};

const TechnicalOverviewIssueBox = (props: {
  data: {
    title?: string;
    list?: IIssueListData[];
    children?: { title?: string; list: IIssueListData[] }[];
  }[];
  onClickIssue: (key: string) => void;
}) => {
  const { data, onClickIssue } = props;

  return (
    <>
      {data.map((item, idx) => (
        <div
          key={idx}
          className={`issue-list-wrapper ${!!item.title ? "list-have-title" : ""}`}
        >
          {item.title && <p className="issue-title">{item.title}</p>}
          {!!item.list && item.list.length > 0 && (
            <ul className="issue-list">
              {item.list.map(issue => (
                <li key={issue.title} className="flex-between-align-center">
                  <div className="issue-content flex-start-align-center">
                    <ImageCustom
                      src={
                        issue.type === EAuditErrorStatus.Error
                          ? IconError
                          : issue.type === EAuditErrorStatus.Warning
                            ? IconWarning
                            : issue.type === EAuditErrorStatus.Success
                              ? IconSuccess
                              : IconInforBlue
                      }
                      alt="Icon-Issue"
                      width={"22px"}
                      height={"22px"}
                      className="content-type-icon"
                    />
                    <p className="issue-subtitle fit-one-line">{issue.title}</p>
                    <Tooltip title={issue.infor} placement="top-start">
                      <div>
                        <ImageCustom
                          src={IconInfor}
                          alt="Icon-Infor"
                          width={"16px"}
                          height={"16px"}
                        />
                      </div>
                    </Tooltip>
                  </div>
                  <div
                    className={`issue-action ${!issue.key ? "no-key" : ""}  flex-end-align-center`}
                  >
                    {!!issue.issueCount && (
                      <button
                        onClick={() => {
                          if (!!issue.key) {
                            onClickIssue(issue.key);
                          }
                        }}
                        className={`${issue.issueCount > 0 && issue.type === EAuditErrorStatus.Error ? "error" : "warning"}`}
                      >
                        {issue.issueCount} vấn đề
                      </button>
                    )}
                    {!!issue.recommendAction && (
                      <Tooltip
                        title={issue.recommendAction}
                        placement="top-start"
                      >
                        <p className="action-recommend">Recommend action</p>
                      </Tooltip>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          )}
          {!!item.children && item.children.length > 0 && (
            <>
              {item.children.map((item2, idx2) => (
                <div className="issue-child-list" key={idx2}>
                  <ul className="issue-list">
                    {!!item2.title && (
                      <p className="issue-detail-title">{item2.title}</p>
                    )}

                    {item2.list.map(item2 => (
                      <li
                        key={item2.title}
                        className="flex-between-align-center"
                      >
                        <div className="issue-content flex-start-align-center">
                          <ImageCustom
                            src={
                              item2.type === EAuditErrorStatus.Error
                                ? IconError
                                : item2.type === EAuditErrorStatus.Warning
                                  ? IconWarning
                                  : item2.type === EAuditErrorStatus.Success
                                    ? IconSuccess
                                    : IconInforBlue
                            }
                            alt="Icon-Issue"
                            width={"22px"}
                            height={"22px"}
                            className="content-type-icon"
                          />
                          <p className="issue-subtitle fit-one-line">
                            {item2.title}
                          </p>
                          <Tooltip title={item2.infor} placement="top-start">
                            <div>
                              <ImageCustom
                                src={IconInfor}
                                alt="Icon-Infor"
                                width={"16px"}
                                height={"16px"}
                              />
                            </div>
                          </Tooltip>
                        </div>
                        <div className={`issue-action flex-end-align-center`}>
                          {!!item2.issueCount && (
                            <button
                              onClick={() => {
                                if (!!item2.key) {
                                  onClickIssue(item2.key);
                                }
                              }}
                              className={`${item2.issueCount > 0 && item2.type === EAuditErrorStatus.Error ? "error" : item2.issueCount > 0 && item2.type === EAuditErrorStatus.Warning ? "warning" : "infor"}`}
                            >
                              {item2.issueCount} vấn đề
                            </button>
                          )}
                          <Tooltip
                            title={item2.recommendAction}
                            placement="top-start"
                          >
                            <p className="action-recommend">Recommend action</p>
                          </Tooltip>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </>
          )}
        </div>
      ))}
    </>
  );
};
