import {
  Stack,
  SxProps,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
} from "@mui/material";
import { TableBoxWrapper, TableDataWrapper } from "./styles";

import { IBasePagingRes } from "../../../models/common/models.type";
import { ImageCustom, LoadingIndicator } from "../..";
import variableStyles from "../../../theme/variable-styles";
import IconInfor from "../../../assets/images/domai-audit/overview-section/icon_infor_fill_grey.svg";
import GridPagination from "../TableData/GridPagination";
import ImageNoData from "../../../assets/images/common/img_project_empty.png";

export interface ITableNavDetailNoPaginationHeader<T> {
  field?: keyof T;
  title?: string;
  handleItem?: (item: T) => React.ReactNode;
  align?: "left" | "right" | "center";
  notFilter?: boolean;
  width?: number | string;
  sx?: SxProps<Theme>;
  className?: string;
  isSticky?: boolean;
  infor?: string;
  isLink?: boolean;
}

export interface ITableData<T> {
  data?: IBasePagingRes<T>;
  headersTable: ITableNavDetailNoPaginationHeader<T>[];
  minWidth?: number | string;
  maxHeight?: string;
  minHeight?: string;
  loading?: boolean;
  titleText?: string;
  colorHeader?: string;
  colorPagination?: string;
  isFullTable?: boolean;
  handlePageChange?: (page: number) => void;
  handleViewMore?: () => void;
  isInfiniteScroll?: boolean;
  scrollbarSize?: "normal" | "large";
  isHasEmptyRow?: boolean;
}

export const TableNavDetailNoPagination = (props: ITableData<any>) => {
  const {
    loading,
    data,
    headersTable,
    minWidth = 1050,
    maxHeight = "calc(100vh - 273px)",
    minHeight = "0px",
    titleText,
    colorHeader = variableStyles.Info800,
    colorPagination = "transparent",
    isFullTable = false,
    handlePageChange,
    handleViewMore,
    isInfiniteScroll = false,
    scrollbarSize = "normal",
    isHasEmptyRow = false,
  } = props;

  return (
    <TableBoxWrapper colorpagination={colorPagination}>
      {!!titleText && <h4>{titleText}</h4>}
      <div className="table-wrapper">
        <TableContainer
          sx={{
            maxHeight: maxHeight,
            minHeight: minHeight,
            borderRadius:
              !!data &&
              (!!handleViewMore ||
                Math.ceil((data?.total || 0) - data.pageSize) > 0)
                ? "12px 12px 0 0"
                : "12px",
            scrollBehavior: "smooth !important",
          }}
          className={
            scrollbarSize === "normal" ? "scrollbar" : "scrollbar-large"
          }
        >
          <TableDataWrapper stickyHeader colorheader={colorHeader}>
            <TableHead>
              <TableRow>
                {headersTable.map((item, key) => {
                  return (
                    <TableCell
                      key={key}
                      sx={{
                        ...item.sx,
                        width: item.width,
                      }}
                      className={`${item.className} ${item.isSticky ? "sticky-column" : ""}`}
                    >
                      <div className="header-table-cell-wrapper flex-start-align-center">
                        <p className="fit-one-line">{item.title}</p>
                        {!!item.infor && (
                          <Tooltip title={item.infor} placement="top-start">
                            <div>
                              <ImageCustom
                                src={IconInfor}
                                alt="Icon-Infor"
                                width={"14px"}
                                height={"14px"}
                              />
                            </div>
                          </Tooltip>
                        )}
                      </div>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            {loading ? (
              <TableBody>
                <TableRow className="box-loading-wrapper">
                  <TableCell colSpan={headersTable.length}>
                    <Stack sx={{ width: "100%", height: "168px" }}>
                      <div className={"box-loading flex-column-center"}>
                        <LoadingIndicator />
                      </div>
                    </Stack>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <>
                {!!data && data.items.length > 0 ? (
                  <TableBody>
                    {data.items.map((item, idx) => (
                      <TableRow
                        hover
                        key={idx}
                        className={`${
                          (!item?.compareWith ||
                            (!!item?.compareWith &&
                              item?.compareWith?.length <= 0)) &&
                          !item?.keyword
                            ? "not-collapse"
                            : ""
                        }`}
                      >
                        {headersTable.map((field, key) => {
                          if (field.handleItem) {
                            return (
                              <TableCell
                                key={key}
                                sx={{
                                  ...field.sx,
                                  textAlign: field.align,
                                  width: field.width,
                                }}
                                className={`${field.className}  ${field.isSticky ? "sticky-column" : ""}`}
                              >
                                {field.handleItem(item)}
                              </TableCell>
                            );
                          } else {
                            return (
                              <TableCell
                                sx={{
                                  ...field.sx,
                                  textAlign: field.align,
                                  width: field.width,
                                }}
                                className={`${field.className}  ${field.isSticky ? "sticky-column" : ""}`}
                                key={key}
                              >
                                {field.isLink ? (
                                  <a
                                    className="fit-one-line"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={field.field ? item[field.field] : ""}
                                  >
                                    {field.field ? item[field.field] : null}
                                  </a>
                                ) : (
                                  <>{field.field ? item[field.field] : null}</>
                                )}
                              </TableCell>
                            );
                          }
                        })}
                      </TableRow>
                    ))}
                    {!!isHasEmptyRow && (
                      <>
                        {Array.from(Array(10 - data.items.length)).map(
                          (_, idx) => (
                            <TableRow
                              key={idx}
                              className={`not-collapse empty-row`}
                            >
                              <TableCell></TableCell>
                            </TableRow>
                          )
                        )}
                      </>
                    )}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow className="box-empty-wrapper">
                      <TableCell colSpan={headersTable.length}>
                        <Stack sx={{ width: "100%", height: "168px" }}>
                          <div className={"box-empty flex-column-center"}>
                            <ImageCustom
                              src={ImageNoData}
                              alt="Icon-NoData"
                              width={"120px"}
                              height={"120px"}
                            />
                            <p className={"text-empty"}>No data</p>
                          </div>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </>
            )}
          </TableDataWrapper>
        </TableContainer>
        {!!data && data.items.length > 0 && (
          <>
            {isFullTable ? (
              <>
                {Math.ceil((data?.total || 0) - data.pageSize) > 0 && (
                  <>
                    {!isInfiniteScroll && (
                      <div className="pagination-wrapper">
                        <GridPagination
                          total={data.total}
                          page={data.page}
                          pageSize={data.pageSize}
                          onPageChange={page => {
                            handlePageChange && handlePageChange(page);
                          }}
                          text="records"
                        />
                      </div>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                {!!handleViewMore && (
                  <button
                    onClick={() => handleViewMore()}
                    className="view-all-button"
                  >
                    Xem thêm
                  </button>
                )}
              </>
            )}
          </>
        )}
      </div>
    </TableBoxWrapper>
  );
};
