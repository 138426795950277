import { LoadingIndicatorWrapper } from "./styles";

interface LoadingIndicatorProps {
  className?: string;
}

export default function LoadingIndicator(props: LoadingIndicatorProps) {
  const { className = "" } = props;
  return (
    <LoadingIndicatorWrapper className={className}>
      <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </LoadingIndicatorWrapper>
  );
}
