import { styled } from "@mui/material/styles";
import { FormControl, FormControlProps } from "@mui/material";
import variableStyles from "../../../theme/variable-styles";

interface InputSearchProps extends FormControlProps {
  width?: string;
  typestyle?: "outlined" | "contained";
  size?: "small" | "medium";
}

export const InputSearchWrapper = styled(FormControl)<InputSearchProps>(
  ({ theme, width, typestyle, size }) => ({
    position: "relative",
    width: !!width ? width : "100%",
    "& .MuiInputBase-root": {
      height: size === "small" ? 32 : 40,
      backgroundColor:
        typestyle === "contained"
          ? variableStyles.blueSecondary50
          : variableStyles.colorWhite,
      borderRadius: size === "small" ? "6px" : "8px",
      padding: "0 4px",
      "&.Mui-focused": {
        "& fieldset": {
          boxShadow: "0px 0px 0px 4px #23B4FF33",
          border: typestyle === "contained" ? "none" : `1px solid #0C97FF`,
        },
        "& .search-select": {
          "&.MuiInputBase-root": {
            "& fieldset": {
              border: `1px solid ${variableStyles.NaturalColor300} !important`,
              boxShadow: "none",
            },
            "& .MuiSelect-select": { boxShadow: "none" },
          },
        },
      },
      "& .MuiInputAdornment-root": {
        marginRight: "6px",
      },
      "& .MuiInputBase-input": {
        fontFamily: variableStyles.Inter,
        fontWeight: variableStyles.fwRegular,
        fontSize: "14px",
        lineHeight: "24px",
        color: variableStyles.NaturalColor800,
        padding: size === "small" ? "10px 0" : "6px 4px 6px 0px",
        "&::placeholder": {
          color: variableStyles.NaturalColor700,
          opacity: "1",
        },
      },
      "& fieldset": {
        borderRadius: "8px",
        border:
          typestyle === "contained"
            ? "none"
            : `1px solid ${variableStyles.NaturalColor300}`,
      },
      "&:hover": {
        "& fieldset": {
          border: typestyle === "contained" ? "none" : `1px solid #0C97FF`,
        },
        "& .search-select": {
          "&.MuiInputBase-root": {
            "& fieldset": {
              border: `1px solid ${variableStyles.NaturalColor300} !important`,
              boxShadow: "none",
            },
            "& .MuiSelect-select": { boxShadow: "none" },
          },
        },
      },
    },
    "& .Mui-error": {
      "& fieldset": {
        boxShadow: `0px 0px 0px 4px #90580433`,
        border: `1px solid ${variableStyles.Warning800} !important`,
      },
    },
    "& .helper-text-error": {
      display: "flex",
      alignItems: "center",
      gap: "4px",
      justifyContent: "flex-start",
      marginTop: "8px",
      "& .input-search-error": {
        fontWeight: variableStyles.fwRegular,
        fontSize: "13px",
        lineHeight: "18.2px",
        color: variableStyles.Warning800,
      },
    },
    "& .search-select": {
      "&.MuiInputBase-root": {
        height: "32px",
        "&.Mui-focused": {
          "& fieldset": {
            border: `1px solid ${variableStyles.blueSecondary600} !important`,
          },
          "& .MuiSelect-select": { boxShadow: "none" },
        },
      },
      "& fieldset": {},
    },
  })
);
