import React from "react";
import { Box, LinearProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import { getColorByStatus } from "../../../../../../../../../common/handles/common.handles";
import variableStyles from "../../../../../../../../../theme/variable-styles";
import { MultiColorProgressWrapper } from "./styles";
import { EAuditErrorStatus } from "../../../../../../../../../models/common/models.enum";

interface MultiColorProgressProps {
  value: number; // Current progress value
  label: string;
  status: EAuditErrorStatus;
  stages: { percentage: number }[]; // Stages with colors and respective percentages
  width: number | string; // Width of
}

const MultiColorLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 4,
  borderRadius: "100px",
}));

const MultiColorProgress: React.FC<MultiColorProgressProps> = ({
  value,
  stages,
  label,
  status,
  width,
}) => {
  const totalPercentage = stages.reduce(
    (acc, stage) => acc + stage.percentage,
    0
  );

  const colors = [
    variableStyles.Success500,
    variableStyles.Warning500,
    variableStyles.Error500,
  ];

  const percentageValue = Math.min((value / totalPercentage) * 100, 100);
  const color = getColorByStatus(status).primary;

  return (
    <MultiColorProgressWrapper width={width}>
      <div className="progress-bar-wrapper">
        {/* Render the Progress Bar */}
        {stages.map((stage, index) => (
          <Box
            key={index}
            sx={{
              flexGrow: stage.percentage / 100,
              height: 4,
              backgroundColor: colors[index],
              borderRadius:
                index === 0
                  ? "5px 0 0 5px"
                  : index === stages.length - 1
                    ? "0 5px 5px 0"
                    : "0",
              zIndex: 1,
            }}
          />
        ))}

        {/* Linear Progress to represent the actual value */}
        <MultiColorLinearProgress
          variant="determinate"
          value={percentageValue}
        />

        {/* Circle Indicator */}
        <Box
          sx={{
            position: "absolute",
            left: `75%`,
            transform: "translate(-50%, -50%)",
            top: "50%",
            zIndex: 2,
            width: 12,
            height: 12,
            backgroundColor: color, // Blue color for the circle
            borderRadius: "50%",
            border: `2px solid ${variableStyles.NaturalColor100}`,
            boxShadow: `0 0 0 0px ${color}`,
          }}
        />

        {/* Label for the time */}
        <p
          style={{
            position: "absolute",
            left: `75%`,
            transform: "translate(-50%, -150%)",
            top: "50%",
            color: color, // Text color (dark blue)
            width: "max-content",
            fontWeight: variableStyles.fwMedium,
            fontSize: "10px",
            lineHeight: "12.1px",
          }}
        >
          {label}
        </p>
      </div>
    </MultiColorProgressWrapper>
  );
};

export default MultiColorProgress;
