import api from "../remote/baseApi";
import ApiEndpoints from "../remote/apiEndpoints";
import { envConfig } from "../../config/env.config";
import {
  IBasePagingRes,
  IBaseResponseModel,
} from "../../models/common/models.type";
import {
  ICreateAuditUXReportReq,
  ICreateDomainCompatitiveReq,
  ICreatePageAuditReq,
  ICreatePageCompatitiveReq,
  ICreateReportCommonReq,
  IGetReportsReq,
  IGetReportsRes,
} from "../../models/report";

const API_REPORT = ApiEndpoints.REPORT;
const baseURL = envConfig.API_KEY_PROJECT_ENDPOINT;

const createDomainAudit = (
  data: ICreateReportCommonReq,
  signalAction: AbortSignal
): Promise<IBaseResponseModel<any>> => {
  return api.post(baseURL, API_REPORT.CREATE_DOMAIN_AUDIT.url, data, {
    signal: signalAction,
  });
};

const createDomainAuditSEO = (
  data: ICreateReportCommonReq,
  signalAction: AbortSignal
): Promise<IBaseResponseModel<any>> => {
  return api.post(baseURL, API_REPORT.CREATE_DOMAIN_AUDIT_SEO.url, data, {
    signal: signalAction,
  });
};

const createDomainAuditUX = (
  data: ICreateAuditUXReportReq,
  signalAction: AbortSignal
): Promise<IBaseResponseModel<any>> => {
  return api.post(baseURL, API_REPORT.CREATE_DOMAIN_AUDIT_UX.url, data, {
    signal: signalAction,
  });
};

const createDomainCompetitive = (
  data: ICreateDomainCompatitiveReq,
  signalAction: AbortSignal
): Promise<IBaseResponseModel<any>> => {
  return api.post(baseURL, API_REPORT.CREATE_DOMAIN_COMPETITIVE.url, data, {
    signal: signalAction,
  });
};
const createPageAudit = (
  data: ICreatePageAuditReq,
  signalAction: AbortSignal
): Promise<IBaseResponseModel<any>> => {
  return api.post(baseURL, API_REPORT.CREATE_PAGE_AUDIT.url, data, {
    signal: signalAction,
  });
};
const createPageCompetitive = (
  data: ICreatePageCompatitiveReq,
  signalAction: AbortSignal
): Promise<IBaseResponseModel<any>> => {
  return api.post(baseURL, API_REPORT.CREATE_PAGE_COMPETITIVE.url, data, {
    signal: signalAction,
  });
};

const getReportList = (
  params: IGetReportsReq
): Promise<IBaseResponseModel<IBasePagingRes<IGetReportsRes>>> => {
  return api.get(baseURL, API_REPORT.GET_REPORTS.url, {
    params: params,
  });
};
const getReport = (id: string): Promise<IBaseResponseModel<IGetReportsRes>> => {
  return api.get(baseURL, API_REPORT.GET_REPORT.url, {
    params: { id },
  });
};

const deleteReport = (params: {
  id: string;
}): Promise<IBaseResponseModel<boolean>> => {
  return api.delete(baseURL, `${API_REPORT.DELETE_REPORT.url}`, {
    params: params,
  });
};

const ReportService = {
  createDomainAudit,
  createDomainAuditSEO,
  createDomainAuditUX,
  createDomainCompetitive,
  createPageAudit,
  createPageCompetitive,
  getReportList,
  getReport,
  deleteReport,
};

export default ReportService;
