import { styled } from "@mui/material/styles";
import variableStyles from "../../theme/variable-styles";

interface DragAndDropSingleProps {
  error?: boolean;
  disabled?: boolean;
  progresspercent: number | null;
}

export const DragAndDropFileWrapper = styled("div")<DragAndDropSingleProps>(
  ({ error, disabled, progresspercent }) => ({
    padding: "34px 12px 12px",
    backgroundColor: variableStyles.NaturalColor100,
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    gap: "33px",
    pointerEvents: disabled ? "none" : "auto",
    "& .input-file-upload": {
      display: "none"
    },
    "& .file-upload-box": {
      "& .upload-text": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        "& .upload-icon": {
          marginBottom: "12px"
        },
        "& .upload-title": {
          marginBottom: "4px",
          color: variableStyles.NaturalColor950,
          fontWeight: variableStyles.fwSemiBold,
          fontSize: "16px",
          lineHeight: "24px"
        },
        "& .upload-subtitle": {
          color: variableStyles.NaturalColor800,
          fontWeight: variableStyles.fwRegular,
          fontSize: "12px",
          lineHeight: "18px",
          "& button.MuiButtonBase-root": {
            color: variableStyles.blueSecondary700,
            fontWeight: variableStyles.fwRegular,
            fontSize: "12px",
            lineHeight: "18px",
            paddingBottom: "2px",
            "&:hover": {
              textDecoration: "underline",
              color: `${variableStyles.blueSecondary700} !important`
            }
          }
        }
      }
    },
    "& .drag-file-box": {
      width: "100%",
      height: "100%",
      position: "absolute",
      top: "0px",
      left: "0px"
    },
    "& .file-infor": {
      padding: "7px 15px 7px 7px",
      backgroundColor: variableStyles.NaturalColor0,
      borderRadius: "12px",
      border: `1px solid ${variableStyles.NaturalColor200}`,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "&.error": {
        border: `1px solid ${variableStyles.Error400}`
      },
      "& .infor-detail": {
        display: "flex",
        gap: "8px",
        alignItems: "center"
      },
      "& .detail-text": {
        "& .text-name": {
          color: variableStyles.NaturalColor950,
          fontWeight: variableStyles.fwMedium,
          fontSize: "12px",
          lineHeight: "18px"
        },
        "& .text-size": {
          color: variableStyles.NaturalColor800,
          fontWeight: variableStyles.fwRegular,
          fontSize: "12px",
          lineHeight: "18px"
        }
      },
      "& .infor-progress-bar": {
        height: "7px",
        width: "96px",
        backgroundColor: variableStyles.NaturalColor100,
        borderRadius: "50px",
        "& .progress-bar-child": {
          height: "7px",
          width: progresspercent ? `${progresspercent}%` : "0%",
          backgroundColor: variableStyles.blueSecondary500,
          borderRadius: "50px",
          boxShadow: " 0px 0px 2px 0px #11111140"
        }
      }
    },
    "& .upload-error": {
      color: variableStyles.Error500,
      fontWeight: variableStyles.fwRegular,
      fontSize: "12px",
      lineHeight: "18px"
    }
  })
);
