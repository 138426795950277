import { useMediaQuery } from "@mui/material";
import moment from "moment";
import queryString from "query-string";
import React, { useEffect, useRef, useState } from "react";
import { EBacklinkCheckTabs, IDataFilterBacklinkType } from "../../..";
import { DEFAULT_BASE_PAGING_RES } from "../../../../../../../common/constants/value.constant";
import CommonHandle from "../../../../../../../common/handles/common.handles";
import {
  BackLinkTag,
  FastFilterList,
  NumberRangePicker,
  TableNavDetailNoPagination,
} from "../../../../../../../components";
import InputSearch from "../../../../../../../components/controls/InputSearch";
import SelectCustom from "../../../../../../../components/SelectCustom";
import { ITableNavDetailNoPaginationHeader } from "../../../../../../../components/Table/TableNavDetailNoPagination";
import { DATE_FORMAT } from "../../../../../../../config/constants";
import { IGetBackLinkOverviewTableRes } from "../../../../../../../models/audit-report";
import {
  IBacklinkCheckOverview,
  IBacklinkCheckOverviewReq,
  IBacklinkCheckOverviewRes,
} from "../../../../../../../models/backlink-check";
import { EBackLinkTag } from "../../../../../../../models/common/models.enum";
import {
  IBasePagingRes,
  IValue,
} from "../../../../../../../models/common/models.type";
import BacklinkCheckService from "../../../../../../../services/api/backlink.check.service";
import _ from "lodash";

interface BackLinkOverviewTabProps {
  dataFilter: IDataFilterBacklinkType;
  onChangeParamsURL: (value: string) => void;
}

export default function BacklinkOverviewTab(props: BackLinkOverviewTabProps) {
  const { dataFilter, onChangeParamsURL } = props;

  const timerOut = useRef<number>();
  const matches1365 = useMediaQuery("(max-width: 1365.98px)");
  const matches1019 = useMediaQuery("(max-width: 1019.98px)");

  const [keySearchState, setKeySearchState] = useState<string | null>(null);
  const [resDataPaging, setResDataPaging] = useState<
    IBasePagingRes<IBacklinkCheckOverview>
  >({ ...DEFAULT_BASE_PAGING_RES });
  const [dataFilterTemp, setDataFilterTemp] =
    useState<IDataFilterBacklinkType | null>(null);

  const dataLinkFilter: IValue[] = [
    { value: "all", title: "Tất cả" },
    { value: "newlink", title: "New link" },
    { value: "lostlink", title: "Lost link" },
  ];

  const dataFollowFilter: IValue[] = [
    { value: "all", title: "Tất cả" },
    { value: "follow", title: "Follow" },
    { value: "nofollow", title: "No Follow" },
  ];
  const backlinkTypeFilter: IValue[] = [
    { value: "all", title: "Tất cả" },
    { value: "image", title: "Image" },
    { value: "text", title: "Text" },
    { value: "form", title: "Form" },
    { value: "frame", title: "Frame" },
  ];

  useEffect(() => {
    setKeySearchState(dataFilter.searchLink);
  }, [dataFilter.searchLink]);

  useEffect(() => {
    if (!_.isEqual(dataFilterTemp, dataFilter)) {
      getDataOfPage();
      setDataFilterTemp(dataFilter);
    }
  }, [dataFilter]);

  const getDataOfPage = async () => {
    if (dataFilter.tab === EBacklinkCheckTabs.Overview) {
      const params: IBacklinkCheckOverviewReq = {
        type: "",
        domain: dataFilter.target || "",
        pageSize: Number(dataFilter.pageSize) || 10,
        pageIndex: Number(dataFilter.pageIndex) || 1,
      };
      const res = await BacklinkCheckService.getBackLinkCheckOverview(params);

      if (
        !res.isError &&
        !!res.data.backlinks &&
        res.data.backlinks.length > 0
      ) {
        setResDataPaging({
          ...DEFAULT_BASE_PAGING_RES,
          items: [...res.data.backlinks],
          pageSize: Number(dataFilter.pageSize) || 10,
          page: Number(dataFilter.pageIndex) || 1,
          total: res.data.total,
        });
      }
    }
  };

  const onChangeSearch = (e: any) => {
    const value = e?.target?.value;
    if (timerOut.current) {
      clearTimeout(timerOut.current);
    }
    timerOut.current = window.setTimeout(() => {
      handleChangeParams([
        { keyParams: "searchLink", valueParams: value.toString() },
      ]);
    }, 700);
  };

  const handleChangeParams = (
    value: { keyParams: string; valueParams: string | null }[]
  ) => {
    const newData: any = { ...dataFilter };
    value.forEach(value => {
      if (
        !value.valueParams ||
        (!!value.valueParams && value.valueParams.length <= 0) ||
        value.valueParams === "all"
      ) {
        delete newData[value.keyParams];
      } else {
        if (newData.hasOwnProperty(value.keyParams)) {
          newData[value.keyParams] = value.valueParams;
        }
      }
    });

    for (const [key, valueFor] of Object.entries(
      newData as IDataFilterBacklinkType
    )) {
      if (
        !valueFor ||
        (!!valueFor && valueFor.length <= 0) ||
        valueFor === "all"
      ) {
        delete newData[key];
      }
    }
    const newParams = queryString.stringify(newData);
    onChangeParamsURL(newParams.toLocaleLowerCase());
  };

  const headersOrganicTable: ITableNavDetailNoPaginationHeader<any>[] = [
    {
      field: "sourceTitle",
      title: `Tiêu đề/ đường dẫn`,
      width: "280px",
      className: "table-cell-link",
      isSticky: true,
      infor: "Tiêu đề/ đường dẫn",
      sx: { verticalAlign: "top", padding: "14px 6px !important" },
      handleItem: (item: any) => {
        return (
          <div className="title-with-link">
            <p className="fit-one-line">{item.sourceTitle}</p>
            <a
              className="fit-one-line"
              href={item.sourceUrl}
              target="_blank"
              rel="noreferrer"
            >
              {item.sourceUrl}
            </a>
          </div>
        );
      },
    },
    {
      field: "pageAScore",
      title: `Điểm uy tín`,
      width: "100px",
      sx: { verticalAlign: "top", padding: "14px 6px !important" },
      handleItem: item => {
        return <p>{CommonHandle.formatCurrency(item.pageAScore)}</p>;
      },
    },
    {
      field: "externalNum",
      title: `External link`,
      width: "100px",
      sx: { verticalAlign: "top", padding: "14px 6px !important" },
      handleItem: item => {
        return <p>{CommonHandle.formatCurrency(item.externalNum)}</p>;
      },
    },
    {
      field: "internalNum",
      title: `Internal link`,
      width: "100px",
      sx: { verticalAlign: "top", padding: "14px 6px !important" },
      handleItem: item => {
        return <p>{CommonHandle.formatCurrency(item.internalNum)}</p>;
      },
    },
    {
      field: "anchor",
      title: `Anchor text/ URL`,
      width: "200px",
      infor: "Anchor text/ URL",
      sx: { verticalAlign: "top", padding: "14px 6px !important" },
      handleItem: (item: any) => {
        const firstTag = [
          { name: EBackLinkTag.Text, value: item.text },
          { name: EBackLinkTag.Form, value: item.form },
          { name: EBackLinkTag.Frame, value: item.frame },
          { name: EBackLinkTag.Image, value: item.image },
        ];

        const secondTag = [
          { name: EBackLinkTag.Newlink, value: item.newLink },
          { name: EBackLinkTag.Lostlink, value: item.lostLink },
        ];

        const renderFirstTag = firstTag.find(tag => tag.value === "true");
        const renderSecondTag = secondTag.find(tag => tag.value === "true");
        return (
          <div className="title-with-link">
            <p className="fit-one-line">{item.anchor}</p>
            <a
              className="fit-one-line"
              href={item.targetUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.targetUrl}
            </a>
            <ul
              className="anchor-tag-list flex-start-align-center"
              style={{ gap: "4px" }}
            >
              {!!renderFirstTag && <BackLinkTag tag={renderFirstTag.name} />}
              {!!renderSecondTag && <BackLinkTag tag={renderSecondTag.name} />}
              <BackLinkTag
                tag={
                  item.noFollow ? EBackLinkTag.NoFollow : EBackLinkTag.Follow
                }
              />
            </ul>
          </div>
        );
      },
    },
    {
      field: "firstSeen",
      title: `First seen`,
      width: "100px",
      sx: { verticalAlign: "top", padding: "14px 6px !important" },
      handleItem: item => {
        return moment(new Date(item.firstSeen * 1000)).format(DATE_FORMAT);
      },
    },
    {
      field: "lastSeen",
      title: `Last seen`,
      width: "100px",
      sx: { verticalAlign: "top", padding: "14px 6px !important" },
      handleItem: item => {
        return moment(new Date(item.lastSeen * 1000)).format(DATE_FORMAT);
      },
    },
  ];

  return (
    <div className="overview-tab-wrapper tab-common-wrapper">
      <div className="tab-header-filter flex-between-align-center">
        <div className="filter-left flex-start-align-center">
          <InputSearch
            placeholder={"Tìm kiếm"}
            onChange={(e: any) => {
              setKeySearchState(e.target.value);
              onChangeSearch(e);
            }}
            value={keySearchState}
            size={"medium"}
            width={"340px"}
            typeStyle="outlined"
            selectData={[
              { title: "Anchor text", value: "anchortext" },
              { title: "Referring URL", value: "referringurl" },
              { title: "Target URL", value: "targeturl" },
            ]}
            onChangeSelect={value =>
              handleChangeParams([
                { keyParams: "searchLinkType", valueParams: value.toString() },
              ])
            }
            valueSelect={dataFilter.searchLinkType || "anchortext"}
          />
          {matches1019 && (
            <div className="responsive-filter flex-end-align-center">
              <SelectCustom
                options={backlinkTypeFilter}
                onChange={event => {
                  handleChangeParams([
                    {
                      keyParams: "backLinkType",
                      valueParams: event.target.value,
                    },
                  ]);
                }}
                placeholder="Chọn loại"
                isSelectedIcon
                value={dataFilter.backLinkType || backlinkTypeFilter[0].value}
                className="action-select-backlinktype"
                fullWidth={false}
              />
              <NumberRangePicker
                onSubmit={(value: {
                  from: number | null;
                  to: number | null;
                }) => {
                  handleChangeParams([
                    {
                      keyParams: "reputationFrom",
                      valueParams: value.from?.toString() || "",
                    },
                    {
                      keyParams: "reputationTo",
                      valueParams: value.to?.toString() || "",
                    },
                  ]);
                }}
                onClear={() => {
                  handleChangeParams([
                    {
                      keyParams: "reputationFrom",
                      valueParams: "",
                    },
                    {
                      keyParams: "reputationTo",
                      valueParams: "",
                    },
                  ]);
                }}
                placeholder="Điểm uy tín"
                modalTitle="Điểm uy tín"
                value={{
                  from: Number(dataFilter.reputationFrom),
                  to: Number(dataFilter.reputationTo),
                }}
                isFormatNumber
              />
            </div>
          )}
        </div>
        <div className="filter-right flex-end-align-center">
          <FastFilterList
            selectedValue={dataFilter.typeLink || "all"}
            onChange={(v: string | null) => {
              handleChangeParams([{ keyParams: "typeLink", valueParams: v }]);
            }}
            data={dataLinkFilter}
            numberOfItemShowed={3}
            className="content-location-filter"
          />
          <FastFilterList
            selectedValue={dataFilter.followType || "all"}
            onChange={(v: string | null) => {
              handleChangeParams([{ keyParams: "followType", valueParams: v }]);
            }}
            data={dataFollowFilter}
            numberOfItemShowed={3}
            className="content-location-filter"
          />
          {!matches1019 && (
            <>
              <SelectCustom
                size="small"
                options={backlinkTypeFilter}
                onChange={event => {
                  handleChangeParams([
                    {
                      keyParams: "backLinkType",
                      valueParams: event.target.value,
                    },
                  ]);
                }}
                placeholder="Chọn loại"
                isSelectedIcon
                value={dataFilter.backLinkType || backlinkTypeFilter[0].value}
                className="action-select-backlinktype"
                fullWidth={false}
              />
              <NumberRangePicker
                onSubmit={(value: {
                  from: number | null;
                  to: number | null;
                }) => {
                  handleChangeParams([
                    {
                      keyParams: "reputationFrom",
                      valueParams: value.from?.toString() || "",
                    },
                    {
                      keyParams: "reputationTo",
                      valueParams: value.to?.toString() || "",
                    },
                  ]);
                }}
                onClear={() => {
                  handleChangeParams([
                    {
                      keyParams: "reputationFrom",
                      valueParams: "",
                    },
                    {
                      keyParams: "reputationTo",
                      valueParams: "",
                    },
                  ]);
                }}
                placeholder="Điểm uy tín"
                modalTitle="Điểm uy tín"
                value={{
                  from: Number(dataFilter.reputationFrom),
                  to: Number(dataFilter.reputationTo),
                }}
                isFormatNumber
                size="small"
              />
            </>
          )}
        </div>
      </div>
      <div className="overview-table">
        <TableNavDetailNoPagination
          headersTable={headersOrganicTable}
          data={resDataPaging}
          maxHeight={
            matches1365 ? "calc(100vh - 366px)" : "calc(100vh - 329px)"
          }
          handlePageChange={page => {
            handleChangeParams([
              {
                keyParams: "pageIndex",
                valueParams: page.toString(),
              },
              {
                keyParams: "pageSize",
                valueParams: "10",
              },
            ]);
          }}
          isFullTable
        />
      </div>
    </div>
  );
}
