import { styled } from "@mui/styles";
import { ImageCustom } from "../../../../../../../../../components";
import { ContentfulPaint } from "../../../../../../../../../models/google/google.page.speed";
import { Box } from "@mui/material";
import classNames from "classnames";

interface IProps {
  data: ContentfulPaint;
  isMobile: boolean;
}

const Wrapper = styled(Box)(() => ({
  overflow: "auto",
  "& .screenshot-thumbnail": {
    border: `1px solid #ebebeb`,
    "& > img": {
      maxHeight: "150px",
      maxWidth: "120px",
    },
  },
}));

const ScreenshotThumbnails = ({ data }: IProps) => {
  const { items } = data.details;

  return (
    <Wrapper className={classNames("flex-between-align-center scrollbar")}>
      {/* Render các node */}
      {items.map((item, index) => {
        return (
          <Box className={"screenshot-thumbnail"} key={index}>
            <ImageCustom
              key={index}
              src={item.data}
              alt={"Screenshot Thumbnails"}
              width={"auto"}
              height={"auto"}
            />
          </Box>
        );
      })}
    </Wrapper>
  );
};

export default ScreenshotThumbnails;
