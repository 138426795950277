import { useTranslation } from "react-i18next";
import ModalCustom from "../../../components/Modal";
import FullWidthTabs from "../../../components/FullWidthTabsTime";
import { useState } from "react";

interface IProps {
  open?: boolean;
  onClose: () => void;
  taskId: number | null;
}

enum ETab {
  Sitemaps = "sitemaps",
  URLs = "urls",
  AuditInfo = "auditinfo",
}

export default function AuditDetailModal(props: IProps) {
  const { open: openModal, onClose, taskId } = props;
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<ETab>(ETab.URLs);

  return (
    <ModalCustom
      onClose={onClose}
      title={"Detail audit domain"}
      open={openModal}
      textClose="Common.Close"
      width="1024px"
    >
      <FullWidthTabs
        tabs={[
          {
            lable: { text: "Danh sách sitemaps" },
            value: ETab.Sitemaps,
            content: "Danh sách sitemaps",
          },
          {
            lable: { text: "Danh sách url" },
            value: ETab.URLs,
            content: "Danh sách url",
          },
          {
            lable: { text: "Thông tin khác" },
            value: ETab.AuditInfo,
            content: "Thông tin khác",
          },
        ]}
        value={activeTab}
        onChange={tab => setActiveTab(tab)}
        time={""}
      />
    </ModalCustom>
  );
}
