import { all, put, takeEvery } from "redux-saga/effects";
import { ROUTE_PATH } from "../../common/constants/app.constant";
import { IBaseResponseModel } from "../../models/common/models.type";

import { EUserType, IActionGetUser } from "../../models/redux/user";
import UserService from "../../services/api/user.service";
import { setUserState } from "./user.duck";

function* getUser({ payload }: IActionGetUser) {
  const { email, navigate } = payload;
  try {
    const resUser: IBaseResponseModel<string> =
      yield UserService.getSingle(email);

    if (!!resUser.success && !!resUser.data) {
      const userInfo = resUser.data;
      yield put(setUserState(JSON.parse(userInfo)));
    } else {
      navigate(ROUTE_PATH.LOGIN);
    }
  } catch (err) {}
}

export default function* rootSaga() {
  yield all([takeEvery(EUserType.GET_USER, getUser)]);
}
