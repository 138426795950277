import { IProject } from "../../models/project";
import {
  EProjectType,
  IActionClearErrorListState,
  IActionClearProjectState,
  IActionClearReportState,
  IActionProject,
  IActionSetErrorListState,
  IActionSetProjectState,
  IActionSetReportState,
  IReduxProjectState,
} from "../../models/redux/project";
import { IGetReportsRes } from "../../models/report";

export const setProjectState = (data: IProject): IActionSetProjectState => ({
  type: EProjectType.SET_PROJECT_STATE,
  payload: { data },
});

export const clearProjectState = (): IActionClearProjectState => ({
  type: EProjectType.CLEAR_PROJECT_STATE,
  payload: {},
});

export const setReportState = (
  data: IGetReportsRes
): IActionSetReportState => ({
  type: EProjectType.SET_REPORT_STATE,
  payload: { data },
});

export const clearReportState = (): IActionClearReportState => ({
  type: EProjectType.CLEAR_REPORT_STATE,
  payload: {},
});

export const setErrorListState = (
  data: string[]
): IActionSetErrorListState => ({
  type: EProjectType.SET_ERROR_LIST_STATE,
  payload: { data },
});

export const clearErrorListState = (): IActionClearErrorListState => ({
  type: EProjectType.CLEAR_ERROR_LIST_STATE,
  payload: {},
});

const defaultState: IReduxProjectState = {
  dataProject: {
    id: "",
    projectId: "",
    projectName: "",
    createdAt: "",
    domain: "",
    createdBy: "",
    updatedAt: "",
    updatedBy: "",
    totalReports: 0,
  },
  dataReport: {
    projectName: "",
    projectId: "",
    target: "",
    auditId: "",
    thirdPartyId: "",
    status: 0,
    reportType: 0,
    createdAt: "",
    createdBy: "",
    keyword: null,
    maxCrawlPages: 0,
    compareWith: [],
  },
  errorList: [],
};

export default function projectReducer(
  state: IReduxProjectState = defaultState,
  action: IActionProject
): IReduxProjectState {
  switch (action.type) {
    case EProjectType.SET_PROJECT_STATE:
      return {
        ...state,
        dataProject: action.payload.data,
      };
    case EProjectType.CLEAR_PROJECT_STATE:
      return {
        ...state,
        dataProject: defaultState.dataProject,
      };
    case EProjectType.SET_REPORT_STATE:
      return {
        ...state,
        dataReport: action.payload.data,
      };
    case EProjectType.CLEAR_REPORT_STATE:
      return {
        ...state,
        dataReport: defaultState.dataReport,
      };
    case EProjectType.SET_ERROR_LIST_STATE:
      return {
        ...state,
        errorList: action.payload.data,
      };
    case EProjectType.CLEAR_ERROR_LIST_STATE:
      return {
        ...state,
        errorList: defaultState.errorList,
      };
    default:
      return state;
  }
}
