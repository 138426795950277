import {
  Button,
  Stack,
  SxProps,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from "@mui/material";
import {
  TableActionListWrapper,
  TableBoxWrapper,
  TableCollapseWrapper,
  TableDataWrapper,
} from "./styles";
import IconEdit from "../../../assets/images/common/icon_edit_line_grey.svg";
import { useEffect, useRef, useState } from "react";
import IconThreeDot from "../../../assets/images/common/icon_threedot_grey.svg";
import IconArrowDown from "../../../assets/images/common/icon_arrow_down_line_black.svg";
import IconDelete from "../../../assets/images/common/icon_delete_line_grey.svg";
import IconList from "../../../assets/images/common/icon_list_line_grey.svg";
import { ImageCustom, LoadingIndicator } from "../..";
import { IBasePagingRes } from "../../../models/common/models.type";
import GridPagination from "../TableData/GridPagination";
import ImageNoData from "../../../assets/images/common/img_project_empty.png";

export interface ITableHeader<T> {
  field?: keyof T;
  title?: string;
  handleItem?: (item: T) => React.ReactNode;
  align?: "left" | "right" | "center";
  notFilter?: boolean;
  width?: number | string;
  sx?: SxProps<Theme>;
  className?: string;
  isSticky?: boolean;
}

export interface IDataActionType {
  text: string;
  icon?: React.ReactNode;
  key: string;
}

export interface ITableData<T> {
  data: IBasePagingRes<T>;
  headersTable: ITableHeader<T>[];
  handleChangeSearchParams?: (pageIndex: number) => void;
  minWidth?: number | string;
  maxHeight?: string;
  loading?: boolean;
  keyField?: string;
  handleDeleteData?: (id: string) => void;
  handleEditData?: (id: string) => void;
  handleOpenDetail?: (id: string) => void;
  textOpenDetail?: string;
}

export const TableDataGrey = (props: ITableData<any>) => {
  const {
    loading,
    data,
    headersTable,
    handleChangeSearchParams,
    minWidth = 1050,
    keyField = "id",
    handleDeleteData,
    handleEditData,
    handleOpenDetail,
    maxHeight = "calc(100vh - 273px)",
    textOpenDetail = "Danh sách báo cáo",
  } = props;

  const ref = useRef<HTMLDivElement | null>(null);

  const [openMenu, setOpenMenu] = useState<{
    anchor: HTMLButtonElement | null;
    id: string;
  } | null>(null);
  const [collapseList, setCollapseList] = useState<string[]>([]);
  const [tableWidth, setTableWidth] = useState<number>(0);

  const handlePageChange = (newPage: number) => {
    handleChangeSearchParams && handleChangeSearchParams(newPage);
  };

  const menuData: {
    text: string;
    icon: string;
    action: (id: string) => void;
  }[] = [
    ...(!!handleEditData
      ? [
          {
            text: "Chỉnh sửa",
            icon: IconEdit,
            action: (id: string) => {
              handleEditData(id);
            },
          },
        ]
      : []),

    ...(!!handleOpenDetail
      ? [
          {
            text: textOpenDetail,
            icon: IconList,
            action: (id: string) => {
              handleOpenDetail && handleOpenDetail(id);
            },
          },
        ]
      : []),
    ...(!!handleDeleteData
      ? [
          {
            text: "Xoá",
            icon: IconDelete,
            action: (id: string) => {
              handleDeleteData && handleDeleteData(id);
            },
          },
        ]
      : []),
  ];

  const handleOpenCollapse = (id: string) => {
    if (collapseList?.includes(id)) {
      const newChecked = collapseList.filter(item => item !== id);
      setCollapseList(newChecked);
    } else {
      setCollapseList(prev => [...prev, id]);
    }
  };

  useEffect(() => {
    if (!!ref.current && ref.current.offsetWidth !== tableWidth) {
      setTableWidth(ref.current.offsetWidth || 0);
    }
    window.addEventListener("resize", () => {
      if (!!ref.current && ref.current.offsetWidth !== tableWidth) {
        setTableWidth(ref.current.offsetWidth || 0);
      }
    });
    return () => window.removeEventListener("resize", () => {});
  }, []);

  return (
    <>
      <TableActionListWrapper
        open={Boolean(openMenu?.anchor)}
        anchorEl={openMenu?.anchor}
        onClose={() => setOpenMenu(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <ul className="action-list">
          {menuData.map((action, idx) => (
            <li key={action.text}>
              <button
                onClick={() => {
                  action.action(openMenu?.id || "");
                  setOpenMenu(null);
                }}
                className={`${idx === menuData.length - 1 ? "delete-action" : ""}`}
              >
                <ImageCustom
                  src={action.icon}
                  alt="Icon-Menu"
                  width={"18px"}
                  height={"18px"}
                />
                <p className="fit-one-line">{action.text}</p>
              </button>
            </li>
          ))}
        </ul>
      </TableActionListWrapper>
      <TableBoxWrapper>
        <div className="table-wrapper" ref={ref}>
          <TableContainer
            sx={{
              maxHeight: maxHeight,
              borderRadius: "12px",
              scrollBehavior: "smooth !important",
            }}
            className="scrollbar"
          >
            <TableDataWrapper stickyHeader>
              <TableHead>
                <TableRow>
                  {headersTable.map((item, key) => {
                    return (
                      <TableCell
                        key={key}
                        sx={{
                          ...item.sx,
                          width: item.width,
                        }}
                        className={`${item.className} ${item.isSticky ? "sticky-column" : ""}`}
                      >
                        {item.title}
                      </TableCell>
                    );
                  })}
                  <TableCell
                    className={`action-cell-wrapper sticky-column`}
                  ></TableCell>
                </TableRow>
              </TableHead>
              {loading ? (
                <TableBody>
                  <TableRow className="box-empty-wrapper">
                    <TableCell colSpan={headersTable.length}>
                      <Stack sx={{ width: "100%" }}>
                        <div className={"box-empty flex-column-center"}>
                          <LoadingIndicator />
                        </div>
                      </Stack>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {!!data.items && data.items.length > 0 ? (
                    data.items.map((item, idx) => (
                      <>
                        <TableRow
                          hover
                          key={idx}
                          className={`${!!openMenu && openMenu.id === item[keyField] ? "hover-active" : ""} ${
                            (!item.compareWith ||
                              (!!item.compareWith &&
                                item.compareWith.length <= 0)) &&
                            !item.keyword
                              ? "not-collapse"
                              : ""
                          }`}
                        >
                          {headersTable.map((field, key) => {
                            if (field.handleItem) {
                              return (
                                <TableCell
                                  key={key}
                                  sx={{
                                    ...field.sx,
                                    textAlign: field.align,
                                    width: field.width,
                                  }}
                                  className={`${field.className}  ${field.isSticky ? "sticky-column" : ""}`}
                                >
                                  {field.handleItem(item)}
                                </TableCell>
                              );
                            } else {
                              return (
                                <TableCell
                                  sx={{
                                    ...field.sx,
                                    textAlign: field.align,
                                    width: field.width,
                                  }}
                                  className={`${field.className}  ${field.isSticky ? "sticky-column" : ""}`}
                                  key={key}
                                >
                                  {field.field ? item[field.field] : null}{" "}
                                </TableCell>
                              );
                            }
                          })}
                          <TableCell
                            className={`action-list-cell-wrapper sticky-column`}
                          >
                            {(!!item.compareWith?.some(
                              (item: any) =>
                                !!item.BrandName && item.BrandName.length > 0
                            ) ||
                              !!item.keyword) && (
                              <Button
                                onClick={e =>
                                  handleOpenCollapse(item[keyField].toString())
                                }
                                className={`open-collapse-button ${collapseList.includes(item[keyField]) ? "active" : ""}`}
                              >
                                <ImageCustom
                                  src={IconArrowDown}
                                  alt="Icon-Arrow-Down"
                                  width={"20px"}
                                  height={"20px"}
                                />
                              </Button>
                            )}
                            <Button
                              onClick={e =>
                                setOpenMenu({
                                  anchor: e.currentTarget,
                                  id: item[keyField].toString(),
                                })
                              }
                              className="open-list-button"
                            >
                              <ImageCustom
                                src={IconThreeDot}
                                alt="Icon-ThreeDot"
                                width={"18px"}
                                height={"18px"}
                              />
                            </Button>
                          </TableCell>
                        </TableRow>
                        {((!!item.compareWith && item.compareWith.length > 0) ||
                          !!item.keyword) && (
                          <TableCollapseWrapper
                            in={collapseList?.includes(item[keyField])}
                            width={tableWidth}
                          >
                            <div
                              className={`collapse-detail-infor ${!!item.compareWith?.some((item: any) => !!item.BrandName && item.BrandName.length > 0) ? "domain" : "page"}`}
                            >
                              {!!item.compareWith?.some(
                                (item: {
                                  BrandName: string;
                                  DomainUrl: string;
                                }) =>
                                  !!item.BrandName && item.BrandName.length > 0
                              ) ? (
                                <ul className="list-domain">
                                  {item.compareWith.map(
                                    (
                                      item: {
                                        BrandName: string;
                                        DomainUrl: string;
                                      },
                                      idx: number
                                    ) => (
                                      <li className="domain-detail" key={idx}>
                                        <p className="detail-order">
                                          {idx + 1}
                                        </p>
                                        <div className="detail-content">
                                          <p className="content-name">
                                            {item.BrandName}
                                          </p>
                                          <p className="content-url fit-one-line">
                                            {item.DomainUrl}
                                          </p>
                                        </div>
                                      </li>
                                    )
                                  )}
                                </ul>
                              ) : (
                                <>
                                  <div className="keyword-wrapper">
                                    <p className="keyword-title">Từ khoá SEO</p>
                                    <p className="keyword-content fit-one-line">
                                      {item?.keyword}
                                    </p>
                                  </div>
                                  <ul className="url-list">
                                    {item?.compareWith?.map(
                                      (
                                        item: {
                                          Url: string;
                                        },
                                        idx: number
                                      ) => (
                                        <li className="domain-detail" key={idx}>
                                          <p className="detail-order">
                                            URL {idx + 1}
                                          </p>
                                          <p className="content-url fit-one-line">
                                            {item.Url}
                                          </p>
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </>
                              )}
                            </div>
                          </TableCollapseWrapper>
                        )}
                      </>
                    ))
                  ) : (
                    <TableRow className="box-empty-wrapper">
                      <TableCell colSpan={headersTable.length}>
                        <Stack sx={{ width: "100%" }}>
                          <div className={"box-empty flex-column-center"}>
                            <ImageCustom
                              src={ImageNoData}
                              alt="Icon-NoData"
                              width={"120px"}
                              height={"120px"}
                            />
                            <p className={"text-empty"}>No data</p>
                          </div>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              )}
            </TableDataWrapper>
          </TableContainer>
        </div>
        {Math.ceil((data?.total || 0) - data.pageSize) > 0 && (
          <GridPagination
            total={data.total}
            page={data.page}
            pageSize={data.pageSize}
            onPageChange={handlePageChange}
          />
        )}
      </TableBoxWrapper>
    </>
  );
};
