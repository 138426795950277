import { ReactNode } from "react";
import { FormControlLabelWrapper, CheckBoxWrapper } from "./styles";
import { ImageCustom } from "../..";
import IconCheck from "../../../assets/images/common/icon_checkbox_line_grey.svg";
import IconCheckActive from "../../../assets/images/common/icon_checkbox_fill_green_line_white.svg";

interface CheckBoxCustomProps {
  value: string;
  label: ReactNode;
  checkedValue?: string;
  onChange: (value: string) => void;
  checked?: boolean;
  iconCheck?: string;
  iconCheckActive?: string;
  width?: string;
  height?: string;
}

export default function CheckBoxCustom({
  value,
  label,
  checkedValue,
  onChange,
  checked = false,
  iconCheck = IconCheck,
  iconCheckActive = IconCheckActive,
  width = "24px",
  height = "24px",
}: CheckBoxCustomProps) {
  const BpRadio = () => {
    return (
      <CheckBoxWrapper
        id={`checkbox-${value}`}
        onChange={() => onChange(value)}
        value={value}
        checked={checked || value === checkedValue}
        checkedIcon={
          <ImageCustom
            src={iconCheckActive}
            alt="Icon-CheckActive"
            width={width}
            height={height}
          />
        }
        icon={
          <ImageCustom
            src={iconCheck}
            alt="Icon-Check"
            width={width}
            height={height}
          />
        }
      />
    );
  };
  return (
    <FormControlLabelWrapper
      value={value}
      control={<BpRadio />}
      label={
        <p
          className={`${checked || value == checkedValue ? "active" : ""} radio-label`}
        >
          {label}
        </p>
      }
    />
  );
}
