import { styled } from "@mui/material/styles";
import variableStyles from "../../../../../../../theme/variable-styles";

export const TechnicalTitleWrapper = styled("div")<{ marginbottom?: string }>(
  ({ marginbottom }) => ({
    marginBottom: !!marginbottom ? marginbottom : "10px",
    display: "flex",
    gap: "4px",
    alignItems: "center",
    paddingBottom: "6px",
    borderBottom: `1px solid ${variableStyles.NaturalColor300}`,
    "& p": {
      color: variableStyles.NaturalColor900,
      fontWeight: variableStyles.fwSemiBold,
      fontSize: "14px",
      lineHeight: "16.94px",
    },
  })
);
