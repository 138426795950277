import { ImageCustom } from "../..";
import { TextareaWrapper } from "./styles";
import IconError from "../../../assets/images/common/icon_warning_fill_red.png";
import { TextareaAutosize } from "@mui/material";

export interface TextAreaProps {
  error?: boolean;
  onChange?: (value: string) => void;
  disabled?: boolean;
  helperText?: any;
  placeholder?: string;
  maxRows?: number;
  minRows?: number;
  value?: any;
  className?: string;
}

export default function TextArea(props: TextAreaProps) {
  const {
    error,
    helperText,
    disabled,
    placeholder,
    onChange,
    maxRows = undefined,
    minRows = 0,
    value,
    className,
  } = props;
  return (
    <TextareaWrapper>
      <TextareaAutosize
        maxRows={maxRows}
        minRows={minRows}
        aria-label="text-area"
        placeholder={placeholder}
        value={value}
        onChange={(v: any) => {
          onChange && onChange(v.target.value);
        }}
        disabled={disabled}
        className={`scrollbar-small ${className}`}
      />
      {error && !!helperText && (
        <div className="helper-text-error">
          <ImageCustom
            src={IconError}
            alt="Icon-Error"
            width={"18px"}
            height={"18px"}
          />
          <p className="date-picker-error">{helperText}</p>
        </div>
      )}
    </TextareaWrapper>
  );
}
