import { Popover } from "@mui/material";
import { styled } from "@mui/material/styles";
import variableStyles from "../../../theme/variable-styles";

export const BubbleNoAxisChartWrapper = styled("div")(({ theme }) => ({
  position: "relative",
  height: "338px",
}));

interface BubbleCircleProps {
  ratio?: number;
  background: string;
}
export const BubbleCircleWrapper = styled("div")<BubbleCircleProps>(
  ({ theme, ratio = 0, background }) => ({
    borderRadius: "50%",
    position: "absolute",
    width: ratio,
    height: ratio,
    backgroundColor: background,
    "& p": {
      position: "absolute",
      top: "calc(50% - 10px)",
      left: "calc(50% - 10px)",
      transform: "translate(-50%, -50%)",
      color: variableStyles.NaturalColor950,
      fontWeight: variableStyles.fwSemiBold,
      width: `calc(${ratio}px - 20px)`,
      margin: "10px",
      textAlign: "center",
    },
  })
);

export const BubbleCirclePopoverWrapper = styled(Popover)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: variableStyles.NaturalColor0,
    border: `1px solid ${variableStyles.NaturalColor200}`,
    borderRadius: "5px",
    padding: "6px",
    textAlign: "center",
    width: "128px",
    boxShadow: "0px 4px 4.9px 0px #00000012",
    "& .tooltip-title": {
      color: variableStyles.NaturalColor950,
      fontWeight: variableStyles.fwSemiBold,
      fontSize: "12px",
      lineHeight: "20px",
    },
    "& .tooltip-description": {
      color: variableStyles.NaturalColor800,
      fontWeight: variableStyles.fwRegular,
      fontSize: "12px",
      lineHeight: "15px",
      "& span": {
        fontWeight: variableStyles.fwSemiBold,
      },
    },
  },
}));
