import { Box, SxProps, Theme, Typography } from "@mui/material";
import { styled } from "@mui/styles";
import palette from "../../../../../../theme/palette";
import ErrorIcon from "../../../../../../assets/images/domai-audit/security-section/svg/error-icon";
import SuccessIcon from "../../../../../../assets/images/domai-audit/security-section/svg/success-icon";
import Desktop from "../../../../../../assets/images/domai-audit/security-section/desktop.png";
import Mobile from "../../../../../../assets/images/domai-audit/security-section/mobile.png";
import classNames from "classnames";
import EmptyImage from "../../../../../../assets/images/common/img_project_empty.png";

export enum EStrategy {
  "desktop" = "desktop",
  "mobile" = "mobile",
}
interface IProps {
  strategy: EStrategy;
  score: number;
  screenshot?: string;
  sx?: SxProps<Theme>;
}

export const Wrapper = styled(Box)(() => ({
  width: "160px",
  height: "180px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",

  "& .wrapper-screenshot": {
    position: "relative",
    width: "133px",
    height: "112px",
    "&.mobile": {
      width: "69px",
      height: "112px",
    },
    "& .wrapper-img": {
      width: "133px",
      height: "112px",
      position: "relative",
      zIndex: "1",
      "&.mobile": {
        width: "69px",
        height: "112px",
      },
    },
    "& .screenshot-img": {
      objectFit: "cover",
      objectPosition: "top",
      backgroundRepeat: "no-repeat",
      width: "123px",
      height: "71px",
      position: "absolute",
      top: 5,
      left: 5,
      "&.mobile": {
        top: 1.5,
        left: 9,
        width: "48px",
        height: "98px",
        borderRadius: 4,
      },
    },
    "& .wrapper-icon-score": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "absolute",
      top: -12,
      right: -12,
      backgroundColor: palette.background.default,
      padding: "2.33px",
      borderRadius: "60px",
      zIndex: "2",
      "&.mobile": { top: -12, right: -6 },
    },
  },

  "& .yours-score-title": {
    width: "max-content",
    marginRight: "6px",
    marginTop: 16,
  },
  "& .yours-score-score": {
    width: "max-content",
    borderRadius: "60px",
    marginTop: 4,
  },
}));

const ScreenshotOnDevice = ({ strategy, score, screenshot, sx }: IProps) => {
  return (
    <Wrapper sx={sx}>
      <Box
        className={classNames(
          "wrapper-screenshot",
          strategy === "mobile" && "mobile"
        )}
      >
        <img
          className={classNames(
            "wrapper-img",
            strategy === "mobile" && "mobile"
          )}
          src={strategy === "desktop" ? Desktop : Mobile}
          alt={
            strategy === "desktop" ? "Desktop screenshot" : "Mobile screenshot"
          }
          width={
            strategy === "desktop" ? "Desktop screenshot" : "Mobile screenshot"
          }
          height={
            strategy === "desktop" ? "Desktop screenshot" : "Mobile screenshot"
          }
        />
        <img
          className={classNames(
            "screenshot-img",
            strategy === "mobile" && "mobile"
          )}
          src={screenshot ?? EmptyImage}
          alt={
            strategy === "desktop" ? "Desktop screenshot" : "Mobile screenshot"
          }
          width={
            strategy === "desktop" ? "Desktop screenshot" : "Mobile screenshot"
          }
          height={
            strategy === "desktop" ? "Desktop screenshot" : "Mobile screenshot"
          }
        />

        <Box
          className={classNames(
            "wrapper-icon-score",
            strategy === "mobile" && "mobile"
          )}
        >
          {score * 100 > 50 ? <SuccessIcon /> : <ErrorIcon />}
        </Box>
      </Box>

      <Typography variant={"caption"} className="yours-score-title">
        {strategy === "desktop" ? "Desktop Score" : "Mobile Score"}
      </Typography>

      <Typography
        variant={"subtitle1"}
        className={classNames(
          "yours-score-score",
          `color-audit-${score * 100 > 50 ? "success" : "error"}`
        )}
      >
        {(score * 100).toFixed()}/100
      </Typography>
    </Wrapper>
  );
};

export default ScreenshotOnDevice;
