import { HubConnection } from "@microsoft/signalr";
import {
  ESignalRType,
  IActionClearSignalR,
  IActionSetSignalRState,
  IActionSignalR,
  IReduxSignalRState,
} from "../../models/redux/signalR";

export const setSignalRState = (idReport: string): IActionSetSignalRState => ({
  type: ESignalRType.SET_SIGNALR_STATE,
  payload: { idReport },
});

export const clearSignalR = (): IActionClearSignalR => ({
  type: ESignalRType.CLEAR_SIGNALR,
  payload: {},
});

const defaultState: IReduxSignalRState = {
  idReport: null,
};

export default function signalRReducer(
  state: IReduxSignalRState = defaultState,
  action: IActionSignalR
): IReduxSignalRState {
  switch (action.type) {
    case ESignalRType.SET_SIGNALR_STATE:
      return {
        idReport: action.payload.idReport,
      };
    case ESignalRType.CLEAR_SIGNALR:
      return {
        idReport: null,
      };
    default:
      return state;
  }
}
