import { Typography } from "@mui/material";
import React from "react";
import { OverviewCommonTitleWrapper } from "./styles";

interface OverviewCommonTitleProps {
  title: string;
  description: string;
  className?: string;
  scores?: number;
}

export default function OverviewCommonTitle(props: OverviewCommonTitleProps) {
  const { title, description, className = "", scores } = props;
  return (
    <OverviewCommonTitleWrapper className={className}>
      <div className="title-wrapper flex-between-align-center">
        <Typography variant="h2" className="title-text fit-one-line">
          {title}
        </Typography>
        {!!scores && (
          <div className="scores-wrapper flex-end-align-center">
            <p className="scores-text">Your score</p>
            <p className="scores-number">{scores}/100</p>
          </div>
        )}
      </div>
      <Typography variant="caption" className="title-description">
        {description}
      </Typography>
    </OverviewCommonTitleWrapper>
  );
}
