import { styled } from "@mui/material/styles";
import variableStyles from "../../theme/variable-styles";

export const KeywordDensityWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: "24px",
  height: "100%",
  "& .MuiTableCell-head": {
    backgroundColor: variableStyles.NaturalColor950,
    color: "white",
    fontWeight: "bold"
  },
  "& .MuiTableCell-body": {
    textAlign: "left"
  },
  "& .MuiTab-root": {
    color: variableStyles.NaturalColor600
  },
  "& .MuiTab-root.Mui-selected": {
    color: variableStyles.NaturalColor1000
  },
  "& .MuiTabs-indicator": {
    backgroundColor: variableStyles.blueSecondary600
  }
}));
