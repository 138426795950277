import { styled } from "@mui/material/styles";
import variableStyles from "../../theme/variable-styles";
import ArrowRight from "../../assets/images/common/icon_arrow_right_line_black.svg";

export const BreadcrumbsWrapper = styled("div")(() => ({
  padding: "12px 24px 0",
  flex: "0 0 auto",
  "& .breadcrumb-list": {
    gap: "32px",
    "& .item": {
      position: "relative",
      "&::after": {
        content: '""',
        width: "16px",
        height: "16px",
        position: "absolute",
        top: "calc(50% - 8px)",
        right: "-24px",
        background: `url(${ArrowRight}) center/contain no-repeat`
      },
      "& a": {
        color: variableStyles.NaturalColor800,
        fontWeight: variableStyles.fwRegular,
        fontSize: "14px",
        lineHeight: "21px"
      }
    },
    "& .last-item": {
      backgroundColor: variableStyles.NaturalColor200,
      padding: "2px 8px",
      borderRadius: "6px",
      color: variableStyles.NaturalColor950,
      fontWeight: variableStyles.fwMedium,
      fontSize: "14px",
      lineHeight: "21px"
    }
  }
}));
