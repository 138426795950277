import { styled } from "@mui/material/styles";
import variableStyles from "../../../theme/variable-styles";

export const DomainAuditErrorWrapper = styled("div")(() => ({
  padding: "24px",
  "& .page-header": {
    marginBottom: "24px",
  },
  "& .page-filter": {
    gap: "12px",
  },
}));
