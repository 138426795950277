import { styled } from "@mui/styles";
import variableStyles from "../../../../../../../../../theme/variable-styles";

export const CoreWebVitalsItemWrapper = styled("div")(() => ({
  border: `1px solid ${variableStyles.NaturalColor300}`,
  boxShadow: `0px 0px 18.3px 0px #0000000A`,
  borderRadius: "6px",
  background: variableStyles.NaturalColor0,
  overflow: "hidden",
  "& .performance-metrics-item-title": {
    padding: "12px 20px",
    borderBottom: `1px solid ${variableStyles.NaturalColor300}`,
    background: variableStyles.NaturalColor100,
    gap: "8px",
    "& .dot-color": {
      flex: "0 0 auto",
    },
    "& p": {
      color: variableStyles.NaturalColor950,
      fontWeight: variableStyles.fwMedium,
      fontSize: "12px",
      lineHeight: "14.52px",
    },
  },
  "& .performance-metrics-item-progress": {
    position: "relative",
    padding: "11px 18px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
