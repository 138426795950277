import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import variableStyles from "../../theme/variable-styles";

export const ProjectBoxWrapper = styled("button")(() => ({
  borderRadius: "12px",
  padding: "6px",
  alignItems: "center",
  justifyContent: "space-between",
  transition: "all 0.2s linear",
  width: "100%",
  backgroundColor: "transparent",
  "&:hover": {
    backgroundColor: `${variableStyles.NaturalColor100} !important`
  },
  ":disabled": {
    pointerEvents: "none"
  },
  "& .infor-box": {
    display: "flex",
    gap: "12px",
    alignItems: "center",
    "& .project-avatar": {
      flex: "0 0 auto"
    },
    "& .project-name": {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "space-between",
      gap: "4px",
      flex: "1 1 auto",
      textAlign: "left",
      "& .name-project": {
        color: variableStyles.NaturalColor950,
        fontWeight: variableStyles.fwSemiBold,
        fontSize: "14px",
        lineHeight: "14px"
      },
      "& .name-domain": {
        color: variableStyles.NaturalColor900,
        fontWeight: variableStyles.fwRegular,
        fontSize: "12px",
        lineHeight: "12px"
      }
    }
  },
  "& .icon-check": {
    marginLeft: "4px"
  },
  "& .project-avatar": {
    padding: "5px",
    borderRadius: "50%",
    overflow: "hidden",
    border: `1px solid ${variableStyles.NaturalColor400}`,
    backgroundColor: variableStyles.NaturalColor50,
    pointerEvents: "none"
  }
}));
