// ----------------------------------------------------------------------

export default function IconButton(theme: any) {
  return {
    MuiIconButton: {
      variants: [
        {
          props: { color: "default" },
          style: {
            "&:hover": { backgroundColor: theme.palette.action.hover }
          }
        },
        {
          props: { color: "inherit" },
          style: {
            "&:hover": { backgroundColor: theme.palette.action.hover }
          }
        }
      ],
      styleOverrides: {
        root: {},
        sizeLarge: {
          height: 56,
          width: 56
        },
        sizeSmall: {
          height: 40,
          width: 40
        },
        sizeMedium: {
          height: 48,
          width: 48
        }
      }
    }
  };
}
