import {
  IBasePagingRes,
  IBaseResponseModel,
} from "../../models/common/models.type";
import api from "../remote/baseApi";
import ApiEndpoints from "../remote/apiEndpoints";
import { envConfig } from "../../config/env.config";
import {
  IChangeStatusResquest,
  IRegisterReq,
  IGetUserReq,
  IUpdateByOwnerRequest,
  IUpdatePasswordResquest,
  IUser,
} from "../../models/user";

const API_USER = ApiEndpoints.USER;
const baseURL = envConfig.API_KEY_ACCOUNT_ENDPOINT;

const createUser = (data: IRegisterReq): Promise<IBaseResponseModel<any>> => {
  return api.post(baseURL, API_USER.CREATE.url, data);
};

const getUsers = (
  data: IGetUserReq
): Promise<IBaseResponseModel<IBasePagingRes<any>>> => {
  return api.get(baseURL, API_USER.GET_USERS.url, {
    params: data,
  });
};

const getSingle = (email: string): Promise<IBaseResponseModel<string>> => {
  return api.get(baseURL, API_USER.GET_SIGNLE.url, {
    params: { email },
  });
};

const updatePassword = (
  data: IUpdatePasswordResquest
): Promise<IBaseResponseModel<any>> => {
  return api.post(baseURL, API_USER.UPDATE_PASSWORD.url, data);
};

const updateByOwner = (
  data: IUpdateByOwnerRequest
): Promise<IBaseResponseModel<any>> => {
  return api.post(baseURL, API_USER.UPDATE_BY_OWNER.url, data);
};

const updateStatusUser = (
  data: IChangeStatusResquest
): Promise<IBaseResponseModel<any>> => {
  return api.post(baseURL, API_USER.UPDATE_STATUS.url, data);
};

const UserService = {
  createUser,
  getSingle,
  getUsers,
  updatePassword,
  updateByOwner,
  updateStatusUser,
};

export default UserService;
