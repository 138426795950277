import { styled } from "@mui/material/styles";
import variableStyles from "../../theme/variable-styles";

export const HomeWrapper = styled("div")(() => ({
  "& .myPJ-header": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "12px 24px 20px",
    gap: "12px",
    borderBottom: `1px solid ${variableStyles.NaturalColor200}`,
    "& .myPJ-title-wrapper": {
      maxWidth: "388px",
      "& .myPJ-title": {
        display: "flex",
        gap: "8px",
        alignItems: "center",
        marginBottom: "8px",
        "& .header-name": {
          color: variableStyles.NaturalColor950,
          fontWeight: variableStyles.fwSemiBold,
          fontSize: "24px",
          lineHeight: "29.05px"
        },
        "& .header-count": {
          color: variableStyles.blueSecondary700,
          fontWeight: variableStyles.fwMedium,
          fontSize: "12px",
          lineHeight: "18px",
          padding: "2px 8px",
          backgroundColor: variableStyles.blueSecondary100,
          borderRadius: "16px",
          flex: "0 0 auto"
        }
      },
      "& .myPJ-description": {
        color: variableStyles.NaturalColor950,
        fontWeight: variableStyles.fwRegular,
        fontSize: "14px",
        lineHeight: "22.4px"
      }
    },
    "& .header-action": { display: "flex", gap: "12px", flex: "0 0 auto" }
  },
  "& .list-project": {
    padding: "24px"
  }
}));
