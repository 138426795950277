import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  ContentfulPaintDetailHeadings,
  ContentfulPaintDetailItem,
} from "../../../../../../../../../../models/google/google.page.speed";
import { renderValueByType } from "..";
import { TableDetailWrapper } from "./styles";

interface IProps {
  headers: ContentfulPaintDetailHeadings[];
  data: ContentfulPaintDetailItem[];
}

export interface ITableHeader {
  key: string;
  lable: string;
}
const MAX_WIDTH = "200px";

function Row(props: {
  row: ContentfulPaintDetailItem;
  headerValues: ContentfulPaintDetailHeadings[];
}) {
  const { row, headerValues } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      {(!!row.node && headerValues.length > 1) ||
      (!row.node && headerValues.length > 0) ? (
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          {headerValues.map((item, index) => {
            const value =
              item.key === null
                ? ""
                : typeof row[item.key] === "object"
                  ? row[item.key].value
                  : row[item.key];

            const valueByType = renderValueByType(item.valueType, value);

            return (
              <TableCell
                key={index}
                align={index === 0 ? "left" : "right"}
                sx={{ maxWidth: MAX_WIDTH }}
              >
                {item.valueType === "node" ? (
                  <>
                    <Box sx={{ margin: 1, padding: "10px 40px" }}>
                      <p>{row?.node?.nodeLabel}</p>
                      <p>{row?.node?.snippet}</p>
                      <p>{row?.node?.selector}</p>
                      <p>{row?.node?.explanation}</p>
                    </Box>
                  </>
                ) : (
                  <Box
                    title={valueByType}
                    component={item.key === "url" ? "a" : "div"}
                    href={valueByType}
                    target="_blank"
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: 2,
                      lineClamp: 2,
                      WebkitBoxOrient: "vertical",
                    }}
                  >
                    {valueByType ?? ""}
                  </Box>
                )}
              </TableCell>
            );
          })}
        </TableRow>
      ) : (
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          {headerValues.map((item, index) => {
            return (
              <TableCell
                key={index}
                align={index === 0 ? "left" : "right"}
                sx={{
                  maxWidth: MAX_WIDTH,
                  border: "none !important",
                  borderRadius: "0 0 6px 6px",
                }}
              >
                <div className="">
                  <p>{row?.node?.nodeLabel}</p>
                  <p>{row?.node?.snippet}</p>
                  <p>{row?.node?.selector}</p>
                  <p>{row?.node?.explanation}</p>
                </div>
              </TableCell>
            );
          })}
        </TableRow>
      )}
    </React.Fragment>
  );
}

export default function DetailTable({ headers, data }: IProps) {
  if (!headers) {
    return null;
  }

  const headerValues = headers;

  if (!headerValues.length) {
    return null;
  }

  return (
    <TableDetailWrapper>
      <TableContainer
        sx={{
          borderRadius: "6px",
          scrollBehavior: "smooth !important",
        }}
        className="scrollbar"
      >
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              {headerValues.map((item, key) => {
                return (
                  <TableCell
                    key={key}
                    align={key === 0 ? "left" : "right"}
                    sx={{ maxWidth: MAX_WIDTH }}
                  >
                    <p className="table-cell-header">{item.label}</p>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, key) => (
              <Row row={row} key={key} headerValues={headerValues} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </TableDetailWrapper>
  );
}
