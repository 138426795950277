import { useMediaQuery } from "@mui/material";
import queryString from "query-string";
import React, { useEffect, useRef, useState } from "react";
import { IDataFilterOrganicKeywordType } from "../../..";
import { DEFAULT_BASE_PAGING_RES } from "../../../../../../../common/constants/value.constant";
import {
  NumberRangePicker,
  TableNavDetailNoPagination,
} from "../../../../../../../components";
import InputSearch from "../../../../../../../components/controls/InputSearch";
import { ITableNavDetailNoPaginationHeader } from "../../../../../../../components/Table/TableNavDetailNoPagination";

interface TopPageTabProps {
  dataFilter: IDataFilterOrganicKeywordType;
  onChangeParamsURL: (value: string) => void;
}

export default function TopPageTab(props: TopPageTabProps) {
  const { dataFilter, onChangeParamsURL } = props;

  const timerOut = useRef<number>();
  const matches1020 = useMediaQuery("(max-width:1020px)");

  const [keySearchState, setKeySearchState] = useState<string | null>(null);

  useEffect(() => {
    setKeySearchState(dataFilter.searchLink);
  }, [dataFilter.searchLink]);

  const onChangeSearch = (e: any) => {
    const value = e?.target?.value;
    if (timerOut.current) {
      clearTimeout(timerOut.current);
    }
    timerOut.current = window.setTimeout(() => {
      handleChangeParams([
        { keyParams: "searchLink", valueParams: value.toString() },
      ]);
    }, 700);
  };

  const handleChangeParams = (
    value: { keyParams: string; valueParams: string | null }[]
  ) => {
    const newData: any = { ...dataFilter };
    value.forEach(value => {
      if (
        !value.valueParams ||
        (!!value.valueParams && value.valueParams.length <= 0) ||
        value.valueParams === "all"
      ) {
        delete newData[value.keyParams];
      } else {
        if (newData.hasOwnProperty(value.keyParams)) {
          newData[value.keyParams] = value.valueParams;
        }
      }
    });

    for (const [key, valueFor] of Object.entries(
      newData as IDataFilterOrganicKeywordType
    )) {
      if (
        !valueFor ||
        (!!valueFor && valueFor.length <= 0) ||
        valueFor === "all"
      ) {
        delete newData[key];
      }
    }
    const newParams = queryString.stringify(newData);
    onChangeParamsURL(newParams.toLocaleLowerCase());
  };

  const headersOrganicTable: ITableNavDetailNoPaginationHeader<any>[] = [
    {
      field: "organicTraffic",
      title: `Từ khoá`,
      width: "160px",
    },
    {
      field: "organicTraffic",
      title: `Vị trí`,
      width: "100px",
    },
    {
      field: "organicKeywords",
      title: `Volume`,
      width: "120px",
    },
    {
      field: "commonKeywords",
      title: `KD`,
      width: "140px",
    },
    {
      field: "commonKeywords",
      title: `CPC`,
      width: "140px",
    },
    {
      field: "commonKeywords",
      title: `Traffic`,
      width: "140px",
    },
    {
      field: "url",
      title: `URL`,
      className: "table-cell-link",
      handleItem: (item: any) => {
        return (
          <a className="fit-one-line" target="_blank" rel="noopener noreferrer">
            {item.url}
          </a>
        );
      },
    },
  ];

  return (
    <div className="toppage-tab-wrapper tab-common-wrapper">
      <div className="tab-header-filter flex-between-align-center">
        <div className="filter-left flex-start-align-center">
          <InputSearch
            placeholder={"Tìm kiếm"}
            onChange={(e: any) => {
              setKeySearchState(e.target.value);
              onChangeSearch(e);
            }}
            value={keySearchState}
            size={"medium"}
            width={"340px"}
            typeStyle="outlined"
          />
        </div>
        <div className="filter-right flex-end-align-center">
          <NumberRangePicker
            onSubmit={(value: { from: number | null; to: number | null }) => {
              handleChangeParams([
                {
                  keyParams: "trafficFrom",
                  valueParams: value.from?.toString() || "",
                },
                {
                  keyParams: "trafficTo",
                  valueParams: value.to?.toString() || "",
                },
              ]);
            }}
            onClear={() => {
              handleChangeParams([
                {
                  keyParams: "trafficFrom",
                  valueParams: "",
                },
                {
                  keyParams: "trafficTo",
                  valueParams: "",
                },
              ]);
            }}
            placeholder="Traffic"
            modalTitle="Traffic"
            value={{
              from: Number(dataFilter.trafficFrom),
              to: Number(dataFilter.trafficTo),
            }}
            isFormatNumber
            size="small"
          />
          <NumberRangePicker
            onSubmit={(value: { from: number | null; to: number | null }) => {
              handleChangeParams([
                {
                  keyParams: "numberKeywordFrom",
                  valueParams: value.from?.toString() || "",
                },
                {
                  keyParams: "numberKeywordTo",
                  valueParams: value.to?.toString() || "",
                },
              ]);
            }}
            onClear={() => {
              handleChangeParams([
                {
                  keyParams: "numberKeywordFrom",
                  valueParams: "",
                },
                {
                  keyParams: "numberKeywordTo",
                  valueParams: "",
                },
              ]);
            }}
            placeholder="Number keyword"
            modalTitle="Number keyword"
            value={{
              from: Number(dataFilter.numberKeywordFrom),
              to: Number(dataFilter.numberKeywordTo),
            }}
            isFormatNumber
            size="small"
          />
        </div>
      </div>
      <div className="overview-table">
        <TableNavDetailNoPagination
          headersTable={headersOrganicTable}
          data={{
            ...DEFAULT_BASE_PAGING_RES,
          }}
        />
      </div>
    </div>
  );
}
