import { styled } from "@mui/material/styles";
import { Popover } from "@mui/material";
import variableStyles from "../../../theme/variable-styles";

export const FilterDateWrapper = styled("button")(({ theme }) => ({
  border: `1px solid ${variableStyles.NaturalColor300}`,
  padding: "10px 12px",
  borderRadius: "12px",
  backgroundColor: "transparent",
  display: "flex",
  alignItems: "center",
  gap: "8px",
  boxShadow: "0px 0px 2px 0px #00000033",
  "&.active": {
    boxShadow: "0px 0px 0px 4px #23B4FF33",
    border: "1px solid #0C97FF !important"
  },
  "& p": {
    fontWeight: variableStyles.fwMedium,
    fontSize: "14px",
    lineHeight: "14px",
    "&.has-time-box": {
      color: variableStyles.NaturalColor1000
    },
    "&.no-time-box": {
      color: variableStyles.NaturalColor700
    }
  }
}));

export const FilterDatePopoverWrapper = styled(Popover)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: "12px",
    width: "320px",
    boxShadow: "0px 5px 24px 0px #0000003D",
    marginTop: "4px"
  }
}));
