import React, { useEffect, useState } from "react";
import {
  BackLinkTag,
  ImageCustom,
  IndexNoPercentBox,
  Modal,
  TableNavDetailNoPagination,
} from "../../../../../../../components";
import OverviewCommonSubTitle from "../../../../OverviewCommonSubTitle";
import { BackLinkContentsWrapper } from "./styles";
import variableStyles from "../../../../../../../theme/variable-styles";
import {
  IBasePagingRes,
  IValue,
} from "../../../../../../../models/common/models.type";
import { DEFAULT_BASE_PAGING_RES } from "../../../../../../../common/constants/value.constant";
import moment from "moment";
import { DATE_FORMAT } from "../../../../../../../config/constants";
import { ITableNavDetailNoPaginationHeader } from "../../../../../../../components/Table/TableNavDetailNoPagination";
import BackLinkChartBox from "./components/BackLinkChartBox";
import AuditReportService from "../../../../../../../services/api/audit.report.service";
import {
  IGetBackLinkAnchorRes,
  IGetBackLinkAuthorizeScoreProfileRes,
  IGetBackLinkOverviewRes,
  IGetBackLinkOverviewTableRes,
  IGetRefferingDomainsRes,
  IGetRefferingIPsRes,
} from "../../../../../../../models/audit-report";
import {
  EBackLinkTag,
  ETypeLinkCheck,
} from "../../../../../../../models/common/models.enum";
import CommonHandle from "../../../../../../../common/handles/common.handles";
import { useSelector } from "react-redux";
import { IStateApp } from "../../../../../../../models/redux";
import { IGetReportsRes } from "../../../../../../../models/report";
import { generatePath, useNavigate } from "react-router-dom";
import { ROUTE_PATH } from "../../../../../../../common/constants/app.constant";
import queryString from "query-string";

const backlinkTabsData: IValue[] = [
  { title: "Tổng quan Backlink", value: 0 },
  { title: "Refering Domain", value: 1 },
  { title: "Refering IPS", value: 2 },
];

export default function BackLinkContents() {
  const navigate = useNavigate();

  const dataReport = useSelector<IStateApp, IGetReportsRes>(
    appState => appState.project?.dataReport
  );

  const [activeTableTab, setActiveTableTab] = useState<number>(0);
  const [dataResOverview, setDataResOverview] =
    useState<IGetBackLinkOverviewRes>();
  const [dataResBackLinkAnchor, setDataResBackLinkAnchor] =
    useState<IGetBackLinkAnchorRes[]>();
  const [
    dataResBackLinkAuthorizeScoreProfile,
    setDataResBackLinkAuthorizeScoreProfile,
  ] = useState<IGetBackLinkAuthorizeScoreProfileRes[]>();
  const [dataResBackLinkOverviewTable, setDataResBackLinkOverviewTable] =
    useState<IGetBackLinkOverviewTableRes[]>();
  const [
    dataResBackLinkOverviewTablePaging,
    setDataResBackLinkOverviewTablePaging,
  ] = useState<IBasePagingRes<any>>({ ...DEFAULT_BASE_PAGING_RES });
  const [dataResRefferingDomainsTable, setDataResRefferingDomainsTable] =
    useState<IGetRefferingDomainsRes[]>();
  const [
    dataResRefferingDomainsTablePaging,
    setDataResRefferingDomainsTablePaging,
  ] = useState<IBasePagingRes<any>>({ ...DEFAULT_BASE_PAGING_RES });
  const [dataResRefferingIPsTable, setDataResRefferingIPsTable] =
    useState<IGetRefferingIPsRes[]>();
  const [dataResRefferingIPsTablePaging, setDataResRefferingIPsTablePaging] =
    useState<IBasePagingRes<any>>({ ...DEFAULT_BASE_PAGING_RES });
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    getDataofPage();
  }, []);

  const getDataofPage = async () => {
    setIsLoading(true);
    await Promise.all([
      await AuditReportService.getBackLinkOverview({
        type: "",
        domain: "",
      }),
      await AuditReportService.getBackLinkAnchor({
        type: "",
        domain: "",
        pageSize: 5,
      }),
      await AuditReportService.getBackLinkAuthorizeScoreProfile({
        type: "",
        domain: "",
      }),
      await AuditReportService.getBackLinkOverviewTable({
        type: "",
        domain: "",
        pageSize: 30,
      }),
    ]).then(
      ([
        resOverview,
        resBackLinkAnchor,
        resBackLinkAuthorizeScoreProfile,
        resBackLinkOverviewTable,
      ]) => {
        if (!resOverview.isError) {
          setDataResOverview(resOverview.data);
        }
        if (!resBackLinkAnchor.isError) {
          setDataResBackLinkAnchor(resBackLinkAnchor.data);
        }
        if (!resBackLinkAuthorizeScoreProfile.isError) {
          setDataResBackLinkAuthorizeScoreProfile(
            resBackLinkAuthorizeScoreProfile.data
          );
        }
        if (
          !resBackLinkOverviewTable.isError &&
          !!resBackLinkOverviewTable.data
        ) {
          setDataResBackLinkOverviewTable(resBackLinkOverviewTable.data);
        }
        setIsLoading(false);
      }
    );
  };

  const headersBacklinkTable: ITableNavDetailNoPaginationHeader<any>[] = [
    {
      field: "sourceTitle",
      title: `Tiêu đề/ đường dẫn trang giới thiệu`,
      width: "280px",
      className: "table-cell-link",
      isSticky: true,
      sx: { verticalAlign: "top", padding: "14px 6px !important" },
      handleItem: (item: any) => {
        return (
          <div className="title-with-link">
            <p className="fit-one-line">{item.sourceTitle}</p>
            <a
              className="fit-one-line"
              href={item.sourceUrl}
              target="_blank"
              rel="noreferrer"
            >
              {item.sourceUrl}
            </a>
          </div>
        );
      },
    },
    {
      field: "anchor",
      title: `Anchor text/ URL`,
      width: "200px",
      sx: { verticalAlign: "top", padding: "14px 6px !important" },
      handleItem: (item: any) => {
        const firstTag = [
          { name: EBackLinkTag.Text, value: item.text },
          { name: EBackLinkTag.Form, value: item.form },
          { name: EBackLinkTag.Frame, value: item.frame },
          { name: EBackLinkTag.Image, value: item.image },
        ];

        const secondTag = [
          { name: EBackLinkTag.Newlink, value: item.newLink },
          { name: EBackLinkTag.Lostlink, value: item.lostLink },
        ];

        const renderFirstTag = firstTag.find(tag => !!tag.value);
        const renderSecondTag = secondTag.find(tag => !!tag.value);
        return (
          <div className="title-with-link">
            <p className="fit-one-line">{item.anchor}</p>
            <a
              className="fit-one-line"
              href={item.targetUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.targetUrl}
            </a>
            <ul
              className="anchor-tag-list flex-start-align-center"
              style={{ gap: "4px" }}
            >
              {!!renderFirstTag && <BackLinkTag tag={renderFirstTag.name} />}
              {!!renderSecondTag && <BackLinkTag tag={renderSecondTag.name} />}
              <BackLinkTag
                tag={
                  item.noFollow ? EBackLinkTag.NoFollow : EBackLinkTag.Follow
                }
              />
            </ul>
          </div>
        );
      },
    },
    {
      field: "firstSeen",
      title: `First seen`,
      width: "100px",
      sx: { verticalAlign: "top", padding: "14px 6px !important" },
      handleItem: item => {
        return moment(item.firstSeen).format(DATE_FORMAT);
      },
    },
    {
      field: "lastSeen",
      title: `Last seen`,
      width: "100px",
      sx: { verticalAlign: "top", padding: "14px 6px !important" },
      handleItem: item => {
        return moment(item.lastSeen).format(DATE_FORMAT);
      },
    },
  ];

  const headersReferingDomainTable: ITableNavDetailNoPaginationHeader<any>[] = [
    {
      field: "domain",
      title: `Tên miền`,
      width: "210px",
      className: "table-cell-link",
      isSticky: true,
      sx: { padding: "14px 6px !important" },
      handleItem: (item: any) => {
        return (
          <a
            className="fit-one-line"
            href={item.domain}
            target="_blank"
            rel="noopener noreferrer"
          >
            {item.domain}
          </a>
        );
      },
    },
    {
      field: "domainAScore",
      title: `Điểm uy tín`,
      width: "150px",
      sx: { padding: "14px 6px !important" },
      handleItem: (item: any) => {
        return <p className="fit-one-line">{item.domainAScore}</p>;
      },
    },
    {
      field: "backLinksNum",
      title: `Số liên kết ngược`,
      width: "150px",
      sx: { padding: "14px 6px !important" },
      handleItem: (item: any) => {
        return <p className="fit-one-line">{item.backLinksNum}</p>;
      },
    },
    {
      field: "ip",
      title: `Quốc gia/IP`,
      width: "200px",
      sx: { padding: "14px 6px !important" },
      handleItem: (item: any) => {
        return (
          <div className="table-cell-IP flex-start-align-center">
            {/* <ImageCustom
              src={IconTemp}
              alt={"country-icon"}
              width={"16px"}
              height={"12px"}
            /> */}
            <p className="fit-one-line">{item.ip}</p>
          </div>
        );
      },
    },
  ];

  const headersReferingIPSTable: ITableNavDetailNoPaginationHeader<any>[] = [
    {
      field: "ip",
      title: `Quốc gia/IP`,
      width: "280px",
      sx: { padding: "14px 6px !important" },
      handleItem: (item: any) => {
        return (
          <div className="table-cell-IP flex-start-align-center">
            {/* <ImageCustom
              src={IconTemp}
              alt={"country-icon"}
              width={"16px"}
              height={"12px"}
            /> */}
            <p className="fit-one-line">{item.ip}</p>
          </div>
        );
      },
    },
    {
      field: "domainsNum",
      title: `Số tên miền`,
      width: "200px",
      isSticky: true,
      sx: { padding: "14px 6px !important" },
      handleItem: (item: any) => {
        return <p className="fit-one-line">{item.domainsNum}</p>;
      },
    },

    {
      field: "backLinksNum",
      title: `Số liên kết ngược`,
      width: "200px",
      sx: { padding: "14px 6px !important" },
      handleItem: (item: any) => {
        return <p className="fit-one-line">{item.backLinksNum}</p>;
      },
    },
  ];

  const handleChangePage = (page: number) => {
    if (activeTableTab === 0) {
      if (!!dataResBackLinkOverviewTable) {
        const data = [...dataResBackLinkOverviewTable];
        const newData = {
          page: page,
          pageSize: 10,
          total: data.length,
          items: [...data]?.slice(page * 10 - 10, page * 10),
          isFull: true,
        };
        setDataResBackLinkOverviewTablePaging(newData);
        if (!openModal) {
          setOpenModal(true);
        }
      }
    } else if (activeTableTab === 1) {
      if (!!dataResRefferingDomainsTable) {
        const data = [...dataResRefferingDomainsTable];
        const newData = {
          page: page,
          pageSize: 10,
          total: data.length,
          items: [...data]?.slice(page * 10 - 10, page * 10),
          isFull: true,
        };
        setDataResRefferingDomainsTablePaging(newData);
        if (!openModal) {
          setOpenModal(true);
        }
      }
    } else {
      if (!!dataResRefferingIPsTable) {
        const data = [...dataResRefferingIPsTable];
        const newData = {
          page: page,
          pageSize: 10,
          total: data.length,
          items: [...data]?.slice(page * 10 - 10, page * 10),
          isFull: true,
        };
        setDataResRefferingIPsTablePaging(newData);
        if (!openModal) {
          setOpenModal(true);
        }
      }
    }
  };

  const hanldeChangeTabs = async (tab: number) => {
    setActiveTableTab(tab);
    if (tab === 1 && !dataResRefferingDomainsTable) {
      setIsLoading(true);
      const res = await AuditReportService.getRefferringDomains({
        type: "",
        domain: "",
        pageSize: 30,
      });
      if (!res.isError && !!res.data) {
        setDataResRefferingDomainsTable(res.data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } else if (tab === 2 && !dataResRefferingIPsTable) {
      setIsLoading(true);
      const res = await AuditReportService.getRefferringIPs({
        type: "",
        domain: "",
        pageSize: 30,
      });
      if (!res.isError && !!res.data) {
        setDataResRefferingIPsTable(res.data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    }
  };

  const tempData: any[] = [
    {
      title: "TỔNG SỐ BACKLINK",
      value: CommonHandle.formatNumberCurrency(dataResOverview?.total),
      infor:
        "Tổng số backlink là một chỉ số SEO quan trọng, đề cập đến số lượng liên kết từ các trang web khác dẫn đến website của bạn. Xây dựng một website có nhiều liên kết chất lượng cao trỏ đến giúp tăng khả năng xếp hạng website đó trên trang kết quả của các công cụ tìm kiếm.",
    },
    {
      title: "ĐIỂM UY TÍN TÊN MIỀN",
      value: CommonHandle.formatNumberCurrency(dataResOverview?.aScore),
      infor:
        "Điểm uy tín tên miền là chỉ số đánh giá mức độ uy tín và khả năng xếp hạng của một tên miền trên công cụ tìm kiếm. Một cách tương đối, điểm uy tín tên miền càng cao cho thấy tên miền có lợi thế hơn về điểm đánh giá SEO.",
    },
    {
      title: "TỔNG SỐ REFERING DOMAIN",
      value: CommonHandle.formatNumberCurrency(dataResOverview?.domainsNum),
      infor:
        "Tổng số referring domain là số lượng các tên miền khác nhau có liên kết đến trang web của bạn. Referring domain là một thước đo quan trọng trong việc xếp hạng vì referring Domain cao cho thấy website đó được nhiều nguồn khác tin cậy và đánh giá cao.",
    },
    {
      title: "TỔNG SỐ REFERING IPS",
      value: CommonHandle.formatNumberCurrency(dataResOverview?.ipsNum),
      infor:
        "Tổng số Referring IPs là một chỉ số cho biết số lượng địa chỉ IP khác biệt có chứa backlink trỏ về website của bạn. Referring IPs cao cho thấy backlink của bạn đến từ nhiều nguồn khác nhau, giúp giảm nguy cơ bị phạt vì xây dựng liên kết không tự nhiên. Đây là một chỉ số quan trọng trong SEO.",
    },
  ];

  return (
    <>
      <Modal
        title={
          activeTableTab === 0
            ? "Tổng quan Backlink"
            : activeTableTab === 1
              ? "Refering Domain"
              : "Refering IPS"
        }
        open={openModal}
        width={"744px"}
        onClose={() => setOpenModal(false)}
        textSubmit="Xem tất cả"
        onSubmit={() => {
          const path = generatePath(ROUTE_PATH.BACKLINK_CHECK);
          let newParams = {};
          if (activeTableTab === 0) {
            newParams = {
              tab: "overview",
              target: `https://${dataReport.target}`,
              type: ETypeLinkCheck.RootDomain,
            };
          } else if (activeTableTab === 1) {
            newParams = {
              tab: "referringdomain",
              target: `https://${dataReport.target}`,
              type: ETypeLinkCheck.RootDomain,
            };
          } else {
            newParams = {
              tab: "referringips",
              target: `https://${dataReport.target}`,
              type: ETypeLinkCheck.RootDomain,
            };
          }
          const paramSearch = queryString.stringify(newParams);

          const newWindow = window.open(
            `${path}?${paramSearch?.toLocaleLowerCase()}`,
            "_blank",
            "noopener,noreferrer"
          );
          if (newWindow) newWindow.opener = null;
        }}
        maxheight="calc(100vh - 88px)"
      >
        <TableNavDetailNoPagination
          headersTable={
            activeTableTab === 0
              ? headersBacklinkTable
              : activeTableTab === 1
                ? headersReferingDomainTable
                : headersReferingIPSTable
          }
          data={
            activeTableTab === 0
              ? dataResBackLinkOverviewTablePaging
              : activeTableTab === 1
                ? dataResRefferingDomainsTablePaging
                : dataResRefferingIPsTablePaging
          }
          handleViewMore={() => {}}
          isFullTable
          handlePageChange={page => handleChangePage(page)}
          maxHeight={"calc(100vh - 280px)"}
        />
      </Modal>
      <BackLinkContentsWrapper>
        <div className="backlink-overview-wrapper">
          <OverviewCommonSubTitle
            title="Backlink"
            className="backlink-subtitle"
          />
          <p className="backlink-description">
            Backlink còn được gọi là liên kết ngược, là các liên kết từ một
            website khác trỏ đến website của bạn. Website có nhiều backlink chất
            lượng thường xếp hạng cao hơn trên kết quả tìm kiếm.
          </p>
          <ul className="overview-list">
            {tempData.map(data => (
              <li key={data.title}>
                <IndexNoPercentBox data={data} />
              </li>
            ))}
          </ul>
        </div>
        <div className="overview-chart-list">
          {!!dataResOverview && (
            <BackLinkChartBox
              data={{
                pieChartData: {
                  listKeyField: ["followsNum", "noFollowsNum"],
                  chartData: [
                    { name: "Follow", value: dataResOverview.followsNum },
                    {
                      name: "No Follow",
                      value: dataResOverview.noFollowsNum,
                    },
                  ],
                },

                title: "Backlink: Follow vs Nofollow",
                infor: "Backlink: Follow vs Nofollow",
                description: `Tỉ lệ backlink follow và nofflow chỉ ra số lượng backlink trực tiếp chuyển tín hiệu SEO (Linkjuice) từ trang nguồn đến trang đích (follow backlink) và số lượng backlink không trực tiếp chuyển tín hiệu SEO (Linkjuice) từ trang nguồn đến trang đích (nofollow backlink)`,
                recommendAction: `Kết hợp cả backlink follow và nofflow để tạo sự tự nhiên cho hồ sơ backlink, một tỉ lệ thích hợp là khoảng 70 - 80% backlink follow và 20 - 30% backlink nofollow.`,
              }}
            />
          )}
          {!!dataResBackLinkAnchor && (
            <BackLinkChartBox
              data={{
                barChartData: {
                  chartData: dataResBackLinkAnchor,
                  listKeyField: ["backLinksNum"],
                  YAxisKey: "anchor",
                  fieldTextList: [
                    {
                      value: "Số liên kết ngược",
                      color: variableStyles.GreenPrimaryColor400,
                    },
                  ],
                  height: "260px",
                },

                title: "Backlink: Top Anchor Text",
                infor: "Backlink: Top Anchor Text",
                description:
                  "Top Anchor Text trong backlink là danh sách các từ khóa được sử dụng nhiều nhất làm văn bản neo (anchor text) trong các liên kết trỏ về website. Sử dụng Anchor Text một cách thích hợp giúp tăng tính tự nhiên cho hồ sơ backlink đồng thời cải thiện kết quả xếp hạng website.",
                recommendAction: `Kết hợp các loại anchor text một cách tự nhiên, đa dạng để tránh bị coi là thao túng thứ hạng. Với các backlink có anchor text là các từ khóa độc hại: "sex", "jav",... hoặc từ khóa không liên quan, cần liên hệ với các chủ website (nguồn backlink) để yêu cầu xóa, sửa các backlink không mong muốn hoặc sử dụng Google Disavow Tool để yêu cầu Google không tính các backlink này vào hồ sơ SEO của bạn.`,
              }}
            />
          )}
          {!!dataResBackLinkAuthorizeScoreProfile && (
            <BackLinkChartBox
              data={{
                barChartData: {
                  chartData: dataResBackLinkAuthorizeScoreProfile,
                  listKeyField: ["totalDomains"],
                  YAxisKey: "range",
                  fieldTextList: [
                    {
                      value: "Số lượng tên miền",
                      color: variableStyles.Info600,
                    },
                  ],
                  height: "400px",
                },
                title: "Refering Domain: Authority Score",
                infor:
                  "Chỉ số sức mạnh của referring domain là một chỉ số được sử dụng để đánh giá độ uy tín và chất lượng của một tên miền. Tên miền có chỉ số sức mạnh cao thì backlink đến từ tên miền đó càng có giá trị cho website của bạn.",
                description:
                  "Chỉ số sức mạnh của referring domain là một chỉ số được sử dụng để đánh giá độ uy tín và chất lượng của một tên miền. Tên miền có chỉ số sức mạnh cao thì backlink đến từ tên miền đó càng có giá trị cho website của bạn.",
                recommendAction: `Đề xuất: Xây dựng nhiều backlink từ các Domain có chỉ số sức mạnh cao và liên quan đến lĩnh vực của bạn (Tối thiểu 20 Referrign Domain có Authority Score > 50) để tăng sức mạnh, điểm uy tín và lượng truy cập cho website.`,
              }}
              colorFillList={[variableStyles.Info600]}
            />
          )}
        </div>
        <div className="backlink-tables-wrapper">
          <ul className="backlink-table-tabs">
            {backlinkTabsData.map(tab => (
              <li
                key={tab.value}
                className={`${activeTableTab === tab.value ? "active" : ""}`}
              >
                <button onClick={() => hanldeChangeTabs(tab.value)}>
                  <p className="fit-one-line"> {tab.title}</p>
                </button>
              </li>
            ))}
          </ul>
          <TableNavDetailNoPagination
            headersTable={
              activeTableTab === 0
                ? headersBacklinkTable
                : activeTableTab === 1
                  ? headersReferingDomainTable
                  : headersReferingIPSTable
            }
            data={{
              ...DEFAULT_BASE_PAGING_RES,
              items:
                activeTableTab === 0
                  ? dataResBackLinkOverviewTable?.slice(0, 10) || []
                  : activeTableTab === 1
                    ? dataResRefferingDomainsTable?.slice(0, 10) || []
                    : dataResRefferingIPsTable?.slice(0, 10) || [],
            }}
            colorHeader={variableStyles.NaturalColor950}
            handleViewMore={() => {
              handleChangePage(1);
            }}
            loading={isLoading}
          />
        </div>
      </BackLinkContentsWrapper>
    </>
  );
}
