import { styled } from "@mui/material/styles";
import variableStyles from "../../../../../../../../../theme/variable-styles";

export const MultiColorProgressWrapper = styled("div")<{
  width: number | string;
}>(({ width }) => ({
  display: "flex",
  alignItems: "flex-end",
  height: "24px",
  width: width,
  "& .progress-bar-wrapper": {
    position: "relative",
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
}));
