import { styled } from "@mui/material/styles";
import variableStyles from "../../../../theme/variable-styles";

export const OverviewCommonCaptionWrapper = styled("div")<{ width?: string }>(
  ({ width }) => ({
    width: !!width ? width : "100%",
    maxWidth: "100%",
    "& .caption-header": {
      marginBottom: "9px",
      "& .icon-caption": {
        marginRight: "10px",
      },
      "& h4": {
        marginRight: "4px",
      },
    },
    "& .caption-description": {
      color: `${variableStyles.NaturalColor950} !important`,
      "&.is-summary": {
        color: `${variableStyles.blueSecondary900} !important`,
        fontWeight: `${variableStyles.fwMedium} !important`,
        fontSize: "24px !important",
        lineHeight: "29.05px !important",
        padding: "6px 8px",
        backgroundColor: variableStyles.NaturalColor100,
        borderRadius: "6px",
        width: "max-content",
      },
    },
  })
);
