import { IProject } from "../../project";
import { IGetReportsRes } from "../../report";

export interface IReduxProjectState {
  dataProject: IProject;
  dataReport: IGetReportsRes;
  errorList: string[];
}

export enum EProjectType {
  SET_PROJECT_STATE = "SET_PROJECT_STATE",
  CLEAR_PROJECT_STATE = "CLEAR_PROJECT_STATE",
  SET_REPORT_STATE = "SET_REPORT_STATE",
  CLEAR_REPORT_STATE = "CLEAR_REPORT_STATE",
  SET_ERROR_LIST_STATE = "SET_ERROR_LIST_STATE",
  CLEAR_ERROR_LIST_STATE = "CLEAR_ERROR_LIST_STATE",
}
export interface IActionSetProjectState {
  type: EProjectType.SET_PROJECT_STATE;
  payload: { data: IProject };
}

export interface IActionClearProjectState {
  type: EProjectType.CLEAR_PROJECT_STATE;
  payload: {};
}

export interface IActionSetReportState {
  type: EProjectType.SET_REPORT_STATE;
  payload: { data: IGetReportsRes };
}

export interface IActionClearReportState {
  type: EProjectType.CLEAR_REPORT_STATE;
  payload: {};
}

export interface IActionSetErrorListState {
  type: EProjectType.SET_ERROR_LIST_STATE;
  payload: { data: string[] };
}

export interface IActionClearErrorListState {
  type: EProjectType.CLEAR_ERROR_LIST_STATE;
  payload: {};
}

export type IActionProject =
  | IActionSetProjectState
  | IActionClearProjectState
  | IActionSetReportState
  | IActionClearReportState
  | IActionSetErrorListState
  | IActionClearErrorListState;
