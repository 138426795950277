import React from "react";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import { Helmet } from "react-helmet";
import variableStyles from "../../theme/variable-styles";
import SelectCustom from "../../components/SelectCustom";
import VALUE_LIST from "../../common/constants/value.constant";
import DeleteIcon from "@mui/icons-material/Delete";
import AlarmIcon from "@mui/icons-material/Alarm";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";

const colorStyle = {
  width: 50,
  height: 50,
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginLeft: "10px",
  color: variableStyles.colorWhite,
  border: `1px solid ${variableStyles.grey500}`
};

const textStyle = {
  width: 120
};

const colorData = [
  {
    title: "Primary color",
    colorText: variableStyles.GreenPrimaryColor400,
    colors: [
      { color: variableStyles.GreenPrimaryColor50, text: "50" },
      { color: variableStyles.GreenPrimaryColor100, text: "100" },
      { color: variableStyles.GreenPrimaryColor200, text: "200" },
      { color: variableStyles.GreenPrimaryColor300, text: "300" },
      { color: variableStyles.GreenPrimaryColor400, text: "400" },
      { color: variableStyles.GreenPrimaryColor500, text: "500" },
      { color: variableStyles.GreenPrimaryColor600, text: "600" },
      { color: variableStyles.GreenPrimaryColor700, text: "700" },
      { color: variableStyles.GreenPrimaryColor800, text: "800" },
      { color: variableStyles.GreenPrimaryColor900, text: "900" },
      { color: variableStyles.GreenPrimaryColor950, text: "950" }
    ]
  },
  {
    title: "Natural color",
    colorText: variableStyles.NaturalColor400,
    colors: [
      { color: variableStyles.NaturalColor0, text: "0" },
      { color: variableStyles.NaturalColor50, text: "50" },
      { color: variableStyles.NaturalColor100, text: "100" },
      { color: variableStyles.NaturalColor200, text: "200" },
      { color: variableStyles.NaturalColor300, text: "300" },
      { color: variableStyles.NaturalColor400, text: "400" },
      { color: variableStyles.NaturalColor500, text: "500" },
      { color: variableStyles.NaturalColor600, text: "600" },
      { color: variableStyles.NaturalColor700, text: "700" },
      { color: variableStyles.NaturalColor800, text: "800" },
      { color: variableStyles.NaturalColor900, text: "900" },
      { color: variableStyles.NaturalColor950, text: "950" },
      { color: variableStyles.NaturalColor1000, text: "1000" }
    ]
  },
  {
    title: "Secondary color",
    colorText: variableStyles.blueSecondary500,
    colors: [
      { color: variableStyles.blueSecondary50, text: "50" },
      { color: variableStyles.blueSecondary100, text: "100" },
      { color: variableStyles.blueSecondary200, text: "200" },
      { color: variableStyles.blueSecondary300, text: "300" },
      { color: variableStyles.blueSecondary400, text: "400" },
      { color: variableStyles.blueSecondary500, text: "500" },
      { color: variableStyles.blueSecondary600, text: "600" },
      { color: variableStyles.blueSecondary700, text: "700" },
      { color: variableStyles.blueSecondary800, text: "800" },
      { color: variableStyles.blueSecondary900, text: "900" },
      { color: variableStyles.blueSecondary950, text: "950" }
    ]
  },
  {
    title: "Grey color",
    colorText: variableStyles.grey500,
    colors: [
      { color: variableStyles.grey50, text: "50" },
      { color: variableStyles.grey100, text: "100" },
      { color: variableStyles.grey200, text: "200" },
      { color: variableStyles.grey300, text: "300" },
      { color: variableStyles.grey400, text: "400" },
      { color: variableStyles.grey500, text: "500" },
      { color: variableStyles.grey600, text: "600" },
      { color: variableStyles.grey700, text: "700" },
      { color: variableStyles.grey800, text: "800" },
      { color: variableStyles.grey900, text: "900" },
      { color: variableStyles.greyA100, text: "A100" },
      { color: variableStyles.greyA200, text: "A200" },
      { color: variableStyles.greyA400, text: "A400" },
      { color: variableStyles.greyA700, text: "A700" }
    ]
  },
  {
    title: "Info color",
    colorText: variableStyles.Info500,
    colors: [
      { color: variableStyles.Info50, text: "50" },
      { color: variableStyles.Info100, text: "100" },
      { color: variableStyles.Info200, text: "200" },
      { color: variableStyles.Info300, text: "300" },
      { color: variableStyles.Info400, text: "400" },
      { color: variableStyles.Info500, text: "500" },
      { color: variableStyles.Info600, text: "600" },
      { color: variableStyles.Info700, text: "700" },
      { color: variableStyles.Info800, text: "800" },
      { color: variableStyles.Info900, text: "900" },
      { color: variableStyles.InfoA100, text: "A100" },
      { color: variableStyles.InfoA200, text: "A200" },
      { color: variableStyles.InfoA400, text: "A400" },
      { color: variableStyles.InfoA700, text: "A700" }
    ]
  },
  {
    title: "Success color",
    colorText: variableStyles.Success500,
    colors: [
      { color: variableStyles.Success50, text: "50" },
      { color: variableStyles.Success100, text: "100" },
      { color: variableStyles.Success200, text: "200" },
      { color: variableStyles.Success300, text: "300" },
      { color: variableStyles.Success400, text: "400" },
      { color: variableStyles.Success500, text: "500" },
      { color: variableStyles.Success600, text: "600" },
      { color: variableStyles.Success700, text: "700" },
      { color: variableStyles.Success800, text: "800" },
      { color: variableStyles.Success900, text: "900" },
      { color: variableStyles.SuccessA100, text: "A100" },
      { color: variableStyles.SuccessA200, text: "A200" },
      { color: variableStyles.SuccessA400, text: "A400" },
      { color: variableStyles.SuccessA700, text: "A700" }
    ]
  },
  {
    title: "Warning color",
    colorText: variableStyles.Warning500,
    colors: [
      { color: variableStyles.Warning50, text: "50" },
      { color: variableStyles.Warning100, text: "100" },
      { color: variableStyles.Warning200, text: "200" },
      { color: variableStyles.Warning300, text: "300" },
      { color: variableStyles.Warning400, text: "400" },
      { color: variableStyles.Warning500, text: "500" },
      { color: variableStyles.Warning600, text: "600" },
      { color: variableStyles.Warning700, text: "700" },
      { color: variableStyles.Warning800, text: "800" },
      { color: variableStyles.Warning900, text: "900" },
      { color: variableStyles.WarningA100, text: "A100" },
      { color: variableStyles.WarningA200, text: "A200" },
      { color: variableStyles.WarningA400, text: "A400" },
      { color: variableStyles.WarningA700, text: "A700" }
    ]
  },
  {
    title: "Error color",
    colorText: variableStyles.Error500,
    colors: [
      { color: variableStyles.Error50, text: "50" },
      { color: variableStyles.Error100, text: "100" },
      { color: variableStyles.Error200, text: "200" },
      { color: variableStyles.Error300, text: "300" },
      { color: variableStyles.Error400, text: "400" },
      { color: variableStyles.Error500, text: "500" },
      { color: variableStyles.Error600, text: "600" },
      { color: variableStyles.Error700, text: "700" },
      { color: variableStyles.Error800, text: "800" },
      { color: variableStyles.Error900, text: "900" },
      { color: variableStyles.ErrorA100, text: "A100" },
      { color: variableStyles.ErrorA200, text: "A200" },
      { color: variableStyles.ErrorA400, text: "A400" },
      { color: variableStyles.ErrorA700, text: "A700" }
    ]
  }
];

const textFieldStyle = {
  marginLeft: "10px"
};

const selectStyle = {
  marginTop: "10px"
};

const Material = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title> Material | SEO </title>
      </Helmet>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="subtitle1">Color</Typography>
        </Grid>
        {colorData.map(item => {
          return (
            <Grid item xs={12} key={item.title}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center"
                }}
              >
                <Box
                  sx={{
                    ...textStyle,
                    color: item.colorText
                  }}
                >
                  {item.title}
                </Box>

                {item.colors.map(item => {
                  return (
                    <Box
                      key={item.color}
                      sx={{
                        ...colorStyle,
                        backgroundColor: item.color
                      }}
                    >
                      {item.text}
                    </Box>
                  );
                })}
              </Box>
            </Grid>
          );
        })}
        <Grid item xs={12}>
          <Typography variant="subtitle1">Divider</Typography>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">TextField</Typography>
          <Typography variant="subtitle2">TextField outlined</Typography>
          <TextField
            sx={textFieldStyle}
            variant="outlined"
            size="large"
            label="label"
            placeholder="placeholder"
          />
          <TextField
            sx={textFieldStyle}
            variant="outlined"
            label="label"
            placeholder="placeholder"
          />
          <TextField
            sx={textFieldStyle}
            variant="outlined"
            size="small"
            label="label"
            placeholder="placeholder"
          />

          <Typography variant="subtitle2">TextField filled</Typography>
          <TextField
            sx={textFieldStyle}
            variant="filled"
            label="label"
            placeholder="placeholder"
          />
          <TextField
            sx={textFieldStyle}
            variant="filled"
            size="small"
            label="label"
            placeholder="placeholder"
          />
          <Typography variant="subtitle2">TextField standard</Typography>
          <TextField
            sx={textFieldStyle}
            variant="standard"
            label="label"
            placeholder="placeholder"
          />
          <TextField
            sx={textFieldStyle}
            variant="standard"
            size="small"
            label="label"
            placeholder="placeholder"
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">Select custom </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle2">Select outlined </Typography>
          <Box sx={selectStyle}>
            <SelectCustom
              size="small"
              variant="outlined"
              options={[
                {
                  title: "Tất cả",
                  value: "all"
                },
                ...VALUE_LIST.AUDIT_STATUS_OPTIONS
              ]}
              onChange={event => {}}
              label="Lọc theo trạng thái"
              value={"all"}
            />
          </Box>
          <Box sx={selectStyle}>
            <SelectCustom
              variant="outlined"
              options={[
                {
                  title: "Tất cả",
                  value: "all"
                },
                ...VALUE_LIST.AUDIT_STATUS_OPTIONS
              ]}
              onChange={event => {}}
              label="Lọc theo trạng thái"
              value={"all"}
            />
          </Box>
          <Box sx={selectStyle}>
            <SelectCustom
              size="large"
              variant="outlined"
              options={[
                {
                  title: "Tất cả",
                  value: "all"
                },
                ...VALUE_LIST.AUDIT_STATUS_OPTIONS
              ]}
              onChange={event => {}}
              label="Lọc theo trạng thái"
              value={"all"}
            />
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle2">Select filled </Typography>
          <Box sx={selectStyle}>
            <SelectCustom
              size="small"
              variant="filled"
              options={[
                {
                  title: "Tất cả",
                  value: "all"
                },
                ...VALUE_LIST.AUDIT_STATUS_OPTIONS
              ]}
              onChange={event => {}}
              label="Lọc theo trạng thái"
              value={"all"}
            />
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle2">Select standard </Typography>
          <Box sx={selectStyle}>
            <SelectCustom
              size="small"
              variant="standard"
              options={[
                {
                  title: "Tất cả",
                  value: "all"
                },
                ...VALUE_LIST.AUDIT_STATUS_OPTIONS
              ]}
              onChange={event => {}}
              label="Lọc theo trạng thái"
              value={"all"}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">Button</Typography>
          <Button
            size="large"
            variant="outlined"
            sx={textFieldStyle}
            color="success"
          >
            Button outlined large
          </Button>
          <Button
            size="medium"
            variant="outlined"
            sx={textFieldStyle}
            color="error"
          >
            Button outlined medium
          </Button>
          <Button
            size="small"
            variant="outlined"
            sx={textFieldStyle}
            color="warning"
          >
            Button outlined small
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button size="large" variant="contained" sx={textFieldStyle}>
            Button contained large
          </Button>
          <Button
            size="medium"
            variant="contained"
            color="secondary"
            sx={textFieldStyle}
          >
            Button contained medium
          </Button>

          <Button
            size="small"
            variant="contained"
            sx={textFieldStyle}
            color="info"
          >
            Button contained small
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button size="large" variant="text" sx={textFieldStyle}>
            Button text large
          </Button>
          <Button size="medium" variant="text" sx={textFieldStyle}>
            Button text medium
          </Button>
          <Button size="small" variant="text" sx={textFieldStyle}>
            Button text small
          </Button>
        </Grid>
        <Grid item xs={12}>
          <IconButton aria-label="delete" size="small">
            <DeleteIcon />
          </IconButton>
          <IconButton aria-label="delete" disabled color="primary">
            <DeleteIcon />
          </IconButton>
          <IconButton color="secondary" aria-label="add an alarm">
            <AlarmIcon />
          </IconButton>
          <IconButton color="primary" aria-label="add to shopping cart">
            <AddShoppingCartIcon />
          </IconButton>
        </Grid>

        <Grid item xs={12}>
          <Tooltip title="Delete">
            <IconButton>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">Typography</Typography>
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            size="small"
            options={[]}
            renderInput={value => <TextField {...value} />}
          />
        </Grid>
        <Grid item xs={12}>
          <BasicCard />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h1">H1</Typography>
          <Typography variant="h2">H2</Typography>
          <Typography variant="h3">H3</Typography>
          <Typography variant="h4">H4</Typography>
          <Typography variant="h5">H5</Typography>
          <Typography variant="h6">H6</Typography>
          <Typography variant="subtitle1">subtitle1</Typography>
          <Typography variant="subtitle2">subtitle2</Typography>
          <Typography variant="body1">body1</Typography>
          <Typography variant="body2">body2</Typography>
          <Box>
            <Typography variant="button">button</Typography>
          </Box>
          <Box>
            <Typography variant="caption">caption</Typography>
          </Box>
          <Typography variant="overline">overline</Typography>
          <Typography variant="inherit">inherit</Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

function BasicCard() {
  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography
          sx={{ fontSize: 14 }}
          color="text.blueSecondary"
          gutterBottom
        >
          Word of the Day
        </Typography>
        <Typography variant="h5" component="div">
          be{bull}nev{bull}o{bull}lent
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.blueSecondary">
          adjective
        </Typography>
        <Typography variant="body2">
          well meaning and kindly.
          <br />
          {'"a benevolent smile"'}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions>
    </Card>
  );
}

export default Material;
