import React, { useState } from "react";
import { Button } from "@mui/material";
import { MenuItemWrapper, SelectProjectWrapper } from "./styles";
import { ImageCustom } from "../..";
import variableStyles from "../../../theme/variable-styles";
import { IProject } from "../../../models/project";
import IconCheck from "../../../assets/images/common/icon_check_line_green.svg";

interface SelectProjectProps {
  value?: string | number;
  onChange: (value: any) => void;
  options: IProject[];
  className?: string;
  onGetMore: () => void;
  disabled?: boolean;
}

export default function SelectProject(props: SelectProjectProps) {
  const {
    value,
    onChange,
    options,
    className = "",
    onGetMore,
    disabled = false,
  } = props;

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const renderValue = (value: any) => {
    const result = options.find(o => o.id === value);
    if (!!result) {
      return result;
    }
    return undefined;
  };

  const loadMoreItems = (event: any) => {
    if (
      event.target.scrollHeight - event.target.scrollTop ===
      event.target.clientHeight
    ) {
      onGetMore();
    }
  };

  return (
    <SelectProjectWrapper
      labelId="simple-select-label"
      id="simple-select"
      value={value}
      onChange={onChange}
      className={`${className}`}
      IconComponent={() => (
        <figure
          className="select-icon"
          aria-expanded={isOpen ? "true" : "false"}
        >
          <ImageCustom
            src={IconCheck}
            alt="Icon-Check"
            width={"18px"}
            height={"18px"}
            className="icon-check"
          />
        </figure>
      )}
      displayEmpty
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      renderValue={(selected: any) => {
        const result = renderValue(selected);
        return (
          <>
            <figure className="project-avatar">
              <ImageCustom
                src={`https://t1.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=http://${result?.domain}&size=24`}
                alt="Avatar-Image"
                width={"24px"}
                height={"24px"}
              />
            </figure>
            <div className="project-name">
              <p className="name-project fit-one-line">{result?.projectName}</p>
              <p className="name-domain fit-one-line">{result?.domain}</p>
            </div>
          </>
        );
      }}
      MenuProps={{
        MenuListProps: {
          onScroll: loadMoreItems,
          className: "scrollbar-small",
        },
        sx: {
          "& .MuiPaper-root": {
            backgroundColor: "transparent",
            paddingTop: "6px",
            borderRadius: "12px",
          },
          "& .MuiList-root": {
            padding: "12px 8px !important",
            borderRadius: "12px",
            backgroundColor: variableStyles.colorWhite,
            display: "flex",
            flexDirection: "column",
            gap: "12px",
            maxHeight: "500px",
            overflow: "auto",
          },
        },
      }}
      disabled={disabled}
    >
      {options.map((option, index) => (
        <MenuItemWrapper value={option.id} key={index}>
          <Button className="infor-button">
            <figure className="project-avatar">
              <ImageCustom
                src={`https://t1.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=http://${option.domain}&size=24`}
                alt="Avatar-Image"
                width={"24px"}
                height={"24px"}
              />
            </figure>
            <div className="project-name">
              <p className="name-project fit-one-line">{option.projectName}</p>
              <p className="name-domain fit-one-line">{option.domain}</p>
            </div>
            <ImageCustom
              src={IconCheck}
              alt="Icon-Check"
              width={"18px"}
              height={"18px"}
              className="icon-check"
            />
          </Button>
        </MenuItemWrapper>
      ))}
    </SelectProjectWrapper>
  );
}
