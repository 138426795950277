import React, { useEffect } from "react";
import {
  Control,
  FieldErrors,
  useFieldArray,
  UseFormReset
} from "react-hook-form";
import { ControlForm, ImageCustom } from "../../../../..";
import InputForm from "../../../../../controls/InputForm";
import IconLink from "../../../../../../assets/images/common/icon_link_line_grey.svg";
import { PageCompetitiveBoxWrapper } from "../styles";
import { ICreatePageCompatitiveReq } from "../../../../../../models/report";
import { Button } from "@mui/material";
import IconPlusGrey from "../../../../../../assets/images/common/icon_plus_circle_grey_line_white.svg";
import IconDelete from "../../../../../../assets/images/common/icon_minus_circle_red_line_white.svg";

interface PageCompetitiveReportProps {
  control: Control<ICreatePageCompatitiveReq, any>;
  errors: FieldErrors<ICreatePageCompatitiveReq>;
  reset: UseFormReset<ICreatePageCompatitiveReq>;
}

export default function PageCompetitiveReport(
  props: PageCompetitiveReportProps
) {
  const { control, errors, reset } = props;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "compareWith"
  });

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  return (
    <PageCompetitiveBoxWrapper>
      <div className="page-competitive-wrapper scrollbar-small">
        <ControlForm
          title="Your URL"
          infor="Làm nổi bật sản phẩm/dịch vụ bằng tiêu đề thu hút người dùng."
        >
          <InputForm
            placeholder="https://..."
            required
            name={"target"}
            errors={errors}
            control={control}
            size="small"
            startIcon={IconLink}
            isLink
          />
        </ControlForm>
        <ControlForm
          title="Từ khóa SEO"
          infor="Làm nổi bật sản phẩm/dịch vụ bằng tiêu đề thu hút người dùng."
        >
          <InputForm
            placeholder="Nhập từ khóa"
            required
            name="keyword"
            errors={errors}
            control={control}
            size="small"
          />
        </ControlForm>
        {fields.map((field, idx) => (
          <div className="url-target-box" key={field.id}>
            <ControlForm
              title={`URL ${idx + 1}`}
              infor="Làm nổi bật sản phẩm/dịch vụ bằng tiêu đề thu hút người dùng."
            >
              <InputForm
                placeholder="https://..."
                required
                name={`compareWith[${idx}].url`}
                errors={errors.compareWith?.[idx]?.url}
                control={control}
                size="small"
                startIcon={IconLink}
                isLink
                isNotSafeRead
              />
            </ControlForm>
            {fields.length > 1 && (
              <Button onClick={() => remove(idx)} className="delete-button">
                <ImageCustom
                  src={IconDelete}
                  alt="Icon-Delete"
                  width={"18px"}
                  height={"18px"}
                />
              </Button>
            )}
          </div>
        ))}
      </div>
      {fields.length < 2 && (
        <Button onClick={() => append({ url: "" })} className="add-more-button">
          <ImageCustom
            src={IconPlusGrey}
            alt="Icon-Plus"
            width={"20px"}
            height={"20px"}
          />
          <p>Add more</p>
        </Button>
      )}
    </PageCompetitiveBoxWrapper>
  );
}
