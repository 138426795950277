import { useState, useEffect, useRef } from "react";
import { Button } from "@mui/material";
import {
  ControlForm,
  ImageCustom,
  LoadingIndicator,
  Modal,
  NoSearchDataBox,
  TableDataGrey,
} from "../../../../components";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import InputForm from "../../../../components/controls/InputForm";
import { Helmet } from "react-helmet";
import { PostsWrapper } from "./styles";
import { ITableHeader } from "../../../../components/Table/TableData/TableData";
import IconAdd from "../../../../assets/images/common/icon_plus_line_white.svg";
import InputSearch from "../../../../components/controls/InputSearch";
import IconFile from "../../../../assets/images/common/icon_file_fill_green.png";
import IconSearch from "../../../../assets/images/common/icon_search_line_blue.svg";
import {
  IGetOutlinePostReq,
  IGetOutlinePostRes,
  IProjectRes,
} from "../../../../models/outline";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import OutlineService from "../../../../services/api/outline.service";
import { IBasePagingRes } from "../../../../models/common/models.type";
import { DEFAULT_BASE_PAGING_RES } from "../../../../common/constants/value.constant";
import CommonHandle from "../../../../common/handles/common.handles";
import { ROUTE_PATH } from "../../../../common/constants/app.constant";
import { toggleMessage } from "../../../../components/Toast/Toast";
import { toggleConfirmModal } from "../../../../components/ConfirmModal/ConfirmModal";
import { localStorageHanlde } from "../../../../services/services";

const Posts = () => {
  const { project_id } = useParams();
  const timerOut = useRef<number>();
  const navigate = useNavigate();

  const [dataRes, setDataRes] = useState<IBasePagingRes<IGetOutlinePostRes>>({
    ...DEFAULT_BASE_PAGING_RES,
  });
  const [dataSingleRes, setSingleDataRes] = useState<IProjectRes | null>(null);
  const [openCreatePost, setOpenCreatePost] = useState<boolean>(false);
  const [params, setParams] = useState<IGetOutlinePostReq>({
    pageIndex: 1,
    pageSize: 10,
    projectId: "",
  });
  const [dataEditPost, setDataEditPost] = useState<{
    name: string;
    id: string;
  } | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [inputSearch, setInputSearch] = useState<string>("");

  useEffect(() => {
    if (!!project_id && project_id.length > 0) {
      setParams({
        pageIndex: 1,
        pageSize: 10,
        projectId: project_id,
      });
      getSinglePost();
    }
  }, [project_id]);

  useEffect(() => {
    if (!!project_id && project_id.length > 0 && params.projectId.length > 0) {
      getPostsList();
    }
  }, [params]);

  useEffect(() => {
    if (!!dataSingleRes?.name) {
      const data = [
        { title: "Outline", link: ROUTE_PATH.OUTLINE },
        {
          title: dataSingleRes.name,
          link: `${ROUTE_PATH.OUTLINE}/${dataSingleRes.id}`,
        },
      ];
      localStorageHanlde.storeBreadcrumbs(data);
    }
  }, [dataSingleRes]);

  useEffect(() => {
    return () => {
      localStorageHanlde.clearBreadcrumbs();
    };
  }, []);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(`${"Please enter post name"}`),
  }) as any;

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      name: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const getPostsList = async () => {
    setIsLoading(true);
    const res = await OutlineService.getListPost(params);
    if (res.success) {
      setDataRes(res.data);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const getSinglePost = async () => {
    if (project_id) {
      const res = await OutlineService.getSingleProject(project_id);
      if (res.success) {
        setSingleDataRes(res.data);
      }
    }
  };

  const onChangeSearch = (e: any) => {
    const value = e?.target?.value;
    if (timerOut.current) {
      clearTimeout(timerOut.current);
    }
    timerOut.current = window.setTimeout(() => {
      setParams(prev => ({
        ...prev,
        pageIndex: 1,
        pageSize: 10,
        keyword: value,
      }));
    }, 700);
  };

  const clearSearch = () => {
    setParams(prev => ({
      ...prev,
      pageIndex: 1,
      pageSize: 10,
      keyword: "",
    }));
    setInputSearch("");
  };

  const headersTable: ITableHeader<IGetOutlinePostRes>[] = [
    {
      field: "name",
      title: `Tên bài viết`,
      width: "200px",
    },
    {
      field: "createdAt",
      title: `Ngày tạo`,
      handleItem: item => {
        return CommonHandle.formatDate(item.createdAt);
      },
    },
    {
      field: "updatedAt",
      title: `Ngày cập nhật`,
      width: "250px",
      handleItem: item => {
        return CommonHandle.formatDate(item.updatedAt);
      },
    },
  ];

  const onSubmit = async (value: { name: string }) => {
    if (!dataEditPost) {
      if (project_id) {
        const res = await OutlineService.createPost({
          name: value.name,
          projectId: project_id,
        });
        if (res.success) {
          toggleMessage({
            type: "success",
            message: "Tạo thành công tên bài viết",
            title: "Tạo thành công",
          });
          handleCloseCreatePost();
          getPostsList();
        } else {
          toggleMessage({
            type: "error",
            message: "Tạo tên bài viết không thành công",
            title: "Không thành công",
          });
        }
      }
    } else {
      const res = await OutlineService.updatePostName({
        name: value.name,
        id: dataEditPost.id,
      });
      if (res.success) {
        toggleMessage({
          type: "success",
          message: "Tạo thành công tên bài viết",
          title: "Tạo thành công",
        });
        handleCloseCreatePost();
        getPostsList();
      } else {
        toggleMessage({
          type: "error",
          message: "Tạo tên bài viết không thành công",
          title: "Không thành công",
        });
      }
    }
  };

  const handleCloseCreatePost = () => {
    setOpenCreatePost(false);
    reset();
  };

  const asyncFuncDelete: (id: string) => Promise<void> = async (id: string) => {
    const res = await OutlineService.deletePost(id);
    if (res.success) {
      toggleMessage({
        type: "success",
        message: "Xoá thành công bài viết",
        title: "Xoá thành công",
      });
      getPostsList();
    } else {
      toggleMessage({
        type: "error",
        message: "Xoá bài viết không thành công",
        title: "Xoá không thành công",
      });
    }
  };

  return (
    <>
      <Modal
        title={!!dataEditPost ? "Edit Post" : "Create new Post"}
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt  eiusmo"
        open={openCreatePost}
        onSubmit={handleSubmit(onSubmit)}
        textSubmit={!!dataEditPost ? "Edit" : "Create Post"}
        onClose={handleCloseCreatePost}
      >
        <form
          style={{ display: "flex", flexDirection: "column", gap: "16px" }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <ControlForm
            title="Name"
            infor="Làm nổi bật sản phẩm/dịch vụ bằng tiêu đề thu hút người dùng."
            classname="brand-form"
          >
            <InputForm
              placeholder="Your post name "
              required
              name="name"
              errors={errors}
              control={control}
              size="small"
            />
          </ControlForm>
        </form>
      </Modal>
      <PostsWrapper>
        <Helmet>
          <title>Outline</title>
        </Helmet>
        <div className="outline-header">
          <div className="outline-post-title">
            <ImageCustom
              src={IconFile}
              alt="Icon-File"
              width={"24px"}
              height={"24px"}
            />
            <p className="header-name fit-one-line">{dataSingleRes?.name}</p>
            <p className="header-count">{dataRes.total} bài viết</p>
          </div>
          <div className="header-action">
            <InputSearch
              placeholder={"Tìm kiếm"}
              onChange={e => {
                onChangeSearch(e);
                setInputSearch(e.target.value);
              }}
              size={"medium"}
              width={"198px"}
              typeStyle="outlined"
              value={inputSearch}
            />
            <Button
              color="primary"
              size="small"
              variant="contained"
              onClick={_ => setOpenCreatePost(true)}
              className="add-button"
            >
              <ImageCustom
                src={IconAdd}
                alt="Icon-Add"
                width={"18px"}
                height={"18px"}
              />
              <p>Tạo bài viết</p>
            </Button>
          </div>
        </div>
        {isLoading ? (
          <LoadingIndicator className="loading-indicator" />
        ) : (
          <>
            {dataRes.items.length > 0 ? (
              <TableDataGrey
                data={dataRes}
                headersTable={headersTable}
                handleOpenDetail={(id: string) => {
                  const path = generatePath(ROUTE_PATH.OUTLINE_POST_DETAIL, {
                    project_id: project_id,
                    id: id,
                  });
                  navigate(path);
                }}
                handleDeleteData={(id: string) => {
                  toggleConfirmModal({
                    open: true,
                    title: `Xoá bài viết`,
                    content: `Bạn có chắc chắc muốn xoá bài viết này không?`,
                    onSubmit: () => asyncFuncDelete(id.toString()),
                    submitText: "Xoá",
                    type: "warning-red",
                  });
                }}
                handleEditData={(id: string) => {
                  const dataAction = dataRes.items.find(
                    item => item.id.toString() === id
                  );
                  if (!!dataAction) {
                    setDataEditPost({
                      name: dataAction.name,
                      id: dataAction.id.toString(),
                    });
                    setValue("name", dataAction.name);
                    setOpenCreatePost(true);
                  }
                }}
              />
            ) : (
              <NoSearchDataBox
                inputSearch={inputSearch}
                onClearSearch={clearSearch}
                openCreateModal={() => setOpenCreatePost(true)}
                openText="Tạo bài viết"
              />
            )}
          </>
        )}
      </PostsWrapper>
    </>
  );
};

export default Posts;
