import { Popover } from "@mui/material";
import { styled } from "@mui/material/styles";
import variableStyles from "../../theme/variable-styles";

interface PopoverCustomProps {
  boxref: any;
  popover_width?: number;
}

export const FilterSelectCustomWrapper = styled("div")(({ theme }) => ({
  cursor: "pointer",
  "& .filter-select-wrapper": {
    border: `1px solid ${variableStyles.NaturalColor300}`,
    borderRadius: "12px",
    padding: "9px 12px",
    boxShadow: "0px 0px 2px 0px #00000033 ",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "max-content",
    gap: ".5ch",
    "&.active": {
      boxShadow: "0px 0px 0px 4px #23B4FF33",
      border: "1px solid #0C97FF !important"
    },
    "&.select-box-error": {
      boxShadow: `0px 0px 0px 4px #90580433`,
      border: `1px solid ${variableStyles.Warning800} !important`
    },
    "& .filter-text": {
      color: variableStyles.NaturalColor900,
      fontWeight: variableStyles.fwMedium,
      fontSize: "14px",
      lineHeight: "19.6px"
    },
    "& .select-selected-text": {
      color: variableStyles.NaturalColor1000,
      fontWeight: variableStyles.fwMedium,
      fontSize: "14px",
      lineHeight: "19.6px"
    },
    "& .select-placeholder": {
      color: variableStyles.NaturalColor1000,
      fontWeight: variableStyles.fwMedium,
      fontSize: "14px",
      lineHeight: "19.6px"
    },
    "& .filter-arrow": {
      opacity: "0.8",
      transition: "all .2s linear",
      "&.active": {
        transform: "rotate(-180deg)"
      }
    }
  },
  "& .error-text": {
    color: variableStyles.Warning800,
    fontSize: "13px",
    lineHeight: "13px",
    display: "flex",
    alignItems: "center",
    gap: "4px",
    margin: "6px 0 0"
  }
}));

export const PopoverWrapper = styled(Popover)<PopoverCustomProps>(
  ({ theme, boxref, popover_width: popoverWidth }) => ({
    "& .MuiPaper-root": {
      marginTop: "4px",
      width: popoverWidth ? popoverWidth : `${boxref.current?.offsetWidth}px`,
      borderRadius: "12px",
      boxShadow: "0px 5px 24px 0px #0000003D",
      padding: "8px",
      "& .select-list": {
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        gap: "4px",
        "& li": {
          "&.active": {
            "& button": {
              "& .active-icon": {
                display: "block"
              }
            }
          },
          "& button": {
            width: "100%",
            justifyContent: "space-between",
            transition: "background-color .1s ease-in-out",
            backgroundColor: "transparent",
            padding: "14px 8px",
            borderRadius: "12px",
            "&:hover": {
              backgroundColor: variableStyles.NaturalColor100
            },
            "& p": {
              color: variableStyles.NaturalColor900,
              fontWeight: variableStyles.fwMedium,
              fontSize: "14px",
              lineHeight: "14px"
            }
          }
        }
      }
    }
  })
);
