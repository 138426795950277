import { styled } from "@mui/material/styles";
import variableStyles from "../../../../../../../../theme/variable-styles";

export const TechnicalIssueTabWrapper = styled("div")(() => ({
  "& .issue-list": {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    "&:not(:last-of-type)": {
      marginBottom: "24px",
    },
    "& .list-title": {
      fontSize: "14px",
      lineHeight: "14px",
      marginBottom: "8px",
      "&.error": {
        color: variableStyles.Error600,
        fontWeight: variableStyles.fwBold,
      },
      "&.warning": {
        color: variableStyles.Warning600,
        fontWeight: variableStyles.fwSemiBold,
      },
      "&.infor": {
        color: variableStyles.Info500,
        fontWeight: variableStyles.fwSemiBold,
      },
    },
  },
}));
