import { styled } from "@mui/material/styles";
import variableStyles from "../../../../theme/variable-styles";

export const OverviewSideBarWrapper = styled("div")(() => ({
  backgroundColor: variableStyles.NaturalColor0,
  flex: "0 0 auto",
  width: "288px",
  height: "calc(100vh - 154px)",
  borderRight: `1px solid ${variableStyles.NaturalColor300}`,
  boxShadow:
    "0px 39px 67.8px 0px #1956580A,0px 21px 37px -15px #1956580F,0px 1px 1px 0px #00000014",
  transition: "all .2s linear",
  "@keyframes hidden": {
    "0%": {
      opacity: "1",
    },
    "50%": {
      opacity: "1",
    },
    "100%": {
      pointerEvents: "none",
      opacity: "0",
      display: "none",
    },
  },
  "&.notExpaned": {
    width: "69px",
    "& .header-text": {
      animation: "hidden .2s linear forwards",
    },
    "& .sidebar-header": {
      justifyContent: "center",
    },
    "& .sidebar-list": {
      "& .sidebar-button": {
        "& p": {
          animation: "hidden .2s linear forwards",
        },
      },
    },
  },
  "& .sidebar-header": {
    padding: "24px 16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    borderBottom: `1px solid ${variableStyles.NaturalColor300}`,
    position: "relative",
    "& .header-text": {
      color: variableStyles.NaturalColor950,
      fontWeight: variableStyles.fwSemiBold,
      fontSize: "18px",
      lineHeight: "20px",
      minWidth: "max-content",
      transition: "all 0.2s linear",
      position: "absolute",
      left: "16px",
    },
    "& .header-button": {
      backgroundColor: "transparent",
      "& img": {
        pointerEvents: "none",
      },
    },
  },
  "& .sidebar-list": {
    padding: "12px",
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    "& .sidebar-button": {
      backgroundColor: "transparent",
      display: "flex",
      alignItems: "center",
      gap: "10px",
      padding: "12px",
      borderRadius: "8px",
      width: "100%",
      justifyContent: "flex-start",
      border: `1px solid transparent`,
      transition: "all 0.2s linear",
      fontFamily: variableStyles.Inter,
      "&.active": {
        backgroundColor: variableStyles.GreenPrimaryColor50,
        border: `1px solid ${variableStyles.GreenPrimaryColor100}`,
        "& .image-box": {
          "& .box-default": {
            zIndex: 0,
            transform: "scaleX(0)",
          },
          "& .box-active": {
            zIndex: 1,
            transform: "scaleX(1)",
          },
        },
        "& p": {
          color: variableStyles.GreenPrimaryColor500,
          fontWeight: variableStyles.fwSemiBold,
        },
      },
      "&:hover": {
        backgroundColor: variableStyles.GreenPrimaryColor50,
      },
      "& p": {
        color: variableStyles.NaturalColor950,
        fontWeight: variableStyles.fwRegular,
        fontSize: "14px",
        lineHeight: "18px",
        transition: "all 0.2s linear",
        position: "relative",
        minWidth: "max-content",
      },
      "& .image-box": {
        width: "18px",
        height: "18px",
        position: "relative",
        minWidth: "18px",
        pointerEvents: "none",
        "& img": {
          position: "absolute",
          top: "0",
          left: "0",
          transition: "all 0.2s linear",
          "&.box-default": {
            zIndex: 1,
            transform: "scaleX(1)",
          },
          "&.box-active": {
            zIndex: 0,
            transform: "scaleX(0)",
          },
        },
      },
    },
  },
}));
