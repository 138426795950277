import React from "react";
import { IValue } from "../../models/common/models.type";
import { TabsCustomWrapper } from "./styles";

interface TabsCustomProps {
  data: IValue[];
  onChange: (value: any) => void;
  selectedValue: any;
}

export default function TabsCustom(props: TabsCustomProps) {
  const { data, onChange, selectedValue } = props;
  return (
    <TabsCustomWrapper>
      {data.map(tab => (
        <li
          key={tab.value}
          className={`${selectedValue === tab.value ? "active" : ""}`}
        >
          <button className="tab-button" onClick={() => onChange(tab.value)}>
            <p className="fit-one-line">{tab.title}</p>
          </button>
        </li>
      ))}
    </TabsCustomWrapper>
  );
}
