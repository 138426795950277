// ----------------------------------------------------------------------

import variableStyles from "../variable-styles";

export default function Typography(theme: any) {
  return {
    MuiTypography: {
      styleOverrides: {
        h1: {
          color: `${variableStyles.NaturalColor1000} !important`,
          fontWeight: variableStyles.fwBold,
          fontSize: "32px !important",
          lineHeight: "60px !important",
        },
        h2: {
          color: variableStyles.GreenPrimaryColor600,
          fontWeight: variableStyles.fwSemiBold,
          fontSize: "32px !important",
          lineHeight: "38.73px !important",
        },
        h3: {
          color: variableStyles.blueSecondary800,
          fontWeight: variableStyles.fwSemiBold,
          fontSize: "24px !important",
          lineHeight: "29.05px !important",
        },
        h4: {
          color: variableStyles.NaturalColor1000,
          fontWeight: variableStyles.fwSemiBold,
          fontSize: "18px !important",
          lineHeight: "21.78px !important",
        },
        h5: {
          color: variableStyles.NaturalColor1000,
          fontWeight: variableStyles.fwBold,
          fontSize: "32px",
          lineHeight: "60px",
        },
        h6: {
          color: variableStyles.NaturalColor1000,
          fontWeight: variableStyles.fwBold,
          fontSize: "32px",
          lineHeight: "60px",
        },
        body1: {
          color: variableStyles.NaturalColor1000,
          fontWeight: variableStyles.fwBold,
          fontSize: "32px",
          lineHeight: "60px",
        },
        body2: {
          color: variableStyles.NaturalColor1000,
          fontWeight: variableStyles.fwBold,
          fontSize: "32px",
          lineHeight: "60px",
        },
        caption: {
          color: variableStyles.NaturalColor1000,
          fontWeight: variableStyles.fwRegular,
          fontSize: "12px !important",
          lineHeight: "18px !important",
        },
        subtitle2: {
          color: variableStyles.Info800,
          fontWeight: variableStyles.fwSemiBold,
          fontSize: "16px",
          lineHeight: "24px",
        },
      },
    },
  };
}
