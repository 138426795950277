import { styled } from "@mui/material/styles";
import variableStyles from "../../theme/variable-styles";

export const NotFoundWrapper = styled("div")(() => ({
  "& .notfound-content": {
    height: "calc(100vh - 52px)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    "& .notfound-image": {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      pointerEvents: "none"
    },
    "& .notfound-title": {
      color: variableStyles.NaturalColor1000,
      fontWeight: variableStyles.fwBold,
      fontSize: "48px",
      lineHeight: "57.6px"
    },
    "& .notfound-description": {
      color: variableStyles.NaturalColor700,
      fontWeight: variableStyles.fwRegular,
      fontSize: "16px",
      lineHeight: "22.4px",
      width: "354px",
      textAlign: "center"
    }
  }
}));
