import React, { useEffect, useState } from "react";
import { CheckBoxCustom } from "../..";
import { IValue } from "../../../models/common/models.type";

interface CheckBoxGroupCustomProps {
  options: IValue[];
  onChange: (value: string[]) => void;
  checkedData: string[];
  className?: string;
}

export default function CheckBoxGroupCustom(props: CheckBoxGroupCustomProps) {
  const { options, onChange, checkedData, className = "" } = props;

  const handleChange = (value: string) => {
    const valueIndex = checkedData.findIndex(
      data => data.toString() === value.toString()
    );
    const newChecked = [...checkedData];

    if (valueIndex > -1) {
      newChecked.splice(valueIndex, 1);
    } else {
      newChecked.push(value.toString());
    }
    onChange(newChecked);
  };

  return (
    <ul className={className}>
      {options.map(option => (
        <li key={option.value}>
          <CheckBoxCustom
            onChange={handleChange}
            label={option.title}
            value={option.value}
            checked={checkedData.includes(option.value.toString())}
          />
        </li>
      ))}
    </ul>
  );
}
