import { styled } from "@mui/material/styles";
import { DateRangePicker } from "react-date-range";
import variableStyles from "../../../theme/variable-styles";
import IconArrow from "../../../assets/images/common/icon_arrow_right_line_black.svg";

export const DateRangePickerWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  gap: "16px",
  padding: "20px",
  "& .clear-date-button": {
    color: variableStyles.blueSecondary700,
    fontFamily: variableStyles.Inter,
    fontWeight: variableStyles.fwSemiBold,
    fontSize: "12px",
    lineHeight: "12px",
    textDecoration: "underline",
    width: "max-content",
    backgroundColor: "transparent"
  },
  "& .input-date-wrapper": {
    display: "flex",
    gap: "28px",
    alignItems: "center",
    position: "relative",
    "& .date-picker": {
      "& .MuiInputBase-root": {
        padding: "0"
      },
      "& input.MuiInputBase-input": {
        padding: "13px 12px",
        color: variableStyles.NaturalColor950,
        fontWeight: variableStyles.fwMedium,
        fontSize: "14px",
        lineHeight: "19.6px",
        "&::placeholder": {
          color: variableStyles.NaturalColor500,
          fontWeight: variableStyles.fwSemiBold,
          fontSize: "14px",
          lineHeight: "19.6px"
        }
      },
      "& fieldset": {
        border: `1px solid ${variableStyles.NaturalColor300}`
      }
    },
    "& .input-arrow": {
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)"
    }
  },
  "& .picker-action": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    gap: "12px",
    paddingTop: "16px",
    borderTop: `1px solid ${variableStyles.NaturalColor200}`
  }
}));

export const DateRangePickerBox = styled(DateRangePicker)(({ theme }) => ({
  "& .rdrMonthAndYearWrapper": {
    paddingTop: "0",
    marginTop: "8px",
    height: "40px"
  },
  ".rdrDefinedRangesWrapper": {
    display: "none !important"
  },
  ".rdrDateDisplayWrapper": {
    backgroundColor: "#fff",
    "& .rdrDateDisplay": {
      margin: "0"
    }
  },
  ".rdrDateInput": {
    borderColor: variableStyles.NaturalColor200,
    boxShadow: "none",
    borderRadius: "8px",
    "& input": {
      height: 40,
      fontFamily: variableStyles.Inter
    }
  },
  "& .rdrMonth": {
    width: "280px",
    padding: "0"
  },
  ".rdrMonthPicker > *, .rdrYearPicker > *": {
    fontFamily: variableStyles.Inter
  },
  ".rdrPprevButton": {
    "& i": {
      backgroundImage: `url(${IconArrow})`,
      transform: "rotate(180deg)"
    }
  },
  ".rdrNextButton": {
    "& i": {
      margin: "0",
      backgroundImage: `url(${IconArrow})`,
      transform: "none"
    }
  },
  ".rdrNextPrevButton": {
    margin: "0",
    width: "40px",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: `1px solid ${variableStyles.NaturalColor200}`,
    backgroundColor: " #fff",
    borderRadius: "8px",
    "& i": {
      borderWidth: "0 !important",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "contain",
      width: "18px",
      height: "18px"
    }
  },
  ".rdrDay": {
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .rdrDayNumber": {
      width: "36px",
      height: "36px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& span": {
        color: variableStyles.NaturalColor600,
        fontFamily: variableStyles.Inter,
        fontWeight: 600,
        fontSize: "12px",
        lineHeight: "16.8px"
      },
      top: "unset",
      left: "unset",
      bottom: "unset",
      right: "unset",
      borderRadius: "12px"
    },
    "& .rdrStartEdge, .rdrInRange, .rdrEndEdge": {
      width: "36px",
      height: "36px",
      top: "unset",
      left: "unset",
      bottom: "unset",
      right: "unset",
      borderRadius: "12px",
      backgroundColor: variableStyles.GreenPrimaryColor100
    },
    "& .rdrStartEdge, .rdrEndEdge": {
      backgroundColor: variableStyles.GreenPrimaryColor500
    },
    "& .rdrStartEdge ~ .rdrDayNumber, .rdrEndEdge ~ .rdrDayNumber ": {
      "& span": {
        color: `${variableStyles.NaturalColor0} !important`
      }
    },
    "&  .rdrInRange ~ .rdrDayNumber": {
      "& span": {
        color: `${variableStyles.NaturalColor800} !important`
      }
    }
  },
  "& .rdrDayEndPreview": {
    borderTopRightRadius: "12px !important",
    borderBottomRightRadius: "12px !important",
    right: "0px !important"
  },
  "& .rdrDayStartPreview": {
    borderTopLeftRadius: "12px !important",
    borderBottomLeftRadius: "12px !important",
    left: "2px !important"
  },
  "& .rdrDayEndOfWeek .rdrDayInPreview": {
    borderTopRightRadius: "12px !important",
    borderBottomRightRadius: "12px !important",
    right: "2px !important"
  },
  "& .rdrDayEndOfWeek .rdrDayStartPreview": {
    borderTopRightRadius: "12px !important",
    borderBottomRightRadius: "12px !important",
    left: "2px !important",
    right: "2px !important"
  },
  "& .rdrDayEndOfWeek .rdrDayStartPreview.rdrDayEndPreview": {
    left: "2px !important"
  },
  "& .rdrDayStartOfWeek .rdrDayInPreview": {
    borderTopLeftRadius: "12px !important",
    borderBottomLeftRadius: "12px !important",
    left: "2px !important"
  },
  "& .rdrDayStartOfWeek .rdrDayEndPreview": {
    borderTopLeftRadius: "12px !important",
    borderBottomLeftRadius: "12px !important",
    right: "0px !important",
    left: "2px !important"
  },
  "& .rdrDayStartOfWeek .rdrDayStartPreview": {
    left: "2px !important"
  },
  "& .rdrDay  .rdrDayStartPreview.rdrDayEndPreview": {
    borderTopLeftRadius: "12px !important",
    borderBottomLeftRadius: "12px !important",
    left: "2px !important",
    right: "2px !important"
  },
  "& .rdrDay .rdrDayEndPreview": {
    right: "2px !important"
  },
  "& .rdrDayStartPreview,& .rdrDayEndPreview, & .rdrDayInPreview": {
    height: "36px",
    top: "2px"
  }
}));
