import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
// MUI
import { Button, Divider } from "@mui/material";
// models
// redux
import InputPassword from "../../components/controls/InputPassword";
import InputForm from "../../components/controls/InputForm";
import { RegisterWrapper } from "./styles";
import { ImageCustom } from "../../components";
import LogoIcon from "../../assets/images/login/login_logo_img_line_green.png";
import EmailIcon from "../../assets/images/login/login_icon_email_line_green.svg";
import UserIcon from "../../assets/images/common/icon_user_line_green.svg";
import PasswordIcon from "../../assets/images/login/login_icon_password_line_green.svg";
import Banner from "../../assets/images/login/login_img_banner.png";
import { IRegisterReq } from "../../models/user";
import UserService from "../../services/api/user.service";
import { Roles } from "../../models/common/models.enum";
import { ROUTE_PATH } from "../../common/constants/app.constant";
import { toggleMessage } from "../../components/Toast/Toast";
import { toggleConfirmModal } from "../../components/ConfirmModal/ConfirmModal";
import { useState } from "react";

const Register = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const regexEmail =
    /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|([0-9]{10})+$/g;

  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required(`${"Vui lòng nhập tên đầy đủ của bạn!"}`),
    email: Yup.string()
      .required(`${"Vui lòng nhập email!"}`)
      .matches(regexEmail, {
        message: "Vui lòng nhập đúng email!",
      }),
    password: Yup.string().required(`${"Vui lòng nhập mật khẩu!"}`),
  }) as any;

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IRegisterReq>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: {
      fullName: null,
      email: null,
      password: null,
    },
    resolver: yupResolver(validationSchema),
  });

  const asyncFuncLogin: () => Promise<void> = async () => {
    navigate(ROUTE_PATH.LOGIN);
  };

  const onSubmit = async (value: IRegisterReq) => {
    const params: IRegisterReq = { ...value, role: Roles.Customer };
    const res = await UserService.createUser(params);
    if (!res.isError && !!res.data) {
      toggleConfirmModal({
        open: true,
        title: `Thành công`,
        content: `Bạn đã tạo thành công tài khoản`,
        onSubmit: () => asyncFuncLogin(),
        submitText: "Đăng nhập",
        type: "confirm",
        isNotCancel: true,
      });
      setIsLoading(false);
    } else {
      setIsLoading(false);
      toggleMessage({
        type: "error",
        title: "Không thành công",
        message: "Tạo tài khoản không thành công!",
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>{"Register"}</title>
      </Helmet>
      <RegisterWrapper>
        <div className="login-box-left">
          <Link to="/" className="left-logo">
            <ImageCustom
              src={LogoIcon}
              alt="LOGIN_LOGO"
              width={"246px"}
              height={"36px"}
            />
          </Link>
          <h2>Đăng kí</h2>
          <form onSubmit={handleSubmit(onSubmit)}>
            <InputForm
              placeholder="Họ và tên"
              required
              name="fullName"
              errors={errors}
              control={control}
              startIcon={UserIcon}
              size="medium"
            />
            <Divider className=""></Divider>
            <InputForm
              placeholder="Email"
              required
              name="email"
              errors={errors}
              control={control}
              startIcon={EmailIcon}
              size="medium"
            />
            <InputPassword
              placeholder="Mật khẩu"
              name="password"
              errors={errors}
              control={control}
              startIcon={PasswordIcon}
            />
            <Button
              color="primary"
              fullWidth
              size="medium"
              type="submit"
              variant="contained"
              onClick={() => {
                setIsLoading(true);
                handleSubmit(onSubmit)();
              }}
              disabled={isLoading}
              className="button-submit"
            >
              {isLoading && <span className="loader"></span>}
              <p className={`text-submit ${isLoading ? "is-loading" : ""}`}>
                Đăng kí
              </p>
            </Button>
          </form>
          <div className="sign-up-question">
            <p>Bạn đã có tài khoản?</p>
            <Button
              size="large"
              variant="text"
              onClick={() => {
                navigate(ROUTE_PATH.LOGIN);
              }}
              disabled={isLoading}
            >
              Đăng nhập
            </Button>
          </div>
        </div>
        <div className="login-box-right">
          <p className="right-text">
            We’re here to Increase
            <br />
            your Productivity
          </p>
          <ImageCustom
            src={Banner}
            alt="LOGIN_BANNER"
            width={"726px"}
            height={"479px"}
            className="right-banner"
          />
        </div>
      </RegisterWrapper>
    </>
  );
};

export default Register;
