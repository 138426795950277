import React, { useState } from "react";
import { ImageCustom } from "..";
import { IValue } from "../../models/common/models.type";
import { FastFilterListPopoverWrapper, FastFilterListWrapper } from "./style";
import IconThreeDot from "../../assets/images/common/icon_threedot_grey.svg";
interface FastFilterListProps {
  data: IValue[];
  onChange: (value: string | null) => void;
  selectedValue: string;
  disabled?: boolean;
  isClearable?: boolean;
  hasShowMore?: boolean;
  numberOfItemShowed?: number;
  className?: string;
}

export default function FastFilterList(props: FastFilterListProps) {
  const {
    data,
    onChange,
    selectedValue,
    disabled = false,
    isClearable = false,
    hasShowMore = false,
    numberOfItemShowed = 0,
    className = "",
  } = props;

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [dataState, setDataState] = useState<IValue[]>(data);
  const [tempSelected, setTempSelected] = useState<string | null>(null);
  const [tempDataState, setTempDataState] = useState<IValue[]>(data);

  const handleSortData = (value: string) => {
    if (value === selectedValue && isClearable) {
      setTempSelected(null);
    } else if (value !== selectedValue) {
      const newData: IValue[] = [...data]
        .filter(f => f.value !== "all")
        .sort(function (x, y) {
          return x.value == value ? -1 : y.value == value ? 1 : 0;
        });
      if (data[0].value === "all") {
        newData.unshift(data[0]);
      }
      setTempDataState(newData);
      setTempSelected(value);
    }
  };

  const onClose = () => {
    setAnchorEl(null);
    onChange(tempSelected);
    setDataState(tempDataState);
  };

  return (
    <>
      <FastFilterListPopoverWrapper
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <ul className="popover-list">
          {dataState.map(item => (
            <li
              key={item.value}
              className={`${selectedValue === item.value || tempSelected === item.value ? "active" : ""}`}
            >
              <button
                onClick={() => {
                  handleSortData(item.value);
                }}
              >
                {!!item.icon && (
                  <ImageCustom
                    src={item.icon}
                    alt="Select-Icon"
                    width={"20px"}
                    height={"20px"}
                    className={`icon-select`}
                  />
                )}
                {item.title}
              </button>
            </li>
          ))}
        </ul>
      </FastFilterListPopoverWrapper>
      <FastFilterListWrapper
        className={`${disabled ? "disabled" : ""} ${className}`}
      >
        {dataState
          .filter((dataItem, idx) => {
            if (numberOfItemShowed <= 0) {
              return dataItem;
            } else {
              if (idx < numberOfItemShowed) {
                return dataItem;
              }
            }
          })
          .map(item => (
            <li
              key={item.value}
              className={`${selectedValue === item.value ? "active" : ""}`}
            >
              <button
                onClick={() => {
                  if (item.value === selectedValue && isClearable) {
                    onChange(null);
                  } else {
                    onChange(item.value);
                  }
                }}
              >
                {!!item.icon && (
                  <ImageCustom
                    src={item.icon}
                    alt="Select-Icon"
                    width={"20px"}
                    height={"20px"}
                    className={`icon-select`}
                  />
                )}
                {item.title}
              </button>
            </li>
          ))}
        {!!hasShowMore && (
          <li className={`show-more`}>
            <button
              onClick={event => {
                setAnchorEl(event.currentTarget);
              }}
            >
              <ImageCustom
                src={IconThreeDot}
                alt="IconThreeDot"
                width={"20px"}
                height={"20px"}
                className={`icon-three-dot`}
              />
            </button>
          </li>
        )}
      </FastFilterListWrapper>
    </>
  );
}
