import { styled } from "@mui/material/styles";
import variableStyles from "../../../../../../../../../theme/variable-styles";

export const BackLinkChartWrapper = styled("div")(() => ({
  border: `1px solid ${variableStyles.NaturalColor200}`,
  boxShadow:
    "0px 39px 67.8px 0px #1956580A,0px 21px 37px -15px #1956580F,0px 1px 1px 0px #00000014",
  borderRadius: "6px",
  backgroundColor: variableStyles.NaturalColor0,
  "& .header-title": {
    padding: "24px 24px 0",
  },
  "& .header-recommendAction": {
    padding: "0.5ch 24px 24px",
    color: `${variableStyles.NaturalColor950} !important`,
    "& span": {
      fontWeight: variableStyles.fwSemiBold,
    },
  },
  "& .chart-wrapper": {
    borderTop: `1px solid ${variableStyles.NaturalColor200}`,
    padding: "24px 10px 24px 0px",
  },
}));
