import React from "react";
import { useSelector } from "react-redux";
import {
  ReportFooter,
  ReportHeader,
  ReportPageHeader,
} from "../../../../components";
import { IStateApp } from "../../../../models/redux";
import { IGetReportsRes } from "../../../../models/report";
import SEOSection from "../../components/OverviewSection/SEOSection";
import { DomainAuditSEODetailWrapper } from "./styles";

export default function DomainAuditSEODetail() {
  const dataReport = useSelector<IStateApp, IGetReportsRes>(
    appState => appState.project?.dataReport
  );
  return (
    <DomainAuditSEODetailWrapper>
      <ReportPageHeader className="report-header" title="Domain Audit SEO" />
      <div className="report-wrapper scrollbar">
        <ReportHeader
          title="Báo Cáo Đánh Giá SEO"
          description="Tối ưu hóa SEO là chìa khóa để nâng cao thứ hạng trên công cụ tìm kiếm. Thực hiện các cải tiến SEO website ngay hôm nay để tăng cường khả năng tiếp cận và thu hút khách hàng tiềm năng."
          brandName={dataReport.projectName}
          domain={dataReport.target}
          height={"calc(100vh - 168px)"}
        />
        <SEOSection />
        <ReportFooter
          conclusion={
            <ul
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <li>
                Những phân tích trong báo cáo này sẽ là bước khởi đầu giúp
                website của bạn tối ưu hóa hiệu quả SEO, cải thiện thứ hạng tìm
                kiếm và thu hút lượng truy cập chất lượng. Đừng bỏ lỡ cơ hội
                nâng cao sức mạnh kinh doanh trực tuyến – hãy hành động ngay hôm
                nay!
              </li>
              <li>
                Nếu bạn cần hỗ trợ tối ưu SEO hoặc nâng cấp website, chúng tôi
                luôn sẵn sàng đồng hành cùng bạn. Hãy liên hệ để biến mục tiêu
                tăng trưởng của bạn thành hiện thực!"
              </li>
            </ul>
          }
          height={"calc(100vh - 168px)"}
          title="Sẵn sàng bứt phá thứ hạng!"
        />
      </div>
    </DomainAuditSEODetailWrapper>
  );
}
