import React from "react";

interface IProps {
  fill?: string;
}

const SuccessIcon: React.FC<IProps> = ({ fill = "#22C55E" }: IProps) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.1654 12.0002C24.1654 18.4435 18.942 23.6668 12.4987 23.6668C6.05538 23.6668 0.832031 18.4435 0.832031 12.0002C0.832031 5.55684 6.05538 0.333496 12.4987 0.333496C18.942 0.333496 24.1654 5.55684 24.1654 12.0002ZM17.2007 8.46478C17.5425 8.80649 17.5425 9.36051 17.2007 9.70221L11.3674 15.5355C11.0257 15.8773 10.4717 15.8773 10.13 15.5355L7.79665 13.2022C7.45494 12.8605 7.45494 12.3065 7.79665 11.9648C8.13835 11.6231 8.69237 11.6231 9.03408 11.9648L10.7487 13.6794L13.356 11.0721L15.9633 8.46478C16.305 8.12307 16.859 8.12307 17.2007 8.46478Z"
      fill={fill}
    />
  </svg>
);

export default SuccessIcon;
