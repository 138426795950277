import { Box, SxProps, Theme, Typography } from "@mui/material";
import ProgressHalfCircle from "../ProgressHalfCircle";
import DotIcon from "../../../../../../../../../components/DotIcon";
import PerformanceScreenshot from "../PerformanceScreenshot";
import { EStrategy } from "../../../ScreenshotOnDevice";
import MetricsItem from "../MetricsItem";
import MarkdownRenderer from "../../../../../../../../../components/MarkdownRenderer";
import {
  AuditRef,
  Audits,
  CategoriesItem,
  CategoryGroups,
  FullPageScreenshot,
  LighthouseResultI18n,
  StackPacks,
} from "../../../../../../../../../models/google/google.page.speed";
import { useState } from "react";
import { getPassedAuditIds } from "../..";
import OtherGroupAudit from "../OtherGroupAudit";
import GroupAudit from "../GroupAudit";
import ScreenshotWithNodes from "../ScreenshotThumbnails";
import { EAuditErrorStatus } from "../../../../../../../../../models/common/models.enum";

interface IProps {
  data: CategoriesItem;
  fullPageScreenshot: FullPageScreenshot;
  audits: Audits;
  stackPacks: StackPacks[];
  i18n: LighthouseResultI18n;
  categoryGroups: CategoryGroups;
  strategy: EStrategy;
  sx?: SxProps<Theme>;
}

export const getAuditMetricsIds = (auditRefs?: AuditRef[]) => {
  if (!auditRefs) {
    return [];
  }
  return auditRefs.filter(f => (f.group ? f.group === "metrics" : false));
};

export const getAuditDiagnosticsIds = (auditRefs?: AuditRef[]) => {
  if (!auditRefs) {
    return [];
  }
  return auditRefs.filter(f => (f.group ? f.group === "diagnostics" : false));
};

const PerformanceSection = ({
  data,
  sx,
  fullPageScreenshot,
  stackPacks,
  audits,
  i18n,
  categoryGroups,
  strategy,
}: IProps) => {
  const [show, setShow] = useState<boolean>(false);
  const score = data?.score ?? 0;

  const metrics = getAuditMetricsIds(data?.auditRefs);
  const diagnostics = getAuditDiagnosticsIds(data?.auditRefs);
  const passedAudit = getPassedAuditIds(data?.auditRefs);

  const onShow = () => setShow(pre => !pre);

  const metricsTitle = categoryGroups["metrics"].title;
  const diagnosticsTitle = categoryGroups["diagnostics"].title;
  const viewTreemapLabel = ""; //i18n.rendererFormattedStrings.viewTreemapLabel;

  const pointRangeData = [
    { status: EAuditErrorStatus.Error, text: "0-49" },
    { status: EAuditErrorStatus.Warning, text: "50-89" },
    { status: EAuditErrorStatus.Success, text: "90-100" },
  ];

  return (
    <Box className="performance-section" component={"section"} sx={sx}>
      <div className="performance-wrapper">
        <div className="performance-left-content">
          <ProgressHalfCircle
            value={score}
            lable={data?.title ?? ""}
            size="large"
          />
          {data?.description ? (
            <p className="description">
              <MarkdownRenderer content={data?.description ?? ""} />
              <MarkdownRenderer content={data?.manualDescription ?? ""} />
            </p>
          ) : (
            <p className="description">
              Các giá trị chỉ là ước tính và có thể thay đổi.{" "}
              <a
                href="https://developer.chrome.com/docs/lighthouse/performance/performance-scoring?utm_source=lighthouse&utm_medium=devtools&hl=vi"
                target="_blank"
                rel="noopener noreferrer"
              >
                Điểm hiệu quả được tính
              </a>{" "}
              trực tiếp từ những chỉ số này.{" "}
              <a
                href="https://googlechrome.github.io/lighthouse/scorecalc/#FCP=645&LCP=1072&TBT=67&CLS=0&SI=1047&TTI=1940&device=desktop&version=12.2.0"
                target="_blank"
                rel="noopener noreferrer"
              >
                Xem máy tính.
              </a>
            </p>
          )}

          <div className="point-wrapper flex-center">
            {pointRangeData.map(point => (
              <div
                className="point-range-item flex-between-align-center"
                key={point.text}
              >
                <DotIcon status={point.status} />
                <p className="point-range-number">{point.text}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="performance-right-content">
          <PerformanceScreenshot
            strategy={strategy}
            screenshot={fullPageScreenshot.screenshot.data}
          />
        </div>
      </div>
      {/* overview performances */}
      {/* Metrics  */}
      <Typography variant="subtitle2" sx={{ mt: "24px" }}>
        {metricsTitle}
      </Typography>
      <ul className="metrics-list-wrapper">
        {metrics.map(item => {
          const audit = audits ? audits[item.id] : null;
          return (
            <li key={item.id}>
              <MetricsItem
                acronym={item.acronym}
                audit={audit}
                weight={item.weight}
              />
            </li>
          );
        })}
      </ul>
      {/* Metrics  */}
      {/* View treemap */}
      <Box className="performance-treemap-wrapper">
        <Typography variant="subtitle2" sx={{ mt: "24px", mb: 1 }}>
          {viewTreemapLabel}
        </Typography>

        <ScreenshotWithNodes
          data={audits["screenshot-thumbnails"]}
          isMobile={strategy === EStrategy.mobile}
        />
      </Box>
      {/* View treemap */}
      {/* Diagnostics */}
      <GroupAudit
        groupTitle={diagnosticsTitle}
        data={diagnostics}
        audits={audits}
        stackPacks={stackPacks}
        isDiagnostics
        filterTitle={i18n.rendererFormattedStrings.showRelevantAudits}
      />
      {/* Diagnostics */}

      <OtherGroupAudit
        groupTitle={
          i18n.rendererFormattedStrings.passedAuditsGroupTitle ??
          "Passed audits"
        }
        onShow={onShow}
        isShow={show}
        data={passedAudit}
        audits={audits}
        stackPacks={stackPacks}
      />
    </Box>
  );
};

export default PerformanceSection;
