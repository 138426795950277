import EN from "./locales/en";
import VI from "./locales/vn";
import i18next from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";

export interface ILang {
  FilterDate: {
    Default: string;
    Daily: string;
    Weekly: string;
    Monthly: string;
    ViewBy: string;
    ErrorSelectDateMessage: string;
  };
  Lang: {
    Code: string;
    TextEN: string;
    TextVN: string;
  };
  Login: {
    ErrorEmail: string;
    LoginDescription: string;
    ButtonText: string;
  };
  Sidebar: {
    Overview: string;
    Manage: string;
    Account: string;
    Dashboard: string;
    ManageUser: string;
    ManageCashflow: string;
    RecordingBalance: string;
    AccountName: string;
    BankAccount: string;
    ManageExchangeRate: string;
    Transaction: string;
    Setting: string;
    Notification: string;
    FAQ: string;
    Cashflow: string;
    ManageAdminUser: string;
    ManagePartnerUser: string;
  };
  Modal: {
    CancelText: string;
    SubmitText: string;
  };
  Setting: {
    AccountInformation: string;
    TimeRegister: string;
    ModalTitle: string;
    ModalOldPassword: string;
    ModalPassword: string;
    ModalOldPasswordPlaceholder: string;
    ModalPasswordPlaceholder: string;
    ModalConfirmPassword: string;
    UpdateSuccess: string;
    UpdateFail: string;
  };
  Role: {
    Owner: string;
    Admin: string;
    ViewReport: string;
  };
  Common: {
    InsertNew: string;
    Edit: string;
    Update: string;
    Delete: string;
    Status: string;
    DateCreated: string;
    RowPerPage: string;
    Of: string;
    More: string;
    Code: string;
    Name: string;
    NameValidate: string;
    Description: string;
    Title: string;
    Content: string;
    Price: string;
    DiscountPrice: string;
    Type: string;
    Prominent: string;
    StartDate: string;
    EndDate: string;
    Quantity: string;
    Product: string;
    Customer: string;
    Detail: string;
    Cancel: string;
    Confirm: string;
    Inflow: string;
    Outflow: string;
    Active: string;
    Deactive: string;
    BankNumber: string;
    Currency: string;
    ApiError: string;
    Amount: string;
    AmountPlaceholder: string;
    Search: string;
    Role: string;
    Date: string;
    FirstName: string;
    LastName: string;
    Password: string;
    Logout: string;
    PasswordValidate: string;
    PasswordOldValidate: string;
    PasswordConfirmValidate: string;
    ResetPassword: string;
    UpdateCashflow: string;
    AddMore: string;
    TimeDefault: string;
    AllAccountBank: string;
    CompanyName: string;
    PhoneNumber: string;
    QuantityMember: string;
    Limit: string;
    PercentComplete: string;
    Close: string;
  };
}

export enum CountryLanguage {
  "EN" = "EN",
  "VI" = "VI"
}

const locale = localStorage.getItem("language");

i18next.use(initReactI18next).init({
  compatibilityJSON: "v3",
  fallbackLng: [locale ? locale.toUpperCase() : `${CountryLanguage.EN}`],
  debug: false,
  resources: {
    EN: {
      translation: EN
    },
    VI: {
      translation: VI
    }
  }
});

const useLanguage = () => {
  const { t } = useTranslation();
  return t;
};

const changeLanguage = (value: CountryLanguage) => {
  Lang.changeLanguage(value);
  localStorage.setItem("language", value);
};

const useI18n = () => {
  const { i18n } = useTranslation();
  return i18n;
};
export { useLanguage, useI18n, changeLanguage };

const Lang = i18next;

export default Lang;
