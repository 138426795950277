import { styled } from "@mui/material/styles";
import variableStyles from "../../../theme/variable-styles";

export const NoSearchDataBoxWrapper = styled("div")(() => ({
  width: "100%",
  height: "calc(100% - 64px)",
  position: "relative",
  "& .outline-nodata": {
    position: "absolute",
    top: "84px",
    left: "50%",
    transform: "translateX(-50%)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    width: "352px",
    "& .nodata-icon-wrapper": {
      backgroundColor: variableStyles.blueSecondary100,
      padding: "8px",
      borderRadius: "50%",
      border: `8px solid ${variableStyles.blueSecondary50}`,
      marginBottom: "12px"
    },
    "& .nodata-title": {
      color: variableStyles.NaturalColor950,
      fontWeight: variableStyles.fwMedium,
      fontSize: "16px",
      lineHeight: "24px"
    },
    "& .nodata-description": {
      color: variableStyles.NaturalColor800,
      fontWeight: variableStyles.fwRegular,
      fontSize: "14px",
      lineHeight: "20px",
      marginTop: "4px"
    },
    "& .no-data-action": {
      display: "flex",
      gap: "12px",
      width: "100%",
      marginTop: "24px",
      "& button": {
        width: "100%"
      }
    }
  }
}));
